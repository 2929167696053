import React from "react";
import { motion } from "framer-motion";
import useMeasure from "react-use-measure";
import { animationCurveEaseOut, animationDurationFastS } from "@constants/animations";

interface ResizeBlockProps {
  children: React.ReactNode;
}

export const ResizeBlock = ({ children }: ResizeBlockProps) => {
  const [ref, { height }] = useMeasure();

  return (
    <motion.div
      animate={{ height }}
      transition={{ ease: animationCurveEaseOut, duration: animationDurationFastS }}
      style={{ overflow: "hidden" }}
    >
      <div ref={ref}>{children}</div>
    </motion.div>
  );
};
