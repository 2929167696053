import React, { Reducer, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { Bridge } from "@modules/bridge/Bridge";
import { ICustomer, IExternalAccount } from "@modules/bridge/types";
import { useQuery } from "@tanstack/react-query";
import { useAccount } from "@contexts/AccountContext";

export enum WithdrawStep {
  SET_AMOUNT,
  ACCEPT_TOS,
  REGISTER_CUSTOMER,
  ADD_BANK_ACCOUNT,
  CONFIRM,
}

interface WithdrawState {
  isFetched: boolean;
  customer?: ICustomer;
  externalAccount?: IExternalAccount;
  step: WithdrawStep;
}

export enum WithdrawActionType {
  SET_STEP,
  SET_CUSTOMER,
  SET_EXTERNAL_ACCOUNT,
}

type WithdrawAction =
  | {
      type: WithdrawActionType.SET_STEP;
      step: WithdrawStep;
    }
  | {
      type: WithdrawActionType.SET_CUSTOMER;
      customer: ICustomer;
    }
  | {
      type: WithdrawActionType.SET_EXTERNAL_ACCOUNT;
      externalAccount: IExternalAccount;
    };

const reducer: Reducer<WithdrawState, WithdrawAction> = (state, action) => {
  switch (action.type) {
    case WithdrawActionType.SET_STEP:
      return { ...state, step: action.step };
    case WithdrawActionType.SET_CUSTOMER:
      return { ...state, customer: action.customer };
    case WithdrawActionType.SET_EXTERNAL_ACCOUNT:
      return { ...state, externalAccount: action.externalAccount };
  }
};

const defaultState: WithdrawState = {
  isFetched: false,
  step: WithdrawStep.SET_AMOUNT,
};

interface WithdrawContextState extends WithdrawState {
  bridge: Bridge;
  dispatch: React.Dispatch<WithdrawAction>;
  shouldSubmitForm: boolean;
  setShouldSubmitForm: (_: boolean) => void;
  loadingForm: boolean;
  setLoadingForm: (_: boolean) => void;
}

const defaultContextState: WithdrawContextState = {
  ...defaultState,
  bridge: null!,
  dispatch: null!,
  shouldSubmitForm: false,
  loadingForm: false,
  setShouldSubmitForm: null!,
  setLoadingForm: null!,
};
const WithdrawContext = React.createContext(defaultContextState);

export const WithdrawProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const account = useAccount();
  const [state, dispatch] = useReducer(reducer, defaultState);

  const bridge = useMemo(() => {
    if (account.address) return Bridge.init(account.address, account.signer);
  }, [account.address, account.signer]);

  const userQuery = useQuery({
    queryKey: ["bridge-user", account.address],
    queryFn: () => bridge?.getUser(),
    enabled: Boolean(account.address),
    retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    retry: true,
    retryOnMount: true,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  const customerId = bridge?.customerId;
  const customerQueryEnabled = Boolean(userQuery.isFetched && customerId);
  const { data: customer, ...customerQuery } = useQuery({
    queryKey: ["bridge-customer", customerId],
    queryFn: () => bridge?.getCustomer(customerId!),
    enabled: customerQueryEnabled,
    retryDelay: 2_000,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  const externalAccountId = bridge?.externalAccountId;
  const externalAccountQueryEnabled = Boolean(userQuery.isFetched && customerId && externalAccountId);
  const { data: externalAccount, ...externalAccountQuery } = useQuery({
    queryKey: ["bridge-external-account", customerId, externalAccountId],
    queryFn: () => bridge?.getExternalAccount(customerId!, externalAccountId!),
    enabled: externalAccountQueryEnabled,
    retryDelay: 2_000,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  useEffect(() => {
    if (customer) dispatch({ type: WithdrawActionType.SET_CUSTOMER, customer });
  }, [customer]);

  useEffect(() => {
    if (externalAccount) dispatch({ type: WithdrawActionType.SET_EXTERNAL_ACCOUNT, externalAccount });
  }, [customer]);

  const isFetched =
    userQuery.isFetched &&
    !userQuery.isError &&
    (!customerQueryEnabled || customerQuery.isFetched) &&
    (!externalAccountQueryEnabled || externalAccountQuery.isFetched);

  const [loadingForm, setLoadingForm] = useState(false);
  const [shouldSubmitForm, setShouldSubmitForm] = useState(false);

  if (!bridge) return null;

  return (
    <WithdrawContext.Provider
      value={{
        ...state,
        bridge,
        customer,
        dispatch,
        externalAccount,
        isFetched,
        shouldSubmitForm,
        setShouldSubmitForm,
        loadingForm,
        setLoadingForm,
      }}
    >
      {children}
    </WithdrawContext.Provider>
  );
};

export const useWithdrawContext = () => useContext(WithdrawContext);
