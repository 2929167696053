import { Token, USER_TOKENS, UserToken } from "@constants";

import { TokenManager } from "@modules/token-managers/TokenManager";
import { EcoManager } from "@modules/token-managers/tokens/EcoManager";
import { OakManager } from "@modules/token-managers/tokens/OakManager";
import { USDManager } from "@modules/token-managers/tokens/USDManager";
import { ECOxManager } from "@modules/token-managers/tokens/ECOxManager";

const managers: Partial<Record<UserToken, TokenManager>> = {};
export function getTokenManager(token: UserToken): TokenManager {
  const storedManager = managers[token];
  if (storedManager) return storedManager;

  let manager: TokenManager;
  switch (token) {
    case UserToken.ECO:
      manager = new EcoManager();
      break;
    case UserToken.ECOx:
      manager = new ECOxManager();
      break;
    case UserToken.OAK:
      manager = new OakManager();
      break;
    case UserToken.USD:
      manager = new USDManager();
      break;
  }

  managers[token] = manager;
  return manager;
}

export function getTokenManagerByToken(token: Token) {
  for (const userToken of USER_TOKENS) {
    const manager = getTokenManager(userToken);
    if (manager.composedBy.includes(token)) return manager;
  }

  throw new Error("Token manager not found for token " + token);
}

export function isUserTypeEqual(tokenA: Token, tokenB: Token) {
  return getTokenManagerByToken(tokenA).id === getTokenManagerByToken(tokenB).id;
}
