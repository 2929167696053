import React, { useEffect, useState } from "react";
import { ethers } from "ethers";

import { UserToken } from "@constants";

import { Button } from "@components/button/Button";
import { NumberPad } from "@components/numberpad/NumberPad";

import styles from "./WithdrawNumpad.module.css";

interface WithdrawNumpadCommonProps {
  maxAmount?: ethers.BigNumberish;
  loading?: boolean;
}

interface WithdrawNumpadProps extends WithdrawNumpadCommonProps {
  fee?: ethers.BigNumberish;
  onChange(amount: ethers.BigNumber): void;
}

interface WithdrawNumpadFooterProps extends WithdrawNumpadCommonProps {
  onSubmit(): void;
  amount: ethers.BigNumber | undefined;
}

export const WithdrawNumpad: React.FC<WithdrawNumpadProps> = ({
  maxAmount,
  loading,
  fee = ethers.constants.Zero,
  onChange,
}) => {
  const [amountString, setAmount] = useState("0");
  const amount = ethers.utils.parseUnits(amountString, 6);

  useEffect(() => {
    onChange(amount);
  }, [amountString]);

  return (
    <NumberPad
      className={styles.numberpad}
      maxDecimals={2}
      value={amountString}
      setValue={setAmount}
      action="withdraw"
      token={UserToken.USD}
      keyboardDisabled={loading}
      fee={ethers.BigNumber.from(fee)}
      maxAmount={maxAmount !== undefined ? ethers.BigNumber.from(maxAmount).add(fee || "0") : undefined}
    />
  );
};

export const WithdrawNumpadFooter = ({ loading, maxAmount, amount, onSubmit }: WithdrawNumpadFooterProps) => {
  // max amount exceeded
  const aboveMaxAmount = maxAmount && amount?.gt(maxAmount);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "var(--size-small)" }}>
      <Button
        type="primary"
        title="Continue"
        loading={loading}
        disabled={!amount || aboveMaxAmount || amount?.isZero()}
        onClick={onSubmit}
      />
    </div>
  );
};
