import React from "react";
import { IncreaseSecurityCard } from "@components/account/access/IncreaseSecurityCard";
import { LoadAccountCard } from "@components/account/access/LoadAccountCard";
import { SaveAccessCard } from "@components/account/access/SaveAccessCard";
import { SavedAccountsCard } from "@components/account/access/SavedAccountsCard";
import { LoadAccountButton } from "@components/account/access/components/LoadAccountButton/LoadAccountButton";
import { SavedBadge } from "@components/account/access/components/SavedBadge/SavedBadge";
import { AdvancedSettingsCard } from "@components/account/advanced/AdvancedSettingsCard";
import { BeamnamesCard } from "@components/account/beamnames/BeamnamesCard";
import View from "@components/view/View";

import { addressToEmoji } from "@helpers";

import { Profile } from "@components/profile/Profile";
import { useAccount } from "@contexts/AccountContext";
import { useWeb3AuthState } from "@redux/slides/web3auth.slide";

import { SettingsCard } from "@components/account/settings/SettingsCard";
import { WithdrawCard } from "@components/account/withdraw/WithdrawCard";
import { LogoutButton } from "@components/account/access/components/LogoutButton/LogoutButton";

export const Account = () => {
  const { hasSavedAccess } = useWeb3AuthState();
  const { address, beamname, profilePicture } = useAccount();

  const user = beamname?.displayName ? `@${beamname.displayName}` : address ? addressToEmoji(address) : "...";

  return (
    <View headerTitle="Account" metaTitle="Account" navbarItem={<SavedBadge isSaved={hasSavedAccess} />}>
      <div
        style={{ display: "flex", flexDirection: "column", gap: "var(--size-medium)", paddingTop: "var(--size-large)" }}
      >
        <Profile
          canEdit
          name={user}
          address={address}
          isProfilePictureSet={profilePicture.exists}
          nameIsEmoji={!beamname?.displayName && !!address}
        />
        {!hasSavedAccess ? (
          <>
            <SaveAccessCard />
            <LoadAccountCard />
          </>
        ) : (
          <IncreaseSecurityCard />
        )}
        <BeamnamesCard />
        {hasSavedAccess ? <SavedAccountsCard /> : null}
        <WithdrawCard />
        <SettingsCard />
        <AdvancedSettingsCard />
        {hasSavedAccess ? (
          <div
            style={{
              display: "flex",
              gap: "var(--size-medium)",
              justifyContent: "center",
              alignItems: "center",
              color: "var(--color-foreground-medium)",
            }}
          >
            <LoadAccountButton />
            <LogoutButton />
          </div>
        ) : null}
      </div>
    </View>
  );
};
