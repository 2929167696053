import { notification } from "antd";
import { BigNumber, ethers } from "ethers";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logError } from "@helpers";

import * as Peanut from "@modules/peanut";
import { Deposit } from "@modules/peanut/types";
import { getPeanutAddress } from "@modules/peanut/constants";

import { getNetworkProvider } from "@modules/blockchain/providers";

import { useAppDispatch } from "@redux";
import { execTransferIn } from "@redux/slides/balances/balances.slide";

import { addressToEmoji, formatBigNumber, formatTokenAmount, notify, round } from "@helpers";
import { getTransaction, isSameAddress } from "@helpers/contracts";

import { PeanutV3__factory } from "@assets/contracts";
import { Button, LinkButton } from "@components/button/Button";
import Card from "@components/card/Card";
import { BodyText } from "@components/text/Text";
import { TokenIcon } from "@components/token";
import View from "@components/view/View";
import { APOLLO_CLIENTS, getTokenByAddress, getTokenInfo, INetwork, Network, Token, UserToken } from "@constants";
import { useStackup } from "@contexts/StackupContext";

import { Zero } from "@ethersproject/constants";

import AnimatedNumber from "react-awesome-animated-number";
import styles from "./Claim.module.css";
import { parseUnits } from "@helpers/token";
import { BEAMLINK_QUERY } from "@queries/BEAMLINK";
import { CheckCircleOutlined } from "@ant-design/icons";
import { getTokenManager } from "@modules/token-managers/tokens";
import { useAccount } from "@contexts/AccountContext";
import { useWeb3AuthState } from "@redux/slides/web3auth.slide";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { LoadAccountComponent } from "..";

interface ClaimContentProps {
  token: UserToken;
  links: { network: INetwork; index: number }[];
}

const TokenResolution: React.FC<{ children: (props: ClaimContentProps) => React.ReactElement | null }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { token, links } = useMemo(() => Peanut.getParamsFromLink(), [window.location.href]);

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  if (!token) return null;

  return children({ token, links });
};

export const Claim: React.FC = props => {
  return <TokenResolution>{resolutionProps => <ClaimContent {...resolutionProps} {...props} />}</TokenResolution>;
};

const ClaimContent: React.FC<ClaimContentProps> = ({ token: tokenId, links }) => {
  const token = getTokenManager(tokenId);
  const { password, rawId, contractVersion } = Peanut.getParamsFromLink();
  const { afterRedirect } = useWeb3AuthState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { address } = useStackup();
  const { beamname, inflationMultiplier } = useAccount();
  const { hasSavedAccess } = useWeb3AuthState();

  const [loading, setLoading] = useState(false);

  const [loadAccountOpen, setLoadAccountOpen] = useState(false);

  const [deposits, setDeposits] = useState<{ deposit: Deposit; link: { network: INetwork; index: number } }[]>([]);

  const totalAmount = deposits
    .filter(deposit => !deposit.deposit.claimed)
    .reduce((totalAmount, currDeposit) => totalAmount.add(currDeposit.deposit.amount), Zero);

  const totalClaimedAmount = deposits
    .filter(deposit => deposit.deposit.claimed)
    .reduce((totalAmount, currDeposit) => totalAmount.add(currDeposit.deposit.amount), Zero);

  const amount = parseUnits(totalAmount, token.decimals);
  const amountClaimed = parseUnits(totalClaimedAmount, token.decimals);

  useEffect(() => {
    const goBack = () => {
      navigate(`/`);
    };

    const getDeposits = async () => {
      if (!inflationMultiplier) {
        return;
      } else if (!password || !links.length) {
        goBack();
      } else {
        const _deposits: { deposit: Deposit; link: { network: INetwork; index: number } }[] = [];
        for (let i = 0; i < links.length; i++) {
          try {
            const provider = getNetworkProvider(links[i].network.chainId);
            const peanutV3 = PeanutV3__factory.connect(
              getPeanutAddress(links[i].network.chainId, contractVersion !== "1"),
              provider,
            );
            const depositStruct = await peanutV3.deposits(links[i].index);
            if (depositStruct) {
              const deposit: Deposit = {
                claimed: false,
                amount: depositStruct.amount,
                tokenId: depositStruct.tokenId,
                pubKey20: depositStruct.pubKey20,
                contractType: depositStruct.contractType,
                tokenAddress: depositStruct.tokenAddress,
              };

              if (deposit.tokenAddress === ethers.constants.AddressZero) {
                const { data } = await APOLLO_CLIENTS[links[i].network.network!].query({
                  query: BEAMLINK_QUERY,
                  variables: { id: links[i].index.toString() },
                });

                if (data.beamLink) {
                  const tokenId = data.beamLink.token.id as Token;
                  deposit.claimed = Boolean(data.beamLink.claimedAt);
                  deposit.amount = BigNumber.from(data.beamLink.amount);
                  deposit.contractType = tokenId === "eco" ? 5 : 1;
                  deposit.tokenAddress = getTokenInfo(tokenId)?.address;
                }
              }

              const composedTokenInfo = getTokenInfo(token.composedBy[0]);

              // divide by inflation multiplier if token is ECO and is not legacy
              if (composedTokenInfo.contractType == 5 && contractVersion === "1") {
                deposit.amount = deposit.amount.div(inflationMultiplier.value);
              }

              _deposits.push({ deposit: deposit, link: links[i] });
            }
          } catch (error) {
            logError("[get-deposit]", error);
          }
        }

        if (_deposits.length) {
          const signerWallet = Peanut.generateKeysFromString(password);
          if (_deposits.every(deposit => deposit.deposit.claimed)) {
            goBack();
            notify({
              duration: 5_000,
              content: (
                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                  <CheckCircleOutlined height={14} style={{ marginTop: -3, marginRight: 6 }} />
                  <div>This Beam Link has been claimed already</div>
                </div>
              ),
            });
          } else if (
            _deposits
              .filter(deposit => !deposit.deposit.claimed)
              .some(deposit => {
                const tokenAddresses = token.composedBy.map(
                  composedToken => getTokenInfo(composedToken, deposit.link.network.network).address,
                );
                return (
                  !isSameAddress(signerWallet.address, deposit.deposit.pubKey20) ||
                  !tokenAddresses.includes(deposit.deposit.tokenAddress)
                );
              })
          ) {
            goBack();
          } else {
            setDeposits(_deposits);
          }
        }
      }
    };

    getDeposits();
  }, [links, navigate, password, rawId, inflationMultiplier]);

  const claim = async () => {
    if (!address || !deposits.length || !password || rawId === undefined) return;
    setLoading(true);
    try {
      for (let i = 0; i < deposits.length; i++) {
        if (deposits[i].deposit.claimed) continue;

        const provider = getNetworkProvider(deposits[i].link.network.chainId);
        const response = await Peanut.sendClaimRequest({
          password,
          recipient: address,
          depositIdx: deposits[i].link.index,
          network: contractVersion === "1" ? deposits[i].link.network.network! : undefined,
        });

        const tx = await getTransaction(provider, response.receipt.transactionHash);
        if (tx.wait) await tx.wait();

        const token = getTokenByAddress(deposits[i].deposit.tokenAddress, deposits[i].link.network.network as Network);

        dispatch(
          execTransferIn({
            token,
            tx: Promise.resolve(),
            network: deposits[i].link.network.network!,
            amount: deposits[i].deposit.amount.toString(),
          }),
        );
      }

      notify({
        content: (
          <>
            You claimed {formatTokenAmount(ethers.utils.formatUnits(totalAmount, token.decimals), 3)} {token.symbol}.
          </>
        ),
        type: "success",
      });

      navigate(`/?c=${token.id}`);
    } catch (error) {
      logError("[claim]", error);
      notification.error({
        placement: "topRight",
        message: "Transfer failed",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (afterRedirect) {
      setLoadAccountOpen(true);
    }
  }, [afterRedirect]);

  const fontSize = getComputedStyle(document.documentElement)
    .getPropertyValue("--font-size-amount-entry")
    .replace("px", "");

  return (
    <View metaTitle="Claim Funds">
      <div className={styles.wrapper}>
        <div className={styles.amountWrapper}>
          <div className={styles.amountInner}>
            <div className={styles.icon}>
              <TokenIcon token={token.id} />
            </div>
            <AnimatedNumber
              size={Number(fontSize)}
              minDigits={1}
              value={round(amount, 3)}
              hasComma={amount === 0}
              duration={amount === 0 ? 0 : 800}
              className={styles.amount}
            />
          </div>

          {amountClaimed ? (
            <BodyText light small>
              {formatTokenAmount(round(amountClaimed, 2))} tokens have already been claimed.
            </BodyText>
          ) : null}
        </div>
        <Card>
          <div className={styles.contentWrapper}>
            <BodyText bold large>
              💰 Someone sent you money!
            </BodyText>
            <BodyText light>
              You&apos;ve been sent {token.symbol}. Claiming the funds will add them to your Beam account.
            </BodyText>
            <Button
              title={`Claim ${formatBigNumber(totalAmount, token.decimals, 2)} ${token.symbol} as ${
                hasSavedAccess
                  ? beamname?.exists
                    ? "@" + beamname.displayName
                    : address
                    ? addressToEmoji(address)
                    : "..."
                  : "guest"
              }`}
              data-cy="claim-btn"
              key="submit"
              type="primary"
              onClick={claim}
              loading={loading || !totalAmount.gt(0)}
              disabled={!deposits.length}
            />
            <div>
              <LinkButton
                title={hasSavedAccess ? "Switch Account" : "Log In"}
                onClick={() => setLoadAccountOpen(true)}
              />
            </div>
          </div>
        </Card>

        <BottomSheet
          title={hasSavedAccess ? "Switch Account" : "Log In"}
          isOpen={loadAccountOpen}
          onClose={() => setLoadAccountOpen(false)}
        >
          <LoadAccountComponent onComplete={() => setLoadAccountOpen(false)} />
        </BottomSheet>
      </div>
    </View>
  );
};
