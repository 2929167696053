import * as Sentry from "@sentry/react";

export const logError = (description: string, error: unknown) => {
  console.error(description, error);
  Sentry.addBreadcrumb({
    timestamp: Date.now(),
    message: description,
    level: "error",
  });
  Sentry.captureException(error);
};
