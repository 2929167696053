import seedrandom from "seedrandom";
import { addressToShortHex } from "./emojiEncode";

export const addressToInt = (address: string, max: number, min = 0) => {
  const hexadecimal = addressToShortHex(address).toLowerCase();

  if (hexadecimal.length !== 12) {
    return min;
  }

  const generator = seedrandom(hexadecimal);
  return Math.floor(generator() * max) + min;
};
