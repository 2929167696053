const preventClose = function (e: BeforeUnloadEvent) {
  e.preventDefault();
  const msg = "Transaction is in progress, do not close the app. Try again in a few seconds";
  e.returnValue = msg;
  return msg;
};

export const blockWindowClosure = {
  enable() {
    window.addEventListener("beforeunload", preventClose, true);
  },
  disable() {
    window.removeEventListener("beforeunload", preventClose, true);
  },
};
