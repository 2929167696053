import React from "react";
import { ethers } from "ethers";
import axios, { AxiosError, isAxiosError } from "axios";
import { logError } from "@helpers";

import { notify } from "@helpers/notify";
import { FAUCET_ADDRESS, FAUCET_URL, getTokenByAddress, NETWORK } from "@constants";
import { ERC20__factory, Faucet__factory } from "@assets/contracts";

import { store } from "@redux/store";
import { incrementBalance } from "@redux/slides/balances/balances.slide";

/**
 * Drips eco to an authenticated Twitter user to the address they specify, signing the payload with a RSA4096/SHA512 key.
 * The faucet on the other end uses will verify that use has not already been dripped using
 * mapping(string -> address) where string is twitter id
 *
 * @param idToken the token ID from the auth0 response from twitter
 * @param address the eth public address of the wallet the user wants drip funds in
 */
export async function dripEcoToUser(idToken: string, address: string): Promise<void> {
  //get the faucet url from the config
  const body = { idToken, address };
  const url = new URL("/api/v1/faucet/twitter", FAUCET_URL);

  try {
    //sign the body with the faucet jwt key, payload is not encrypted
    await axios.post(url.toString(), body);

    notify({
      duration: 5_000,
      content: (
        <span style={{ textAlign: "center", display: "block" }}>
          Congratulations! 🎉 Here&apos;s some ECO to get you started with Beam.
        </span>
      ),
    });

    listenForDrip(address);
  } catch (error) {
    if (isAxiosError(error) && error.code === AxiosError.ERR_BAD_REQUEST) {
      console.warn("dripEcoToUser: User is not eligible for a drip at this time");
      return;
    }

    logError("[dripEcoToUser]", error);
  }
}

export async function logSaveAccess(idToken: string, address: string) {
  const body = { idToken, address };
  const url = new URL("/api/v1/access/log", FAUCET_URL);
  try {
    await axios.post(url.toString(), body);
  } catch (error) {
    logError("[log-save-access]", error);
  }
}

const TRANSFER_TOPIC = "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef";

function listenForDrip(address: string) {
  try {
    const provider = new ethers.providers.StaticJsonRpcProvider(NETWORK.rpcUrl, NETWORK.chainId);
    const faucet = Faucet__factory.connect(FAUCET_ADDRESS, provider);

    const listener = async (_: string, event: ethers.Event) => {
      try {
        const tx = await event.getTransaction();
        const recipient = await tx.wait();
        const transferLog = recipient.logs.find(log => log.topics[0] === TRANSFER_TOPIC);
        if (!transferLog) {
          throw new Error("listenForDrip error: Could not find transfer log");
        }

        const { value } = ERC20__factory.createInterface().decodeEventLog("Transfer", transferLog.data);

        store.dispatch(
          incrementBalance({
            network: "optimism",
            amount: value.toString(),
            token: getTokenByAddress(transferLog.address, "optimism"),
          }),
        );
      } catch (error) {
        logError("listenForDrip]", error);
      }
    };

    faucet.once(faucet.filters.FaucetDripped(address), listener);
  } catch (error) {
    logError("[listenForDrip]", error);
  }
}
