import React, { useCallback, useEffect, useState } from "react";
import { BigNumber, ethers } from "ethers";
import { Zero } from "@ethersproject/constants";
import { useSelector } from "react-redux";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";

import {
  FAUCET_ADDRESS,
  FAUCET_HAPPY_HOUR_ADDRESS,
  getSupportedNetworks,
  Network,
  USER_TOKENS,
  UserToken,
  VENDING_MACHINE_ADDRESSES,
} from "@constants";

// Queries
import { Transfer } from "types/transfer";

import { addressToEmoji, formatBigNumber, notify } from "@helpers";

import { displayBeamName, fetchDisplayName } from "@hooks";
import { useBeamSubgraph } from "@hooks/useBeamSubgraph";
import { useInflationMultipliers } from "@hooks/useInflationMultiplier";

import { Merchant } from "@modules/merchant/Merchant";
import { PEANUT_ADDRESSES } from "@modules/peanut/constants";
import { ProfileClient } from "@modules/profile/ProfileClient";
import { getTokenManager } from "@modules/token-managers/tokens";
import { AutoSwap } from "@modules/blockchain/AutoSwap";
import { BalanceHandler } from "@modules/blockchain/BalanceHadler";
import { SimpleAccountFactoryMigration } from "@modules/migration/SimpleAccountFactoryMigration";

import { useAppDispatch } from "@redux";
import { getBalances, setBalances } from "@redux/slides/balances/balances.slide";
import { activateMerchant, enableMerchantMode, useMerchant } from "@redux/slides/merchant.slide";

import { useStackup } from "./StackupContext";
import { InflationMultiplier } from "../types/inflationMultiplier";

interface IAccountProvider {
  address?: string;
  tempBeamname?: string;
  loadingBalances: boolean;
  loadingAccount?: boolean;
  balanceHandlers: BalanceHandler[];
  inflationMultiplier?: InflationMultiplier;
  inflationMultipliers: InflationMultiplier[];
  beamname?: {
    name?: string;
    displayName?: string;
    tokenId?: ethers.BigNumber;
    exists: boolean;
  };
  signer: ethers.Wallet;
  allBalances: ReturnType<typeof getBalances>;
  balances: Balances;
  lastTransfer: Transfer | null;
  profilePicture: { exists: boolean; url?: string };
  excludeAddress(address: string): void;
  hasSomeBalance: boolean;
}

export type TokenBalances = {
  [key in Network]: BigNumber;
} & { total: BigNumber };

export type Balances = {
  [key in UserToken]: TokenBalances;
};

const DEFAULT_BALANCES: TokenBalances = {
  base: Zero,
  optimism: Zero,
  total: Zero,
};

const accountInitialState: IAccountProvider = {
  signer: {} as ethers.Wallet,
  lastTransfer: null,
  loadingBalances: true,
  allBalances: null!,
  inflationMultipliers: [],
  balanceHandlers: [],
  balances: {
    [UserToken.ECO]: DEFAULT_BALANCES,
    [UserToken.ECOx]: DEFAULT_BALANCES,
    [UserToken.OAK]: DEFAULT_BALANCES,
    [UserToken.USD]: DEFAULT_BALANCES,
  },
  profilePicture: { exists: false },
  excludeAddress: () => ({}),
  hasSomeBalance: false,
};

const AccountContext = React.createContext<IAccountProvider>(accountInitialState);

export const useAccount = () => React.useContext<IAccountProvider>(AccountContext);

const getBalance = (allBalances: ReturnType<typeof getBalances>): Balances => {
  const entries = USER_TOKENS.map(userToken => {
    const manager = getTokenManager(userToken);
    return [userToken, manager.getBalance(allBalances)];
  });
  return Object.fromEntries(entries);
};

// Don't show received tokens notification for transfer from these addresses
const EXCLUDE_RECEIVED_NOTIFICATIONS_BY_ADDRESS: string[] = [
  FAUCET_ADDRESS,
  ...VENDING_MACHINE_ADDRESSES,
  ...PEANUT_ADDRESSES,
];

export const AccountProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { isMerchant } = useMerchant();
  const { address, signer } = useStackup();

  const [profilePicture, setProfilePicture] = useState<IAccountProvider["profilePicture"]>(
    accountInitialState.profilePicture,
  );
  const [balanceHandlers, setBalanceHandlers] = useState<BalanceHandler[]>([]);

  const allBalances = useSelector(getBalances);

  useEffect(() => {
    SimpleAccountFactoryMigration.getSender(signer).then(sender => {
      EXCLUDE_RECEIVED_NOTIFICATIONS_BY_ADDRESS.push(sender.getSender());
    });
  }, [signer.address]);

  useEffect(() => {
    if (address) {
      const handlers = getSupportedNetworks().map(network => new BalanceHandler(address, network.network!));

      setBalanceHandlers(handlers);

      const initialRequests = handlers.map(handler => handler.fetchInitialBalances());
      Promise.all(initialRequests).then(initialBalances => {
        initialBalances.forEach((balances, index) => {
          const handler = handlers[index];
          dispatch(setBalances({ network: handler.network, balances }));
        });

        handlers.forEach(handler => handler.listenTransfers());
      });

      // Exclude old wallet address
      SimpleAccountFactoryMigration.getSender(signer).then(sender => {
        handlers.forEach(handler => handler.excludeAddress(sender.getSender()));
      });

      // Exclude uniswap pools used in AutoSwaps
      handlers.forEach(handler => {
        AutoSwap.UNI_POOLS.filter(pool => pool.network === handler.network).forEach(pool =>
          handler.excludeAddress(AutoSwap.getPoolAddress(pool)),
        );
      });

      return () => {
        handlers.forEach(handler => handler.clear());
      };
    }
  }, [address]);

  useEffect(() => {
    if (balanceHandlers) {
      const notifyFunc: BalanceHandler["transferInCallbacks"][0] = async transfer => {
        const tokenManager = getTokenManager(transfer.userToken);
        let message: React.ReactNode = (
          <div>
            {formatBigNumber(transfer.amount, tokenManager.decimals, 2)} {tokenManager.symbol} received
          </div>
        );

        if (transfer.from === FAUCET_HAPPY_HOUR_ADDRESS) {
          message = (
            <span>
              It&apos;s Beam Happy Hour!
              <br />
              Here&apos;s {formatBigNumber(transfer.amount, tokenManager.decimals, 2)} {tokenManager.symbol} towards you
              next drink. 🎉
            </span>
          );
        } else if (isMerchant) {
          const displayName = await fetchDisplayName(transfer.from);
          message = (
            <div>
              {formatBigNumber(transfer.amount, tokenManager.decimals, 2)} {tokenManager.symbol} received at{" "}
              {moment().format("LT")} from {displayName}.
            </div>
          );
        }

        notify({
          duration: isMerchant ? 30_000 : 5_000,
          content: <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>{message}</div>,
        });
      };

      balanceHandlers.forEach(handler => handler.addTransferInCallback(notifyFunc));

      if (isMerchant) {
        // Reduce polling interval for merchants
        balanceHandlers.forEach(handler => handler.setPollingInterval(15_000));
      }

      return () => {
        balanceHandlers.forEach(handler => handler.removeTransferInCallback(notifyFunc));
      };
    }
  }, [balanceHandlers, isMerchant]);

  const { data: baseData } = useBeamSubgraph("base");
  const optimismSubgraph = useBeamSubgraph("optimism");
  const optimismData = optimismSubgraph.data;
  const loadingAccount = !optimismSubgraph.called || optimismSubgraph.loading;

  const [lastTransfer, setLastTransfer] = useState<Transfer | null>(null);

  // temp emoji beamname if none is registered
  const tempBeamname = address ? addressToEmoji(address) : undefined;

  useEffect(() => {
    if (baseData || optimismData) {
      const [_lastTransfer] = [optimismData?.lastTransfer, baseData?.lastTransfer]
        .filter(item => item)
        .sort((a, b) => (a!.timestamp > b!.timestamp ? 1 : -1));
      if (_lastTransfer) {
        setLastTransfer(_lastTransfer);
      }
    }
  }, [baseData, optimismData]);

  useEffect(() => {
    const beamname = optimismData?.beamname?.name;

    if (beamname) {
      queryClient.setQueryData(["beamname-lookup", address], beamname);
    }

    if (beamname && Merchant.isValidFromBeamname(beamname)) {
      dispatch(activateMerchant());
      dispatch(enableMerchantMode({ enabled: localStorage.getItem("merchant-mode-enabled") === "true" }));
    }
  }, [optimismData?.beamname?.name]);

  useEffect(() => {
    if (address) {
      ProfileClient.getProfilePicture(address).then(pic => {
        const exists = Boolean(pic);
        const url = exists && pic ? pic : undefined;
        setProfilePicture({ exists, url });
      });
      return () => {
        setProfilePicture(accountInitialState.profilePicture);
      };
    }
  }, [address]);

  const balances = getBalance(allBalances);
  const loadingBalances = Object.values(allBalances).some(balances => balances.state === "loading");

  const { inflationMultipliers } = useInflationMultipliers();

  const excludeAddress = (address: string) => {
    balanceHandlers?.forEach(handler => handler.excludeAddress(address));
  };

  const hasSomeBalance = useCallback(() => {
    return Object.values(balances).some(balance => !balance.total.isZero());
  }, [balances]);

  return (
    <AccountContext.Provider
      value={{
        signer,
        address,
        lastTransfer,
        loadingBalances,
        balanceHandlers,
        excludeAddress,
        allBalances,
        balances,
        profilePicture,
        loadingAccount,
        inflationMultipliers,
        inflationMultiplier: inflationMultipliers[0],
        beamname:
          optimismData &&
          (optimismData.beamname
            ? {
                ...optimismData.beamname,
                displayName: displayBeamName(optimismData.beamname.name),
                exists: true,
              }
            : { exists: false }),
        tempBeamname,
        hasSomeBalance: hasSomeBalance(),
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
