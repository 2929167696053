import { ethers } from "ethers";

import { Web3AuthCore } from "@modules/web3auth";
import { AuthType } from "@modules/web3auth/constants";

import { store } from "@redux/store";
import { setAuthType } from "@redux/slides/web3auth.slide";

import { getConfig } from "./configuration";

export class Web3AuthSingleFactor extends Web3AuthCore {
  public static readonly SECURITY_QUESTION = "What is the constant password?";

  private static SINGLETON_INSTANCE: Web3AuthSingleFactor;

  private static CONSTANT_SHARE_PASSWORD = "beam";

  public static get() {
    if (!Web3AuthSingleFactor.SINGLETON_INSTANCE) {
      const { tKey, serviceProvider } = getConfig();
      Web3AuthSingleFactor.SINGLETON_INSTANCE = new Web3AuthSingleFactor(tKey, serviceProvider);
    }
    return Web3AuthSingleFactor.SINGLETON_INSTANCE;
  }

  securityQuestion(): string {
    return Web3AuthSingleFactor.SECURITY_QUESTION;
  }

  async save(wallet: ethers.Wallet) {
    await this.tKey._initializeNewKey({ initializeModules: true });
    const response = await super.save(wallet, Web3AuthSingleFactor.CONSTANT_SHARE_PASSWORD);

    await this.removeLocalShare();

    store.dispatch(setAuthType(AuthType.SINGLE));

    return response;
  }

  async recover() {
    await super.answerSecurityQuestions(Web3AuthSingleFactor.CONSTANT_SHARE_PASSWORD);
    return super.recover();
  }

  isSFA() {
    const question = this.getSecurityQuestionFromModule();
    if (!question) {
      // Question doesn't exist
      return true;
    }
    return question === this.securityQuestion();
  }

  private getLocalShareIndex() {
    const pubPoly = this.tKey.metadata.getLatestPublicPolynomial();
    const pubPolyID = pubPoly.getPolynomialID();
    const fullShareIndexesList = this.tKey.metadata.getShareIndexesForPolynomial(pubPolyID);

    const keyDetails = this.tKey.getKeyDetails();
    const sharesIndexesWithDescription = Object.keys(keyDetails.shareDescriptions);

    const wantedShares = ["1", ...sharesIndexesWithDescription];
    return fullShareIndexesList.find(shareIndex => !wantedShares.includes(shareIndex));
  }

  private async removeLocalShare() {
    const localShareIndex = this.getLocalShareIndex();
    if (localShareIndex) {
      await this.tKey.deleteShare(localShareIndex);
    }
  }
}
