import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Client, IUserOperation } from "userop";
import { BigNumber, ethers, utils } from "ethers";
import { CheckCircleOutlined } from "@ant-design/icons";
import { logError } from "@helpers";

import { getOperationFee } from "@hooks/useOperationFee";

import { useAccount } from "@contexts/AccountContext";
import { useStackup } from "@contexts/StackupContext";
import { useCurrentToken } from "@contexts/TokenContext";
import { getNetwork, Network } from "@constants";

import Row from "@components/row/Row";
import Card from "@components/card/Card";
import { NumberPad } from "@components/numberpad/NumberPad";
import { BodyText } from "@components/text/Text";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { Button } from "@components/button/Button";
import { Callout } from "@components/callout/Callout";
import { ResizeBlock } from "@components/resizeblock/ResizeBlock";
import { AddressSearch } from "@components/address_search/AddressSearch";
import { ConfirmSendSheet } from "@components/confirmsendsheet/ConfirmSendSheet";

import { useAppDispatch, useAppSelector } from "@redux";
import { executeTransfer } from "@redux/slides/balances/balances.slide";

import { blockWindowClosure } from "@helpers/window";
import { calculateTransferAmounts } from "@helpers/transfer";
import { convertAmount, copy, formatBigNumber, formatFee, formatTokenAmount, getTransaction, notify } from "@helpers";

import * as Peanut from "@modules/peanut";
import { BeamLink } from "@modules/peanut";
import { getNetworkProvider } from "@modules/blockchain/providers";
import { getTokenManager } from "@modules/token-managers/tokens";
import { Econet, ECONET_V1_REGEX } from "@modules/econet/Econet";

import "./Send.css";

import { ReactComponent as CopyIcon } from "@assets/icons/copy.svg";
import { ReactComponent as ShareIcon } from "@assets/icons/share.svg";

const { Zero } = ethers.constants;

export const Send: React.FC = () => {
  const navigate = useNavigate();
  const { address, signer } = useStackup();

  const configFee = useAppSelector(state => state.config.fee);
  const dispatch = useAppDispatch();

  const { token: tokenId } = useCurrentToken();

  const { balances, loadingBalances } = useAccount();
  const optimismBalance = balances[tokenId].optimism;
  const baseBalance = balances[tokenId].base;

  const tokenManager = useMemo(() => getTokenManager(tokenId), [tokenId]);

  const [amount, setAmount] = useState<string>("0");
  const [toAddress, setToAddress] = useState("");
  const [sendOpen, setSendOpen] = useState(false);
  const [linkOpen, setLinkOpen] = useState(false);
  const [econetTx, setEconetTx] = useState(false);

  const _feeRaw = getOperationFee(tokenId, configFee);
  const transferFeeRaw = econetTx ? ethers.constants.Zero : _feeRaw;

  const transferFee = transferFeeRaw || Zero;
  const linkFee = _feeRaw || Zero;

  const feeString = formatFee(transferFeeRaw, { id: tokenManager.composedBy[0], decimals: tokenManager.decimals });

  const [link, setLink] = useState("");

  const [loading, setLoading] = useState(false);

  const amountConverted = utils.parseUnits(amount, tokenManager.decimals);

  const maxSend = balances[tokenId].total.lte(transferFee) ? Zero : balances[tokenId].total.sub(transferFee);
  const maxLink = balances[tokenId].total.lte(linkFee) ? Zero : balances[tokenId].total.sub(linkFee);
  const aboveMaxSend = amountConverted.gt(maxSend);
  const aboveMaxLink = amountConverted.gt(maxLink);
  const total = amountConverted.add(transferFee);

  const reset = () => {
    setEconetTx(false);
    setSendOpen(false);
    setLinkOpen(false);
    setToAddress("");
    setAmount("0");
    setLink("");
  };

  const shareLink = () => {
    if (navigator.canShare && navigator.canShare({ url: link })) {
      navigator.share({ url: link });
    }
  };

  const createLink = async () => {
    if (!address || !_feeRaw || !optimismBalance || !baseBalance) return;
    setLoading(true);
    const value = convertAmount(amount, tokenManager.decimals);
    const password = Peanut.getRandomString();

    const networkLinks: {
      network: Network;
      state: string;
      indexes: number[];
      balance: BigNumber;
      amount: BigNumber;
      fee: BigNumber;
      userOpHash: null | string;
      txHash: string | null;
      tx?: Promise<void>;
    }[] = [
      {
        network: "optimism",
        state: "loading",
        indexes: [],
        balance: optimismBalance,
        amount: ethers.constants.Zero,
        fee: ethers.constants.Zero,
        userOpHash: null,
        txHash: null,
      },
      {
        network: "base",
        state: "loading",
        indexes: [],
        balance: baseBalance,
        amount: ethers.constants.Zero,
        fee: ethers.constants.Zero,
        userOpHash: null,
        txHash: null,
      },
    ];

    try {
      const balances = networkLinks.map(network => network.balance);
      const transferAmounts = calculateTransferAmounts(value, _feeRaw, balances);

      const isSuperLink = transferAmounts.filter(transfer => !transfer.amount.add(transfer.fee).isZero()).length > 1;
      const manager = getTokenManager(tokenId);

      blockWindowClosure.enable();
      if (isSuperLink) {
        const [optimism, base] = networkLinks;
        const [optimismAmounts, baseAmounts] = transferAmounts;

        optimism.fee = optimismAmounts.fee;
        optimism.amount = optimismAmounts.amount;

        base.fee = baseAmounts.fee;
        base.amount = baseAmounts.amount;

        const feeChain = optimism.fee.isZero() && !base.fee.isZero() ? base : optimism;
        const freeChain = optimism.fee.isZero() && !base.fee.isZero() ? optimism : base;

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        let feeTx: Record<"resolve" | "reject", () => void> = { resolve: () => {}, reject: () => {} };
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        let freeTx: typeof feeTx = { resolve: () => {}, reject: () => {} };

        const feeTxPromise = new Promise<void>((resolve, reject) => {
          feeTx = { resolve, reject };
        });
        const freeTxPromise = new Promise<void>((resolve, reject) => {
          freeTx = { resolve, reject };
        });

        feeChain.tx = feeTxPromise;
        freeChain.tx = freeTxPromise;

        try {
          const feeChainLinks = manager.getTransferAmounts(feeChain.amount, feeChain.fee, feeChain.network);
          const freeChainLinks = manager.getTransferAmounts(freeChain.amount, freeChain.fee, freeChain.network);

          feeChainLinks.forEach(transfer =>
            dispatch(
              executeTransfer({
                amount: transfer.amount.toString(),
                tx: feeTxPromise,
                network: feeChain.network,
                token: transfer.token,
              }),
            ),
          );
          freeChainLinks.forEach(transfer =>
            dispatch(
              executeTransfer({
                amount: transfer.amount.toString(),
                tx: freeTxPromise,
                network: freeChain.network,
                token: transfer.token,
              }),
            ),
          );

          const userOpWithFee = (await manager.createLink({
            dryRun: true,
            signer: signer,
            password: password,
            network: feeChain.network,
            transfers: feeChainLinks,
          })) as IUserOperation;

          const onUserOpWithFeeTx = async (txHash: string) => {
            feeChain.state = "success";
            feeChain.txHash = txHash;

            const tx = await getTransaction(getNetworkProvider(getNetwork(feeChain.network).chainId), txHash, 15);
            const receipt = await tx.wait();
            const deposits = Peanut.getDepositEvent(address, receipt, feeChain.network);

            feeChain.indexes = deposits.map(deposit => deposit.id);
            feeTx.resolve();
          };

          const request = (await manager.createLink({
            signer,
            network: freeChain.network,
            password: password,
            transfers: freeChainLinks,
            userOpWithFee,
            onUserOpWithFeeTx,
          })) as Awaited<ReturnType<Client["sendUserOperation"]>>;

          const userOpTx = await request.wait();

          if (!userOpTx) throw new Error("could not send userOp");

          freeChain.state = "success";
          freeChain.txHash = userOpTx.transactionHash;

          const receipt = await userOpTx.getTransactionReceipt();
          const deposits = Peanut.getDepositEvent(address, receipt, freeChain.network);

          freeChain.indexes = deposits.map(deposit => deposit.id);
          freeTx.resolve();
        } catch (error) {
          logError("[super-link]", error);
          freeChain.state = "error";
          freeTx.reject();

          if (feeChain.state !== "success") {
            feeChain.state = "error";
            feeTx.reject();
          } else {
            notify({
              duration: 5_000,
              type: "warning",
              content: `Beam Link created for only ${formatBigNumber(feeChain.amount, manager.decimals, 2)} ${
                manager.symbol
              }`,
            });
          }

          notify({
            type: "error",
            duration: 5_000,
            content: "Error during BeamLink creation",
          });
        } finally {
          blockWindowClosure.disable();
        }
      } else {
        networkLinks.forEach((network, index) => {
          const { amount, fee } = transferAmounts[index];
          network.amount = amount;
          network.fee = fee;
          if (!amount.isZero()) {
            const createLinkTransfers = manager.getTransferAmounts(amount, fee, network.network);
            const request = manager.createLink({
              signer,
              password,
              network: network.network,
              transfers: createLinkTransfers,
            }) as ReturnType<Client["sendUserOperation"]>;
            const tx = request
              .then(response => {
                network.userOpHash = response.userOpHash;
                return response.wait();
              })
              .then(async result => {
                if (result) {
                  network.state = "success";
                  network.txHash = result.transactionHash;

                  const receipt = await result.getTransactionReceipt();
                  const deposits = Peanut.getDepositEvent(address, receipt, network.network);

                  network.indexes = deposits.map(deposit => deposit.id);
                } else {
                  console.error(`[${network.network}-link] transaction hash is null`, network, result);
                  network.state = "error";
                  throw new Error("transaction hash is null");
                }
              })
              .catch(error => {
                logError(`[${network.network}-transfer]`, error);
                network.state = "error";
                notify({
                  type: "error",
                  duration: 5_000,
                  content: "Error during BeamLink creation",
                });
                throw error;
              });

            createLinkTransfers.forEach(transfer =>
              dispatch(
                executeTransfer({
                  tx,
                  token: transfer.token,
                  network: network.network,
                  amount: transfer.amount.toString(),
                }),
              ),
            );

            tx.finally(() => blockWindowClosure.disable());
            network.tx = tx;
          } else {
            network.state = "success";
          }
        });
      }

      Promise.all(
        networkLinks.map(async link => {
          try {
            return await link.tx;
          } catch {
            return null;
          }
        }),
      ).then(() => {
        const beamLink = new BeamLink(tokenId, password);

        networkLinks
          .filter(link => link.state === "success" && link.indexes.length && !link.amount.isZero())
          .forEach(link => {
            link.indexes.forEach(index => {
              beamLink.addDeposit({
                network: link.network,
                amount: link.amount.toString(),
                id: index.toString(),
              });
            });
          });

        if (beamLink.deposits.length) {
          setLink(beamLink.toURL());

          // Store BeamLink in localStorage
          beamLink.store();
        }
        setLoading(false);
      });
    } catch (error) {
      logError("[beamlink]", error);
    }
  };

  useEffect(() => {
    if (!loadingBalances) {
      try {
        const econetRegex = ECONET_V1_REGEX.exec(window.location.search);
        if (econetRegex) {
          const econetData = Econet.from(econetRegex[0]);
          if (econetData) {
            const tokenManager = getTokenManager(econetData.token);
            navigate(`/send?c=${econetData.token}`);
            setToAddress(econetData.merchant);
            setAmount(ethers.utils.formatUnits(econetData.amount, tokenManager.decimals));
            console.log("econetData", econetData);
            setEconetTx(true);
            setSendOpen(true);
          } else {
            navigate(window.location.pathname);
          }
        }
      } catch (error) {
        console.warn("Error while checking for Econet links", error);
      }
    }
  }, [loadingBalances]);

  useEffect(() => {
    if (!linkOpen && link) {
      setLink("");
      reset();
    }
  }, [linkOpen, setLink]);

  useEffect(() => {
    if (!sendOpen && toAddress) {
      setToAddress("");
      reset();
    }
  }, [sendOpen, setToAddress]);

  return (
    <div className="Send">
      <NumberPad
        value={amount}
        setValue={setAmount}
        token={tokenId}
        fee={transferFeeRaw}
        keyboardDisabled={sendOpen || linkOpen}
      >
        <div className="Send_actions">
          <Button
            type="primary"
            title="Send to user"
            disabled={aboveMaxSend || parseFloat(amount) === 0}
            onClick={() => setSendOpen(true)}
          />
          <Button
            type="primary"
            disabled={aboveMaxLink || parseFloat(amount) === 0}
            onClick={() => setLinkOpen(true)}
            title="Create Beam Link"
            subtitle={
              aboveMaxLink && parseFloat(amount) > 0 ? (
                <div style={{ fontSize: 12, fontWeight: 400 }}>
                  {formatTokenAmount(utils.formatUnits(maxLink, tokenManager.decimals), 3)} max
                </div>
              ) : null
            }
          />
        </div>
      </NumberPad>

      <ConfirmSendSheet
        isOpen={sendOpen}
        onClose={() => {
          setSendOpen(false);
          setEconetTx(false);
        }}
        onReset={reset}
        fee={transferFee}
        amount={amount}
        toAddress={toAddress}
        title={toAddress ? `Confirm send` : `Send to...`}
        alt={<AddressSearch setAddress={setToAddress} />}
        onBack={() => setToAddress("")}
        showAlt={!toAddress}
      />

      <BottomSheet
        isOpen={linkOpen}
        onClose={() => setLinkOpen(false)}
        title={
          !link ? (
            "Confirm Beam Link"
          ) : (
            <div style={{ display: "flex", alignItems: "center", gap: "var(--size-small)", justifyContent: "center" }}>
              <CheckCircleOutlined style={{ color: "var(--color-success)" }} /> Beam Link Created
            </div>
          )
        }
        subtitle={
          !link
            ? `You are creating a Beam Link for ${amount} ${tokenManager.symbol}`
            : `This link can be claimed for ${amount} ${tokenManager.symbol}`
        }
      >
        <div className="Send_confirmWrapper">
          <div className="Send_confirmBlock">
            <ResizeBlock>
              <div className="Send_confirmCard">
                <Card style="muted">
                  {!link ? (
                    <>
                      <Row
                        hasHorizontalPadding={false}
                        deemphasizeTitle
                        title="Amount"
                        trailingContent={
                          <BodyText bold>
                            {formatTokenAmount(amount, 3)} {tokenManager.symbol}
                          </BodyText>
                        }
                      />
                      <Row
                        hasHorizontalPadding={false}
                        deemphasizeTitle
                        title="Fee"
                        trailingContent={
                          <BodyText bold>
                            {formatTokenAmount(feeString, 3)} {tokenManager.symbol}
                          </BodyText>
                        }
                      />
                      <Row
                        hasHorizontalPadding={false}
                        deemphasizeTitle
                        title="Total"
                        trailingContent={
                          <BodyText bold>
                            {formatBigNumber(total, tokenManager.decimals, 3)} {tokenManager.symbol}
                          </BodyText>
                        }
                      />
                    </>
                  ) : (
                    <div className="Send_linkBox" onClick={() => copy(link, "Copied Beam Link")}>
                      <BodyText light>{link}</BodyText>
                    </div>
                  )}
                </Card>
              </div>
            </ResizeBlock>
          </div>
          {!link ? (
            <>
              <Button onClick={createLink} disabled={loading} loading={loading} title="Create Link" />
              <Callout>
                <BodyText small>
                  Make sure to copy the Beam Link once it&apos;s created. If you don&apos;t, the money could be lost
                  forever.
                </BodyText>
              </Callout>
            </>
          ) : (
            <div style={{ display: "flex", width: "100%", gap: "var(--size-medium)" }}>
              <Button
                leadingIcon={<CopyIcon />}
                title="Copy Link"
                type="primary"
                onClick={() => copy(link, "Copied Beam Link")}
              />
              {navigator.canShare && navigator.canShare({ url: link }) ? (
                <Button leadingIcon={<ShareIcon />} type="primary" onClick={shareLink} title="Share Link" />
              ) : null}
            </div>
          )}
          {link && (
            <>
              <BodyText small light centered>
                Anyone who has this Beam Link can claim the money.
              </BodyText>
              <Callout>
                <BodyText small>
                  Make sure to copy the link before leaving this page or refreshing. If you don&apos;t, the money could
                  be lost forever.
                </BodyText>
              </Callout>
            </>
          )}
        </div>
      </BottomSheet>
    </div>
  );
};
