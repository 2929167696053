import React, { forwardRef, SetStateAction, useEffect, useImperativeHandle, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { logError, notify } from "@helpers";
import { CheckCircleOutlined } from "@ant-design/icons";

import BottomSheet, { BottomSheetProps } from "@components/bottomsheet/BottomSheet";
import { XAuthStep } from "@components/account/access/x/XAuthStep";
import flags from "@constants/flags";

import { useStackup } from "@contexts/StackupContext";

import { Web3AuthCore, Web3AuthMultiFactor, Web3AuthSingleFactor } from "@modules/web3auth";
import { AuthMethod, AuthType } from "@modules/web3auth/constants";
import { useAuth0Custom, useWeb3Auth } from "@modules/web3auth/hooks";

import { useAppDispatch } from "@redux";
import { setAfterRedirect, useWeb3AuthState } from "@redux/slides/web3auth.slide";
import { PasswordStep, StepAnimation } from "@components/account/access/components";

enum Step {
  Login,
  Password,
}

interface XDrawerProps {
  action: "Save" | "Load";
  skipPasswordStep?: boolean;
  onComplete: (authType?: AuthType) => void;
  onCancel: BottomSheetProps["onClose"];
  setAccountWarning: React.Dispatch<SetStateAction<boolean>>;
  onboarding?: boolean;
  shopWaitlist?: boolean;
}

export const XDrawer = forwardRef<{ login(): void } | undefined, XDrawerProps>(
  (
    { action, skipPasswordStep, onComplete, onCancel, setAccountWarning, onboarding = false, shopWaitlist = false },
    ref,
  ) => {
    const { getIdTokenClaims } = useAuth0();
    const { signer: stackupSigner } = useStackup();
    const auth0 = useAuth0Custom();
    const web3Auth = useWeb3Auth();
    const dispatch = useAppDispatch();
    const { afterRedirect } = useWeb3AuthState();

    const [step, setStep] = useState(Step.Login);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleComplete = () => {
      setOpen(false);
      onComplete && onComplete();
    };

    const handleCancel = () => {
      setOpen(false);
      onCancel && onCancel();
    };

    const recover = async (password?: string) => {
      setLoading(true);
      return web3Auth
        .recover({ password, onComplete: handleComplete, onError: handleCancel })
        .finally(() => setLoading(false));
    };

    const save = async () => {
      try {
        const web3authInstance = Web3AuthSingleFactor.get();
        await web3authInstance.save(stackupSigner);

        notify({
          duration: 5_000,
          content: (
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <CheckCircleOutlined height={14} style={{ marginTop: -3, marginRight: 6 }} />
              <div>X account connected</div>
            </div>
          ),
        });

        // trigger localStorage update for this tab
        window.dispatchEvent(new Event("storage"));

        handleComplete();
      } catch (error) {
        console.error("error save", error);
        logError("[x-save-access]", error);
        handleCancel();
        notify({
          duration: 5_000,
          type: "error",
          content: "Unexpected error while saving access",
        });
      }
    };

    const login = async () => {
      try {
        await auth0.loginWithTwitter({
          returnTo: location.pathname + location.search,
          resumeOnboarding: shopWaitlist ? false : onboarding,
          resumeShopping: shopWaitlist,
        });
      } catch (error) {
        logError("[x-login]", error);
        handleCancel();
        notify({ content: "Authentication failed", type: "error" });
      }
    };

    useImperativeHandle(ref, () => ({ login }), []);

    const loginAfterRedirect = async () => {
      try {
        const idToken = (await getIdTokenClaims())?.__raw.toString();
        if (!idToken) throw new Error("Invalid credentials.");

        if (flags.save_access.check_account_existence) {
          const { hasSavedAccess } = await Web3AuthCore.checkIfHasSavedAccess(idToken);

          const hasSavedAccessAlready =
            action === "Load" ? hasSavedAccess.twitter : hasSavedAccess.twitter || hasSavedAccess.email;

          if (hasSavedAccessAlready === (action === "Save")) {
            setAccountWarning(true);
            handleCancel();
            return;
          }
        }

        const web3authInstance: Web3AuthSingleFactor | Web3AuthMultiFactor = Web3AuthSingleFactor.get();
        await web3authInstance.loginWithToken(idToken, AuthMethod.TWITTER);

        if (action === "Save") {
          return save();
        }

        const authType = web3authInstance.isSFA() ? AuthType.SINGLE : AuthType.MULTI;

        if (authType === AuthType.MULTI) {
          const multiFactor = Web3AuthMultiFactor.get();
          multiFactor.setTKey(web3authInstance.getTKey());
          multiFactor.setLoginData(web3authInstance.getLoginData());

          if (skipPasswordStep) {
            onComplete && onComplete(authType);
          } else {
            setStep(Step.Password);
          }
        } else {
          recover();
        }
      } catch (error) {
        logError("[x-login-after-redirect]", error);
        handleCancel();
        notify({ content: "Authentication failed", type: "error" });
      }
    };

    useEffect(() => {
      if (afterRedirect) {
        setOpen(true);
        dispatch(setAfterRedirect(undefined));
        loginAfterRedirect();
      }
    }, [afterRedirect]);

    return (
      <BottomSheet closeOnShimTap hideLine onClose={handleCancel} isOpen={open} headerClass="AuthDrawer_header">
        {step === Step.Login ? (
          <StepAnimation key="auth-step-1">
            <XAuthStep />
          </StepAnimation>
        ) : (
          <StepAnimation key="auth-step-2">
            <PasswordStep action={action} loading={loading} onSubmit={recover} />
          </StepAnimation>
        )}
      </BottomSheet>
    );
  },
);
