import React, { useEffect, useState } from "react";
import { OnboardingStep, useOnboarding } from "../Onboarding";

import styles from "./RegisterBeamname.module.css";

import { ReactComponent as SampleBeamname } from "@assets/images/sample_beamname.svg";
import { BodyText, TextBlock } from "@components/text/Text";
import { useAccount } from "@contexts/AccountContext";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { RegisterForm } from "@components/account/beamnames/RegisterForm";
import { useRegistrationFee } from "@hooks/useRegistrationFee";

export const RegisterBeamname: React.FC = () => {
  const { balances, beamname } = useAccount();
  const registrationFee = useRegistrationFee();
  const { nextStep, setButtonAction, setButtonTitle, setIsLoading, setButtonDisabled } = useOnboarding();

  const [isDone, setIsDone] = useState(false);
  const [loading, setLoading] = useState(false);

  const registrationDisabled =
    registrationFee && !registrationFee.isZero() ? balances.eco.optimism.lt(registrationFee) : true;

  const onComplete = () => {
    setLoading(false);
    setIsLoading(false);
    setIsDone(true);
    nextStep();
  };

  // TODO uncomment after redux is added to AccountContext
  //   useEffect(() => {
  //     if (beamname) {
  //       setLoading(false);
  //       if (currStep === OnboardStep.RegisterBeamname) {
  //         nextStep();
  //       }
  //     }
  //   }, [beamname]);

  useEffect(() => {
    setButtonAction(() => (isDone ? nextStep() : setLoading(true)));
    setButtonTitle(isDone ? "Next" : registrationDisabled ? "Not enough ECO" : "Register Beam Name");
    setButtonDisabled(!isDone && !loading && registrationDisabled);

    return () => {
      setButtonDisabled(false);
    };
  }, [registrationDisabled, loading, isDone]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (beamname?.exists) {
      setIsDone(true);
    }
  }, [beamname]);

  return (
    <>
      <OnboardingStep
        isDone={Boolean(beamname?.exists)}
        brand={<SampleBeamname />}
        stepTitle="Register a Beam Name"
        postStepContent={
          <>
            <TextBlock>
              <BodyText>
                Beam Names cost 600 ECO, and make it so your friends can easily find you and send you money.
              </BodyText>
              <BodyText>Once registered, that name is yours forever. No one can take it from you.</BodyText>
            </TextBlock>
            {!isDone && !loading && registrationDisabled ? (
              <div className={styles.warning_note}>
                <BodyText centered>
                  You don’t have enough ECO to purchase a Beam Name. You can do this later from your account.
                </BodyText>
              </div>
            ) : null}
          </>
        }
      />
      <RegisterSheet isOpen={loading} onClose={onComplete} />
    </>
  );
};

interface RegisterSheetProps {
  isOpen: boolean;
  onClose: () => void;
}

export const RegisterSheet: React.FC<RegisterSheetProps> = ({ isOpen, onClose }) => {
  return (
    <BottomSheet title="Register Beam Name" isOpen={isOpen} onClose={onClose}>
      <RegisterForm onClose={onClose} />
    </BottomSheet>
  );
};
