import { NETWORK } from "@constants";
import { ethers } from "ethers";

export const PEANUT_ADDRESSES = [
  ethers.utils.getAddress("0xdB60C736A30C41D9df0081057Eae73C3eb119895"), // mainnet
  ethers.utils.getAddress("0xEA9E5A64ED3F892baD4b477709846b819013dEFC"), // optimism mainnet
  ethers.utils.getAddress("0x8fC5d63bDD1e0144F8ADD647AC677C6023C48965"), // optimism goerli
  ethers.utils.getAddress("0x22e993d9108dbde9f32553c3fd0a404acd2b7150"), // base mainnet
  ethers.utils.getAddress("0xf4CFc38596E8Ea93eBD8528ddf5d037f650c417A"), // base goerli
  // legacy peanut addresses
  ethers.utils.getAddress("0x1aBe03DC4706aE47c4F2ae04EEBe5c8607c74e17"), // optimism mainnet
  ethers.utils.getAddress("0xDC608f2Bc4f0AFf02D12d51Ca8b543B343525c8a"), // optimism goerli
];

export function getPeanutAddress(chainId: number, legacy = false) {
  switch (chainId) {
    case 1:
      return PEANUT_ADDRESSES[0];
    case 10:
      return legacy ? PEANUT_ADDRESSES[5] : PEANUT_ADDRESSES[1];
    case 420:
      return legacy ? PEANUT_ADDRESSES[6] : PEANUT_ADDRESSES[2];
    case 8453:
      return PEANUT_ADDRESSES[3];
    case 84531:
      return PEANUT_ADDRESSES[4];
  }
  throw new Error("Peanut Protocol Not Available on this network");
}

export const PEANUT_V3_ADDRESS = getPeanutAddress(NETWORK.chainId);

let claimUrl: URL;
try {
  claimUrl = new URL("/peanut/claim", process.env.REACT_APP_RELAYER_URL!);
} catch (error) {
  throw new Error("Invalid Relayer URL");
}
export const PEANUT_CLAIM_URL = claimUrl.toString();
