import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useQuery } from "@apollo/client";

import { APOLLO_CLIENT_ENS, APOLLO_CLIENTS, NETWORK } from "@constants";
import { BEAMNAME_SEARCH } from "@queries/BEAMNAME_SEARCH";

import { useBeamnameReverse } from "@hooks/useBeamnameReverse";
import { useResolveEnsAddress } from "@hooks/useResolveEnsAddress";
import { ENS_DOMAINS } from "@queries/ens/ENS_DOMAINS";
import { useResolveReverseEns } from "@hooks/useResolveReverseEns";

// OP Subgraph - Apollo Client
const apolloClient = APOLLO_CLIENTS[NETWORK.network!];

interface AddressIdentifier {
  address: string;
  beamname?: string;
  ens?: string;
}

/**
 * Search Accounts using BeamName or ENS names
 * @param _input ENS domain or BeamName
 * @dev BeamNames search starts at 3 characters
 */
export const useBeamnameSearch = (_input: string) => {
  const [input, setInput] = useState(_input);
  const [loading, setLoading] = useState(false);
  const [identifiers, setIdentifiers] = useState<AddressIdentifier[]>([]);

  const { data: beamnameData } = useBeamnameReverse(input);
  const { data: ensData } = useResolveEnsAddress(input);
  const { data: ensResolveData } = useResolveReverseEns(input);

  const {
    data,
    error,
    loading: loadingQuery,
    fetchMore,
    refetch,
  } = useQuery(BEAMNAME_SEARCH, {
    variables: { input, offset: 0 },
    client: apolloClient,
    skip: !(input.length >= 3),
  });

  const { data: ensNames } = useQuery(ENS_DOMAINS, {
    variables: { addresses: data?.beamnames.map(beamname => beamname.owner.id.toLowerCase()) || [] },
    client: APOLLO_CLIENT_ENS,
    skip: data ? data.beamnames.length === 0 : true,
  });

  useEffect(() => {
    if (_input.length >= 3) {
      setLoading(true);
      const timeout = setTimeout(() => setInput(_input.toLowerCase()), 300);
      return () => clearTimeout(timeout);
    } else {
      setInput("");
    }
  }, [_input]);

  useEffect(() => {
    setLoading(loadingQuery);
  }, [loadingQuery, data]);

  useEffect(() => {
    const results: Record<string, AddressIdentifier> = {};

    if (ensData?.ens && ensData.address !== ethers.constants.AddressZero) {
      try {
        const address = ethers.utils.getAddress(ensData.address);
        results[address] = { address: ensData.address, ens: ensData.ens };
      } catch (error) {
        console.warn("invalid address for ENS", error);
      }
    } else if (ethers.utils.isAddress(input)) {
      const address = ethers.utils.getAddress(input);
      const ens = ensResolveData?.address === address ? ensResolveData.ens : undefined;
      results[address] = { address, ens, beamname: beamnameData };
    }

    if (error) console.warn("Address search error: ", error);

    if (data) {
      data.beamnames.forEach(beamname => {
        const address = ethers.utils.getAddress(beamname.owner.id);
        results[address] = { ...results[address], address, beamname: beamname.name };
      });
    }

    setIdentifiers(Object.values(results));
  }, [data, ensData, error, ensNames, ensResolveData]);

  const getMore = () => {
    fetchMore({
      variables: {
        offset: data?.beamnames.length,
      },
    });
  };

  return {
    loading,
    getMore,
    refetch,
    data: identifiers,
  };
};
