import React from "react";
import { Space } from "antd";

import { Button } from "@components/button/Button";
import { IconCircle } from "@components/icon/IconCircle/IconCircle";
import { BodyText, HeaderText, TextBlock } from "@components/text/Text";

import { ReactComponent as LockIcon } from "@assets/icons/lock_icon.svg";

interface XConfirmationStepProps {
  loading?: boolean;
  onNext?: () => void;
}

export const XConfirmationStep: React.FC<XConfirmationStepProps> = ({ loading, onNext }) => {
  return (
    <Space size={16} direction="vertical" style={{ width: "100%" }}>
      <IconCircle style={{ margin: "0 auto" }} icon={<LockIcon />} />
      <HeaderText centered size="small">
        Verify account ownership
      </HeaderText>

      <TextBlock>
        <BodyText centered>Before you set a password, we need to verify you are the owner of this account.</BodyText>
        <BodyText centered light>
          Reconnect to your X account to verify ownership.
        </BodyText>
      </TextBlock>
      <Button
        key="save-access"
        type="primary"
        htmlType="submit"
        title="Connect to X account"
        onClick={onNext}
        loading={loading}
      />
    </Space>
  );
};
