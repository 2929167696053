import config from "@constants/config";

import { INCREASE_SECURITY_MODAL_LAST_SHOWED_AT_KEY } from "@modules/web3auth/constants/progressiveSecurity";

export function getProgressiveSecuritySheetState(address: string): boolean {
  const { modalShowInterval } = config.features.saveAccess.progressiveSecurity;

  const storageKey = `${INCREASE_SECURITY_MODAL_LAST_SHOWED_AT_KEY}-${address.toLowerCase().substring(0, 10)}`;
  const showedUpAt = parseInt(window.localStorage.getItem(storageKey) || "0");
  if (Date.now() - showedUpAt > modalShowInterval) {
    window.localStorage.setItem(storageKey, Date.now().toString());
    return true;
  }
  return false;
}
