import React, { useEffect, useMemo, useState } from "react";
import { ethers, utils } from "ethers";
import { useNavigate } from "react-router-dom";
import QrScanner from "qr-scanner";

import { useDisplayName } from "@hooks/useDisplayName";
import { getOperationFee } from "@hooks/useOperationFee";
import { QRScannerError, useQrScanner } from "@hooks/useQrScanner";

import { useAccount } from "@contexts/AccountContext";

import Row from "@components/row/Row";
import Card from "@components/card/Card";
import { BodyText } from "@components/text/Text";
import { Button } from "@components/button/Button";
import { useCurrentToken } from "@contexts/TokenContext";
import { NumberPad } from "@components/numberpad/NumberPad";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { ConfirmSendSheet } from "@components/confirmsendsheet/ConfirmSendSheet";

import { notify, shortAddr } from "@helpers";
import { isAddressBlacklisted } from "@helpers/ofacBlacklist";

import { useAppSelector } from "@redux";

import { BeamLink } from "@modules/peanut";
import { getTokenManager } from "@modules/token-managers/tokens";
import { Econet, ECONET_V1_REGEX } from "@modules/econet/Econet";

import { ReactComponent as LinkChevron } from "@assets/icons/link_chevron.svg";

import "./Scan.css";

export const Scan: React.FC = () => {
  const navigate = useNavigate();
  const { token: tokenId } = useCurrentToken();
  const configFee = useAppSelector(state => state.config.fee);

  const tokenManager = useMemo(() => getTokenManager(tokenId), [tokenId]);

  const { balances } = useAccount();

  const [amount, setAmount] = useState("0");
  const [toAddress, setToAddress] = useState("");
  const [econetTx, setEconetTx] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [sendingToDetailsSheetOpen, setSendingToDetailsSheetOpen] = useState(false);

  const _feeRaw = getOperationFee(tokenId, configFee);
  const transferFeeRaw = econetTx ? ethers.constants.Zero : _feeRaw;
  const fee = transferFeeRaw || ethers.constants.Zero;

  const recipientDisplayName = useDisplayName(toAddress);

  const maxSend = balances[tokenId].total.sub(fee);
  const aboveMaxSend = utils.parseUnits(amount, tokenManager.decimals).gt(maxSend);

  const reset = () => {
    setEconetTx(false);
    setConfirmationOpen(false);
    setToAddress("");
    setAmount("0");
  };

  useEffect(() => {
    document.documentElement.classList.add("disable-body-scroll");

    return () => {
      document.documentElement.classList.remove("disable-body-scroll");
    };
  });

  const onDecodeResult = (result: QrScanner.ScanResult) => {
    if (result) {
      const text = result.data;

      if (text) {
        const econetRegex = ECONET_V1_REGEX.exec(text);

        // econet:v0:NETWORK:TOKENID:MERCHANT_ADDR:MERCHANT_AMT:REWARD_AMT
        // for example:
        // econet:v0:optimism:usdc:0xMERCHANT:99:1
        // for 99 optimism USDC to merchant, 1 staying in consumer wallet

        if (econetRegex) {
          try {
            const econet = Econet.from(econetRegex[0]);
            if (econet) {
              navigate(`/scan?c=${econet.token}`);
              const tokenManager = getTokenManager(econet.token);
              setToAddress(econet.merchant);
              // TODO: either pos needs to be prepared for a SuperSend, or this needs to restrict to one network
              setAmount(ethers.utils.formatUnits(econet.amount, tokenManager.decimals));
              setEconetTx(true);
              setConfirmationOpen(true);
            }
          } catch (e) {
            console.warn("econet error", e);
          }
          return;
        }
        const beamLink = BeamLink.fromURL(text);
        if (beamLink) {
          window.location.replace(beamLink.toURL());
          return;
        }

        let possibleNewValue = text;
        if (possibleNewValue.indexOf("/") >= 0) {
          const index = possibleNewValue.lastIndexOf("0x");
          possibleNewValue = possibleNewValue.substring(index, index + 42);
        }

        // error message if scanned address is blacklisted
        if (isAddressBlacklisted(possibleNewValue)) {
          notify({ type: "error", content: "For regulatory reasons, this address cannot be sent to." });
        } else if (ethers.utils.isAddress(possibleNewValue)) {
          setToAddress(possibleNewValue);
        }
      }
    }
  };

  const onDecodeError = (err: Error | string) => {
    if (typeof err === "string") {
      switch (err) {
        case QRScannerError.CAMERA_NOT_FOUND:
          navigate("/");
          notify({ content: "Camera access denied or not found.", type: "error" });
          break;
      }
    }
  };

  const { ref } = useQrScanner({ onDecodeResult, onDecodeError });

  return (
    <div className="Scan">
      {toAddress ? <div className="Scan_overlay" /> : <ViewFinder />}
      <video
        muted
        controls={false}
        ref={ref}
        style={{
          position: "fixed",
          objectFit: "cover",
          zIndex: 0,
          overflow: "hidden",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
      {toAddress ? (
        <NumberPad
          transparent
          fee={fee}
          token={tokenId}
          value={amount}
          setValue={setAmount}
          keyboardDisabled={confirmationOpen}
          header={
            <div
              className="Scan_toContainer"
              onClick={() => {
                setSendingToDetailsSheetOpen(true);
              }}
            >
              <label>Sending to {recipientDisplayName}</label>
              <LinkChevron />
            </div>
          }
        >
          <div className="Scan_actions">
            <Button
              type="primary"
              title="Send to user"
              disabled={aboveMaxSend || parseFloat(amount) === 0}
              onClick={() => setConfirmationOpen(true)}
            />
          </div>
        </NumberPad>
      ) : null}
      <ConfirmSendSheet
        isOpen={confirmationOpen}
        toAddress={toAddress}
        fee={fee}
        amount={amount}
        title={"Confirm Send"}
        onClose={() => {
          setEconetTx(false);
          setConfirmationOpen(false);
        }}
        onReset={reset}
      />

      <BottomSheet
        title="Sending to"
        closeOnShimTap
        isOpen={sendingToDetailsSheetOpen}
        onClose={() => setSendingToDetailsSheetOpen(false)}
      >
        <Card style="muted">
          <Row
            deemphasizeTitle
            title="To"
            trailingContent={<BodyText bold>{recipientDisplayName}</BodyText>}
            hasHorizontalPadding={false}
          />
          <Row
            deemphasizeTitle
            title="Address"
            hasHorizontalPadding={false}
            trailingContent={<BodyText bold>{shortAddr(toAddress)}</BodyText>}
          />
        </Card>
      </BottomSheet>
    </div>
  );
};

// contents of the view finder
const ViewFinder = () => {
  const { token: tokenId } = useCurrentToken();
  const tokenManager = getTokenManager(tokenId);
  return (
    <div className="Scan_viewFinder">
      <div className="Scan_viewFinderInner">
        <div />
      </div>
      <div
        className="Scan_viewFinderText"
        style={{
          color: "white",
          zIndex: 2,
          textAlign: "center",
          fontFamily: "var(--font-stack-default)",
          fontSize: 16,
          fontStyle: "normal",
          fontWeight: 400,
        }}
      >
        <span>Scan a Beam Code to send {tokenManager.symbol}</span>
      </div>
    </div>
  );
};
