import React, { useState } from "react";
import { ethers } from "ethers";

import { WithdrawModal } from "@components/account/withdraw/modal/WithdrawModal";
import { WithdrawCardBody } from "@components/account/withdraw/card_body/WithdrawCardBody";
import { WithdrawProvider } from "@components/account/withdraw/context/WithdrawContext";

import { Token, UserToken } from "@constants";
import { useAccount } from "@contexts/AccountContext";
import { useConfigFee } from "@redux/slides/config.slide";
import { getTokenManager } from "@modules/token-managers/tokens";
import { useWeb3AuthState } from "@redux/slides/web3auth.slide";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { BodyText, TextBlock } from "@components/text/Text";
import { Button } from "@components/button/Button";
import { useNavigate } from "react-router-dom";

function getMaxAmount(
  balances: Record<Token, string>,
  fee: ethers.BigNumberish = ethers.utils.parseUnits("0.5", 6),
): ethers.BigNumber {
  const usdTokenManager = getTokenManager(UserToken.USD);
  const balance = usdTokenManager.composedBy.reduce((acc, token) => acc.add(balances[token]), ethers.constants.Zero);
  if (balance.lte(fee)) return ethers.constants.Zero;
  return balance.sub(fee);
}

const MIN_WITHDRAW_AMOUNT = ethers.utils.parseUnits("1", 6); // 1 USD

export const WithdrawCard = () => {
  const fees = useConfigFee();
  const { allBalances } = useAccount();
  const { hasSavedAccess } = useWeb3AuthState();

  const maxAmount = getMaxAmount(allBalances.optimism.balances, fees?.perToken.usd);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [saveAccessSheetOpen, setSaveAccessSheetOpen] = useState(false);

  if (maxAmount.lt(MIN_WITHDRAW_AMOUNT)) return null;

  const handleClick = () => {
    if (!hasSavedAccess) {
      setSaveAccessSheetOpen(true);
    } else {
      setOpen(true);
    }
  };

  const handleCreateAccountClick = () => {
    setSaveAccessSheetOpen(false);
    navigate("/save-access");
  };

  return (
    <React.Fragment>
      <WithdrawCardBody amount={maxAmount} onClick={handleClick} />
      <WithdrawProvider>
        <WithdrawModal isOpen={open} available={maxAmount} fee={fees?.perToken.usd} onClose={() => setOpen(false)} />
      </WithdrawProvider>
      <BottomSheet
        isOpen={saveAccessSheetOpen}
        title="Account creation required"
        onClose={() => {
          setSaveAccessSheetOpen(false);
        }}
        footer={
          <div>
            <Button title="Create account" onClick={handleCreateAccountClick} />
          </div>
        }
      >
        <div>
          <TextBlock>
            <BodyText>Creating an account with Beam is required for withdrawing funds.</BodyText>
            <BodyText>
              <b>Why do we require this?</b> If something goes awry with the withdrawal (extremely unlikely, but
              possible) we need a way to contact you to ensure your funds aren&apos;t lost.
            </BodyText>
          </TextBlock>
        </div>
      </BottomSheet>
    </React.Fragment>
  );
};
