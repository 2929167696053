import moment from "moment";
import { ethers } from "ethers";

import { NETWORK } from "@constants/network";

import STAGING_CONFIG from "@constants/config/staging.json";
import PRODUCTION_CONFIG from "@constants/config/production.json";

const CONFIG_RAW = NETWORK.isTestnet ? STAGING_CONFIG : PRODUCTION_CONFIG;

const CONFIG = {
  optimism: {
    contracts: {
      beamnames: ethers.utils.getAddress(CONFIG_RAW.optimism.contracts.beamnames),
      vendingMachine: ethers.utils.getAddress(CONFIG_RAW.optimism.contracts.vendingMachine),
      vendingMachineHelper: ethers.utils.getAddress(CONFIG_RAW.optimism.contracts.vendingMachineHelper),
    },
    tokens: {
      eco: ethers.utils.getAddress(CONFIG_RAW.optimism.tokens.eco),
      ecox: ethers.utils.getAddress(CONFIG_RAW.optimism.tokens.ecox),
      usdc: ethers.utils.getAddress(CONFIG_RAW.optimism.tokens.usdc),
      usdv: ethers.utils.getAddress(CONFIG_RAW.optimism.tokens.usdv),
      usdce: ethers.utils.getAddress(CONFIG_RAW.optimism.tokens.usdce),
      usdt: ethers.utils.getAddress(CONFIG_RAW.optimism.tokens.usdt),
    },
  },
  base: {
    contracts: [],
    tokens: {
      eco: ethers.utils.getAddress(CONFIG_RAW.base.tokens.eco),
      usdc: ethers.utils.getAddress(CONFIG_RAW.base.tokens.usdc),
      usdbc: ethers.utils.getAddress(CONFIG_RAW.base.tokens.usdbc),
      oak: ethers.utils.getAddress(CONFIG_RAW.base.tokens.oak),
    },
  },
  features: {
    saveAccess: {
      web3auth: {
        clientId: CONFIG_RAW.features.saveAccess.web3auth.clientId,
        verifier: CONFIG_RAW.features.saveAccess.web3auth.verifier,
        network: CONFIG_RAW.features.saveAccess.web3auth.network,
      },
      auth0: {
        domain: CONFIG_RAW.features.saveAccess.auth0.domain,
        clientId: CONFIG_RAW.features.saveAccess.auth0.clientId,
      },
      progressiveSecurity: {
        modalShowInterval: parseDuration(CONFIG_RAW.features.saveAccess.progressiveSecurity.modalShowInterval),
      },
    },
  },
  coinbase: {
    appId: CONFIG_RAW.coinbase.appId,
    deposit: {
      addresses: CONFIG_RAW.coinbase.deposits.addresses,
    },
  },
  s3: {
    endpoint: CONFIG_RAW.s3.endpoint,
  },
};

function parseDuration(duration: string): number {
  return moment.duration(...duration.split(" ")).asMilliseconds();
}

export default CONFIG;
