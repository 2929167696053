import { ethers } from "ethers";
import { logError } from "@helpers/logError";

export class BundlerJsonRpcProvider extends ethers.providers.StaticJsonRpcProvider {
  private bundlerRpc?: ethers.providers.JsonRpcProvider;
  private backupBundlerRpc?: ethers.providers.JsonRpcProvider;

  private bundlerMethods = new Set([
    "eth_sendUserOperation",
    "eth_estimateUserOperationGas",
    "eth_getUserOperationByHash",
    "eth_getUserOperationReceipt",
    "eth_supportedEntryPoints",
  ]);

  setBundlerRpc(bundlerRpc?: string): BundlerJsonRpcProvider {
    if (bundlerRpc) {
      this.bundlerRpc = new ethers.providers.StaticJsonRpcProvider(bundlerRpc, this.network);
    }
    return this;
  }

  setBackupBundlerRpc(bundlerRpc?: string): BundlerJsonRpcProvider {
    if (bundlerRpc) {
      this.backupBundlerRpc = new ethers.providers.StaticJsonRpcProvider(bundlerRpc, this.network);
    }
    return this;
  }

  send(method: string, params: unknown[]): Promise<unknown> {
    if (this.bundlerRpc && this.bundlerMethods.has(method)) {
      return this.bundlerRpc.send(method, params).catch(error => {
        if (this.backupBundlerRpc) {
          logError("primary bundler failed", error);
          return this.backupBundlerRpc.send(method, params).catch(error => {
            logError("[backup-bundler-failure]", error);
            throw error;
          });
        } else {
          throw error;
        }
      });
    }

    return super.send(method, params);
  }
}
