import axios from "axios";

import { RELAYOOR_URL, FAUCET_URL } from "@constants";

export function checkHasSavedAccessByEmail(email: string): Promise<boolean> {
  const url = new URL(`/api/v1/users/isUserEmailSavedAlready/${email}`, RELAYOOR_URL).toString();
  return axios.get(url).then(response => response.data.data);
}

export function checkHasSavedAccessByTwitterEmail(email: string): Promise<boolean> {
  const url = new URL(`/api/v1/users/isTwitterEmailSavedAlready/${email}`, RELAYOOR_URL).toString();
  return axios.get(url).then(response => response.data.data);
}

export function checkHasSavedAccessByTokenId(idToken: string): Promise<{ email: boolean; twitter: boolean }> {
  const url = new URL("/api/v1/user/saved/twitter", FAUCET_URL).toString();
  return axios.post(url, { idToken }).then(response => response.data.data);
}

export function checkHasShoppingWaitListed(address: string): Promise<boolean> {
  const url = new URL(`/api/v1/users/isShoppingWaitListed/${address}`, RELAYOOR_URL).toString();
  return axios.get(url).then(response => response.data.data);
}
