import Row from "@components/row/Row";
import { UserToken } from "@constants/tokens";
import React, { useEffect, useRef, useState } from "react";
import styles from "./SettingsCard.module.css";
import { BodyText } from "@components/text/Text";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { isDesktop } from "react-device-detect";
import { Popover } from "@components/popover/Popover";
import { Button } from "@components/button/Button";
import { DEFAULT_CURRENCY_KEY, useCurrentToken } from "@contexts/TokenContext";
import { ReactComponent as Eco } from "@assets/icons/eco.svg";
import { ReactComponent as USD } from "@assets/icons/usdc.svg";

export const CurrencySettingsRow = () => {
  const currencyPopoverRef = useRef<HTMLDivElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { setActiveToken } = useCurrentToken();
  const [currentDefaultCurrency, setCurrentDefaultCurrency] = useState<UserToken>(
    localStorage.getItem(DEFAULT_CURRENCY_KEY) as UserToken,
  );

  const handlePopoverClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    setPopoverOpen(!popoverOpen);
  };

  const handleClick = (token: UserToken) => {
    setPopoverOpen(false);
    localStorage.setItem(DEFAULT_CURRENCY_KEY, token);
    setCurrentDefaultCurrency(token);
    setActiveToken(token);
  };

  useEffect(() => {
    if (localStorage.defaultCurrency) {
      setCurrentDefaultCurrency(localStorage.defaultCurrency as UserToken);
    }
  }, [localStorage]);

  return (
    <>
      <Row
        title="Default currency"
        subtitle="What currency to default to in this browser"
        hasHorizontalPadding={false}
        trailingContent={
          <div ref={currencyPopoverRef}>
            <Button
              size="small"
              title={currentDefaultCurrency === UserToken.ECO ? "ECO" : "USD"}
              secondary
              onClick={handlePopoverClick}
              leadingIcon={currentDefaultCurrency === UserToken.ECO ? <Eco /> : <USD />}
            />
          </div>
        }
      />
      <DefaultCurrencyPopover
        targetRef={currencyPopoverRef}
        onClose={() => setPopoverOpen(false)}
        open={popoverOpen}
        currentDefault={currentDefaultCurrency}
        onRowClick={handleClick}
      />
    </>
  );
};

interface DefaultCurrencyPopoverProps {
  targetRef: React.RefObject<HTMLDivElement>;
  open: boolean;
  onClose: () => void;
  currentDefault: UserToken;
  onRowClick: (_: UserToken) => void;
}

const DefaultCurrencyPopover = ({
  targetRef,
  open,
  onClose,
  currentDefault,
  onRowClick,
}: DefaultCurrencyPopoverProps) => {
  const content = (
    <div className={`${styles.themeItemList} ${!isDesktop ? styles.inSheet : ""}`}>
      <Row
        className={styles.themeItem}
        selected={currentDefault === UserToken.USD}
        leadingContent={
          <div className={styles.themeItemLeading}>
            <USD />
            <BodyText bold>USD</BodyText>
          </div>
        }
        onClick={onRowClick.bind(null, UserToken.USD)}
      />
      <Row
        className={styles.themeItem}
        selected={currentDefault === UserToken.ECO}
        onClick={onRowClick.bind(null, UserToken.ECO)}
        leadingContent={
          <div className={styles.themeItemLeading}>
            <Eco />
            <BodyText bold>ECO</BodyText>
          </div>
        }
      />
    </div>
  );

  if (!isDesktop) {
    return (
      <BottomSheet title="Set default currency" isOpen={open} onClose={onClose}>
        {content}
      </BottomSheet>
    );
  }

  return (
    <Popover targetRef={targetRef} open={open} onClose={onClose} position="topRight">
      {content}
    </Popover>
  );
};
