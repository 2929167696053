import { ethers } from "ethers";
import { IUserOperation, Presets, UserOperationMiddlewareFn } from "userop";
import { OpToJSON } from "userop/dist/utils";

import {
  getNetwork,
  Network,
  PAYMASTER_URL_BASE,
  PAYMASTER_URL_OPTIMISM,
  SIMPLE_ACCOUNT_FACTORY_ADDRESS,
  STACKUP_BASE_RPC_URL,
  STACKUP_OPTIMISM_RPC_URL,
} from "@constants";
import { logError } from "@helpers/logError";

import { Client } from "@modules/smart-wallet/BundlerClient";
import { BeamWallet } from "@modules/smart-wallet/BeamWallet";

export function getStackupRpcUrl(network: Network) {
  return network === "base" ? STACKUP_BASE_RPC_URL : STACKUP_OPTIMISM_RPC_URL;
}

export function getPimlicoRpcUrl(network: Network) {
  return getNetwork(network).bundler;
}

function getStackupPaymasterUrl(network: Network) {
  return network === "base" ? PAYMASTER_URL_BASE : PAYMASTER_URL_OPTIMISM;
}

export function getFlatPaymaster(network: Network) {
  const url = getStackupPaymasterUrl(network);
  return Presets.Middleware.verifyingPaymaster(url, { type: "flat" });
}

export function getFreePaymaster(
  network: Network,
  userOpWithFee: IUserOperation,
  onUserOpFee: (userOpTx: string) => void,
): UserOperationMiddlewareFn {
  const networkData = getNetwork(network);
  const paymasterRpc = getStackupPaymasterUrl(network);
  const context = { type: "free", userOpWithFee };

  return async ctx => {
    ctx.op.verificationGasLimit = ethers.BigNumber.from(ctx.op.verificationGasLimit).mul(3);

    const provider = new ethers.providers.StaticJsonRpcProvider(paymasterRpc, networkData.chainId);
    const pm = await provider.send("pm_sponsorUserOperation", [OpToJSON(ctx.op), ctx.entryPoint, context]);

    ctx.op.paymasterAndData = pm.paymasterAndData;
    ctx.op.preVerificationGas = pm.preVerificationGas;
    ctx.op.verificationGasLimit = pm.verificationGasLimit;
    ctx.op.callGasLimit = pm.callGasLimit;

    try {
      onUserOpFee(pm.userOpWithFee.transaction.hash);
    } catch (error) {
      logError("[getFreePaymaster:onUserOpFee]", error);
    }
  };
}

export const getBeamWallet = (signer: ethers.Signer, network: Network, paymaster?: UserOperationMiddlewareFn) => {
  const networkData = getNetwork(network);
  return BeamWallet.init(signer, networkData.rpcUrl, {
    factory: SIMPLE_ACCOUNT_FACTORY_ADDRESS,
    network: networkData.chainId,
    paymasterMiddleware: paymaster,
    overrideBundlerRpc: getStackupRpcUrl(network),
    backupBundlerRpc: getStackupRpcUrl(network),
  });
};
export const getClient = (network: Network) => {
  return Client.init(getNetwork(network).rpcUrl, {
    overrideBundlerRpc: getPimlicoRpcUrl(network),
    backupBundlerRpc: getStackupRpcUrl(network),
    chainId: getNetwork(network).chainId,
  });
};
