import React from "react";
import classNames from "classnames";

import "./Row.css";

interface RowProps {
  title?: string | React.ReactNode;
  subtitle?: string;
  trailingContent?: React.ReactNode;
  leadingContent?: React.ReactNode;
  onClick?: () => void;
  hasBottomBorder?: boolean;
  hasHorizontalPadding?: boolean;
  deemphasizeTitle?: boolean;
  verticalAlignment?: "top" | "center";
  children?: React.ReactNode;
  className?: string;
  selected?: boolean;
  compact?: boolean;
  dataCy?: string;
}

const Row = ({
  title,
  subtitle,
  trailingContent,
  leadingContent,
  onClick,
  hasBottomBorder = true,
  hasHorizontalPadding = true,
  deemphasizeTitle = false,
  verticalAlignment,
  children,
  className,
  selected,
  compact,
  dataCy,
}: RowProps) => {
  return (
    <div
      className={classNames([
        "Row",
        className,
        {
          "has-bottom-border": hasBottomBorder,
          "is-clickable": onClick,
          "has-horizontal-padding": hasHorizontalPadding,
          "verticalAlign-top": verticalAlignment === "top",
          "deemphasized-title": deemphasizeTitle,
          "is-selected": selected,
          compact: compact,
        },
      ])}
      onClick={onClick}
    >
      <div className="Row_content" data-cy={dataCy}>
        {leadingContent && <div className="Row_leading">{leadingContent}</div>}
        <div className="Row_body">
          {title && (typeof title === "string" ? <p className="Row_title">{title}</p> : title)}
          {subtitle && <p className="Row_subtitle">{subtitle}</p>}
          {children}
        </div>
        {trailingContent && <div className="Row_trailing">{trailingContent}</div>}
      </div>
    </div>
  );
};

export default Row;
