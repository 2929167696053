import React, { CSSProperties, ReactNode, SetStateAction, useEffect, useState } from "react";
import { BigNumber, ethers, utils } from "ethers";
import classNames from "classnames";
import { sentenceCase } from "sentence-case";
import { useNavigate } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";

import "./NumberPad.css";

import { LinkButton } from "@components/button/Button";
import { BalanceAvailableSheet } from "@components/numberpad/BalanceAvailableSheet/BalanceAvailableSheet";

import { UserToken } from "@constants/tokens";
import { useAccount } from "@contexts/AccountContext";
import { formatTokenAmount, roundMax } from "@helpers";
import { getTokenManager } from "@modules/token-managers/tokens";
import { ReactComponent as BackspaceIcon } from "@assets/icons/backspace.svg";

import { TokenIcon } from "../token";

export interface NumberPadProps {
  value: string;
  setValue: React.Dispatch<SetStateAction<string>>;
  token: UserToken;
  fee?: BigNumber;
  maxAmount?: BigNumber;
  transparent?: boolean;
  style?: CSSProperties;
  header?: React.ReactNode;
  message?: React.ReactNode;
  keyboardDisabled?: boolean;
  className?: string;
  action?: string;
  maxDecimals?: number;
  amountView?: React.ReactNode;
}

const MAX_NUM_INTEGERS = 5;

export const NumberPad: React.FC<React.PropsWithChildren<NumberPadProps>> = ({
  value,
  setValue,
  token,
  fee = ethers.constants.Zero,
  transparent = false,
  style = {},
  header,
  children,
  message,
  keyboardDisabled = false,
  maxAmount,
  className,
  action = "send",
  maxDecimals = 6,
  amountView,
}) => {
  const navigate = useNavigate();
  const tokenManager = getTokenManager(token);

  // If max amount is not set, use the balance of the token
  if (!maxAmount) {
    const { balances } = useAccount();
    maxAmount = balances[token].total;
  }

  const [feeDrawerOpen, setFeeDrawerOpen] = useState(false);

  const maxAvailable = fee.lt(maxAmount) ? maxAmount.sub(fee) : ethers.constants.Zero;
  const aboveMax = ethers.utils.parseUnits(value, tokenManager.decimals).gt(maxAmount.sub(fee));

  maxDecimals = Math.min(maxDecimals, tokenManager.decimals);

  const hitNumber = (valToAdd: number | ".") => {
    setValue((currVal: string) => {
      let newVal = currVal;
      const decimalsEnabled = newVal.indexOf(".") >= 0;
      const numIntegers = decimalsEnabled ? newVal.indexOf(".") : newVal.length;
      const numDecimals = decimalsEnabled ? newVal.length - newVal.indexOf(".") : 0;

      if (valToAdd === ".") {
        if (currVal.includes(".")) return newVal;
      } else if (
        (decimalsEnabled || numIntegers >= MAX_NUM_INTEGERS) && // max character length
        (!decimalsEnabled || numDecimals > maxDecimals)
      )
        return newVal;

      newVal = currVal.concat(valToAdd.toString());

      // add leading zero
      if (newVal[0] === ".") {
        newVal = "0".concat(newVal);
      }
      // remove unnecessary leading zeros
      if (newVal.length > 1) {
        while (newVal[0] === "0" && newVal[1] !== "." && newVal.length > 1) {
          newVal = newVal.substring(1, newVal.length);
        }
      }
      return newVal;
    });
  };

  const backspace = () => {
    if (value.length) {
      setValue(currVal => currVal.substring(0, currVal.length - 1) || "0");
    }
  };

  const setMax = () => {
    const max = ethers.utils.formatUnits(maxAvailable, tokenManager.decimals);
    // enforce decimal limit but remove commas
    setValue(roundMax(parseFloat(max.replace(",", "")), 3).toString());
  };

  useEffect(() => {
    if (!keyboardDisabled) {
      const keyPressed = ({ key }: KeyboardEvent) => {
        if (key === "Backspace") {
          backspace();
        } else if (key === "." || !isNaN(parseInt(key))) {
          // validate key pressed
          hitNumber(key as number | ".");
        }
      };
      window.addEventListener("keydown", keyPressed);
      return () => window.removeEventListener("keydown", keyPressed);
    }
  }, [keyboardDisabled]);

  return (
    <div
      className={classNames("NumberPad", className, {
        "NumberPad-withHeader": header,
        "is-disabled": keyboardDisabled,
      })}
      style={{ ...style }}
    >
      {keyboardDisabled && <div className="NumberPad_block" />}
      {header && <div className="NumberPad_header">{header}</div>}
      <div className="NumberPad_amountContainer">
        {amountView || (
          <div className="NumberPad_amount">
            <div className="NumberPad_amountIcon">
              <TokenIcon token={token} />
            </div>

            <div className="NumberPad_amountValue">{value}</div>
          </div>
        )}
        <div className="NumberPad_messageContainer">
          {message ??
            (!maxAvailable.isZero() ? (
              <>
                <span onClick={() => setFeeDrawerOpen(true)}>
                  {aboveMax && value && parseFloat(value) > 0 ? (
                    <>Not enough {tokenManager.symbol}</>
                  ) : (
                    <>
                      {formatTokenAmount(utils.formatUnits(maxAvailable, tokenManager.decimals), 3)}{" "}
                      {tokenManager.symbol} available to {action}{" "}
                    </>
                  )}
                  <QuestionCircleOutlined color={transparent ? "#FFF" : "#000"} style={{ cursor: "pointer" }} />
                </span>
                <span>&nbsp;{" · "}&nbsp;</span>
                <LinkButton title={`${sentenceCase(action)} max`} onClick={setMax} size="small" />
              </>
            ) : (
              <>
                <span onClick={() => setFeeDrawerOpen(true)}>
                  <>You don&apos;t have any {tokenManager.symbol}</>
                  <QuestionCircleOutlined color={transparent ? "#FFF" : "#000"} style={{ cursor: "pointer" }} />
                </span>
                <span>&nbsp;{" · "}&nbsp;</span>
                <LinkButton title="Fund account" onClick={() => navigate("/deposit")} size="small" />
              </>
            ))}
        </div>
      </div>

      <div className="NumberPad_keypad">
        <div className="NumberPad_keypadRow">
          <Number value={1} onClick={() => hitNumber(1)} dataCy="num-1" />
          <Number value={2} onClick={() => hitNumber(2)} dataCy="num-2" />
          <Number value={3} onClick={() => hitNumber(3)} dataCy="num-3" />
        </div>
        <div className="NumberPad_keypadRow">
          <Number value={4} onClick={() => hitNumber(4)} dataCy="num-4" />
          <Number value={5} onClick={() => hitNumber(5)} dataCy="num-5" />
          <Number value={6} onClick={() => hitNumber(6)} dataCy="num-6" />
        </div>
        <div className="NumberPad_keypadRow">
          <Number value={7} onClick={() => hitNumber(7)} dataCy="num-7" />
          <Number value={8} onClick={() => hitNumber(8)} dataCy="num-8" />
          <Number value={9} onClick={() => hitNumber(9)} dataCy="num-9" />
        </div>
        <div className="NumberPad_keypadRow">
          <Number muted value={"."} color="transparent" onClick={() => hitNumber(".")} dataCy="num-decimal" />
          <Number value={0} onClick={() => hitNumber(0)} dataCy="num-0" />
          <Number
            muted
            color="transparent"
            onClick={backspace}
            value={<BackspaceIcon />}
            visuallyHidden={value === "0"}
            dataCy="num-backspace"
          />
        </div>
      </div>
      <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", zIndex: 2 }}>
        {children}
      </div>
      <BalanceAvailableSheet
        action={action}
        token={token}
        open={feeDrawerOpen}
        fee={fee}
        balance={maxAmount}
        onClose={() => setFeeDrawerOpen(false)}
      />
    </div>
  );
};

interface NumberProps {
  value: number | string | ReactNode;
  color?: string;
  onClick: () => void;
  muted?: boolean;
  visuallyHidden?: boolean;
  dataCy?: string;
}

const Number: React.FC<NumberProps> = ({ value, muted, visuallyHidden, onClick, dataCy }) => {
  return (
    <button
      className={`NumberPad_key ${muted ? "is-muted" : ""} ${visuallyHidden ? "is-hidden" : ""}`}
      onClick={onClick}
      data-cy={dataCy}
    >
      <label>{value}</label>
    </button>
  );
};
