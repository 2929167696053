import { useEffect } from "react";
import { notification } from "antd";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { logError } from "@helpers";

import { useAppDispatch } from "@redux";
import { RootState } from "@redux/store";
import { setFeeConfig } from "@redux/slides/config.slide";

import { RELAYER_GET_FEES_ENDPOINT, UserToken } from "@constants";

interface FeeResponse {
  base: {
    recipient: string;
  };
  optimism: {
    recipient: string;
  };
  flat: {
    eco: string;
    ecox: string;
    oak: string;
    usdc: string;
  };
}

export const useConfigFeeInit = (): RootState["config"]["fee"] | undefined => {
  const dispatch = useAppDispatch();

  const { data } = useQuery(
    ["flat-fee"],
    async () => {
      try {
        const response = await axios.get<FeeResponse>(RELAYER_GET_FEES_ENDPOINT, {
          retry: 999999,
          retryTimeout: 4000,
        } as AxiosRequestConfig);
        const { data } = response;
        return {
          recipients: {
            base: data.base.recipient,
            optimism: data.optimism.recipient,
          },
          perToken: {
            [UserToken.ECO]: data.flat.eco,
            [UserToken.ECOx]: data.flat.ecox,
            [UserToken.OAK]: data.flat.oak,
            [UserToken.USD]: data.flat.usdc,
          },
        };
      } catch (error) {
        logError("[fetch-fees]", error);
        notification.error({ message: "Unable to determine fees" });
        return null;
      }
    },
    {
      initialData: null,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    } as never,
  );

  useEffect(() => {
    if (data) {
      dispatch(setFeeConfig(data));
    }
  }, [data]);

  return data || undefined;
};
