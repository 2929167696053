import React from "react";
import View from "@components/view/View";
import { BodyText, HeaderText, TextBlock } from "@components/text/Text";

export const TermsOfService = () => {
  return (
    <View headerTitle="Terms of Service" metaTitle="Terms of Service">
      <div style={{ padding: 16 }}>
        <TextBlock>
          <HeaderText>BEAM WALLET TERMS OF SERVICE</HeaderText>

          <BodyText>Last updated: July 16, 2023</BodyText>
          <BodyText>
            Beam Wallet LLC (together with its affiliates, “Beam” or “we” or “us”) makes available to users (“you” or
            “Users”) the website www.beam.eco (the “Site”) and through the Site certain software services (together with
            the Site, the “Services”), including a non-custodial digital wallet service that provides a front-end
            interface for you to (i) generate digital wallets to store certain digital assets (the “Supported Assets”),
            and (ii) see, send, and receive Supported Assets using such a generated digital wallet. We may add or remove
            functionality from the Services from time to time without notice to you.
          </BodyText>
          <HeaderText>Agreement to Terms</HeaderText>
          <BodyText>
            By using any of the Services, you agree to be bound by these terms of service (the “Terms”). If you
            don&apos;t agree to be bound by these Terms, you may not access or use the Services.
          </BodyText>
          <BodyText>
            These Terms include, in the “Dispute Resolution, Arbitration Agreement, Class Action Waiver, and Jury Trial
            Waiver” section, an agreement to resolve disputes by arbitration on an individual basis as well as a class
            action waiver. It is also important that you review the “Limitation of Liability” and “Warranty Disclaimer”
            sections of these Terms.
          </BodyText>
          <HeaderText>Privacy Policy</HeaderText>
          <BodyText>
            Please refer to our privacy policy, available at www.beam.eco/privacy for information on how we collect, use
            and disclose information from our App users.
          </BodyText>
          <HeaderText>Changes to Terms or Services</HeaderText>
          <BodyText>
            We may modify the Terms at any time at our sole discretion, with or without notice to you. The modified
            Terms will be effective at the time they are posted on the Site. It&apos;s important that you review the
            Terms regularly for modifications because if you continue to use the Services after we have modified the
            Terms, you are agreeing to be bound by the modified Terms. If you don&apos;t agree to be bound by the
            modified Terms, then you may not use the Services. Because our Services are evolving over time we may change
            or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.
          </BodyText>
          <HeaderText>Who May Use the Services</HeaderText>
          <BodyText>
            You may use the Services if you are 18 years or older and are not barred from using the Services under
            applicable law. You may not use the Services if (a) you are a sanctioned person identified by the U.S.
            Department of the Treasury&apos;s Office of Foreign Assets Control Specially Designated Nationals and
            Blocked Persons (“SDN”), or other U.S. non-SDN restricted or prohibited parties lists, (b) you are
            organized, resident, or located in a comprehensively sanctioned jurisdiction, or (c) your use would violate
            other applicable laws or regulations.
          </BodyText>
          <HeaderText>Digital Wallet Generation</HeaderText>
          <BodyText>
            The Services provide a convenient front-end interface that allows you to generate a digital wallet that is
            on a blockchain network (your “Wallet”) to store Supported Assets. As a part of generating your Wallet, you
            will also generate a private key (your “Private Key”) that can be used to authorize transactions using your
            Wallet, as well as to import your Wallet into a different service. The Services are a non-custodial service,
            which means we do not store, nor do we have access to, your Wallet, Private Key, or Supported Assets. We do
            not have access to or store passwords, Private Keys, passphrases, transaction history, or other credentials
            associated with your use of the Services or your Wallet. We are not in a position to help you retrieve your
            credentials or Private Key. You are solely responsible for remembering, storing, and keeping your Private
            Key or the credentials or components for any other method you use to access your Wallet (“Access Methods”)
            in a secure manner. Any third party with knowledge of your Access Methods can gain control of your Wallet,
            and therefore steal your Supported Assets, without any possibility for you or Beam to retrieve them.
          </BodyText>
          <BodyText>
            IF YOU LOSE YOUR ACCESS METHODS, YOU WILL NOT BE ABLE TO ACCESS YOUR SUPPORTED ASSETS. YOU ACKNOWLEDGE THAT
            BEAM IS NOT RESPONSIBLE IN ANY WAY FOR THE SECURITY OF YOUR ACCESS METHODS. YOU AGREE TO HOLD BEAM AND ITS
            AFFILIATES HARMLESS FOR ANY LOSSES ARISING FROM YOUR LOSING YOUR ACCESS METHODS, INCLUDING YOUR PRIVATE KEY.
            YOU AGREE THAT BEAM AND ITS AFFILIATES SHALL NOT BE LIABLE IN ANY WAY IF YOU LOSE YOUR ACCESS METHODS.
          </BodyText>
          <HeaderText>Storing, Sending, and Receiving Assets</HeaderText>
          <BodyText>
            The Services also provide a convenient front-end interface for you to easily access your Wallet in order to
            see, send, and receive your Supported Assets. Because of this ease of access as well as the developmental
            nature of the Services, you should not store a large value of Supported Assets in your Wallet. In general,
            you should store at most the equivalent of US$1,000.00 in your Wallet; however, what constitutes a “large”
            value depends on your specific circumstances and how much you can afford to lose, which may be substantially
            less than US$1,000.00.
          </BodyText>
          <BodyText>
            Sending and receiving Supported Assets through the Services involves transactions confirmed and recorded on
            blockchain networks. Such networks are decentralized peer-to-peer networks run by independent third parties,
            which we do not own, control, or operate. You will be charged a network fee denominated in order to send
            Supported Assets, and this fee will be denominated in the Supported Asset being sent. We have no control
            over blockchain networks and, therefore, cannot and do not ensure that the transactions you broadcast on the
            Services will be confirmed and processed. You acknowledge that we do not store, send, or receive Supported
            Assets on your behalf and you agree that the transactions you configure through the Services may fail, or
            may be substantially delayed by the underlying blockchain networks. On occasions, the blockchain protocol of
            a given Supported Asset may change, which may have consequences on its key characteristics including but not
            limited to their availability, name, security, valuation value or the way it operates.
          </BodyText>
          <HeaderText>Blockchain Network Risks</HeaderText>
          <BodyText>
            You acknowledge that you are fully aware of all applicable laws and technical constraints relating to the
            blockchain networks applicable to your use of the Services. You acknowledge that you have been warned of the
            following associated risks and advised of the following recommendations:
          </BodyText>
          <ul>
            <li>
              Regulatory Changes. Blockchain technologies and related services are subject to continuous regulatory
              changes and scrutiny around the world, including but not limited to anti-money laundering and financial
              regulations. You acknowledge that certain Services, including their availability, could be impacted by one
              or more regulatory requirements.
            </li>
            <li>
              Tax. Transactions or operations in cryptoassets, including the Supported Assets, including but not limited
              to exchanges, air-drops, forks, and gains arising from staking, may be considered tax events according to
              the law under which you are subject to taxation. These rules may be unclear or subject to change, and you
              are therefore encouraged to consult your own tax or accounting adviser before engaging in cryptoasset
              activities.
            </li>
            <li>
              Technology. You understand that some of the technology supported or made available through the Services
              are new and untested, and not provided by Beam and therefore outside of Beam&apos;s control. Advances in
              cryptography, or other technical advances such as the development of quantum computers, could present
              risks to blockchain networks which could result in the theft or loss of Supported Assets. Other adverse
              changes in market forces or in the technology, broadly construed, may prevent or compromise the Services.
            </li>
            <li>
              Cybersecurity. Hackers or other groups or organizations may attempt to interfere with the Services and
              your Wallet in several ways, including without limitation denial of service attacks, side-channel attacks,
              spoofing, smurfing, malware attacks, or consensus-based attacks.
            </li>
          </ul>
          <HeaderText>Services in Development; Feedback</HeaderText>
          <BodyText>
            The Services are subject to ongoing development and are not guaranteed to be free of bugs or errors. Please
            review the “Warranty Disclaimers” section of these Terms carefully.
          </BodyText>
          <BodyText>
            We welcome feedback, comments, ideas, and suggestions for improvements to the Services (“Feedback”). You can
            submit Feedback by contacting us at support@beam.eco. You grant to us a non-exclusive, worldwide, perpetual,
            irrevocable, fully-paid, royalty-free, sublicensable and transferable license under any and all intellectual
            property rights that you own or control to use, copy, modify, create derivative works based upon and
            otherwise exploit the Feedback for any purpose.
          </BodyText>
          <HeaderText>Ownership and Control of Supported Assets</HeaderText>
          <BodyText>
            You own and control Supported Assets held in your Wallet. You shall bear all risk of loss of your Supported
            Assets. We shall have no liability for digital asset changes in value or any loss associated with your use
            of the Services. At any time, subject to outages, downtime, and other applicable policies, you may move
            Supported Assets from your Wallet by sending it to a different blockchain address.
          </BodyText>
          <HeaderText>Rights in Site and Services Granted by Beam</HeaderText>
          <BodyText>
            The Site and the Services are proprietary to Beam and its licensors and must not be used other than strictly
            in accordance with these Terms. Beam grants to you a limited, non-exclusive, non-transferable,
            non-sublicensable license to use the Site for the purposes of accessing and using the Services in accordance
            with these Terms.
          </BodyText>
          <HeaderText>Acceptable Use</HeaderText>
          <BodyText>You agree not to use the Services in ways that:</BodyText>
          <ul>
            <li>
              violate, misappropriate, or infringe the rights of Beam, our users, or others, including privacy,
              publicity, intellectual property, or other proprietary rights;
            </li>
            <li>Are illegal, defamatory, threatening, intimidating, or harassing;</li>
            <li>Involve impersonating someone;</li>
            <li>
              Breach any duty toward or rights of any person or entity, including rights of publicity, privacy, or
              trademark;
            </li>
            <li>
              Involve sending illegal or impermissible communications such as bulk messaging, auto-messaging,
              auto-dialing, and the like;
            </li>
            <li>
              Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure
              implemented by us or any of our service providers or any other third party (including another user) to
              protect the Services or Content;
            </li>
            <li>Disguise your location through IP proxying or other methods;</li>
            <li>
              Interfere with, or attempt to interfere with, the access of any user, host or network, including, without
              limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;
            </li>
            <li>Violate any applicable law or regulation; or</li>
            <li>Encourage or enable any other individual to do any of the foregoing.</li>
          </ul>
          <BodyText>
            You agree to comply with all applicable laws including but not limited to money laundering laws, export
            restrictions, end-user restrictions, antiterrorism laws, and economic sanctions. You are not permitted to
            use the Site or the Services if doing so would violate applicable laws and regulations, including but not
            limited to those promulgated by the United Nations Security Council, Singapore, the United Kingdom, the
            United States (including those prohibiting dealings with sanctioned persons identified by the U.S.
            Department of the Treasury&apos;s Office of Foreign Assets Control Specially Designated Nationals and
            Blocked Persons (“SDN”), or other U.S. non-SDN restricted or prohibited parties lists, and those prohibiting
            dealings with persons organized, resident, or located in comprehensively sanctioned jurisdictions), and/or
            any other applicable national, provincial, federal, state, municipal or local laws and regulations (each as
            amended from time to time).
          </BodyText>
          <HeaderText>Third Party Services</HeaderText>
          <BodyText>
            The Services may contain links to third-party services (“Third Party Materials”). When using any Third Party
            Materials, you understand that you are at no time transferring your assets to us. We provide access to Third
            Party Materials only as a convenience, do not have control over their content, and do not recommend, warrant
            or endorse, and are not responsible for the availability or legitimacy of, the content, products, services,
            or assets on or accessible from those Third Party Materials (including any related websites, resources or
            links displayed therein). You are solely responsible for any fees or costs associated with accessing Third
            Party Materials.
          </BodyText>
          <BodyText>
            We make no warranties or representations, express or implied, about such linked Third Party Materials, the
            third parties they are owned and operated by, the information contained on them, the suitability of their
            products or services, or the assets they make accessible. You acknowledge sole responsibility for and assume
            all risk arising from your use of any Third Party Materials, third-party websites, applications, or
            resources. Third Party Materials, such as Dapps and DEXs, may provide access to assets which have high risks
            of illiquidity, devaluation, lockup, or loss. We shall not bear any liability, whatsoever, for any damage
            caused by any Third-Party Materials. You should use care in linking your Wallet with any Third-Party
            Materials or otherwise providing any third-parties with access to your App.
          </BodyText>
          <HeaderText>Termination</HeaderText>
          <BodyText>
            We may terminate your access to and use of the Services, at our sole discretion, at any time and without
            notice to you. Upon any termination, discontinuation or cancelation of Services, all rights and/or licenses
            granted to you under these Terms shall immediately cease and terminate and you shall forthwith cease the use
            and/or access of the Site and the Services in any way whatsoever. You will still be able to use your Private
            Key to import your Wallet elsewhere to send and receive Supported Assets.
          </BodyText>
          <HeaderText>Warranty Disclaimers</HeaderText>
          <BodyText>
            YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE SITE AND THE SERVICES AND CONTENT IS AT YOUR SOLE RISK
            AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU. THE SITE
            AND SERVICES ARE PROVIDED ON AN &apos;AS IS&apos; AND &apos;AS AVAILABLE&apos; BASIS WITHOUT ANY
            REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY. TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW, BEAM SPECIFICALLY DISCLAIMS ANY EXPRESS OR IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. BEAM DOES NOT MAKE ANY REPRESENTATIONS OR
            WARRANTIES THAT ACCESS TO THE SERVICES OR ANY OF THE MATERIALS CONTAINED THEREIN WILL BE CONTINUOUS,
            UNINTERRUPTED, TIMELY, OR ERROR-FREE. SERVICE INTERRUPTIONS MAY CAUSE YOU TO BE SIGNED OUT OF YOUR ACCOUNT
            AND REQUIRE YOU TO RE-ENTER YOUR PRIVATE KEY OR OTHERWISE USE YOUR ACCESS METHODS TO REGAIN ACCESS.
          </BodyText>
          <BodyText>
            YOU ARE SOLELY IN CONTROL OF AND RESPONSIBLE FOR STORING AND SECURING YOUR ACCESS METHODS, INCLUDING YOUR
            PRIVATE KEY, FOR YOUR WALLET. BEAM DOES NOT STORE A BACKUP OF, AND WILL NOT BE ABLE TO RECOVER, YOUR PRIVATE
            KEY OR ANY OF YOUR ACCESS METHODS. IF YOU LOSE ALL OF YOUR ACCESS METHODS, THEN YOU WILL LOSE ACCESS TO ALL
            ASSETS YOU HAVE STORED IN YOUR WALLET. DO NOT LOSE ALL OF YOUR ACCESS METHODS. YOU SHOULD ALWAYS BACKUP YOUR
            ACCESS METHODS, INCLUDING YOUR PRIVATE KEY, IN A SECURE MANNER.
          </BodyText>
          <HeaderText>Indemnity</HeaderText>
          <BodyText>
            You agree that you will indemnify and hold harmless Beam and its affiliates, officers, directors, employees
            and agents, from and against any claims, disputes, demands, liabilities, damages, losses, and costs and
            expenses, including, without limitation, reasonable legal and accounting fees arising out of or in any way
            connected with (i) your access to or use of the Site or the Services, (ii) Third Party Materials, or (iii)
            your violation of these Terms.
          </BodyText>
          <HeaderText>Limitation of Liability</HeaderText>
          <BodyText>
            TO THE MAXIMUM EXTENT NOT PROHIBITED BY LAW, BEAM AND ITS AFFILIATES SHALL NOT BE LIABLE FOR DAMAGES OF ANY
            TYPE, WHETHER DIRECT OR INDIRECT, ARISING OUT OF OR IN ANY WAY RELATED TO YOUR USE OR INABILITY TO USE THE
            SERVICES, INCLUDING BUT NOT LIMITED TO DAMAGES ALLEGEDLY ARISING FROM THE COMPROMISE OR LOSS OF YOUR WALLET,
            SUPPORTED ASSETS, ACCESS METHODS, PRIVATE KEY, OR FUNDS, OR LOSS OF OR INABILITY TO RESTORE ACCESS FROM YOUR
            ACCESS METHODS OR PRIVATE KEY, OR FOR MISTAKES, OMISSIONS, INTERRUPTIONS, DELAYS, DEFECTS AND/OR ERRORS IN
            THE TRANSMISSION OF TRANSACTIONS OR MESSAGES TO ANY DIGITAL ASSET NETWORK, OR THE FAILURE OF ANY MESSAGE TO
            SEND OR BE RECEIVED BY THE INTENDED RECIPIENT IN THE INTENDED FORM, OR FOR DIMINUTION OF VALUE OF ETHER OR
            ANY OTHER DIGITAL TOKEN OR DIGITAL ASSET ON A DIGITAL ASSET NETWORK. BEAM SHALL NOT BE LIABLE UNDER ANY
            CIRCUMSTANCES FOR ANY LOST PROFITS OR ANY SPECIAL, INCIDENTAL, INDIRECT, INTANGIBLE, OR CONSEQUENTIAL
            DAMAGES, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN
            CONNECTION WITH AUTHORIZED OR UNAUTHORIZED USE OF THE SERVICES, EVEN IF AN AUTHORIZED REPRESENTATIVE OF BEAM
            HAS BEEN ADVISED OF OR KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
          </BodyText>
          <BodyText>
            BEAM MAKES NO WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED, ABOUT LINKED THIRD PARTY SERVICES, THE
            THIRD PARTIES THEY ARE OWNED AND OPERATED BY, THE INFORMATION CONTAINED ON THEM, ASSETS AVAILABLE THROUGH
            THEM, OR THE SUITABILITY, PRIVACY, OR SECURITY OF THEIR PRODUCTS OR SERVICES. YOU ACKNOWLEDGE SOLE
            RESPONSIBILITY FOR AND ASSUME ALL RISK ARISING FROM YOUR USE OF THIRD-PARTY SERVICES, THIRD-PARTY WEBSITES,
            APPLICATIONS, OR RESOURCES, INCLUDING RISK OF LOSS FOR ASSETS TRADED THROUGH SUCH THIRD-PARTY SERVICES. BEAM
            SHALL NOT BE LIABLE UNDER ANY CIRCUMSTANCES FOR DAMAGES ARISING OUT OF OR IN ANY WAY RELATED TO SOFTWARE,
            PRODUCTS, SERVICES, AND/OR INFORMATION OFFERED OR PROVIDED BY THIRD-PARTIES AND ACCESSED THROUGH THE APP,
            SITE OR SERVICES, INCLUDING ANY DAPPS.
          </BodyText>
          <BodyText>
            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT SHALL THE TOTAL AGGREGATE
            LIABILITY OF BEAM AND ITS AFFILIATES TO YOU FOR ALL DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW
            IN CASES INVOLVING PERSONAL INJURY) EXCEED THE AMOUNT OF ONE HUNDRED U.S. DOLLARS (US$100.00) OR ITS
            EQUIVALENT IN THE LOCAL CURRENCY OF THE APPLICABLE JURISDICTION.
          </BodyText>
          <HeaderText>Dispute Resolution, Arbitration Agreement, Class Action Waiver, and Jury Trial Waiver</HeaderText>
          <ul>
            <li>
              Nature of Arbitration. Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral
              arbitrator instead of a judge or jury, and court review of an arbitration award is very limited. However,
              the arbitrator can award the same damages and relief on an individual basis that a court can award to an
              individual. All issues are for the arbitrator to decide, including issues relating to arbitrability, the
              scope or enforceability of this agreement to arbitrate, and issues that this agreement to arbitrate
              indicates that a court can resolve.
            </li>
            <li>
              Scope of Arbitration. You and Beam agree that any complaint, dispute, controversy or claim, whether
              founded in contract, tort, statutory or common law, concerning or arising out of these Terms or your use
              of the Services, including the applicability, scope or validity of this agreement to arbitrate (a
              “Claim”), shall be pursuant to the binding arbitration provisions below. Such Claim will be resolved
              exclusively by individual (not class or classwide) binding arbitration with a single neutral arbitrator
              who has the power to award the same individual damages and individual relief that a court can award.
              Notwithstanding the foregoing, you and Beam each may: (i) bring an individual action in small claims
              court, and (ii) seek injunctive or other equitable relief in a court of competent jurisdiction to prevent
              the actual or threatened infringement, misappropriation or violation of a party&apos;s copyrights,
              trademarks, trade secrets, patents or other intellectual property rights.
            </li>
            <li>
              Arbitration Procedures. The arbitration shall be administered by the American Arbitration Association
              (“AAA”) in accordance with the then-current Consumer Arbitration Rules, including the Procedures for the
              Resolution of Disputes through Document Submission (the “AAA Rules”), as may be modified by this agreement
              to arbitrate. The AAA Rules are available at www.adr.org. In the event the AAA is unavailable or unwilling
              to administer the arbitration consistent with this agreement to arbitrate, another administrator will be
              selected by the parties or by a court. The arbitrator shall be a practicing attorney or retired judge
              having experience with and knowledge of online commerce law and, to the extent possible, laws governing
              digital assets. Any arbitration hearings shall be held in the county in which you reside or at another
              mutually-agreed location. If the value of the relief sought is $25,000 or less, the arbitration will be
              conducted based solely on written submissions pursuant to the AAA Rules, unless you or Beam request
              otherwise pursuant to the AAA Rules. The arbitrator shall decide the substance of all Claims in accordance
              with applicable law. The arbitrator shall be responsible for determining all threshold arbitrability
              issues, including issues relating to whether this User Agreement (including the agreement to arbitrate) or
              any other terms of use applicable to the Services are enforceable, unconscionable, or illusory, as well as
              any defense to arbitration, including waiver, delay, laches, or estoppel. The award of the arbitrator
              shall be final and binding, and a judgment on the award may be entered by any court having jurisdiction.
              The parties agree and acknowledge that this agreement evidences a transaction involving interstate
              commerce and that the Federal Arbitration Act (Title 9 of the United States Code) shall govern the
              interpretation, enforcement, and proceedings pursuant to the agreement to arbitrate in this User
              Agreement.
            </li>
            <li>
              Costs of Arbitration. Payment of all AAA or arbitrator fees will be governed by the AAA Rules. In
              accordance with the AAA Rules, the party initiating the arbitration (either you or Beam) is responsible
              for paying the applicable filing fee. If the value of the relief you seek for a Claim is more than $10,000
              and you are able to demonstrate that the costs of accessing arbitration will be prohibitive as compared to
              the costs of accessing a court for purposes of pursuing litigation on an individual basis, Beam will pay
              as much of the AAA or arbitrator fees as the arbitrator deems necessary to prevent the cost of accessing
              the arbitration from being prohibitive. Each party shall be responsible for paying its own attorneys&apos;
              fees; provided, however, that in the event the arbitrator determines that either the substance of your or
              Beam&apos;s claim or the relief sought was frivolous or brought for an improper purpose, then you or Beam
              may seek to recover from you or Beam any fees it paid, including reasonable attorneys&apos; fees, to the
              extent permitted by the AAA Rules and applicable law.
            </li>
            <li>
              Class Action and Jury Trial Waiver. TO THE EXTENT PERMISSIBLE BY LAW, YOU AND BEAM EACH AGREE THAT ALL
              CLAIMS MUST BE BROUGHT IN A PARTY&apos;S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
              ANY PURPORTED CLASS, COLLECTIVE ACTION, OR REPRESENTATIVE PROCEEDING (COLLECTIVELY “CLASS ACTION WAIVER”).
              UNLESS BOTH YOU AND BEAM AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON&apos;S
              CLAIMS OR ENGAGE IN ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS ARBITRATION. IN ADDITION, THE
              ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE
              INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT
              PARTY&apos;S INDIVIDUAL CLAIM(S). YOU ACKNOWLEDGE THAT, BY AGREEING TO THESE TERMS, YOU AND BEAM ARE EACH
              WAIVING THE RIGHT TO A TRIAL BY JURY AND THE RIGHT TO PARTICIPATE IN A CLASS ACTION. IF THE CLASS ACTION
              WAIVER IS HELD TO BE UNENFORCEABLE WITH RESPECT TO A CLAIM, THEN THIS AGREEMENT TO ARBITRATE SHALL NOT
              APPLY TO THAT CLAIM.
            </li>
            <li>
              Severability. With the exception of the Class Action Waiver above, if any provision of this agreement to
              arbitrate is found unenforceable, the unenforceable provision will be severed, and the remaining terms
              will be enforced.
            </li>
          </ul>
          <HeaderText>General Terms</HeaderText>
          <BodyText>
            These Terms constitute the entire and exclusive understanding and agreement between Beam and you regarding
            the Site and the Services, and these Terms supersede and replace any and all prior oral or written
            understandings or agreements between Beam and you regarding the same. If any provision of these Terms is
            held invalid or unenforceable (either by an arbitrator appointed pursuant to these Terms or by a court of
            competent jurisdiction), then that provision will be enforced to the maximum extent permissible and the
            other provisions of these Terms will remain in full force and effect. You may not assign or transfer these
            Terms, by operation of law or otherwise, without our prior written consent. Any attempt by you to assign or
            transfer these Terms, without such consent, will be null and void. We may freely assign or transfer these
            Terms without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the
            parties, their successors and permitted assigns.
          </BodyText>
          <BodyText>
            Any notices or other communications provided by us under these Terms, including those regarding
            modifications to these Terms, will be given by posting to the Site or in the App, and/or through other
            electronic communication. You agree and consent to receive electronically all communications, agreements,
            documents, notices and disclosures (collectively, “Communications”) that we provide in connection with your
            use of the Site and the Services.
          </BodyText>
          <BodyText>
            These Terms and any action related thereto will be governed by the laws of the State of California in the
            United States of America without regard to its conflict of laws principles.
          </BodyText>
          <BodyText>
            Our failure to enforce any right or provision of these Terms will not be considered a waiver of such right
            or provision. The waiver of any such right or provision will be effective only if in writing and signed by a
            duly authorized representative of Beam. Except as expressly set forth in these Terms, the exercise by either
            party of any of its remedies under these Terms will be without prejudice to its other remedies under these
            Terms or otherwise.
          </BodyText>
          <HeaderText>Contact Information</HeaderText>
          <BodyText>
            If you have any questions about these Terms or the Services, please contact us at support@beam.eco.
          </BodyText>
        </TextBlock>
      </div>
    </View>
  );
};
