import React from "react";
import classNames from "classnames";
import styles from "./IconButton.module.css";

interface IconButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, "size" | "type"> {
  icon: React.ReactNode;
  size?: "normal" | "small" | "xsmall";
  dataCy?: string;
}

export const IconButton = ({ icon, size = "normal", className, dataCy, ...rest }: IconButtonProps) => {
  const dataCyValue = dataCy || (typeof className === "string" ? className.replace(/\s+/g, "") : dataCy);
  const iconClass = classNames(className, styles[size], styles.button);
  return (
    <button {...rest} className={iconClass} data-cy={dataCyValue}>
      {icon}
    </button>
  );
};
