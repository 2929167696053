import React from "react";
import { useQueryClient } from "@tanstack/react-query";

import { Button } from "@components/button/Button";
import { BodyText } from "@components/text/Text";
import { Callout } from "@components/callout/Callout";
import { useBridge } from "@components/account/withdraw/hooks/useBridge";
import { ProcessingView } from "@components/account/withdraw/bridge/common/ProcessingView";
import { AddBankForm, BankForm } from "@components/account/withdraw/bridge/bank/AddBankForm";
import { useWithdrawContext, WithdrawActionType } from "@components/account/withdraw/context/WithdrawContext";

import { logError, notify } from "@helpers";
import { IRegisterExternalAccount } from "@modules/bridge/types";

interface CreateCustomerStepProps {
  onComplete(): void;
}

export const AddBankStep: React.FC<CreateCustomerStepProps> = ({ onComplete }) => {
  const bridge = useBridge();
  const queryClient = useQueryClient();
  const { dispatch, loadingForm, setLoadingForm } = useWithdrawContext();

  const handleSubmit = async (values: BankForm) => {
    setLoadingForm(true);

    const data: IRegisterExternalAccount = {
      account_owner_name: values.ownerName,
      bank_name: values.bankName,
      account_number: values.accountNumber,
      routing_number: values.routingNumber,
    };

    try {
      const externalAccount = await bridge.registerExternalAccount(bridge.customerId!, data);

      dispatch({ type: WithdrawActionType.SET_EXTERNAL_ACCOUNT, externalAccount });
      queryClient.setQueryData(
        ["bridge-external-account", externalAccount.customer_id, externalAccount.id],
        externalAccount,
      );

      onComplete();
    } catch (error) {
      logError("bridge-create-bank", error);
      notify({ type: "error", content: "Unexpected error while adding bank account" });
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <div style={{ position: "relative", height: loadingForm ? "100%" : undefined }}>
      {!loadingForm && (
        <div style={{ marginBottom: "var(--size-medium)" }}>
          <Callout style="info">
            <BodyText small centered>
              The bank account you will withdraw into. Try to match the exact values provided by your bank.
            </BodyText>
          </Callout>
        </div>
      )}
      <AddBankForm onFinish={handleSubmit} />
      {loadingForm ? <ProcessingView /> : null}
    </div>
  );
};

export const AddBankFooter = () => {
  const { loadingForm, setShouldSubmitForm } = useWithdrawContext();
  return (
    <div>
      <Button title="Continue" loading={loadingForm} onClick={() => setShouldSubmitForm(true)} />
    </div>
  );
};
