import { ethers } from "ethers";

import { getTokenInfo, MULTICALL3_ADDRESS, Network, Token } from "@constants";

import { network, vending } from "@components/fund/constants";

import { calculateTransferAmounts } from "@helpers/transfer";

import { BalancesState } from "@redux/slides/balances/balances.types";
import { ERC20__factory, Multicall3__factory } from "@assets/contracts";

import { AutoSwap } from "@modules/blockchain/AutoSwap";
import { getNetworkProvider } from "@modules/blockchain/providers";
// import { getTokenManagerByToken } from "@modules/token-managers/tokens";

import Quoter from "@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json";
import config from "@constants/config";

interface IVendingMachinePrices {
  buy: ethers.BigNumber;
  sell: ethers.BigNumber;
}
interface IVendingMachineFees {
  buy: ethers.BigNumber;
  sell: ethers.BigNumber;
  recipient: string;
}

interface IVendingMachineTokens {
  eco: Token;
  usd: Token;
}

interface ITransfer {
  token: Token;
  amount: ethers.BigNumber;
}

export interface IVendingMachineOpts {
  recipient: string;
  prices: IVendingMachinePrices;
  fees: IVendingMachineFees;
  network: Network;
  balances: BalancesState["networks"];
  tokens: IVendingMachineTokens;
}

interface ISwapOpts {
  autoSwap: AutoSwap;
  amount: ethers.BigNumber;
  bips?: number;
  retries?: number;
}

type IBuyOpts = Omit<ISwapOpts, "amount">;

export class VendingMachine {
  static readonly SWAPPABLE_TOKENS = [Token.USDC, Token.USDT, Token.USDCe];
  private onExcludeAddress?: (addr: string) => void;

  constructor(public readonly opts: IVendingMachineOpts) {}

  /**
   * Calculate conversion amount
   * @param amount ECO amount in wei
   * @param price USD/ECO price
   * @return USDC amount in wei
   */
  static calculateConversion(amount: ethers.BigNumber, price: ethers.BigNumber) {
    const a = amount.mul(price).div(ethers.BigNumber.from(10).pow(18));
    const b = ethers.BigNumber.from(10).pow(18 - 6);

    const quotient = a.div(b);
    const remainder = a.mod(b);

    return !remainder.isZero() ? quotient.add(1) : quotient;
  }

  /**
   * Buy ECO tokens from the vending machine
   * @param amount ECO amount
   * @param feeAmount fee amount in ECO tokens
   * @param opts buy options
   */
  async buy(amount: ethers.BigNumber, feeAmount: ethers.BigNumber, opts?: IBuyOpts) {
    const txs: ethers.UnsignedTransaction[] = [];

    const usdBalance = this.getBalance(this.opts.tokens.usd);

    const buyUsdAmount = VendingMachine.calculateConversion(amount, this.opts.prices.buy);
    let usdAmount = buyUsdAmount.add(feeAmount);

    let transferFee: ITransfer;
    const transfersOut: ITransfer[] = [];

    // Determine if swap is needed
    if (usdAmount.gt(usdBalance)) {
      if (!opts) throw new Error("Swap is not configured");

      // Subtract fee since it can be paid in other coins
      // Subtract USDCe balance since it can be used directly
      let swapAmount = usdAmount.sub(this.opts.fees.buy).sub(usdBalance);

      if (swapAmount.isNegative()) swapAmount = ethers.constants.Zero;

      usdAmount = usdBalance;

      const swapData = await this.swap({ ...opts, amount: swapAmount });

      transferFee = swapData.feeTx;

      txs.push(...swapData.txs);
      transfersOut.push(
        { token: this.opts.tokens.usd, amount: usdAmount },
        ...swapData.swapInAmounts.map(swap => ({ token: swap.token, amount: swap.amount })),
      );
    } else {
      transferFee = { token: this.opts.tokens.usd, amount: this.opts.fees.buy };
      transfersOut.push({ token: this.opts.tokens.usd, amount: usdAmount.sub(this.opts.fees.buy) });
    }

    // Create approval data
    const approvalData = ERC20__factory.createInterface().encodeFunctionData("approve", [
      config.optimism.contracts.vendingMachine,
      buyUsdAmount,
    ]);

    // Create buy data from vending machine
    const buyData = vending.interface.encodeFunctionData("buy", [amount, this.opts.prices.buy]);

    // Create transfer fee data
    const transferFeeData = ERC20__factory.createInterface().encodeFunctionData("transfer", [
      this.opts.fees.recipient,
      transferFee.amount,
    ]);

    txs.push(
      { to: getTokenInfo(this.opts.tokens.usd, this.opts.network).address, data: approvalData },
      { to: config.optimism.contracts.vendingMachine, data: buyData },
      { to: getTokenInfo(transferFee.token, this.opts.network).address, data: transferFeeData },
    );

    return { transfersOut, transferFee, txs };
  }

  async swap(opts: ISwapOpts): Promise<{
    swapInAmounts: ITransfer[];
    swapOutAmounts: ITransfer[];
    feeTx: ITransfer;
    txs: ethers.UnsignedTransaction[];
  }> {
    const { autoSwap, bips = 14 } = opts;
    let { retries = 3 } = opts;
    let { amount: swapAmount } = opts;

    const maxSwapAmount = this.getSwappableTokenAmounts(this.opts.tokens.usd).reduce(
      (acc, { amount }) => acc.add(amount),
      ethers.constants.Zero,
    );

    // Add 0.014% to total to account for pool fee + slippage
    swapAmount = swapAmount.mul(100_000 + bips).div(100_000);
    if (swapAmount.gt(maxSwapAmount)) {
      retries = 0;
      swapAmount = maxSwapAmount;
    }

    const {
      trades,
      swapInAmounts,
      swapOutAmounts,
      fee: feeTx,
    } = await this.getTrades(autoSwap, this.opts.tokens.usd, swapAmount, this.opts.fees.buy);

    const swapOutAmount = swapOutAmounts.reduce((acc, { amount }) => acc.add(amount), ethers.constants.Zero);

    if (swapOutAmount.lt(opts.amount)) {
      if (!retries) throw new Error("Not enough USD after swaps");
      return this.swap({ ...opts, retries: retries - 1, bips: bips * 2 });
    }

    // Don't receive transfer notifications from uniswap pools
    Object.keys(AutoSwap.POOLS).forEach(poolAddr => this.onExcludeAddress && this.onExcludeAddress(poolAddr));

    const swapTxs = await autoSwap.swap(trades, this.opts.recipient);
    const approvalTxs = swapInAmounts.map(swap => ({
      to: getTokenInfo(swap.token, network).address,
      data: ERC20__factory.createInterface().encodeFunctionData("approve", [
        autoSwap.getSwapRouterAddress(),
        swap.amount,
      ]),
    }));

    return { swapInAmounts, swapOutAmounts, feeTx, txs: [...approvalTxs, ...swapTxs] };
  }

  getSwappableTokenAmounts(token: Token, balances = this.getBalances()) {
    return this.getAvailableTokens(token, balances)
      .map((token): ITransfer => ({ token, amount: ethers.BigNumber.from(balances[token]) }))
      .sort((left, right) => (left.amount.lt(right.amount) ? 1 : -1));
  }

  getTransferFee(
    fee: ethers.BigNumber,
    mainToken: Token,
    balances = this.getBalances(),
    tokenBalances = this.getSwappableTokenAmounts(mainToken, balances),
  ): ITransfer {
    const tokenInfo: ITransfer = { token: mainToken, amount: ethers.BigNumber.from(balances[mainToken]) };
    const allBalances = [...tokenBalances, tokenInfo];
    const feeTransfers = calculateTransferAmounts(
      ethers.constants.Zero,
      fee,
      allBalances.map(item => item.amount),
    ).map((transfer, index) => ({ token: allBalances[index].token, ...transfer }));

    const transfer = feeTransfers.find(transfer => !transfer.fee.isZero())!;
    return { token: transfer.token, amount: transfer.fee };
  }

  async getTrades(autoSwap: AutoSwap, token: Token, amount: ethers.BigNumber, fee: ethers.BigNumber) {
    const balances = this.getBalances();
    const tokenBalances = this.getSwappableTokenAmounts(token, balances);
    const feeTransfer = this.getTransferFee(fee, token, balances, tokenBalances);

    const amounts = calculateTransferAmounts(
      amount,
      ethers.constants.Zero,
      tokenBalances.map(item => {
        if (feeTransfer.token === item.token) return item.amount.sub(feeTransfer.amount);
        return item.amount;
      }),
    ).map((transfer, index) => ({ token: tokenBalances[index].token, ...transfer }));

    const swapBalances = amounts.reduce((acc, swap) => {
      if (!swap.amount.isZero()) {
        acc[swap.token] = swap.amount.toString();
      }
      return acc;
    }, {} as Record<Token, string>);

    const quotes = await this.getQuote(autoSwap, token, swapBalances);

    const swapInAmounts: ITransfer[] = amounts.filter(swap => !swap.amount.isZero());

    const tradeRequests = swapInAmounts.map(swap =>
      autoSwap.createTrade(swap.token, token, swap.amount, quotes[swap.token]),
    );

    const trades = await Promise.all(tradeRequests);

    const swapOutAmounts = Object.keys(quotes).map(
      (token): ITransfer => ({
        token: token as Token,
        amount: quotes[token as Token],
      }),
    );

    return { trades, swapInAmounts, swapOutAmounts, quotes, fee: feeTransfer };
  }

  async getQuote(
    autoSwap: AutoSwap,
    token: Token,
    balances: Partial<Record<Token, string>>,
  ): Promise<Record<Token, ethers.BigNumber>> {
    const provider = getNetworkProvider(autoSwap.network);
    const multicall = Multicall3__factory.connect(MULTICALL3_ADDRESS, provider);

    const quotesFor = this.getAvailableTokens(token, balances);

    const quoteCalls = await Promise.all(
      quotesFor.map(tokenFrom => autoSwap.getQuotePopulate(tokenFrom, token, balances[tokenFrom]!)),
    );
    const quotesResponses = await multicall.callStatic.aggregate3(
      quoteCalls.map(call => ({ callData: call.data!, target: call.to!, allowFailure: true })),
    );

    const quoterInterface = new ethers.utils.Interface(Quoter.abi);
    const quoteEntries = quotesResponses
      .map((response, index) => {
        if (!response.success) {
          console.warn(`quoteExactInputSingle unsuccessful for token ${quotesFor[index]} to token ${token}`);
          return ethers.constants.Zero;
        }
        return quoterInterface.decodeFunctionResult("quoteExactInputSingle", response.returnData).amountOut;
      })
      .map((quote, index) => [quotesFor[index], quote]);

    return Object.fromEntries(quoteEntries);
  }

  setExcludeAddrHandler(handler: typeof this.onExcludeAddress) {
    this.onExcludeAddress = handler;
  }

  getBalances() {
    return this.opts.balances[this.opts.network].balances;
  }

  private getBalance(token: Token) {
    return ethers.BigNumber.from(this.getBalances()[token]);
  }

  private getAvailableTokens(token: Token, balances: Partial<Record<Token, string>>) {
    return VendingMachine.SWAPPABLE_TOKENS.filter(swappable => token !== swappable).filter(
      token => !ethers.BigNumber.from(balances[token] ?? 0).isZero(),
    );
  }
}
