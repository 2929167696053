import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";

import { addressToInt, isSameAddress } from "@helpers";
import { useAccount } from "@contexts/AccountContext";
import { ProfileClient } from "@modules/profile/ProfileClient";

import styles from "./Avatar.module.css";

import { ReactComponent as Beam } from "@assets/images/profile_placeholder_beam.svg";
import { ReactComponent as Eco } from "@assets/images/profile_placeholder_eco.svg";
import { ReactComponent as Coinbase } from "@assets/images/profile_placeholder_coinbase.svg";
import { ReactComponent as Profile1 } from "@assets/images/profile_placeholder_1_light.svg";
import { ReactComponent as Profile2 } from "@assets/images/profile_placeholder_2_light.svg";
import { ReactComponent as Profile3 } from "@assets/images/profile_placeholder_3_light.svg";
import { ReactComponent as Profile4 } from "@assets/images/profile_placeholder_4_light.svg";
import { ReactComponent as Profile5 } from "@assets/images/profile_placeholder_5_light.svg";
import { ReactComponent as Profile6 } from "@assets/images/profile_placeholder_6_light.svg";
import classNames from "classnames";

interface AvatarProps {
  address: string;
  size?: number;
  showAsBeam?: boolean;
  showAsEco?: boolean;
  showAsCoinbase?: boolean;
  className?: string;
}

const PlaceholderAsset: React.FC<{ id: number }> = ({ id }) => {
  switch (id) {
    case 1:
      return <Profile1 />;
    case 2:
      return <Profile2 />;
    case 3:
      return <Profile3 />;
    case 4:
      return <Profile4 />;
    case 5:
      return <Profile5 />;
    case 6:
      return <Profile6 />;
    default:
      return <Profile1 />;
  }
};

export const Avatar = ({ address, showAsBeam, showAsEco, showAsCoinbase, size = 32, className }: AvatarProps) => {
  const account = useAccount();
  const placeholderId = useMemo(() => addressToInt(address, 7), [address]);

  const { data: profilePic } = useQuery(
    ["avatar-profile-pic", address],
    () => {
      if (account.address && isSameAddress(address, account.address) && account.profilePicture.exists)
        return account.profilePicture.url;
      return ProfileClient.getProfilePicture(address);
    },
    { enabled: !showAsBeam },
  );

  return (
    <div
      style={{
        width: size,
        height: size,
        backgroundColor: profilePic ? "var(--color-background)" : undefined,
        borderRadius: size <= 20 ? 4 : undefined,
      }}
      className={classNames(styles.wrapper, className)}
    >
      {showAsBeam ? (
        <Beam />
      ) : showAsEco ? (
        <Eco />
      ) : showAsCoinbase ? (
        <Coinbase />
      ) : (
        <PlaceholderAsset id={placeholderId} />
      )}
      <AnimatePresence initial={false}>
        {profilePic && (
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            alt={"@" + account.beamname?.displayName}
            src={profilePic}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
