import React, { useState } from "react";
import { ethers } from "ethers";

import BottomSheet, { BottomSheetProps } from "@components/bottomsheet/BottomSheet";
import { AddBankFooter, AddBankStep } from "@components/account/withdraw/bridge/bank/AddBankStep";
import { CreateCustomerStep } from "@components/account/withdraw/bridge/customer/CreateCustomerStep";
import {
  WithdrawConfirm,
  WithdrawConfirmFooter,
} from "@components/account/withdraw/confirm_modal/WithdrawConfirmModal";
import { CreateCustomerFooter } from "@components/account/withdraw/bridge/customer/CreateCustomerForm";
import { WithdrawNumpad, WithdrawNumpadFooter } from "@components/account/withdraw/numpad/WithdrawNumpad";
import { BridgeTOSConfirmation } from "@components/account/withdraw/bridge/tos_confirmation/BridgeTOSConfirmation";
import {
  useWithdrawContext,
  WithdrawActionType,
  WithdrawStep,
} from "@components/account/withdraw/context/WithdrawContext";

import { Token, UserToken } from "@constants";
import { formatBigNumber, formatCurrency, logError, notify } from "@helpers";
import { getBeamWallet, getClient, getFlatPaymaster } from "@helpers/bundler";

import { useAccount } from "@contexts/AccountContext";

import { Transfer } from "@modules/transfer/Transfer";
import { BridgeCurrency, IBridgeTransfer } from "@modules/bridge/types";
import { AutoSwapTransfer } from "@modules/transfer/AutoSwapTransfer";

import { useAppDispatch, useAppSelector } from "@redux";
import { useConfigFee } from "@redux/slides/config.slide";
import { executeTransfer, getBalances } from "@redux/slides/balances/balances.slide";

interface WithdrawModalProps extends Pick<BottomSheetProps, "isOpen" | "onClose" | "onAnimationEnd"> {
  available?: ethers.BigNumberish;
  fee?: ethers.BigNumberish;
}

function getTitle(step: WithdrawStep): string {
  switch (step) {
    case WithdrawStep.SET_AMOUNT:
      return "Withdraw USD";
    case WithdrawStep.ACCEPT_TOS:
      return "Agree to terms";
    case WithdrawStep.REGISTER_CUSTOMER:
      return "Tell us about yourself";
    case WithdrawStep.ADD_BANK_ACCOUNT:
      return "Add a bank";
    case WithdrawStep.CONFIRM:
      return "Confirm withdrawal";
  }
}

function getSubtitle(step: WithdrawStep, amount: ethers.BigNumber | undefined) {
  if (step === WithdrawStep.SET_AMOUNT || step === WithdrawStep.CONFIRM || !amount) return undefined;
  return `Withdrawing ${formatCurrency(amount)} USD`;
}

function getBridgeCurrency(token: Token): BridgeCurrency {
  switch (token) {
    case Token.USDC:
      return "usdc";
    case Token.USDT:
      return "usdt";
    default:
      throw new Error("Invalid bridge currency");
  }
}

export const WithdrawModal: React.FC<WithdrawModalProps> = ({ isOpen, available, fee, onClose, onAnimationEnd }) => {
  const account = useAccount();
  const fees = useConfigFee();
  const appDispatch = useAppDispatch();
  const balances = useAppSelector(getBalances);

  const [amount, setAmount] = useState<ethers.BigNumber>();
  const [clickedBeforeLoaded, setClickedBeforeLoaded] = useState(false);

  const { bridge, step, customer, externalAccount, dispatch, isFetched } = useWithdrawContext();

  const executeWithdraw = async () => {
    if (!amount || !account.address || !fees) return;

    const amountString = formatBigNumber(amount, 6, 2, { useGrouping: false });

    try {
      const transfer = new AutoSwapTransfer({
        oneOut: true,
        balances: balances,
        from: account.address,
        userToken: UserToken.USD,
        destinationNetwork: "optimism",
        acceptedTokens: [Token.USDC, Token.USDT],
        fee: fees.perToken.usd,
        amount: ethers.utils.parseUnits(amountString, 6),
      });

      const { fee, transfers, transformations } = await transfer.run();

      let withdrawData: IBridgeTransfer;
      try {
        withdrawData = await bridge.createTransfer({
          amount: amountString,
          developer_fee: "0",
          on_behalf_of: bridge.customerId!,
          destination: {
            currency: "usd",
            payment_rail: "ach",
            external_account_id: bridge.externalAccountId!,
          },
          source: {
            currency: getBridgeCurrency(transfers[0].token),
            payment_rail: "optimism",
            from_address: account.address,
          },
        });
      } catch (error) {
        logError("execute-withdraw-error", error);
        notify({ type: "error", content: "Unexpected error while creating the withdraw request" });
        return;
      }

      const txs = [
        ...transformations,
        ...transfers.map(transfer =>
          Transfer.getTransferTx(transfer, withdrawData.source_deposit_instructions.to_address),
        ),
      ];

      if (fee) txs.push(Transfer.getTransferTx(fee, fees.recipients.optimism));

      const wallet = await getBeamWallet(account.signer, "optimism", getFlatPaymaster("optimism"));
      wallet.executeBatch(
        txs.map(tx => tx.to),
        txs.map(tx => tx.data),
      );

      const client = await getClient("optimism");
      const result = client.sendUserOperation(wallet);

      const afterTransferBalances = transfer.getBalances();
      for (const token in afterTransferBalances) {
        appDispatch(
          executeTransfer({
            token: token as Token,
            network: "optimism",
            amount: afterTransferBalances[token as Token],
            type: "replace",
            tx: result.then(userOp => userOp.wait()),
          }),
        );
      }
    } catch (error) {
      logError("execute-withdraw-error-transfer", error);
      notify({ type: "error", content: "Unexpected error while creating the withdraw request" });
      return;
    }

    onClose?.();
    notify({ content: `${formatCurrency(amount)} USD withdrew` });
  };

  const handleAnimationEnd = (isOpen: boolean) => {
    if (!isOpen) {
      dispatch({ type: WithdrawActionType.SET_STEP, step: WithdrawStep.SET_AMOUNT });
    }
    onAnimationEnd?.(isOpen);
  };

  const startWithdraw = () => {
    if (!isFetched) {
      setClickedBeforeLoaded(true);
      return;
    }

    if (customer?.status !== "active") {
      dispatch({ type: WithdrawActionType.SET_STEP, step: WithdrawStep.ACCEPT_TOS });
      return;
    }
    if (!externalAccount) {
      dispatch({ type: WithdrawActionType.SET_STEP, step: WithdrawStep.ADD_BANK_ACCOUNT });
      return;
    }

    dispatch({ type: WithdrawActionType.SET_STEP, step: WithdrawStep.CONFIRM });
  };

  return (
    <BottomSheet
      desktopDisplay="side"
      isOpen={isOpen}
      title={getTitle(step)}
      subtitle={getSubtitle(step, amount)}
      onClose={onClose}
      onAnimationEnd={handleAnimationEnd}
      height="100%"
      footer={
        step === WithdrawStep.SET_AMOUNT ? (
          <WithdrawNumpadFooter
            amount={amount}
            maxAmount={available}
            onSubmit={startWithdraw}
            loading={!isFetched && clickedBeforeLoaded}
          />
        ) : step === WithdrawStep.REGISTER_CUSTOMER ? (
          <CreateCustomerFooter />
        ) : step === WithdrawStep.ADD_BANK_ACCOUNT ? (
          <AddBankFooter />
        ) : step === WithdrawStep.CONFIRM ? (
          <WithdrawConfirmFooter
            onConfirm={executeWithdraw}
            onCancel={() => {
              dispatch({ type: WithdrawActionType.SET_STEP, step: WithdrawStep.SET_AMOUNT });
            }}
          />
        ) : undefined
      }
    >
      {step === WithdrawStep.SET_AMOUNT ? (
        <WithdrawNumpad maxAmount={available} fee={fee} onChange={setAmount} />
      ) : step === WithdrawStep.ACCEPT_TOS ? (
        <BridgeTOSConfirmation
          onComplete={() => dispatch({ type: WithdrawActionType.SET_STEP, step: WithdrawStep.REGISTER_CUSTOMER })}
        />
      ) : step === WithdrawStep.REGISTER_CUSTOMER ? (
        <CreateCustomerStep
          onComplete={() => dispatch({ type: WithdrawActionType.SET_STEP, step: WithdrawStep.ADD_BANK_ACCOUNT })}
        />
      ) : step === WithdrawStep.ADD_BANK_ACCOUNT ? (
        <AddBankStep
          onComplete={() => {
            dispatch({ type: WithdrawActionType.SET_STEP, step: WithdrawStep.CONFIRM });
          }}
        />
      ) : step === WithdrawStep.CONFIRM && amount ? (
        <WithdrawConfirm amount={amount} />
      ) : null}
    </BottomSheet>
  );
};
