import React from "react";
import { ethers } from "ethers";

import { formatCurrency } from "@helpers";

import Row from "@components/row/Row";
import Card from "@components/card/Card";
import { RowGroup } from "@components/row/RowGroup";

import { ReactComponent as LinkChevron } from "@assets/icons/link_chevron.svg";
import { ReactComponent as ArrowUpCircle } from "@assets/icons/arrow_up_circle.svg";

import "./WithdrawCardBody.css";

function getCardSubtitle(_amount: ethers.BigNumber) {
  const amount = formatCurrency(_amount);
  return `${amount} USD available to withdraw`;
}

interface WithdrawCardBodyProps {
  amount: ethers.BigNumber;
  onClick(): void;
}

export const WithdrawCardBody: React.FC<WithdrawCardBodyProps> = ({ amount, onClick }) => {
  return (
    <Card onClick={onClick} className="withdraw_card">
      <RowGroup compact>
        <Row
          compact
          hasBottomBorder={false}
          hasHorizontalPadding={false}
          title="Withdraw USD"
          subtitle={getCardSubtitle(amount)}
          trailingContent={
            <div className="withdraw_card--icon">
              <LinkChevron />
            </div>
          }
          leadingContent={<ArrowUpCircle />}
        />
      </RowGroup>
    </Card>
  );
};
