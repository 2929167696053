export class Merchant {
  static isValidFromBeamname(beamname: string): boolean {
    return (
      beamname.startsWith("beampay") ||
      beamname.startsWith("payments") ||
      beamname.startsWith("merchant") ||
      beamname.startsWith("plada") ||
      beamname.startsWith("carlos") ||
      beamname.startsWith("bromberg")
    );
  }
}
