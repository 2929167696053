import React from "react";

import { Badge } from "@components/badge/Badge";

import { ReactComponent as ShieldIcon } from "@assets/icons/check_shield.svg";
import { ReactComponent as ExclamationIcon } from "@assets/icons/exclamation_shield.svg";

import "./SavedBadge.css";

interface SavedBadgeProps {
  isSaved: boolean;
}

export const SavedBadge: React.FC<SavedBadgeProps> = ({ isSaved }) => {
  return (
    <Badge
      size="small"
      icon={isSaved ? <ShieldIcon /> : <ExclamationIcon />}
      text={isSaved ? "Logged in" : "Guest account"}
      style="outline"
      data-cy="saved-badge"
    />
  );
};
