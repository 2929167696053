import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { BodyText, TextBlock } from "@components/text/Text";

export const XAuthStep: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: 24,
        flexDirection: "column",
        alignItems: "center",
        gap: "var(--size-medium)",
      }}
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "var(--color-foreground-medium)" }} spin />} />
      <TextBlock>
        <BodyText light>Waiting on successful log in to your X account.</BodyText>
      </TextBlock>
      <div />
    </div>
  );
};
