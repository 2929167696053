import { Token, UserToken } from "@constants";
import { TokenManager } from "@modules/token-managers/TokenManager";

export class USDManager extends TokenManager {
  constructor() {
    super(UserToken.USD, {
      symbol: "USD",
      decimals: 6,
      composedBy: [Token.USDC, Token.USDT, Token.USDCe, Token.USDbC, Token.USDV],
    });
  }
}
