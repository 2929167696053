// https://medium.com/@stheodorejohn/findlast-method-polyfill-in-javascript-bridging-browser-gaps-c3baf6aabae1
if (!Array.prototype.findLast) {
  // eslint-disable-next-line
  Array.prototype.findLast = function (callback: any) {
    if (this == null) {
      throw new TypeError("this is null or not defined");
    }
    const arr = Object(this);
    const len = arr.length >>> 0;
    for (let i = len - 1; i >= 0; i--) {
      if (callback(arr[i], i, arr)) {
        return arr[i];
      }
    }
    return undefined;
  };
}
