import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, LinkButton } from "@components/button/Button";
import { BodyText, TextBlock } from "@components/text/Text";

import BottomSheet from "@components/bottomsheet/BottomSheet";
import { Callout } from "@components/callout/Callout";
import { useAccount } from "@contexts/AccountContext";
import { useAppDispatch } from "@redux";
import { logout } from "@redux/slides/web3auth.slide";
import { removeAllLocalStorage } from "@modules/web3auth/helpers";

export const LogoutButton = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { signer, hasSomeBalance } = useAccount();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleConfirmClick = () => {
    removeAllLocalStorage(signer);
    dispatch(logout());
    setOpen(false);
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      <LinkButton title="Log out" onClick={handleClick} />
      <BottomSheet
        title="Confirm log out"
        isOpen={open}
        footer={
          <div style={{ display: "flex", flexDirection: "column", gap: "var(--size-small)" }}>
            <Button title="Yes, log out" onClick={handleConfirmClick} />
            <Button
              secondary
              title="Stay logged in"
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
        }
        onClose={() => {
          setOpen(false);
        }}
      >
        <TextBlock>
          {hasSomeBalance && (
            <>
              <Callout>
                <BodyText>
                  Warning: make sure you know your password or have saved your private key. Logging out without either
                  can make your funds unrecoverable.
                </BodyText>
              </Callout>
              <br />
            </>
          )}
          <BodyText>
            This will log you out of your current account (and wallet) and return you to a guest account.
          </BodyText>
        </TextBlock>
      </BottomSheet>
    </>
  );
};
