import React from "react";
import styles from "./RowGroup.module.css";

interface RowGroupProps {
  children: React.ReactNode;
  compact?: boolean;
}

export const RowGroup = ({ children, compact }: RowGroupProps) => {
  return <div className={`${styles.wrapper} ${compact && styles.compact}`}>{children}</div>;
};
