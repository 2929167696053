import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { XAuthStep } from "@components/account/access/x/XAuthStep";
import { StepAnimation, VerificationStep } from "@components/account/access/components";
import { EmailConfirmationStep } from "@components/account/access/increase-security/confirmation-step/EmailConfirmationStep";
import { XConfirmationStep } from "@components/account/access/increase-security/confirmation-step/XConfirmationStep";

import { useWeb3AuthState } from "@redux/slides/web3auth.slide";

import { AuthMethod, AuthType } from "@modules/web3auth/constants";
import { Web3AuthCore, Web3AuthMultiFactor } from "@modules/web3auth";
import { useAuth0Custom, useEmailOTP } from "@modules/web3auth/hooks";

import { notify } from "@helpers";

import flags from "@constants/flags";

enum ConfirmationInnerStep {
  Verify,
  OTP,
  Redirect,
}

interface ConfirmationStepProps {
  onNext?: () => void;
  onClose?: () => void;
}

export const ConfirmationStep: React.FC<ConfirmationStepProps> = ({ onNext, onClose }) => {
  const { getIdTokenClaims } = useAuth0();
  const auth0 = useAuth0Custom();
  const { userInfo, resumeProgressiveSecurity } = useWeb3AuthState();
  const otp = useEmailOTP(AuthType.MULTI);

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(ConfirmationInnerStep.Verify);

  const login = async () => {
    try {
      setLoading(true);
      await auth0.loginWithTwitter({
        returnTo: "/account",
        resumeProgressiveSecurity: true,
      });
    } catch (error) {
      setLoading(false);
      console.warn("twitter login error", error);
      onClose && onClose();
      notify({ content: "Authentication failed", type: "error" });
    }
  };

  const loginAfterRedirect = async () => {
    try {
      const idToken = (await getIdTokenClaims())?.__raw.toString();
      if (!idToken) throw new Error("Invalid credentials.");

      if (flags.save_access.check_account_existence) {
        const { hasSavedAccess } = await Web3AuthCore.checkIfHasSavedAccess(idToken);

        const hasSavedAccessAlready = hasSavedAccess.twitter || hasSavedAccess.email;

        if (hasSavedAccessAlready) {
          onClose && onClose();
          return;
        }
      }

      const web3Auth = Web3AuthMultiFactor.get();
      await web3Auth.loginWithToken(idToken, AuthMethod.TWITTER);

      onNext && onNext();
    } catch (error) {
      console.error("confirmation step loginAfterRedirect error", { error });
      onClose && onClose();
      notify({ content: "Authentication failed", type: "error" });
    }
  };

  useEffect(() => {
    if (resumeProgressiveSecurity) {
      setStep(ConfirmationInnerStep.Redirect);
      loginAfterRedirect();
    }
  }, [resumeProgressiveSecurity]);

  if (!userInfo) return null;

  if (userInfo.typeOfLogin === AuthMethod.TWITTER) {
    const handleXNext = async () => {
      await login();
      setStep(ConfirmationInnerStep.Redirect);
    };

    return (
      <React.Fragment>
        {step === ConfirmationInnerStep.Verify ? (
          <StepAnimation key="confirmation-step-1">
            <XConfirmationStep loading={loading} onNext={handleXNext} />
          </StepAnimation>
        ) : step === ConfirmationInnerStep.Redirect ? (
          <StepAnimation key="confirmation-step-2">
            <XAuthStep />
          </StepAnimation>
        ) : null}
      </React.Fragment>
    );
  }

  const email = userInfo.name;

  const sendEmailOTP = (email: string) => {
    setLoading(true);
    return otp.sendEmailOTP({
      email,
      action: "Save",
      onComplete() {
        setStep(ConfirmationInnerStep.OTP);
        setLoading(false);
      },
      onCheckFailed() {
        onClose && onClose();
      },
      onError() {
        onClose && onClose();
      },
    });
  };

  const handleResend = () => sendEmailOTP(email);

  const handleCodeClick = async (code: string) => {
    if (!email) return;
    setLoading(true);

    return otp.handleCodeClick({
      code,
      email,
      onComplete() {
        onNext && onNext();
        setLoading(false);
      },
      onError() {
        setLoading(false);
        onClose && onClose();
      },
    });
  };
  const handleNextStep = async () => {
    await sendEmailOTP(email);
    setStep(ConfirmationInnerStep.OTP);
  };

  return (
    <React.Fragment>
      {step === ConfirmationInnerStep.Verify ? (
        <StepAnimation key="confirmation-step-1">
          <EmailConfirmationStep email={email} onNext={handleNextStep} />
        </StepAnimation>
      ) : step === ConfirmationInnerStep.OTP ? (
        <StepAnimation key="confirmation-step-2">
          <VerificationStep
            type="email"
            loading={loading}
            destination={email}
            onSubmit={handleCodeClick}
            onResend={handleResend}
          />
        </StepAnimation>
      ) : null}
    </React.Fragment>
  );
};
