import React, { Children, ReactNode } from "react";

export const DotSeparated = ({
  gap = "var(--size-small)",
  children,
  dot = "·",
}: {
  gap?: string | number;
  children: ReactNode;
  dot?: string;
}) => {
  const childCount = React.Children.toArray(children).length;
  return (
    <div style={{ display: "flex", alignItems: "center", gap: gap }}>
      {children &&
        Children.map(children, (child, index) => (
          <>
            <span>{child}</span>
            {index < childCount - 1 && <span>{dot}</span>}
          </>
        ))}
    </div>
  );
};
