import BN from "bn.js";

import ThresholdKey from "@tkey/default";
import SFAServiceProvider from "@tkey/service-provider-sfa";
import { IServiceProvider } from "@tkey/common-types";

export function isSFAServiceProvider(provider: IServiceProvider): provider is SFAServiceProvider {
  return provider.serviceProviderName === "SfaServiceProvider";
}

export async function isMetadataPresent(tKey: ThresholdKey, privateKeyBN: BN) {
  const metadata = await tKey.storageLayer.getMetadata({ privKey: privateKeyBN });
  // eslint-disable-next-line
  return !!(metadata && Object.keys(metadata).length > 0 && (metadata as any).message !== "KEY_NOT_FOUND");
}

export function pkToBN(privateKey: string): BN {
  if (privateKey.startsWith("0x")) privateKey = privateKey.slice(2);
  return new BN(privateKey, "hex");
}
