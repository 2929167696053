import { Button, LinkButton } from "@components/button/Button";
import Card from "@components/card/Card";
import Row from "@components/row/Row";
import { BodyText } from "@components/text/Text";
import { BEAMNAMES_ADDRESS, FAUCET_ADDRESS } from "@constants/contracts";
import { useFactoryAddress } from "@contexts/FactoryAddressContext";
import { useStackup } from "@contexts/StackupContext";
import { isSameAddress } from "@helpers/contracts";
import { copy } from "@helpers/copy";
import { formatBigNumber, formatFee, formatTokenAmount, shortAddr, shortHash } from "@helpers/format";
import { isBeamInternalTxn, isCoinbaseTransaction } from "@helpers/transfer";
import { useDisplayName } from "@hooks/useDisplayName";
import { TransferCluster } from "@hooks/useHistory";
import { PEANUT_ADDRESSES } from "@modules/peanut/constants";
import { getTokenManagerByToken } from "@modules/token-managers/tokens";
import moment from "moment";
import React, { useState } from "react";

import { ReactComponent as Base } from "@assets/icons/base.svg";
import { ReactComponent as CopyIcon } from "@assets/icons/copy.svg";
import { ReactComponent as Chevron } from "@assets/icons/link_chevron.svg";
import { ReactComponent as Optimism } from "@assets/icons/optimism.svg";
import { Avatar } from "@components/avatar/Avatar";

import { IconButton } from "@components/iconbutton/IconButton";
import { RowGroup } from "@components/row/RowGroup";
import { Tooltip } from "@components/tooltip/Tooltip";
import { animationCurveEaseOut, animationDurationFastS } from "@constants/animations";
import { blockExplorerLink } from "@helpers/blockExplorer";
import { capitalizeFirstLetter } from "@helpers/text";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./History.module.css";
import { CopyButton } from "@components/button/CopyButton";
import { DotSeparated } from "@components/dotseparated/DotSeparated";

const isClaimedBeamlink = (transferCluster: TransferCluster) => {
  return transferCluster.beamlinks.length && transferCluster.beamlinks.every(link => link.recipient);
};

const getNetworkIcon = (network: string): React.ReactNode | null => {
  switch (network) {
    case "optimism":
    case "goerli-optimism":
      return <Optimism />;
    case "base":
    case "goerli-base":
      return <Base />;
    default:
      return null;
  }
};

const NetworkTitle = ({ network }: { network: string }) => (
  <div className={styles.networkName}>
    {getNetworkIcon(network)}
    <BodyText>{capitalizeFirstLetter(network)}</BodyText>
  </div>
);

const viewTooltipText = "View on Etherscan";

export const TransferDetails: React.FC<{
  transferCluster: TransferCluster;
}> = ({ transferCluster }) => {
  const { address } = useStackup();
  const oldFactoryAddress = useFactoryAddress();

  const isReceive =
    isSameAddress(transferCluster.to.address, address!) ||
    isSameAddress(transferCluster.to.address, oldFactoryAddress!);
  const isFaucet = isSameAddress(transferCluster.from.address, FAUCET_ADDRESS);
  const isBeamNameRegistration = isSameAddress(transferCluster.to.address, BEAMNAMES_ADDRESS);

  let displayAddress = isReceive ? transferCluster.from : transferCluster.to;
  if (transferCluster.beamlinks.length) {
    const beamAddress = transferCluster.beamlinks[0][isReceive ? "sender" : "recipient"];
    if (beamAddress) displayAddress = beamAddress;
  }

  const displayName = displayAddress.beamname ?? useDisplayName(displayAddress.address);

  const [networkDetailsOpen, setNetworkDetailsOpen] = useState(false);

  const getText = () => {
    if (
      transferCluster.beamlinks.length ||
      PEANUT_ADDRESSES.some(
        peanutAddress =>
          isSameAddress(peanutAddress, transferCluster.from.address) ||
          isSameAddress(peanutAddress, transferCluster.to.address),
      )
    ) {
      const isSender = Boolean(address && isSameAddress(transferCluster.from.address, address));
      return `${isSender ? "Send" : "Receive"} (Beam Link)`;
    }
    if (isBeamNameRegistration) return "Send (Beam Name Registration)";
    if (isFaucet) return "A gift for signing up!";

    let text = isReceive ? "Receive" : "Send";

    if (
      transferCluster.vendingMachineSwap &&
      transferCluster.vendingMachineSwap.sent &&
      transferCluster.vendingMachineSwap.received
    ) {
      text = `Swapped ${formatBigNumber(
        transferCluster.vendingMachineSwap.sent.amount,
        transferCluster.vendingMachineSwap.sent.token.decimals,
        3,
      )} ${transferCluster.vendingMachineSwap.sent.token.name} for ${formatBigNumber(
        transferCluster.vendingMachineSwap.received.amount,
        transferCluster.vendingMachineSwap.received.token.decimals,
        3,
      )} ${transferCluster.vendingMachineSwap.received.token.name}`;
    }

    return text;
  };

  return (
    <div style={{ width: "100%", gap: "var(--size-medium)", display: "flex", flexDirection: "column" }}>
      <Card style="muted">
        <RowGroup>
          <Row
            deemphasizeTitle
            hasHorizontalPadding={false}
            title="Type"
            trailingContent={<BodyText bold>{getText()}</BodyText>}
          />

          <Row
            deemphasizeTitle
            hasHorizontalPadding={false}
            title="Date"
            trailingContent={
              <BodyText bold>{moment(transferCluster.timestamp * 1000).format("LT · MMM Do, YYYY")}</BodyText>
            }
          />

          {transferCluster.vendingMachineSwap &&
          transferCluster.vendingMachineSwap.sent &&
          transferCluster.vendingMachineSwap.received ? (
            <>
              <Row
                deemphasizeTitle
                hasHorizontalPadding={false}
                title="Sent"
                trailingContent={
                  <BodyText bold>
                    {formatBigNumber(
                      transferCluster.vendingMachineSwap.sent.amount,
                      transferCluster.vendingMachineSwap.sent.token.decimals,
                      3,
                    )}{" "}
                    {transferCluster.vendingMachineSwap.sent.token.name}
                  </BodyText>
                }
              />
              <Row
                deemphasizeTitle
                hasHorizontalPadding={false}
                title="Received"
                trailingContent={
                  <BodyText bold>
                    {formatBigNumber(
                      transferCluster.vendingMachineSwap.received.amount,
                      transferCluster.vendingMachineSwap.received.token.decimals,
                      3,
                    )}{" "}
                    {transferCluster.vendingMachineSwap.received.token.name}
                  </BodyText>
                }
              />
            </>
          ) : (
            <Row
              deemphasizeTitle
              hasHorizontalPadding={false}
              title="Amount"
              trailingContent={
                <BodyText bold>
                  {formatBigNumber(transferCluster.amount, transferCluster.token.decimals, 3)}{" "}
                  {getTokenManagerByToken(transferCluster.token.id).symbol}
                </BodyText>
              }
            />
          )}

          {!isReceive ? (
            <Row
              deemphasizeTitle
              hasHorizontalPadding={false}
              title="Fee"
              trailingContent={
                <BodyText bold>
                  {formatTokenAmount(formatFee(transferCluster.fee, transferCluster.token), 3)}{" "}
                  {getTokenManagerByToken(transferCluster.token.id).symbol}
                </BodyText>
              }
            />
          ) : null}

          {transferCluster.beamlinks.length &&
          transferCluster.beamlinks.some(beamlink => beamlink.url) &&
          !isClaimedBeamlink(transferCluster) ? (
            <Row
              deemphasizeTitle
              hasHorizontalPadding={false}
              title={"Claim Link"}
              trailingContent={
                <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <Button
                    leadingIcon={<CopyIcon />}
                    title="Copy Claim Link"
                    type="secondary"
                    size="small"
                    onClick={() =>
                      copy(transferCluster.beamlinks.find(beamlink => beamlink.url)!.url!, "Copied Beam Link")
                    }
                  />
                </div>
              }
            />
          ) : null}
          {transferCluster.beamlinks.length && isClaimedBeamlink(transferCluster) ? (
            <Row
              deemphasizeTitle
              hasHorizontalPadding={false}
              title={isReceive ? "Sender" : "Claimed by"}
              trailingContent={
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "var(--size-xsmall)",
                      justifyContent: "end",
                    }}
                  >
                    {!isFaucet && (
                      <Avatar
                        size={20}
                        address={
                          isReceive
                            ? transferCluster.beamlinks[0].sender.address
                            : transferCluster.beamlinks[0].recipient!.address
                        }
                      />
                    )}
                    <BodyText bold>{displayName}</BodyText>
                  </div>
                  <BodyText small light>
                    <DotSeparated gap={4}>
                      {shortAddr(transferCluster.beamlinks[0][isReceive ? "sender" : "recipient"]!.address)}
                      <Tooltip content="Copy address">
                        <CopyButton
                          type="invisible"
                          size="xsmall"
                          copyText={transferCluster.beamlinks[0][isReceive ? "sender" : "recipient"]!.address}
                        />
                      </Tooltip>
                    </DotSeparated>
                  </BodyText>
                </div>
              }
            />
          ) : !transferCluster.beamlinks.length ? (
            <Row
              deemphasizeTitle
              hasHorizontalPadding={false}
              title={isReceive ? "From" : "To"}
              trailingContent={
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "var(--size-xsmall)",
                      justifyContent: "end",
                    }}
                  >
                    <Avatar
                      size={20}
                      showAsBeam={isBeamInternalTxn(transferCluster)}
                      showAsCoinbase={isCoinbaseTransaction(transferCluster.from.address)}
                      address={isReceive ? transferCluster.from.address : transferCluster.to.address}
                    />
                    <BodyText bold>{isFaucet ? "Beam Team" : displayName}</BodyText>
                  </div>
                  <BodyText
                    small
                    light
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: "var(--size-xsmall)",
                    }}
                  >
                    <DotSeparated gap={4}>
                      {shortAddr(transferCluster[isReceive ? "from" : "to"].address)}
                      <Tooltip content="Copy address">
                        <CopyButton
                          type="invisible"
                          size="xsmall"
                          copyText={transferCluster[isReceive ? "from" : "to"].address}
                        />
                      </Tooltip>
                    </DotSeparated>
                  </BodyText>
                </div>
              }
            />
          ) : null}
        </RowGroup>
      </Card>

      <Card
        style="normal"
        onClick={!networkDetailsOpen ? () => setNetworkDetailsOpen(open => !open) : undefined}
        dataCy="network-details"
      >
        <div style={{ overflow: "hidden" }}>
          <div
            className={styles.toggle}
            onClick={networkDetailsOpen ? () => setNetworkDetailsOpen(open => !open) : undefined}
          >
            Network details
            <div>
              <AnimatePresence>
                {!networkDetailsOpen && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ ease: animationCurveEaseOut, duration: animationDurationFastS }}
                  >
                    View
                  </motion.span>
                )}
              </AnimatePresence>
              <IconButton
                icon={<Chevron />}
                size="small"
                dataCy="network-details-toggle"
                style={{ transform: `rotate(${networkDetailsOpen ? "-90deg" : "90deg"})` }}
                className={styles.toggleIcon}
              />
            </div>
          </div>

          <AnimatePresence>
            {networkDetailsOpen && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ ease: animationCurveEaseOut, duration: animationDurationFastS }}
              >
                <div className={styles.networkDetails}>
                  <div className={styles.networkDetailsBorder} />
                  {transferCluster.beamlinks.length ? (
                    <>
                      {isClaimedBeamlink(transferCluster) ? (
                        <>
                          {transferCluster.beamlinks.map(beamlink => (
                            <Row
                              deemphasizeTitle
                              key={`transfer-${beamlink.claimedTxHash}`}
                              dataCy={`transfer-${beamlink.claimedTxHash}`}
                              hasHorizontalPadding={false}
                              title={<NetworkTitle network={beamlink.network.id} />}
                              trailingContent={
                                <>
                                  <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
                                    <BodyText bold>Claimed</BodyText>
                                    <BodyText small light>
                                      {shortHash(beamlink.claimedTxHash!)} ·{" "}
                                      <Tooltip content={viewTooltipText}>
                                        <LinkButton
                                          target="_blank"
                                          title="View"
                                          size="small"
                                          href={blockExplorerLink(beamlink.claimedTxHash!, beamlink.network)}
                                        />
                                      </Tooltip>
                                    </BodyText>
                                  </div>
                                </>
                              }
                            />
                          ))}
                        </>
                      ) : null}
                      {transferCluster.beamlinks.map(beamlink => (
                        <Row
                          deemphasizeTitle
                          key={`transfer-${beamlink.createdTxHash}`}
                          hasHorizontalPadding={false}
                          title={<NetworkTitle network={beamlink.network.id} />}
                          dataCy={`transfer-${beamlink.createdTxHash}`}
                          trailingContent={
                            <>
                              <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
                                <BodyText bold>Created</BodyText>
                                <BodyText small light>
                                  {shortHash(beamlink.createdTxHash)} ·{" "}
                                  <Tooltip content={viewTooltipText}>
                                    <LinkButton
                                      target="_blank"
                                      title="View"
                                      size="small"
                                      href={blockExplorerLink(beamlink.createdTxHash, beamlink.network)}
                                    />
                                  </Tooltip>
                                </BodyText>
                              </div>
                            </>
                          }
                        />
                      ))}
                    </>
                  ) : transferCluster.vendingMachineSwap ? (
                    <Row
                      deemphasizeTitle
                      key={`transfer-${transferCluster.transfers[0].txHash}`}
                      hasHorizontalPadding={false}
                      title={<NetworkTitle network={transferCluster.transfers[0].network.id} />}
                      dataCy={`transfer-${transferCluster.transfers[0].txHash}`}
                      trailingContent={
                        <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
                          <BodyText bold>Swap</BodyText>
                          <BodyText small light>
                            {transferCluster.transfers[0].txHash.substring(0, 10) +
                              "..." +
                              transferCluster.transfers[0].txHash.substring(58, 66)}{" "}
                            ·{" "}
                            <Tooltip content={viewTooltipText}>
                              <LinkButton
                                target="_blank"
                                title="View"
                                size="small"
                                href={blockExplorerLink(
                                  transferCluster.transfers[0].txHash,
                                  transferCluster.transfers[0].network,
                                )}
                              />
                            </Tooltip>
                          </BodyText>
                        </div>
                      }
                    />
                  ) : (
                    transferCluster.transfers.map(transfer => (
                      <Row
                        deemphasizeTitle
                        key={`transfer-${transfer.id}`}
                        hasHorizontalPadding={false}
                        title={<NetworkTitle network={transfer.network.id} />}
                        data-cy={`transfer-${transfer.id}`}
                        trailingContent={
                          <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
                            <BodyText bold>
                              {formatBigNumber(transfer.amount, transfer.token.decimals, 3)} {transfer.token.name}
                            </BodyText>
                            <BodyText small light>
                              {transfer.txHash.substring(0, 10) + "..." + transfer.txHash.substring(58, 66)} ·{" "}
                              <Tooltip content={viewTooltipText}>
                                <LinkButton
                                  target="_blank"
                                  title="View"
                                  size="small"
                                  href={blockExplorerLink(transfer.txHash, transfer.network)}
                                />
                              </Tooltip>
                            </BodyText>
                          </div>
                        }
                      />
                    ))
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </Card>
    </div>
  );
};
