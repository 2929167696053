import React, { CSSProperties } from "react";

import { UserToken } from "@constants";

import { ReactComponent as EcoLogo } from "@assets/images/eco_logo.svg";
import { ReactComponent as ECOxLogo } from "@assets/images/ecox_logo.svg";
import { ReactComponent as USDCLogo } from "@assets/images/usdc_logo.svg";
import OakLogoUrl from "@assets/images/oak_token.svg";

export const TokenIcon: React.FC<{ token: UserToken; size?: number }> = ({ token, size = 24 }) => {
  const style: CSSProperties = { width: size, height: size };

  if (token === UserToken.USD) return <USDCLogo style={style} />;
  if (token === UserToken.OAK) return <img src={OakLogoUrl as unknown as string} alt={token} style={style} />;
  if (token === UserToken.ECOx) return <ECOxLogo style={{ ...style, transform: "scale(0.7)" }} />;
  return <EcoLogo style={style} />;
};
