import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3AuthOptions } from "@web3auth/single-factor-auth";
import { TORUS_LEGACY_NETWORK_TYPE } from "@toruslabs/constants";

import { NETWORK } from "@constants/network";
import config from "@constants/config";

export enum AuthMethod {
  TWITTER = "twitter",
  EMAIL = "email",
}

export enum AuthType {
  SINGLE,
  MULTI,
}
export const AUTH0_DOMAIN = config.features.saveAccess.auth0.domain;
export const AUTH0_CLIENT_ID = config.features.saveAccess.auth0.clientId;

export const AUTH0_REDIRECT_URI = new URL("/serviceworker/redirect", window.location.origin).toString();

export const WEB3AUTH_OPTIONS: Web3AuthOptions = {
  clientId: config.features.saveAccess.web3auth.clientId,
  enableLogging: NETWORK.isTestnet,
  web3AuthNetwork: config.features.saveAccess.web3auth.network as TORUS_LEGACY_NETWORK_TYPE,
};

export const ETHEREUM_PRIVATE_KEY_PROVIDER = new EthereumPrivateKeyProvider({
  config: {
    chainConfig: {
      chainId: NETWORK.chainId.toString(),
      blockExplorer: NETWORK.blockExplorer,
      rpcTarget: NETWORK.rpcUrl,
      ticker: "ETH",
      tickerName: "Ethereum",
      displayName: NETWORK.name,
    },
  },
});
