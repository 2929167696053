import axios from "axios";
import * as Sentry from "@sentry/react";
import auth0, { PasswordlessStartOptions } from "auth0-js";

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_REDIRECT_URI } from "@modules/web3auth/constants";
import { logError } from "@helpers/logError";

const webAuth = new auth0.WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
  redirectUri: AUTH0_REDIRECT_URI,
  responseType: "token id_token",
});

const start = (opts: PasswordlessStartOptions) =>
  new Promise<{
    Id: string;
    email: string;
    emailVerified: boolean;
  }>((res, reject) =>
    webAuth.passwordlessStart(opts, (err, result) => {
      if (err) reject(err);
      else res(result);
    }),
  );

const login = (opts: { connection: "email" | "sms"; otp: string; email?: string; phoneNumber?: string }) => {
  const url = new URL("/oauth/token", AUTH0_DOMAIN).toString();
  return axios({
    method: "POST",
    url: url,
    headers: { "content-type": "application/json" },
    data: {
      grant_type: "http://auth0.com/oauth/grant-type/passwordless/otp",
      client_id: AUTH0_CLIENT_ID,
      username: opts.email || opts.phoneNumber,
      otp: opts.otp,
      realm: opts.connection,
      scope: "openid profile email",
    },
  })
    .catch(error => {
      throw error.response.data;
    })
    .then(
      response =>
        response.data as {
          access_token: string;
          id_token: string;
          scope: string;
          expires_in: number;
          token_type: string;
        },
    );
};

interface IDecodedToken {
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  iss: string;
  aud: string;
  iat: number;
  exp: number;
  sub: string;
  sid: string;
  nonce: string;
}

function parseTokenV2(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  try {
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join(""),
    );

    Sentry.addBreadcrumb({
      timestamp: Date.now(),
      message: "[parseTokenV2] decoded successfully",
      level: "info",
    });

    return JSON.parse(jsonPayload) as IDecodedToken;
  } catch (error) {
    Sentry.addBreadcrumb({
      timestamp: Date.now(),
      message: "[parseTokenV2]",
      data: { token },
      level: "error",
    });
    logError("[parseTokenV2]", error);
    return null;
  }
}

function parseToken(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  try {
    return JSON.parse(atob(base64)) as IDecodedToken;
  } catch (error) {
    Sentry.addBreadcrumb({
      timestamp: Date.now(),
      message: "[parseToken]",
      data: { token },
      level: "error",
    });
    logError("[parseToken]", error);
    return parseTokenV2(token);
  }
}

export default {
  parseToken,
  passwordless: {
    start,
    login,
  },
};
