import { graphql } from "@gql";

export const INFLATION_MULTIPLIER_QUERY = graphql(`
  query INFLATION_MULTIPLIER {
    inflationMultiplier: inflationMultipliers(orderBy: blockNumber, orderDirection: desc, first: 20) {
      id
      value
      blockNumber
      timestamp
    }
  }
`);
