import React from "react";
import styles from "./Callout.module.css";
import classNames from "classnames";

interface CalloutProps {
  children: React.ReactNode;
  style?: "warning" | "info";
  className?: string;
  includeIcon?: boolean;
}

export const Callout = ({ children, style = "warning", className, includeIcon = true }: CalloutProps) => {
  const calloutClass = classNames({
    [styles.wrapper]: true,
    [styles.styleWarning]: style === "warning",
    [styles.styleInfo]: style === "info",
    [`${className}`]: Boolean(className),
  });

  return (
    <div className={calloutClass} data-cy="callout">
      {style === "warning" && includeIcon && (
        <div className={styles.icon}>
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.99121 18.7422C14.9746 18.7422 19.0879 14.6289 19.0879 9.6543C19.0879 4.67969 14.9658 0.566406 9.98242 0.566406C5.00781 0.566406 0.90332 4.67969 0.90332 9.6543C0.90332 14.6289 5.0166 18.7422 9.99121 18.7422ZM9.99121 11.1572C9.47266 11.1572 9.17383 10.8672 9.15625 10.3398L9.0332 5.9541C9.01562 5.40918 9.40234 5.03125 9.98242 5.03125C10.5449 5.03125 10.958 5.41797 10.9404 5.96289L10.7998 10.3398C10.7822 10.876 10.4922 11.1572 9.99121 11.1572ZM9.99121 14.1982C9.39355 14.1982 8.90137 13.7676 8.90137 13.1875C8.90137 12.6074 9.38477 12.1768 9.99121 12.1768C10.5889 12.1768 11.0723 12.6074 11.0723 13.1875C11.0723 13.7764 10.5801 14.1982 9.99121 14.1982Z"
              fill="#FF9900"
            />
          </svg>
        </div>
      )}
      {children}
    </div>
  );
};
