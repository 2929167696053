import BN from "bn.js";

import ThresholdKey from "@tkey/default";
import TorusStorageLayer from "@tkey/storage-layer-torus";
import SFAServiceProvider from "@tkey/service-provider-sfa";
import SecurityQuestionsModule from "@tkey/security-questions";
import PrivateKeyModule, { SECP256K1Format } from "@tkey/private-keys";

import { getPersistentData } from "@modules/web3auth/helpers";
import { WEB3AUTH_OPTIONS } from "@modules/web3auth/constants";

import { NETWORK } from "@constants";

export function getConfig() {
  const securityQuestionsModule = new SecurityQuestionsModule();
  const privateKeyModule = new PrivateKeyModule([new SECP256K1Format(new BN(0))]);
  const storageLayer = new TorusStorageLayer({
    hostUrl: "https://metadata.tor.us",
  });

  const serviceProvider = new SFAServiceProvider({
    postboxKey: getPersistentData()?.postboxKey,
    enableLogging: NETWORK.isTestnet,
    web3AuthOptions: WEB3AUTH_OPTIONS,
  });

  const tKey = new ThresholdKey({
    serviceProvider,
    storageLayer,
    modules: {
      privateKey: privateKeyModule,
      securityQuestions: securityQuestionsModule,
    },
  });

  return { tKey, serviceProvider };
}
