import { useEffect, useRef } from "react";
import QrScanner from "qr-scanner";

interface QrScannerOpts {
  onDecodeResult: (result: QrScanner.ScanResult) => void;
  onDecodeError?: (err: Error | string) => void;
}

class ScanResults {
  static readonly VALID_WINDOW = 5_000; // 5s

  public readonly results: { data: string; timestamp: number }[] = [];

  static init() {
    return new ScanResults();
  }

  isValid(data: string) {
    const isValid = !this.getValidResults().includes(data);
    if (isValid) this.add(data);
    return isValid;
  }

  add(data: string) {
    this.results.push({ data, timestamp: Date.now() });
  }

  getValidResults(): string[] {
    return this.results
      .filter(result => result.timestamp + ScanResults.VALID_WINDOW > Date.now())
      .map(result => result.data);
  }
}

export enum QRScannerError {
  CAMERA_NOT_FOUND = "Camera not found.",
}

export const useQrScanner = (opts: QrScannerOpts) => {
  const ref = useRef<HTMLVideoElement>(null);
  const results = useRef(ScanResults.init());

  useEffect(() => {
    if (!ref.current) return;
    const handleResult = (result: QrScanner.ScanResult) => {
      if (results.current.isValid(result.data)) opts.onDecodeResult(result);
    };

    let scanner: QrScanner | undefined;
    const exec = async () => {
      const hasCamera = await QrScanner.hasCamera();
      if (!hasCamera) {
        opts.onDecodeError?.(QRScannerError.CAMERA_NOT_FOUND);
        return;
      }

      if (!ref.current) return;
      scanner = new QrScanner(ref.current, handleResult, {
        maxScansPerSecond: 10,
        onDecodeError: opts.onDecodeError,
      });

      scanner.start().catch(opts.onDecodeError);
    };

    exec();

    return () => {
      scanner?.destroy();
    };
  }, []);

  return { ref };
};
