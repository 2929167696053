import React, { useMemo, useState } from "react";
import { ethers } from "ethers";
import { Client, IUserOperation } from "userop";
import { CheckCircleOutlined } from "@ant-design/icons";
import useMeasure from "react-use-measure";
import { AnimatePresence, motion } from "framer-motion";

import { useDisplayName } from "@hooks/useDisplayName";

import { useStackup } from "@contexts/StackupContext";
import { useAccount } from "@contexts/AccountContext";

import { getNetwork, NETWORK } from "@constants";
import { animationCurveEaseOut, animationDurationFastS } from "@constants/animations";

import Row from "@components/row/Row";
import Card from "@components/card/Card";
import { Avatar } from "@components/avatar/Avatar";
import { useCurrentToken } from "@contexts/TokenContext";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { IconButton } from "@components/iconbutton/IconButton";
import { Button, LinkButton } from "@components/button/Button";
import { ResizeBlock } from "@components/resizeblock/ResizeBlock";

import { blockWindowClosure } from "@helpers/window";
import { calculateTransferAmounts, NetworkTransfer } from "@helpers/transfer";
import {
  convertAmount,
  copy,
  formatBigNumber,
  formatFee,
  formatTokenAmount,
  logError,
  notify,
  shortAddr,
} from "@helpers";

import { useAppDispatch } from "@redux";
import { executeTransfer } from "@redux/slides/balances/balances.slide";

import { getTokenManager } from "@modules/token-managers/tokens";

import "./ConfirmSendSheet.css";
import { ReactComponent as LinkChevron } from "@assets/icons/link_chevron.svg";
import { Callout } from "@components/callout/Callout";
import { BodyText, TextBlock } from "@components/text/Text";

interface ConfirmSendProps {
  isOpen: boolean;
  title: string;
  fee: ethers.BigNumberish;
  amount: string;
  toAddress: string;
  onClose: () => void;
  onReset: () => void;
  alt?: JSX.Element | null;
  onBack?: () => void;
  showAlt?: boolean;
}

export const ConfirmSendSheet: React.FC<ConfirmSendProps> = ({
  isOpen,
  title,
  fee,
  amount,
  toAddress,
  onClose,
  onReset,
  alt,
  onBack,
  showAlt,
}) => {
  const dispatch = useAppDispatch();
  const { token: tokenId } = useCurrentToken();
  const { signer, address } = useStackup();

  const tokenManager = useMemo(() => getTokenManager(tokenId), [tokenId]);

  const [ref, { height }] = useMeasure();

  const { balances } = useAccount();
  const optimismBalance = balances[tokenId].optimism;
  const baseBalance = balances[tokenId].base;

  const [loading, setLoading] = useState(false);

  const feeString = formatFee(fee, { id: tokenManager.composedBy[0], decimals: tokenManager.decimals });

  const recipientDisplayName = useDisplayName(toAddress);

  const amountConverted = ethers.utils.parseUnits(amount, tokenManager.decimals);
  const isSelfTransfer = toAddress.trim().toLowerCase() === address?.toLowerCase();

  const total = amountConverted.add(fee);

  const hasEnoughFunds = useMemo(() => {
    try {
      const value = convertAmount(amount, tokenManager.decimals);
      calculateTransferAmounts(value, ethers.BigNumber.from(fee), [optimismBalance, baseBalance]);
      return true;
    } catch (e) {
      return false;
    }
  }, [amount, fee, tokenManager, optimismBalance, baseBalance]);

  const send = async () => {
    if (!fee || !optimismBalance || !baseBalance) return;
    setLoading(true);
    const value = convertAmount(amount, tokenManager.decimals);
    try {
      notify({
        duration: 5_000,
        content: (
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <CheckCircleOutlined height={14} style={{ marginTop: -3, marginRight: 6 }} />
            <div>
              {formatBigNumber(value, tokenManager.decimals, 2)} {tokenManager.symbol} sent
            </div>
          </div>
        ),
      });

      onReset();

      const networkTransfers: NetworkTransfer[] = [
        {
          network: NETWORK.network!,
          state: "loading",
          balance: optimismBalance,
          amount: ethers.constants.Zero,
          fee: ethers.constants.Zero,
          userOpHash: null,
          txHash: null,
        },
        {
          network: getNetwork("base").network!,
          state: "loading",
          balance: baseBalance,
          amount: ethers.constants.Zero,
          fee: ethers.constants.Zero,
          userOpHash: null,
          txHash: null,
        },
      ];

      const balances = networkTransfers.map(network => network.balance);
      const transferAmounts = calculateTransferAmounts(value, ethers.BigNumber.from(fee), balances);

      const isSuperSend = transferAmounts.filter(transfer => !transfer.amount.add(transfer.fee).isZero()).length > 1;

      blockWindowClosure.enable();

      const manager = getTokenManager(tokenId);

      if (isSuperSend) {
        const [optimism, base] = networkTransfers;
        const [optimismAmounts, baseAmounts] = transferAmounts;

        optimism.fee = optimismAmounts.fee;
        optimism.amount = optimismAmounts.amount;

        base.fee = baseAmounts.fee;
        base.amount = baseAmounts.amount;

        const feeChain = optimism.fee.isZero() && !base.fee.isZero() ? base : optimism;
        const freeChain = optimism.fee.isZero() && !base.fee.isZero() ? optimism : base;

        let feeTx: Record<"resolve" | "reject", (v?: unknown) => void> = {
          resolve: null!,
          reject: null!,
        };
        let freeTx: typeof feeTx = {
          resolve: null!,
          reject: null!,
        };

        const feeTxPromise = new Promise((resolve, reject) => {
          feeTx = { resolve, reject };
        });
        const freeTxPromise = new Promise((resolve, reject) => {
          freeTx = { resolve, reject };
        });

        const feeTransfers = manager.getTransferAmounts(feeChain.amount, feeChain.fee, feeChain.network);
        const freeTransfers = manager.getTransferAmounts(freeChain.amount, freeChain.fee, freeChain.network);

        feeTransfers.forEach(transfer =>
          dispatch(
            executeTransfer({
              tx: feeTxPromise,
              token: transfer.token,
              network: feeChain.network,
              amount: transfer.amount.toString(),
            }),
          ),
        );

        freeTransfers.forEach(transfer =>
          dispatch(
            executeTransfer({
              tx: freeTxPromise,
              token: transfer.token,
              network: freeChain.network,
              amount: transfer.amount.toString(),
            }),
          ),
        );

        try {
          const onUserOpWithFeeTx = (txHash: string) => {
            feeChain.state = "success";
            feeChain.txHash = txHash;
            feeTx.resolve();
          };

          const userOpWithFee = (await manager.transfer({
            dryRun: true,
            signer,
            to: toAddress,
            transfers: feeTransfers,
            network: feeChain.network,
          })) as IUserOperation;

          const request = (await manager.transfer({
            signer,
            to: toAddress,
            transfers: freeTransfers,
            network: freeChain.network,
            userOpWithFee,
            onUserOpWithFeeTx,
          })) as Awaited<ReturnType<Client["sendUserOperation"]>>;

          const userOpTx = await request.wait();

          if (!userOpTx) throw new Error("could not send userOp");

          freeChain.state = "success";
          freeChain.txHash = userOpTx.transactionHash;
          freeTx.resolve();
        } catch (error) {
          logError("[super-send]", error);
          freeChain.state = "error";
          freeTx.reject();

          if (feeChain.state !== "success") {
            feeChain.state = "error";
            feeTx.reject();
          } else {
            notify({
              duration: 5_000,
              type: "warning",
              content: `Only ${formatBigNumber(feeChain.amount, tokenManager.decimals, 2)} ${tokenManager.symbol} sent`,
            });
          }

          notify({
            type: "error",
            duration: 5_000,
            content:
              feeChain.state !== "success"
                ? "Error during SuperSend - No tokens transferred"
                : "Error during SuperSend - Some tokens transferred",
          });
        } finally {
          blockWindowClosure.disable();
        }
      } else {
        networkTransfers.forEach((network, index) => {
          const { amount, fee } = transferAmounts[index];
          network.amount = amount;
          network.fee = fee;
          if (!amount.isZero()) {
            const transfers = manager.getTransferAmounts(amount, fee, network.network);

            const request = manager.transfer({
              to: toAddress,
              transfers,
              signer,
              network: network.network,
            }) as ReturnType<Client["sendUserOperation"]>;

            const tx = request
              .then(response => {
                network.userOpHash = response.userOpHash;
                return response.wait();
              })
              .then(result => {
                if (result) {
                  network.state = "success";
                  network.txHash = result.transactionHash;
                } else {
                  console.error(`[${network.network}-transfer] transaction hash is null`, network, result);
                  network.state = "error";
                  throw new Error("transaction hash is null");
                }
              })
              .catch(error => {
                logError(`[${network.network}-transfer]`, error);
                network.state = "error";
                notify({
                  type: "error",
                  duration: 5_000,
                  content: "Error during send - No tokens transferred",
                });
                throw error;
              });

            tx.finally(() => blockWindowClosure.disable());

            transfers.forEach(transfer =>
              dispatch(
                executeTransfer({
                  tx,
                  token: transfer.token,
                  network: network.network,
                  amount: transfer.amount.toString(),
                }),
              ),
            );
          } else {
            network.state = "success";
          }
        });
      }
    } catch (error) {
      logError("[gasless-transfer]", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      subtitle={`Sending ${amount} ${tokenManager.symbol}`}
      navItemLeading={
        alt &&
        !showAlt && (
          <IconButton
            onClick={onBack}
            icon={
              <div style={{ display: "inline-flex", transform: "rotate(180deg)" }}>
                <LinkChevron />
              </div>
            }
          />
        )
      }
    >
      <ResizeBlock>
        <div style={{ height, position: "relative" }}>
          <AnimatePresence initial={false}>
            <motion.div
              ref={ref}
              key={`${alt && showAlt ? "altWrapper" : "confirmWrapper"}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: animationDurationFastS, ease: animationCurveEaseOut }}
              style={{ position: "absolute", top: 0, left: 0, right: 0 }}
              className={`${alt && showAlt ? "" : "ConfirmSend_Wrapper"}`}
            >
              {alt && showAlt ? (
                <>{alt}</>
              ) : (
                <>
                  <div className="ConfirmSend_Block">
                    <Card style="muted">
                      <Row
                        hasHorizontalPadding={false}
                        deemphasizeTitle
                        title="To"
                        trailingContent={
                          <div className="ConfirmSend_RowTrailing">
                            <div className="ConfirmSend_UserRow">
                              <Avatar address={toAddress} size={18} />
                              {recipientDisplayName}
                            </div>
                            <div className="ConfirmSend_RowDetails">
                              {shortAddr(toAddress)}
                              <>&nbsp;·&nbsp;</>
                              <LinkButton
                                title="Copy address"
                                size="small"
                                onClick={() => copy(toAddress, "Copied Address.")}
                              />
                            </div>
                          </div>
                        }
                      />
                    </Card>
                    <Card style="muted">
                      <>
                        <Row
                          hasHorizontalPadding={false}
                          deemphasizeTitle
                          title="Amount"
                          trailingContent={
                            <div className="ConfirmSend_RowTrailing">
                              <div>
                                {formatTokenAmount(amount, 3)} {tokenManager.symbol}
                              </div>
                            </div>
                          }
                        />
                        <Row
                          hasHorizontalPadding={false}
                          deemphasizeTitle
                          title="Fee"
                          trailingContent={
                            <div className="ConfirmSend_RowTrailing">
                              <div>
                                {formatTokenAmount(feeString, 3)} {tokenManager.symbol}
                              </div>
                            </div>
                          }
                        />
                        <Row
                          hasHorizontalPadding={false}
                          deemphasizeTitle
                          title="Total"
                          trailingContent={
                            <div className="ConfirmSend_RowTrailing">
                              <div>
                                {formatBigNumber(total, tokenManager.decimals, 3)} {tokenManager.symbol}
                              </div>
                            </div>
                          }
                        />
                      </>
                    </Card>
                  </div>

                  {!hasEnoughFunds ? (
                    <Callout className="ConfirmSend_hasEnoughFunds">
                      <TextBlock>
                        <BodyText small>
                          You don&apos;t have enough funds to pay this. You can deposit more money into Beam from the
                          Deposit menu.
                        </BodyText>
                      </TextBlock>
                    </Callout>
                  ) : null}

                  <Button
                    type="primary"
                    disabled={!toAddress || isSelfTransfer || !hasEnoughFunds}
                    onClick={send}
                    loading={loading}
                    title="Send"
                  />
                </>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </ResizeBlock>
    </BottomSheet>
  );
};
