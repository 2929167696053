import React from "react";

import { UserToken } from "@constants";

import { BeamQRCode } from "@components/qr/BeamQRCode/BeamQRCode";
import { CopyAddressSheet } from "@components/qr/CopyAddressSheet/CopyAddressSheet";
import { ethers } from "ethers";

interface QRBodyProps {
  value?: string;
  username: string;
  address?: string;
  userToken: UserToken;
  copyDisabled?: boolean;
  amount?: ethers.BigNumber;
  hideLogo?: boolean;
}

export const QRBody: React.FC<QRBodyProps> = ({
  address,
  value,
  username,
  userToken,
  amount,
  copyDisabled,
  hideLogo,
}) => {
  const [copySheetOpen, setCopySheetOpen] = React.useState(false);
  return (
    <>
      <div onClick={() => setCopySheetOpen(true)}>
        <BeamQRCode hideLogo={hideLogo} amount={amount} value={value} token={userToken} username={username} />
      </div>
      {!copyDisabled && address ? (
        <CopyAddressSheet address={address} open={copySheetOpen} onClose={() => setCopySheetOpen(false)} />
      ) : null}
    </>
  );
};
