import React from "react";
import { isDesktop } from "react-device-detect";

import Row from "@components/row/Row";
import { BodyText } from "@components/text/Text";
import { RowGroup } from "@components/row/RowGroup";
import { Popover } from "@components/popover/Popover";
import BottomSheet from "@components/bottomsheet/BottomSheet";

import styles from "@components/qr/BeamQRCode/BeamQR.module.css";

import { ReactComponent as OptimismIcon } from "@assets/icons/optimism.svg";
import { ReactComponent as BaseIcon } from "@assets/icons/base.svg";

interface SupportedUSDStableCoinsSheetProps {
  open: boolean;
  onClose: () => void;
  targetRef: React.RefObject<HTMLElement>;
}

export const SupportedUSDStableCoinsSheet = ({ open, onClose, targetRef }: SupportedUSDStableCoinsSheetProps) => {
  const opRows = (
    <>
      <Row
        hasHorizontalPadding={false}
        compact
        leadingContent={
          <div className={styles.networkTitle}>
            <OptimismIcon />
            <BodyText light>Optimism</BodyText>
          </div>
        }
      />
      <Row hasHorizontalPadding={false} title="USDC" compact />
      <Row hasHorizontalPadding={false} title="USDT" compact />
      <Row hasHorizontalPadding={false} title="USDCe" compact />
      <Row hasHorizontalPadding={false} title="USDV" compact hasBottomBorder={false} />
    </>
  );

  const baseRows = (
    <>
      <Row
        hasHorizontalPadding={false}
        deemphasizeTitle
        compact
        leadingContent={
          <div className={styles.networkTitle}>
            <BaseIcon />
            <BodyText light>Base</BodyText>
          </div>
        }
      />
      <Row hasHorizontalPadding={false} title="USDC" compact />
      <Row hasHorizontalPadding={false} title="USDbC" compact />
    </>
  );

  if (isDesktop) {
    return (
      <Popover targetRef={targetRef} open={open} onClose={onClose} position="center">
        <div
          style={{
            minWidth: 300,
            paddingLeft: "var(--size-small)",
            paddingRight: "var(--size-small)",
            display: "flex",
            gap: "var(--size-medium)",
          }}
        >
          <div style={{ flex: 1 }}>{opRows}</div>
          <div style={{ flex: 1 }}>{baseRows}</div>
        </div>
      </Popover>
    );
  }
  return (
    <BottomSheet title="Supported USD Stablecoins" onClose={onClose} closeOnShimTap isOpen={open}>
      <RowGroup compact>
        {opRows}
        <div style={{ height: "var(--size-medium)" }} />
        {baseRows}
      </RowGroup>
    </BottomSheet>
  );
};
