import { Network } from "@constants";
import { BeamLink, BeamLinkFlat } from "@modules/peanut/BeamLink";

let beamLinkStorageSingleton: BeamLinkStorage;

export class BeamLinkStorage {
  static readonly STORAGE_KEY = "created-links";
  static readonly MAX_LINKS = 100;

  private links: BeamLink[] = [];

  protected constructor() {
    this.links = this.getDataFromStorage();
  }

  static init() {
    if (!beamLinkStorageSingleton) {
      beamLinkStorageSingleton = new BeamLinkStorage();
    }
    return beamLinkStorageSingleton;
  }

  private static validateBeamLinks(data: unknown): data is BeamLinkFlat[] {
    if (!Array.isArray(data)) return false;
    return data.every(BeamLink.validate);
  }

  add(link: BeamLink) {
    this.links = [link, ...this.links];
  }

  get(network: Network, id: string) {
    return this.links.find(link => link.deposits.some(deposit => deposit.id === id && deposit.network === network));
  }

  store() {
    localStorage.setItem(BeamLinkStorage.STORAGE_KEY, this.encodeData(this.links.slice(0, BeamLinkStorage.MAX_LINKS)));
  }

  protected decodeData(data: string): unknown {
    return JSON.parse(data);
  }

  protected encodeData(data: unknown): string {
    return JSON.stringify(data);
  }

  private getDataFromStorage() {
    try {
      const encodedData = localStorage.getItem(BeamLinkStorage.STORAGE_KEY);
      if (!encodedData) return [];
      const data = this.decodeData(encodedData);
      if (!BeamLinkStorage.validateBeamLinks(data)) {
        console.warn("Invalid Beam Link storage");

        // Store invalid data in another localStorage item
        localStorage.setItem([BeamLinkStorage.STORAGE_KEY, Date.now()].join("-"), encodedData);

        // Delete invalid data
        localStorage.removeItem(BeamLinkStorage.STORAGE_KEY);

        return [];
      }
      return data.map(BeamLink.from);
    } catch (error) {
      console.warn("[getDataFromStorage] Could not get links from storage", error);
    }
    return [];
  }
}
