import { useQuery } from "@tanstack/react-query";
import { Beamnames__factory } from "@assets/contracts";

import { OPTIMISM_PROVIDER } from "@modules/blockchain/providers";
import { BEAMNAMES_ADDRESS } from "@constants";

const beamnames = Beamnames__factory.connect(BEAMNAMES_ADDRESS, OPTIMISM_PROVIDER);

export const useRegistrationFee = () => {
  const { data: registrationFee } = useQuery(["registration-fee"], () => beamnames.registrationFee(), {
    refetchInterval: 3_600_000, // 1H
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  return registrationFee;
};
