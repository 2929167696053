import { ethers } from "ethers";
import WebStorageModule from "@tkey/web-storage";

import { AuthType } from "@modules/web3auth/constants";
import { Web3AuthCore } from "@modules/web3auth/core/Web3AuthCore";

import { getConfig } from "./configuration";

import { store } from "@redux/store";
import { setAuthType } from "@redux/slides/web3auth.slide";

export class Web3AuthMultiFactor extends Web3AuthCore {
  public static readonly SECURITY_QUESTION = "What is your password?";

  private static SINGLETON_INSTANCE: Web3AuthMultiFactor;

  public static get() {
    if (!Web3AuthMultiFactor.SINGLETON_INSTANCE) {
      const { tKey, serviceProvider } = getConfig();
      Web3AuthMultiFactor.SINGLETON_INSTANCE = new Web3AuthMultiFactor(tKey, serviceProvider);
    }
    return Web3AuthMultiFactor.SINGLETON_INSTANCE;
  }

  securityQuestion(): string {
    return Web3AuthMultiFactor.SECURITY_QUESTION;
  }

  async save(wallet: ethers.Wallet, answer?: string, reset?: boolean) {
    await this.tKey.initialize();

    try {
      await this.getShareFromStorage();
    } catch (err) {
      console.warn("[save:warn] failed trying to get share from local storage");
      if (reset) {
        await this.tKey._initializeNewKey({ initializeModules: true });
        await this.getShareFromStorage();
      }
    }

    const response = await super.save(wallet, answer);

    store.dispatch(setAuthType(AuthType.MULTI));

    return response;
  }

  async reconstruct() {
    try {
      await this.getShareFromStorage();
    } catch (err) {
      console.warn("[reconstruct:warn] failed trying to get share from local storage");
    }

    return super.reconstruct();
  }

  private getShareFromStorage() {
    const webStorageModule = this.tKey.modules["webStorage"] as WebStorageModule;
    return webStorageModule.inputShareFromWebStorage();
  }
}
