import { Space } from "antd";
import Cookies from "js-cookie";
import React, { useMemo, useState } from "react";

import { Button } from "@components/button/Button";
import Card from "@components/card/Card";
import {
  CoinbaseOnRampSheet,
  DepositRow,
  KadoOnRampSheet,
  NoRecommendationsCard,
  OtherMethodsCard,
} from "@components/deposits";
import View from "@components/view/View";

import styles from "./Deposit.module.css";

import { ONRAMP_SERVICES, OnRampService } from "@constants/onramp";
import { getRecommendedService } from "@helpers/onramp";

import { VendingMachineCard } from "@components/fund/VendingMachineCard";
import { RowGroup } from "@components/row/RowGroup";
import { TokenIcon } from "@components/token";
import { UserToken } from "@constants/tokens";
import { useNavigate } from "react-router-dom";

export const getRecommendedDepositMethods = () => {
  const country = Cookies.get("beam_country");
  if (country) {
    const recommended = getRecommendedService(country, ONRAMP_SERVICES);
    const recommendedTypes = recommended.map(item => item.type);
    return {
      recommended,
      recommendedTypes,
      unrecommended: ONRAMP_SERVICES.filter(service => !recommendedTypes.includes(service.type)),
    };
  }
  return { unrecommended: ONRAMP_SERVICES, recommended: [], recommendedTypes: [] };
};

export const Deposit: React.FC = () => {
  const [service, setService] = useState<OnRampService>();

  const { recommended, recommendedTypes, unrecommended: unrecommendedList } = useMemo(getRecommendedDepositMethods, []);

  const navigate = useNavigate();

  return (
    <View
      headerTitle="Deposit"
      metaTitle="Deposit"
      navbarItem={
        <Button
          secondary
          size="small"
          color="light"
          title="Buy ECO"
          leadingIcon={<TokenIcon token={UserToken.ECO} />}
          onClick={() => setService(OnRampService.BUY_ECO)}
          className={styles.buyEco}
        />
      }
    >
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        {recommended.length ? (
          recommended.map(service => (
            <Card key={service.type} onClick={() => setService(service.type)}>
              <RowGroup compact>
                <DepositRow
                  title={`Deposit USDC via ${service.name}`}
                  subtitle={service.recommendedText}
                  icon={<service.icon />}
                />
              </RowGroup>
            </Card>
          ))
        ) : (
          <NoRecommendationsCard />
        )}

        {unrecommendedList.length ? (
          <OtherMethodsCard>
            <RowGroup compact>
              {unrecommendedList.map(service => (
                <DepositRow
                  key={service.type}
                  title={`Deposit USDC via ${service.name}`}
                  icon={<service.icon />}
                  onClick={() => setService(service.type)}
                />
              ))}
            </RowGroup>
          </OtherMethodsCard>
        ) : null}
      </Space>

      {service === OnRampService.COINBASE ? (
        <CoinbaseOnRampSheet
          open
          recommended={recommendedTypes.includes(OnRampService.COINBASE)}
          onClose={() => setService(undefined)}
          onSuccess={() => {
            setService(undefined);
            navigate("/");
          }}
        />
      ) : null}
      {service === OnRampService.KADO ? (
        <KadoOnRampSheet
          open
          recommended={recommendedTypes.includes(OnRampService.KADO)}
          onClose={() => setService(undefined)}
        />
      ) : null}
      <VendingMachineCard open={service === OnRampService.BUY_ECO} onClose={() => setService(undefined)} />
    </View>
  );
};
