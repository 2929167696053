import { graphql } from "@gql";

export const BEAMNAME_QUERY = graphql(`
  query BEAMNAME($name: String) {
    beamnames(where: { name: $name }, first: 1) {
      id
      name
      owner {
        id
      }
    }
  }
`);
