import React, { ReactElement, useEffect, useMemo, useState } from "react";

import { OnboardStep, OnboardingStep, useOnboarding } from "../Onboarding";

import { useAccount } from "@contexts/AccountContext";
import { useStackup } from "@contexts/StackupContext";

import BottomSheet from "@components/bottomsheet/BottomSheet";
import { Button, LinkButton } from "@components/button/Button";
import { QRPunkBlockie } from "@components/qr/QRPunkBlockie";
import Row from "@components/row/Row";
import { BodyText, TextBlock, UpcaseText } from "@components/text/Text";

import * as Onramp from "@helpers/onramp";

import { ReactComponent as Airplane } from "@assets/icons/airplane.svg";
import { ReactComponent as BackIcon } from "@assets/icons/arrow_back.svg";
import { ReactComponent as BaseIcon } from "@assets/icons/base.svg";
import { ReactComponent as LinkChevron } from "@assets/icons/link_chevron.svg";
import { ReactComponent as OpIcon } from "@assets/icons/optimism.svg";
import { ReactComponent as FundAccountBrand } from "@assets/images/fund_account_brand.svg";

import Card from "@components/card/Card";
import { KadoOnRampSheet, OtherMethodsCard } from "@components/deposits";
import { DepositRow } from "@components/deposits/DepositRow/DepositRow";
import { CoinbaseOnRampSheet } from "@components/deposits/OnRampSheet/coinbase/CoinbaseOnRampSheet";
import { IconButton } from "@components/iconbutton/IconButton";
import { RowGroup } from "@components/row/RowGroup";
import { animationCurveEaseOut } from "@constants/animations";
import { OnRampService } from "@constants/onramp";
import { getRecommendedDepositMethods } from "@views/Deposit/Deposit";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./FundAccount.module.css";
import networkIconStyles from "./NetworkIconRotater.module.css";
import { isDesktop } from "react-device-detect";
import { CopyAddressSheet } from "@components/qr/CopyAddressSheet/CopyAddressSheet";

const FundAccountContentWrapper = ({
  children,
  id,
  direction,
  fullHeight = true,
}: {
  children: React.ReactNode;
  id: string;
  direction: number;
  fullHeight?: boolean;
}) => {
  const variants = {
    initial: (direction: number) => {
      return {
        x: direction > 0 ? "15%" : "-15%",
        opacity: 0,
      };
    },
    animate: {
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        x: direction > 0 ? "-15%" : "15%",
        opacity: 0,
      };
    },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={variants}
      custom={direction}
      transition={{
        ease: animationCurveEaseOut,
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: fullHeight ? "100%" : undefined,
      }}
      key={id}
    >
      {children}
    </motion.div>
  );
};

export const FundAccount: React.FC = () => {
  const { balances } = useAccount();
  const { nextStep, currStep, setButtonAction, setButtonTitle, setIsLoading } = useOnboarding();

  const [loading, setLoading] = useState(false);

  const hasSomeBalance = Object.values(balances).some(balance => !balance.total.isZero());

  useEffect(() => {
    if (hasSomeBalance) {
      setLoading(false);
      if (currStep === OnboardStep.FundAccount && loading) {
        nextStep();
      }
    }
  }, [hasSomeBalance, loading]);

  useEffect(() => {
    setButtonTitle(hasSomeBalance ? "Next" : "Add funds");
    setButtonAction(() => (hasSomeBalance ? nextStep() : setLoading(true)));
  }, [balances]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <>
      <OnboardingStep
        isDone={hasSomeBalance}
        brand={<FundAccountBrand />}
        stepTitle={"Add funds to your account"}
        postStepContent={
          <TextBlock>
            <BodyText>You can deposit USDC with Coinbase or Kado, and also convert USDC to ECO.</BodyText>
            <BodyText>Or, ask a friend who already uses Beam to send you some.</BodyText>
          </TextBlock>
        }
      />
      <FundSheet isOpen={loading} onClose={() => setLoading(false)} />
    </>
  );
};

const NetworkIconRotater = () => {
  const variants = {
    initial: {
      opacity: 0,
      y: 20,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      y: -20,
    },
  };

  const [showBase, setShowBase] = useState(false);

  const interval = React.useRef<NodeJS.Timer>();

  useEffect(() => {
    interval.current = setInterval(() => {
      setShowBase(showBase => !showBase);
    }, 1500);

    return () => {
      clearInterval(interval.current);
    };
  }, [showBase, setShowBase]);

  return (
    <div className={networkIconStyles.wrapper}>
      <AnimatePresence initial={false}>
        {showBase ? (
          <motion.div
            className={networkIconStyles.base}
            key="base-icon"
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <BaseIcon />
          </motion.div>
        ) : (
          <motion.div
            className={networkIconStyles.op}
            key="op-icon"
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <OpIcon />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

enum FundMethod {
  Receive = "Receive",
  Deposit = "Deposit",
  Buy = "Buy",
  Coinbase = "Coinbase",
  Kado = "Kado",
}

interface FundSheetProps {
  isOpen: boolean;
  onClose: () => void;
}

const FundSheet: React.FC<FundSheetProps> = ({ isOpen, onClose }) => {
  const [method, setMethod] = useState<FundMethod | null>(null);

  const getDrawerTitle = (method: FundMethod) => {
    switch (method) {
      case FundMethod.Buy:
        return "";
      case FundMethod.Coinbase:
        return "Deposit USDC with Coinbase";
      case FundMethod.Deposit:
        return "Deposit funds";
      case FundMethod.Receive:
        return "Receive funds";
      case FundMethod.Kado:
        return "Deposit USDC with Kado";
    }
  };

  const getDrawerSubtitle = (method: FundMethod) => {
    switch (method) {
      case FundMethod.Buy:
      case FundMethod.Deposit:
      case FundMethod.Receive:
        return undefined;
      case FundMethod.Coinbase:
      case FundMethod.Kado:
        return isDesktop
          ? "This view will close automatically once funds are received"
          : "This will close once funds are received";
    }
  };

  const drawerHeight =
    method && [FundMethod.Receive, FundMethod.Coinbase, FundMethod.Kado].indexOf(method) > -1
      ? "100%"
      : method !== null
      ? 300
      : 600;

  const { recommended, unrecommended: unrecommendedList } = useMemo(getRecommendedDepositMethods, []);

  React.useEffect(() => {
    setDirection(method ? -1 : 1);
  }, [method]);

  const [direction, setDirection] = React.useState(0);
  const hasRecommendedMethods = recommended.length > 0;

  return (
    <>
      <BottomSheet
        title={method ? getDrawerTitle(method) : "Add funds"}
        subtitle={method ? getDrawerSubtitle(method) : undefined}
        isOpen={isOpen}
        height={drawerHeight}
        onClose={onClose}
        onAnimationEnd={isOpen => {
          if (!isOpen) {
            setMethod(null);
          }
        }}
        desktopDisplay="side"
        navItemLeading={method ? <IconButton icon={<BackIcon />} onClick={() => setMethod(null)} /> : undefined}
      >
        <AnimatePresence>
          {method === FundMethod.Receive ? (
            <FundAccountContentWrapper id="receive-wrapper" direction={direction}>
              <Receive onClose={() => setMethod(null)} />
            </FundAccountContentWrapper>
          ) : method === FundMethod.Deposit ? (
            <FundAccountContentWrapper id="deposit-wrapper" direction={direction}>
              <Deposit onClose={() => setMethod(null)} />
            </FundAccountContentWrapper>
          ) : method === FundMethod.Buy ? (
            <FundAccountContentWrapper id="buy-wrapper" direction={direction}>
              <Buy onClose={() => setMethod(null)} />
            </FundAccountContentWrapper>
          ) : method === FundMethod.Coinbase ? (
            <FundAccountContentWrapper id="cb-wrapper" direction={direction}>
              <CoinbaseOnRampSheet
                onSuccess={() => {
                  onClose();
                }}
                renderAsSheet={false}
              />
            </FundAccountContentWrapper>
          ) : method === FundMethod.Kado ? (
            <FundAccountContentWrapper id="kado-wrapper" direction={direction}>
              <KadoOnRampSheet renderAsSheet={false} />
            </FundAccountContentWrapper>
          ) : (
            <FundAccountContentWrapper id="base-wrapper" direction={direction} fullHeight={false}>
              <div className={styles.wrapper}>
                {hasRecommendedMethods && (
                  <>
                    <UpcaseText className={styles.recommendedTitle}>Recommended</UpcaseText>
                    {recommended.map(service => (
                      <Card
                        key={service.type}
                        onClick={() => {
                          if (service.type === OnRampService.COINBASE) {
                            setMethod(FundMethod.Coinbase);
                          } else {
                            setMethod(FundMethod.Kado);
                          }
                        }}
                      >
                        <RowGroup compact>
                          <DepositRow
                            title={`Deposit USDC via ${service.name}`}
                            subtitle={service.recommendedText}
                            icon={<service.icon />}
                          />
                        </RowGroup>
                      </Card>
                    ))}
                    <div />
                    <div />
                  </>
                )}
                <Card style={hasRecommendedMethods ? "muted" : undefined}>
                  <div>
                    {hasRecommendedMethods && (
                      <UpcaseText light className={styles.otherTitle}>
                        Other ways to add funds
                      </UpcaseText>
                    )}
                    <RowGroup compact>
                      <FundOption
                        icon={<Airplane className={styles.fundIcon} />}
                        title="Have a friend send you money"
                        description="Others can scan your Beam code or send you a Beam Link"
                        onClick={() => setMethod(FundMethod.Receive)}
                      />
                      <FundOption
                        icon={<NetworkIconRotater />}
                        title="Deposit funds on Optimism or Base"
                        description="You can send them directly to your Beam address"
                        onClick={() => setMethod(FundMethod.Deposit)}
                      />
                    </RowGroup>
                  </div>
                </Card>
                {!hasRecommendedMethods && unrecommendedList.length ? (
                  <OtherMethodsCard style="muted">
                    <RowGroup compact>
                      {unrecommendedList.map(service => (
                        <DepositRow
                          key={service.type}
                          title={`Deposit USDC via ${service.name}`}
                          icon={<service.icon />}
                          onClick={() => {
                            if (service.type === OnRampService.COINBASE) {
                              setMethod(FundMethod.Coinbase);
                            } else {
                              setMethod(FundMethod.Kado);
                            }
                          }}
                        />
                      ))}
                    </RowGroup>
                  </OtherMethodsCard>
                ) : null}
              </div>
            </FundAccountContentWrapper>
          )}
        </AnimatePresence>
      </BottomSheet>
    </>
  );
};

interface FundOptionProps {
  icon: ReactElement;
  title: string;
  description: string;
  onClick: () => void;
}

const FundOption: React.FC<FundOptionProps> = ({ icon, title, description, onClick }) => {
  return (
    <Row
      leadingContent={<div className={styles.fundRowIcon}>{icon}</div>}
      title={title}
      subtitle={description}
      onClick={onClick}
      hasBottomBorder={false}
      hasHorizontalPadding={false}
      trailingContent={
        <div className={styles.fundRowChevron}>
          <LinkChevron />
        </div>
      }
    />
  );
};

interface FundMethodProps {
  onClose?: () => void;
}
const Receive: React.FC<FundMethodProps> = () => {
  // QR code and copyable address
  const { address } = useStackup();
  return (
    <div className={styles.method_container}>
      <div className={styles.methodDescription}>
        <TextBlock>
          <BodyText centered light>
            Others can scan your Beam Code below to send you funds. Alternatively, ask someone to send you a Beam Link.
          </BodyText>
        </TextBlock>
      </div>
      <div className="Code_qr">
        <div className="Code_qrInner">
          <QRPunkBlockie address={address} />
        </div>
      </div>
      <div className={styles.address}>
        <ShowBeamAddress address={address} />
      </div>
    </div>
  );
};

const Deposit: React.FC<FundMethodProps> = () => {
  // copyable address
  const { address } = useStackup();
  return (
    <div className={styles.method_container}>
      <TextBlock>
        <BodyText centered>
          You can deposit funds on Optimism or Base, and then transfer them to your Beam Wallet.
        </BodyText>
        <BodyText centered>
          Tap the button below to view your Beam Wallet address. Only send funds to this address if you know what you
          are doing.
        </BodyText>
      </TextBlock>
      <div />
      <div className={styles.address}>
        <ShowBeamAddress address={address} />
      </div>
    </div>
  );
};

const Buy: React.FC<FundMethodProps> = ({ onClose }) => {
  // buy usdc with a card
  const { address } = useStackup();
  const { currStep } = useOnboarding();

  const [fundSheetOpen, setFundSheetOpen] = useState(false);

  if (!address) return null;

  const onCloseClick = () => {
    onClose && onClose();
    setFundSheetOpen(false);
  };

  useEffect(() => {
    if (currStep !== OnboardStep.FundAccount) {
      onCloseClick();
    }
  }, [currStep]);

  const url = Onramp.getKadoUrl(address);

  return (
    <div className={styles.method_container}>
      <TextBlock>
        <BodyText centered>
          You can use Beam’s onramp partner to buy USDC. Once purchased it will appear in your Beam Wallet. Purchasing
          takes a few moments and requires a valid form of ID such as a driver’s license.
        </BodyText>
      </TextBlock>
      <div />
      <Button title="Buy USDC" onClick={() => setFundSheetOpen(true)} />
      <BottomSheet
        title="Buy USDC"
        subtitle="Add USDC to your Beam account"
        isOpen={fundSheetOpen}
        onClose={onCloseClick}
      >
        <iframe
          src={url}
          height="630px"
          width="100%"
          title="Onramper widget"
          allow="accelerometer; autoplay; camera; gyroscope; payment"
          id="onramper-widget"
        />
      </BottomSheet>
    </div>
  );
};

interface ShowBeamAddressProps {
  address?: string;
}

export const ShowBeamAddress: React.FC<ShowBeamAddressProps> = ({ address }) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <div>
      <div className={styles.show_button}>
        <LinkButton title="Show Beam Address" onClick={() => setIsShown(!!address)} />
      </div>
      {address ? <CopyAddressSheet open={isShown} onClose={() => setIsShown(false)} address={address} /> : null}
    </div>
  );
};
