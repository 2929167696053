import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Col, Row } from "antd";
import classNames from "classnames";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Badge } from "@components/badge/Badge";
import { BodyText } from "@components/text/Text";
import { Avatar } from "@components/avatar/Avatar";
import { Toggle } from "@components/toggle/Toggle";
import { Tooltip } from "@components/tooltip/Tooltip";
import { WhyEmojiSheet } from "@components/WhyEmojiSheet";
import { Button, LinkButton } from "@components/button/Button";
import { SidebarLink } from "@components/sidebar/SidebarLink";

import { useAccount } from "@contexts/AccountContext";
import { useStackup } from "@contexts/StackupContext";

import { useWeb3AuthState } from "@redux/slides/web3auth.slide";
import { enableMerchantMode, useMerchant } from "@redux/slides/merchant.slide";

import { addressToEmoji, notify } from "@helpers";

import { ReactComponent as Beam } from "@assets/icons/beam.svg";
import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";
import { ReactComponent as DepositIcon } from "@assets/icons/deposit_icon.svg";
import { ReactComponent as History } from "@assets/icons/history.svg";
import { ReactComponent as LinkChevron } from "@assets/icons/link_chevron.svg";
import { ReactComponent as BeamBrand } from "@assets/images/beam_brand.svg";
import { ReactComponent as Cart } from "@assets/icons/cart.svg";
import { ReactComponent as AccountIcon } from "@assets/icons/account.svg";

import { useAppDispatch } from "@redux";

import "./Sidebar.css";

import { animationCurveEaseOut, animationDurationFastS } from "@constants/animations";

interface ISidebarProvider {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isHiddenDesktop: boolean;
}

const SidebarContext = React.createContext<ISidebarProvider>({
  open: false,
  setOpen: () => null,
  isHiddenDesktop: false,
});

interface SidebarProps {
  children: React.ReactNode;
  isHiddenDesktop: boolean;
}

export const useSidebar = () => React.useContext(SidebarContext);

export const Sidebar: React.FC<SidebarProps> = ({ children, isHiddenDesktop = false }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleShimClick = () => {
    setSidebarOpen(false);
  };

  return (
    <SidebarContext.Provider value={{ open: sidebarOpen, setOpen: setSidebarOpen, isHiddenDesktop }}>
      <div className={classNames("SidebarWrapper", { "hidden-desktop": isHiddenDesktop })}>
        <SidebarComponent isOpen={sidebarOpen} isHiddenDesktop={isHiddenDesktop} />
        <div className={classNames("SidebarShim", { "is-visible": sidebarOpen })} onClick={handleShimClick} />
        {children}
      </div>
    </SidebarContext.Provider>
  );
};

const SidebarComponent = ({ isOpen, isHiddenDesktop }: { isOpen: boolean; isHiddenDesktop: boolean }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isMerchant, isMerchantModeEnabled } = useMerchant();
  const { address } = useStackup();
  const { hasSavedAccess } = useWeb3AuthState();
  const { beamname, loadingAccount, balances } = useAccount();
  const { open, setOpen } = useSidebar();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  React.useEffect(() => {
    document.documentElement.classList.toggle("sidebar-open", open);

    return () => {
      document.documentElement.classList.remove("sidebar-open");
    };
  }, [open]);

  const hasSomeBalance = useMemo(() => {
    return Object.values(balances).some(balance => !balance.total.isZero());
  }, [balances]);

  const [emojiSheetOpen, setEmojiSheetOpen] = React.useState(false);

  const accountSection = (
    <motion.div
      className={classNames("Sidebar_account", {
        "is-loading": loadingAccount,
        "Sidebar_account-warning": !hasSavedAccess && hasSomeBalance,
      })}
    >
      <div className="Sidebar_accountLoader" />
      <div className="Sidebar_accountInner">
        <Row align="top" justify="start" className="Sidebar_accountInfo">
          <Col className="Sidebar_accountNameWrapper">
            <div className="Sidebar_accountNameInner">
              <div className="Sidebar_accountIcon">
                <Avatar address={address || ""} size={36} />
              </div>
              <div
                onClick={
                  !beamname?.exists
                    ? () => {
                        setEmojiSheetOpen(true);
                      }
                    : undefined
                }
                className={`Sidebar_accountName ${!beamname?.exists ? "has-emojiname" : ""}`}
              >
                <span>
                  {beamname?.exists ? (
                    <>
                      <i>@</i>
                      {beamname.displayName}
                    </>
                  ) : address ? (
                    addressToEmoji(address)
                  ) : (
                    "..."
                  )}
                </span>
                {beamname?.exists && (
                  <Tooltip content="View your Beam profile">
                    <a
                      href={`https://${beamname.displayName}.beam.eco`}
                      target="_newtab"
                      className="Sidebar_accountLink Sidebar_profileLink"
                    >
                      {beamname.displayName + ".beam.eco"}
                      <LinkChevron />
                    </a>
                  </Tooltip>
                )}
              </div>
            </div>
            {!hasSavedAccess ? (
              <div>
                <BodyText light small>
                  <b>Guest account</b>&mdash;create an account to maintain access to your funds.
                </BodyText>
              </div>
            ) : beamname && !beamname?.exists ? (
              <Link to="/account" className="Sidebar_accountLink">
                Register Beamname
                <LinkChevron style={{ width: 12, height: 12 }} />
              </Link>
            ) : null}
          </Col>
        </Row>

        {!hasSavedAccess && (
          <>
            <Button
              size="small"
              title={"Create account"}
              secondary={!hasSavedAccess && !hasSomeBalance}
              color={hasSomeBalance ? "warning" : undefined}
              onClick={() => navigate("/save-access")}
              className="Sidebar_accountButton"
            />
            <div className="Sidebar_login">
              <LinkButton title="Log in" size="small" href="/load-account" />
            </div>
          </>
        )}

        {isMerchant && (
          <div className="Sidebar_merchantToggle">
            <div className="Sidebar_merchantToggleContent">
              <LayoutGroup>
                <AnimatePresence initial={false} mode="popLayout">
                  {isMerchantModeEnabled && (
                    <motion.div
                      className="Sidebar_merchantIcon"
                      initial={{
                        opacity: 0,
                        scale: 0,
                      }}
                      animate={{ opacity: 1, x: 0, scale: 1 }}
                      exit={{
                        opacity: 0,
                        x: 0,
                        scale: 0,
                      }}
                      transition={{
                        ease: animationCurveEaseOut,
                        duration: animationDurationFastS,
                      }}
                    >
                      <motion.span
                        initial={{
                          x: -24,
                        }}
                        animate={{
                          x: 0,
                        }}
                        transition={{
                          delay: 0.1,
                        }}
                      >
                        <Cart />
                      </motion.span>
                    </motion.div>
                  )}
                </AnimatePresence>
                <motion.span
                  key="merchant-label"
                  layout
                  transition={{
                    ease: animationCurveEaseOut,
                  }}
                >
                  <BodyText small>Merchant mode</BodyText>
                </motion.span>
              </LayoutGroup>
            </div>
            <Toggle
              enabled={isMerchantModeEnabled}
              onToggle={() => {
                const newMode = !isMerchantModeEnabled;
                if (newMode) {
                  notify({ type: "success", content: "Switched to merchant mode" });
                }
                dispatch(enableMerchantMode({ enabled: newMode }));
              }}
            />
          </div>
        )}
      </div>
    </motion.div>
  );

  return (
    <>
      <div className={`Sidebar ${isOpen ? "is-open" : ""} ${isHiddenDesktop && "hidden-desktop"}`}>
        <div className="Sidebar_inner">
          <div>
            <div className="Sidebar_header">
              <Link to="/" className="Sidebar_logo">
                <BeamBrand />
              </Link>
              <button onClick={() => setOpen(false)} className="Sidebar_close">
                <CloseIcon />
              </button>
            </div>
          </div>

          <div className="Sidebar_menu">
            <LayoutGroup>
              <SidebarLink
                to="/history"
                title="History"
                icon={<History />}
                isSelected={location.pathname === "/history"}
                key="sidebar-link-history"
              />
              <SidebarLink
                to="/account"
                title="Account"
                icon={<AccountIcon />}
                isSelected={location.pathname === "/account"}
                key="sidebar-link-account"
                badge={!hasSavedAccess && <Badge text="Guest" size="small" style="outline" />}
              />
              <SidebarLink
                to="/about"
                title="About"
                icon={<Beam />}
                isSelected={location.pathname === "/about"}
                key="sidebar-link-about"
              />
              <AnimatePresence initial={false} mode="popLayout">
                {isMerchantModeEnabled ? null : (
                  <motion.div
                    layout
                    animate={{
                      opacity: 1,
                    }}
                    exit={{
                      opacity: 0,
                    }}
                    transition={{
                      ease: animationCurveEaseOut,
                      duration: animationDurationFastS,
                    }}
                  >
                    <SidebarLink
                      to="/deposit"
                      title="Deposit"
                      icon={<DepositIcon />}
                      isSelected={location.pathname === "/deposit"}
                      key="sidebar-link-deposit"
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </LayoutGroup>
          </div>

          <div>
            {accountSection}

            <div className="Sidebar_footer">
              <ul>
                <li>
                  <LinkButton title="About Beam" href="https://about.beam.eco" target="_blank" size="small" />
                </li>
                <li>
                  <LinkButton title="Terms Of Service" href="/terms" size="small" />
                </li>
                <li>
                  <LinkButton title="Privacy Policy" href="/privacy" size="small" />
                </li>
                <li>
                  <LinkButton title="Cookie Policy" href="/cookies" size="small" />
                </li>
              </ul>
              <a className="Sidebar_eco" href="https://eco.org" target="_blank" rel="noreferrer">
                <BodyText small bold>
                  Built on Eco
                </BodyText>
                <span>
                  <BodyText small>Learn more</BodyText>
                  <LinkChevron />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <WhyEmojiSheet
        emojis={address ? addressToEmoji(address) : ""}
        isOpen={emojiSheetOpen}
        onClose={() => setEmojiSheetOpen(false)}
      />
    </>
  );
};
