import { ethers } from "ethers";

import { UserToken } from "@constants";
import { store } from "@redux/store";

export function getOperationFee(token: UserToken, fees = store.getState().config.fee): ethers.BigNumber {
  if (fees) {
    if (token === UserToken.ECO) return ethers.BigNumber.from(fees.perToken.eco);
    if (token === UserToken.ECOx) return ethers.BigNumber.from(fees.perToken.ecox);
    if (token === UserToken.USD) return ethers.BigNumber.from(fees.perToken.usd);
  }

  return ethers.constants.Zero;
}
