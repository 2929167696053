import { Token, UserToken } from "@constants";
import { TokenManager } from "@modules/token-managers/TokenManager";

export class OakManager extends TokenManager {
  constructor() {
    super(UserToken.OAK, {
      symbol: "OAK",
      decimals: 6,
      composedBy: [Token.OAK],
    });
  }
}
