import BN from "bn.js";
import { ethers } from "ethers";

import ThresholdKey from "@tkey/default";
import PrivateKeyModule from "@tkey/private-keys";
import SFAServiceProvider from "@tkey/service-provider-sfa";
import SecurityQuestionsModule from "@tkey/security-questions";

import config from "@constants/config";

import { logError } from "@helpers";
import Auth0 from "@helpers/auth0";
import { getBeamWallet } from "@helpers/bundler";
import { markOnboardingAsCompleted } from "@helpers/onboarding";
import { dripEcoToUser, logSaveAccess } from "@helpers/twitter";

import { AuthMethod, ETHEREUM_PRIVATE_KEY_PROVIDER } from "@modules/web3auth/constants";
import {
  BasicUserInfo,
  checkHasSavedAccessByTokenId,
  getStoredPasswordShares,
  isMetadataPresent,
  isSFAServiceProvider,
  pkToBN,
  savePasswordShare,
  saveToLocalstorage,
  setSavedAccessCookie,
  storePersistentData,
} from "@modules/web3auth/helpers";

import { store } from "@redux/store";
import { setReconstructed, setUserData, setWeb3AuthSigner } from "@redux/slides/web3auth.slide";

export abstract class Web3AuthCore {
  private isInitialized = false;

  private loginData?: { idToken: string; basicData: BasicUserInfo; postboxKey: BN; hasSavedAccessBefore: boolean };

  protected constructor(protected tKey: ThresholdKey, protected readonly serviceProvider: SFAServiceProvider) {}

  abstract securityQuestion(): string;

  static async checkIfHasSavedAccess(idToken: string) {
    let hasSavedAccess = { twitter: false, email: false };
    try {
      hasSavedAccess = await checkHasSavedAccessByTokenId(idToken);
    } catch (error) {
      logError("[checkHasSavedAccessByTokenId]", error);
    }
    return { hasSavedAccess };
  }

  getSecurityQuestionFromModule() {
    const securityQuestionsModule = this.tKey.modules.securityQuestions as SecurityQuestionsModule;
    try {
      return securityQuestionsModule.getSecurityQuestions();
    } catch (err) {
      console.warn("Question not found", err);
    }
  }

  async save(wallet: ethers.Wallet, answer?: string) {
    if (answer) {
      await this.updateSecurityQuestion(answer);
    }

    await this.reconstruct();

    let privateKey;
    try {
      privateKey = await this.getPrivateKeys();
    } catch (error) {
      logError("[web3Auth:save:getPrivateKeys]", error);
    }

    const idToken = this.loginData?.idToken;
    if (idToken) {
      this.log(idToken, wallet);
    }

    this.storeData(wallet);
    saveToLocalstorage(wallet.privateKey);
    setSavedAccessCookie(wallet.privateKey);

    const pkBn = pkToBN(wallet.privateKey);
    if (pkBn.toString("hex") !== privateKey) {
      const privateKeyModule = this.tKey.modules.privateKey as PrivateKeyModule;
      await privateKeyModule.setPrivateKey("secp256k1n", pkBn);
    } else {
      console.warn("already saved - skipping");
    }
  }

  async recover() {
    await this.reconstruct();
    const privateKey = await this.getPrivateKeys();
    if (!privateKey) throw new Error("pk-not-found: Unable to recover - no key stored");
    const wallet = new ethers.Wallet(privateKey);

    this.storeData(wallet);
    saveToLocalstorage(wallet.privateKey);
    setSavedAccessCookie(wallet.privateKey);

    markOnboardingAsCompleted();

    store.dispatch(setWeb3AuthSigner(wallet.privateKey));
  }

  async reconstruct() {
    await this.tKey.reconstructKey();
    store.dispatch(setReconstructed(true));
  }

  async loginWithToken(idToken: string, authMethod: AuthMethod) {
    await this.init();

    if (!isSFAServiceProvider(this.tKey.serviceProvider)) {
      throw new Error("Invalid service provider");
    }

    const userData = Auth0.parseToken(idToken);

    if (!userData) throw new Error("Invalid user data.");

    const { sub } = userData;

    const basicData = {
      name: userData.name,
      typeOfLogin: authMethod,
      profileImage: userData.picture,
    };

    let postboxKey: BN;
    try {
      postboxKey = await this.tKey.serviceProvider.connect({
        verifier: config.features.saveAccess.web3auth.verifier,
        verifierId: sub,
        idToken: idToken,
      });
    } catch (error) {
      // eslint-disable-next-line
      const message = (error as any).message;
      if (message !== "Duplicate token found") throw error;
      postboxKey = this.tKey.serviceProvider.postboxKey;
    }

    const hasSavedAccessBefore = await isMetadataPresent(this.tKey, postboxKey);

    this.setLoginData({
      postboxKey,
      basicData,
      idToken,
      hasSavedAccessBefore,
    });

    await this.tKey.initialize();

    return { hasSavedAccessBefore };
  }

  setLoginData(data: typeof this.loginData) {
    this.loginData = data;
  }

  getLoginData() {
    return this.loginData;
  }

  setTKey(tKey: typeof this.tKey) {
    this.tKey = tKey;
  }

  getTKey() {
    return this.tKey;
  }

  private storeData(wallet: ethers.Wallet) {
    if (!this.loginData) return;
    const { postboxKey, basicData, idToken, hasSavedAccessBefore } = this.loginData;
    store.dispatch(setUserData({ idToken, userInfo: basicData, hasSavedAccessBefore }));

    storePersistentData(wallet, {
      postboxKey: postboxKey.toString("hex"),
      ...basicData,
    });
  }

  async updateSecurityQuestion(answer: string) {
    await this.init();

    const securityQuestionsModule = this.tKey.modules.securityQuestions as SecurityQuestionsModule;

    const question = this.getSecurityQuestionFromModule();
    if (question) {
      await securityQuestionsModule.changeSecurityQuestionAndAnswer(answer, this.securityQuestion());
    } else {
      await securityQuestionsModule.generateNewShareWithSecurityQuestions(answer, this.securityQuestion());
    }
  }

  async answerSecurityQuestions(answer: string) {
    const shareKeys = Object.keys(Object.values(this.tKey.shares)[0]);

    // Answer question and generate share
    const securityQuestionsModule = this.tKey.modules.securityQuestions as SecurityQuestionsModule;
    await securityQuestionsModule.inputShareFromSecurityQuestions(answer);

    const shareStoreMap = Object.values(this.tKey.shares)[0];
    const shares = Object.values(shareStoreMap);
    const passwordShareFound = Object.entries(shareStoreMap).find(pair => !shareKeys.includes(pair[0]));
    // Get newer share
    const passwordShare = passwordShareFound ? passwordShareFound[1] : shares[shares.length - 1];

    savePasswordShare(passwordShare);
  }

  async init() {
    if (this.isInitialized) return;
    this.isInitialized = true;

    // Init Service Provider
    try {
      await this.serviceProvider.init(ETHEREUM_PRIVATE_KEY_PROVIDER);
    } catch (error) {
      this.isInitialized = false;
      console.error("Web3Auth Core error - service provider initialization", error);
      return;
    }

    try {
      const { userInfo } = store.getState().web3auth;
      if (userInfo) {
        await this.tKey.initialize();

        const passwordShares = getStoredPasswordShares();
        passwordShares.forEach(share => this.tKey.inputShareStore(share));

        await this.reconstruct();
      }
    } catch (error) {
      console.warn("useWeb3Auth init: ", error);
    }
  }

  private async getPrivateKeys() {
    const privateKeyModule = this.tKey.modules.privateKey as PrivateKeyModule;
    const keys = await privateKeyModule.getPrivateKeys();

    const secp256k1nKeys = keys.filter(key => key.type === "secp256k1n");
    if (!secp256k1nKeys.length) return null;

    const { privateKey } = secp256k1nKeys[secp256k1nKeys.length - 1];
    return privateKey.toString("hex");
  }

  private async log(idToken: string, wallet: ethers.Wallet) {
    try {
      const simpleAccount = await getBeamWallet(wallet, "optimism");

      logSaveAccess(idToken, simpleAccount.getSender());
      dripEcoToUser(idToken, simpleAccount.getSender());
    } catch (error) {
      logError("[drip-eco-failed]", error);
    }
  }
}
