import { ethers } from "ethers";
import { OPTIMISM_PROVIDER } from "@modules/blockchain/providers";
import { getTokenInfo, Network, NETWORK, Token } from "@constants";
import { ERC20__factory, L2ECO__factory, VendingMachine__factory } from "@assets/contracts";
import config from "@constants/config";

// connect to vending contract
export const vending = VendingMachine__factory.connect(config.optimism.contracts.vendingMachine, OPTIMISM_PROVIDER);
// get token information
export const network = NETWORK.network as Network;
const tokenUsdType = NETWORK.id === "goerli-optimism" ? Token.USDC : Token.USDCe;
export const tokenEco = getTokenInfo(Token.ECO, network);
export const tokenUsdc = getTokenInfo(tokenUsdType, network);
export const eco = L2ECO__factory.connect(tokenEco.address, OPTIMISM_PROVIDER);
export const usdc = ERC20__factory.connect(tokenUsdc.address, OPTIMISM_PROVIDER);
export const DEFAULT_FEE_ECO = ethers.utils.parseEther("20");
export const DEFAULT_FEE_USDC = ethers.utils.parseUnits("0.5", 6);
