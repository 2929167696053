import React, { useState } from "react";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import { UserToken } from "@constants";
import { useCurrentToken } from "@contexts/TokenContext";
import { formatBigNumber, logError, notify, roundMax } from "@helpers";

import { useAppSelector } from "@redux";
import { useConfigFee } from "@redux/slides/config.slide";
import { getBalances } from "@redux/slides/balances/balances.slide";

import { BodyText } from "@components/text/Text";
import { NumberPad } from "@components/numberpad/NumberPad";
import { Button, LinkButton } from "@components/button/Button";
import { BreakdownSheet } from "@components/breakdownsheet/BreakdownSheet";

import styles from "./VendingMachine.module.css";

import { getBalance, getUsdAmount } from "@components/fund/helpers";
import { useVending, useVendingMachineBalance } from "@components/fund/hooks";
import { DEFAULT_FEE_ECO, DEFAULT_FEE_USDC, network, tokenEco, tokenUsdc } from "@components/fund/constants";

import { VendingMachine } from "@modules/vending-machine/VendingMachine";
import BottomSheet from "@components/bottomsheet/BottomSheet";

enum Operation {
  BUY,
  SELL,
}

interface VendingMachineProps {
  open: boolean;
  onClose: () => void;
}

export const VendingMachineCard = ({ open, onClose }: VendingMachineProps) => {
  const navigate = useNavigate();
  const { token: userTokenId } = useCurrentToken();
  const flatFees = useConfigFee();
  const balances = useAppSelector(getBalances);
  const { prices, swappableBalance, transferFee, buyEco, sellEco } = useVending(open);

  const fee = {
    eco: flatFees?.perToken.eco || DEFAULT_FEE_ECO.toString(),
    usdc: flatFees?.perToken.usd || DEFAULT_FEE_USDC.toString(),
  };

  //set state variables
  const [amount, setAmount] = useState<string>("0");
  const [buyOrSell, setBuyOrSell] = useState(Operation.BUY);
  const [breakdownSheetOpen, setBreakdownSheetOpen] = useState(false);
  const [loadingTransaction, setLoadingTransaction] = useState(false);

  const { data: contractBalance } = useVendingMachineBalance();

  //function to buy eco at the component level, wraps vending machine function
  const doBuyEco = async (amount: ethers.BigNumber) => {
    if (!availableBalance || !prices) return;

    setLoadingTransaction(true);

    try {
      await buyEco(amount, prices.buy);

      notify({
        duration: 5_000,
        content: (
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <CheckCircleOutlined height={14} style={{ marginTop: -3, marginRight: 6 }} />
            <div>You have bought {formatBigNumber(amount)} ECO!</div>
          </div>
        ),
      });
      navigate("/");
    } catch (error) {
      logError("[buy-eco]", error);
      notify({
        type: "error",
        duration: 5_000,
        content: "Unexpected error while buying ECO",
      });
    } finally {
      setLoadingTransaction(false);
    }
  };

  //function to sell eco at the component level, wraps vending machine function
  const doSellEco = async (amount: ethers.BigNumber) => {
    if (!availableBalance || !prices) return;

    setLoadingTransaction(true);

    try {
      await sellEco(amount, prices.sell!);

      notify({
        duration: 5_000,
        content: (
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <CheckCircleOutlined height={14} style={{ marginTop: -3, marginRight: 6 }} />
            <div>You have sold {formatBigNumber(amount)} ECO!</div>
          </div>
        ),
      });
      navigate("/");
    } catch (error) {
      logError("[sell-eco]", error);
      notify({
        type: "error",
        duration: 5_000,
        content: "Unexpected error while selling ECO",
      });
    } finally {
      setLoadingTransaction(false);
    }
  };

  const handleToggleClick = (buyOrSell: Operation) => {
    setAmount("0");
    setBuyOrSell(buyOrSell);
  };

  const amountConverted = ethers.utils.parseUnits(amount, 18);
  const ecoBalance = getBalance(balances[network].balances[tokenEco.id], fee.eco);
  const usdceBalance = ethers.BigNumber.from(balances[network].balances[tokenUsdc.id]);
  const usdBalance =
    VendingMachine.SWAPPABLE_TOKENS.reduce(
      (acc, token) => acc.add(balances.optimism.balances[token]),
      ethers.constants.Zero,
    ) ?? usdceBalance;

  let buyFee = ethers.BigNumber.from(fee.usdc);
  const buyTotal = prices ? getUsdAmount(amountConverted, prices.buy).add(buyFee) : ethers.constants.Zero;

  if (buyTotal.gt(usdceBalance) && !swappableBalance.isZero()) {
    let diff = usdBalance.sub(usdceBalance).sub(swappableBalance);
    if (transferFee && transferFee.token !== tokenUsdc.id) diff = diff.sub(fee.usdc);
    if (diff.gt(ethers.constants.Zero)) buyFee = ethers.BigNumber.from(buyFee).add(diff);
  }

  let availableBalance = usdceBalance.add(swappableBalance);
  if (transferFee.token === tokenUsdc.id) {
    availableBalance = availableBalance.sub(transferFee.amount);
  }
  if (availableBalance.isNegative()) availableBalance = ethers.constants.Zero;

  const isSellEnabled = prices ? !prices.sell.isZero() : false;

  const insufficientBalance =
    !prices ||
    (buyOrSell === Operation.BUY ? buyTotal.sub(buyFee).gt(availableBalance) : amountConverted.gt(ecoBalance));

  const hasEnoughLiquidity =
    !contractBalance ||
    !prices ||
    (buyOrSell === Operation.BUY
      ? amountConverted.lte(contractBalance.eco)
      : getUsdAmount(amountConverted, prices.sell).lte(contractBalance.usdc));

  const buttonState = ((): { disabled: true; message: string } | { disabled: false; message?: never } => {
    switch (true) {
      case amountConverted.isZero():
        return { disabled: true, message: "Enter an amount" };
      case insufficientBalance:
        return { disabled: true, message: "Insufficient balance" };
      case !hasEnoughLiquidity:
        return { disabled: true, message: "Not enough liquidity" };
    }
    return { disabled: false };
  })();

  const setMax = () => {
    let maxAmount: ethers.BigNumber;
    if (buyOrSell === Operation.BUY) {
      if (!availableBalance || !prices) return;
      maxAmount = availableBalance.mul(ethers.BigNumber.from(10).pow(30)).div(prices.buy);
    } else {
      if (!ecoBalance) return;
      maxAmount = ecoBalance;
    }
    setAmount(roundMax(parseFloat(ethers.utils.formatUnits(maxAmount)), 2).toString());
  };

  return (
    <>
      <BottomSheet
        height="100%"
        includePadding
        isOpen={open}
        onClose={onClose}
        desktopDisplay="side"
        title={
          isSellEnabled ? (
            <div className={styles.toggle}>
              <div
                onClick={handleToggleClick.bind(null, Operation.BUY)}
                className={`${styles.toggleItem} ${buyOrSell === Operation.BUY ? styles.selected : undefined}`}
              >
                Buy ECO
              </div>
              <div
                onClick={handleToggleClick.bind(null, Operation.SELL)}
                className={`${styles.toggleItem} ${buyOrSell === Operation.SELL ? styles.selected : undefined}`}
              >
                Sell ECO
              </div>
            </div>
          ) : (
            "Buy ECO"
          )
        }
        footer={
          <div>
            <div className={styles.info}>
              <div className={styles.CurrentPrice} data-cy={"CurrentPrice"}>
                <BodyText small light>
                  <span>
                    Current buy price: &nbsp;
                    <b>
                      {prices
                        ? formatBigNumber(buyOrSell === Operation.BUY ? prices.buy : prices.sell, 18, 4) +
                          " USDCe / ECO"
                        : "Loading..."}
                    </b>
                  </span>
                </BodyText>
              </div>
              <div className={styles.CurrentPrice} data-cy={"TransactionFee"}>
                <BodyText small light>
                  <span>
                    Transaction fee: &nbsp;
                    <b>
                      {fee.eco && fee.usdc
                        ? buyOrSell === Operation.BUY
                          ? formatBigNumber(buyFee, 6) + " USD"
                          : formatBigNumber(fee.eco) + " ECO"
                        : "Loading..."}{" "}
                    </b>
                  </span>
                </BodyText>
              </div>
            </div>
            <Button
              data-cy="transfer-send-btn"
              key="submit"
              type="primary"
              disabled={buttonState.disabled}
              loading={loadingTransaction}
              title={buttonState.disabled ? buttonState.message : buyOrSell === Operation.BUY ? "Buy ECO" : "Sell ECO"}
              subtitle={
                <div style={{ opacity: 0.5 }}>
                  <BodyText small>
                    Estimated {buyOrSell === Operation.BUY ? "Cost" : "Proceeds"}: &nbsp;
                    {!amountConverted.isZero() && prices && fee.eco && fee.usdc
                      ? buyOrSell === Operation.BUY
                        ? formatBigNumber(buyTotal, 6)
                        : formatBigNumber(amountConverted.add(fee.eco), 18)
                      : "N/A"}{" "}
                    {buyOrSell === Operation.BUY ? "USDCe" : "ECO"} &nbsp;
                  </BodyText>
                </div>
              }
              onClick={() => {
                if (buyOrSell === Operation.BUY) {
                  doBuyEco(amountConverted);
                } else if (buyOrSell === Operation.SELL) {
                  doSellEco(amountConverted);
                }
              }}
            />
          </div>
        }
      >
        <div className={styles.wrapper}>
          <NumberPad
            value={amount}
            setValue={setAmount}
            token={userTokenId}
            message={
              <div className={styles.message}>
                {buyOrSell === Operation.SELL && !isSellEnabled ? (
                  <BodyText bold>Selling is disabled</BodyText>
                ) : (
                  <BodyText small>
                    {buyOrSell === Operation.BUY ? (
                      <>
                        <span onClick={() => setBreakdownSheetOpen(true)}>
                          {formatBigNumber(usdBalance, tokenUsdc.decimals, 2)} USD available <QuestionCircleOutlined />
                        </span>
                        <span>&nbsp;{" · "}&nbsp;</span>
                      </>
                    ) : null}

                    {buyOrSell === Operation.BUY && usdBalance.isZero() ? (
                      <LinkButton size="small" title="Deposit funds" href="/deposit" />
                    ) : (
                      <LinkButton size="small" title="Enter maximum" onClick={setMax} disabled={loadingTransaction} />
                    )}
                  </BodyText>
                )}
              </div>
            }
          />
        </div>
      </BottomSheet>
      <BreakdownSheet
        warning
        token={UserToken.USD}
        isOpen={breakdownSheetOpen}
        onClose={() => setBreakdownSheetOpen(false)}
      />
    </>
  );
};
