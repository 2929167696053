import { graphql } from "@gql";

export const BEAMNAME_SEARCH = graphql(`
  query BEAMNAME_SEARCH($input: String, $offset: Int) {
    beamnames(where: { name_starts_with: $input }, first: 20, skip: $offset) {
      name
      owner {
        id
      }
    }
  }
`);
