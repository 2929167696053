import { BigNumber, ethers, Event, EventFilter } from "ethers";
import { Log } from "@ethersproject/abstract-provider";
import { Formatter } from "@ethersproject/providers";
import { logError } from "@helpers";

import { PEANUT_ADDRESSES } from "@modules/peanut/constants";
import { getNetworkProvider } from "@modules/blockchain/providers";

import { store } from "@redux/store";
import { incrementBalance } from "@redux/slides/balances/balances.slide";

import { ERC20__factory, Multicall3__factory } from "@assets/contracts";
import {
  FAUCET_ADDRESS,
  getTokenByAddress,
  getTokenInfo,
  getUserTokenByToken,
  MULTICALL3_ADDRESS,
  Network,
  Token,
  TOKENS,
  USER_TOKENS,
  UserToken,
  VENDING_MACHINE_ADDRESSES,
} from "@constants";
import { getTokenManager } from "@modules/token-managers/tokens";
import { TransferEvent } from "@assets/contracts/ERC20";

const { Zero } = ethers.constants;

interface TransferIn {
  amount: string;
  blockNumber: number;
  transactionHash: string;
  logIndex: number;
}

export interface TransferInCallbacksProps {
  from: string;
  amount: BigNumber;
  userToken: UserToken;
}

const wait = (timeout: number) => new Promise(resolve => setTimeout(resolve, timeout));

export class BalanceHandler {
  static readonly INITIAL_POLLING_INTERVAL = 30_000;

  private readonly provider: ethers.providers.StaticJsonRpcProvider;

  private pollingInterval = BalanceHandler.INITIAL_POLLING_INTERVAL; // 30s

  private transfersIn: TransferIn[] = [];

  private fromBlock: number | undefined;

  private excludedAddresses: Set<string> = new Set([FAUCET_ADDRESS, ...VENDING_MACHINE_ADDRESSES, ...PEANUT_ADDRESSES]);

  constructor(private readonly address: string, public readonly network: Network) {
    this.provider = getNetworkProvider(network);
  }

  setPollingInterval(interval: number) {
    this.pollingInterval = interval;
  }

  addTransferInCallback(fn: (typeof this.transferInCallbacks)[0]) {
    this.transferInCallbacks.push(fn);
    return this;
  }

  removeTransferInCallback(fn: (typeof this.transferInCallbacks)[0]) {
    this.transferInCallbacks = this.transferInCallbacks.filter(_fn => _fn !== fn);
    return this;
  }

  excludeAddress(address: string) {
    this.excludedAddresses.add(address);
    return this;
  }

  async fetchInitialBalances(): Promise<Record<Token, string>> {
    try {
      const { balances, blockNumber } = await this._fetchInitialBalances();
      this.fromBlock = blockNumber;
      return balances;
    } catch (error) {
      logError("[fetchInitialBalances]", error);
      // Wait 1s before retrying
      await wait(1_000);
      return this.fetchInitialBalances();
    }
  }

  async listenTransfers() {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (!this.pollingInterval) break;
      try {
        const currentBlock = await this.provider.getBlockNumber();

        const tokenAddresses = USER_TOKENS.flatMap(userToken => getTokenManager(userToken).composedBy)
          .map(token => getTokenInfo(token, this.network).address)
          .filter(address => address !== ethers.constants.AddressZero);

        const erc20 = ERC20__factory.connect(ethers.constants.AddressZero, this.provider);
        const transferFilter = erc20.filters.Transfer(null, this.address);

        const fromBlock = this.fromBlock! + 1;
        const toBlock = currentBlock;

        this.fromBlock = toBlock;

        const events = await this.getLogs(
          { topics: transferFilter.topics, address: tokenAddresses },
          fromBlock,
          toBlock,
        ).then(events =>
          events.map(event => {
            event.args = erc20.interface.decodeEventLog("Transfer", event.data, event.topics);
            return event as unknown as TransferEvent;
          }),
        );

        const filteredEvents = events.filter(event => {
          const [from] = event.args;

          if (this.excludedAddresses.has(from)) return false;

          const isIncluded = this.transfersIn.some(
            transfer => transfer.transactionHash === event.transactionHash && transfer.logIndex === event.logIndex,
          );

          return !isIncluded;
        });

        filteredEvents.forEach(event => {
          const [, , amount] = event.args;
          const token = getTokenByAddress(event.address, this.network);

          this.transfersIn.push({
            amount: amount.toString(),
            logIndex: event.logIndex,
            blockNumber: event.blockNumber,
            transactionHash: event.transactionHash,
          });

          store.dispatch(
            incrementBalance({
              token: token,
              network: this.network,
              amount: amount.toString(),
            }),
          );
        });

        const transactions = filteredEvents.reduce((acc, event) => {
          const token = getTokenByAddress(event.address, this.network);
          const userToken = getUserTokenByToken(token);
          const id = [event.transactionHash, event.args.from, userToken].join("-");
          if (!acc[id]) {
            acc[id] = {
              userToken,
              transferEvents: [],
              from: event.args.from,
              transactionHash: event.transactionHash,
              amount: ethers.constants.Zero,
            };
          }
          acc[id].transferEvents.push(event);
          acc[id].amount = acc[id].amount.add(event.args.value);
          return acc;
        }, {} as Record<string, { from: string; amount: ethers.BigNumber; userToken: UserToken; transactionHash: string; transferEvents: TransferEvent[] }>);

        Object.values(transactions).forEach(transaction =>
          this.transferInCallbacks.forEach(fn =>
            fn({ from: transaction.from, amount: transaction.amount, userToken: transaction.userToken }),
          ),
        );

        await wait(this.pollingInterval);
      } catch (error) {
        // Wait 3s before trying
        await wait(3_000);
      }
    }
  }

  clear() {
    this.setPollingInterval(0);
  }

  private transferInCallbacks: ((opts: TransferInCallbacksProps) => void)[] = [];

  private async _fetchInitialBalances() {
    const multicall = Multicall3__factory.connect(MULTICALL3_ADDRESS, this.provider);

    const blockCall = {
      allowFailure: false,
      target: MULTICALL3_ADDRESS,
      callData: multicall.interface.encodeFunctionData("getBlockNumber"),
    };

    const calls = TOKENS.map(tokenId => {
      const token = getTokenInfo(tokenId, this.network);
      return {
        allowFailure: false,
        target: token.address,
        callData: ERC20__factory.createInterface().encodeFunctionData("balanceOf", [this.address]),
      };
    });

    const [blockNumberResult, ...result] = await multicall.callStatic.aggregate3([blockCall, ...calls]);

    // Block Number
    const blockNumber = parseInt(blockNumberResult.returnData);

    // Initial Balances
    const balances = Object.fromEntries(
      result.map((callResult, index) => {
        const amount = callResult.returnData === "0x" ? Zero : BigNumber.from(callResult.returnData);
        return [TOKENS[index], amount.toString()];
      }),
    ) as Record<Token, string>;

    return { blockNumber, balances };
  }

  private async getLogs(
    eventFilter: { address?: string | string[]; topics: EventFilter["topics"] },
    fromBlockOrBlockhash?: string | number | undefined,
    toBlock: string | number = "latest",
  ): Promise<Event[]> {
    const params = [
      {
        fromBlock:
          fromBlockOrBlockhash && typeof fromBlockOrBlockhash === "number"
            ? "0x" + BigInt(fromBlockOrBlockhash).toString(16)
            : fromBlockOrBlockhash,
        toBlock: toBlock && "0x" + BigInt(toBlock).toString(16),
        ...eventFilter,
      },
    ];

    const logs: Array<Log> = await this.provider.send("eth_getLogs", params);
    logs.forEach(log => {
      if (log.removed == null) {
        log.removed = false;
      }
    });
    return Formatter.arrayOf(this.provider.formatter.filterLog.bind(this.provider.formatter))(logs);
  }
}
