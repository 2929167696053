import { gql, TypedDocumentNode } from "@apollo/client";

export const ENS_DOMAINS: TypedDocumentNode<
  {
    __typename?: "Query";
    domains?: {
      __typename?: "Domain";
      name?: string;
      resolvedAddress: { __typename?: "Account"; id: string };
    }[];
  },
  {
    addresses: string[];
  }
> = gql(`
  query ENS_DOMAINS($addresses: [String!]) {
    domains(where: { resolvedAddress_in: $addresses }) {
      name
      resolvedAddress {
        id
      }
    }
  }
`);
