import React from "react";
import { ethers } from "ethers";
import { Space } from "antd";
import AnimatedNumber from "react-awesome-animated-number";

import { round } from "@helpers";

export const TokenBalance: React.FC<{
  decimals: number;
  balance?: ethers.BigNumber;
  icon?: React.ReactNode;
}> = ({ icon, decimals, balance }) => {
  const value = balance ? round(parseFloat(ethers.utils.formatUnits(balance, decimals)), 2) : 0;
  const fontSize = getComputedStyle(document.documentElement)
    .getPropertyValue("--font-size-amount-entry")
    .replace("px", "");

  return (
    <Space.Compact direction="horizontal" style={{ alignItems: "baseline", gap: 8 }}>
      {icon}
      <h2>
        <AnimatedNumber
          value={value}
          hasComma={true}
          size={Number(fontSize)}
          duration={value === 0 ? 0 : 800}
          minDigits={1}
          data-cy={`animated-number-${value}`} // Here's the added data-cy attribute
        />
      </h2>
    </Space.Compact>
  );
};
