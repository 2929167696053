import { useEffect, useState } from "react";
import { useAccount } from "@contexts/AccountContext";

// Store latest 3 items
const STORE_COUNT = 3;
const RECENTS_KEY = "RECENT_AMOUNT_KEY";

function getStorageKey(address: string) {
  return RECENTS_KEY + "-" + address.toLowerCase().substring(0, 10);
}

function storeRecents(address: string, items: number[]) {
  window.localStorage.setItem(getStorageKey(address), JSON.stringify(items));
}
function getStoredRecents(address?: string): number[] {
  if (!address) return [];
  const storedText = window.localStorage.getItem(getStorageKey(address));
  return JSON.parse(storedText || "[]");
}

export const useRecents = () => {
  const account = useAccount();
  const [list, setList] = useState<number[]>([]);

  useEffect(() => {
    if (account.address) setList(getStoredRecents(account.address));
  }, [account.address]);

  const add = (item: number) => {
    if (!list.includes(item)) {
      const newList = [item, ...list].slice(0, STORE_COUNT);
      setList(newList);
      if (account.address) storeRecents(account.address, newList);
    }
  };

  return { list, add };
};
