import { ethers } from "ethers";

import config from "@constants/config";
import { Network } from "@constants/network";

import { isBaseNetwork } from "@modules/blockchain/utils";

export enum Token {
  ECO = "eco",
  ECOx = "ecox",
  OAK = "oak",
  USDC = "usdc",
  USDCe = "usdce",
  USDbC = "usdbc",
  USDT = "usdt",
  USDV = "usdv",
}

export enum UserToken {
  ECO = "eco",
  ECOx = "ecox",
  OAK = "oak",
  USD = "usd",
}

export interface TokenInfo {
  id: Token;
  name: string;
  address: string;
  decimals: number;
  contractType: number;
}

export const TOKENS: Token[] = [
  Token.ECO,
  Token.ECOx,
  Token.USDC,
  Token.USDT,
  Token.USDCe,
  Token.USDbC,
  Token.USDV,
  Token.OAK,
];

export const USER_TOKENS: UserToken[] = [UserToken.ECO, UserToken.ECOx, UserToken.USD, UserToken.OAK];

export function getUserTokenByToken(token: Token): UserToken {
  switch (token) {
    case Token.ECO:
      return UserToken.ECO;
    case Token.ECOx:
      return UserToken.ECOx;
    case Token.USDC:
    case Token.USDT:
    case Token.USDCe:
    case Token.USDV:
    case Token.USDbC:
      return UserToken.USD;
    case Token.OAK:
      return UserToken.OAK;
  }
}

export function getTokenInfo(token: Token, network: Network | number = "optimism"): TokenInfo {
  const _isBaseChain = isBaseNetwork(network);
  switch (token) {
    case Token.ECO:
      return {
        id: token,
        name: "ECO",
        address: _isBaseChain ? config.base.tokens.eco : config.optimism.tokens.eco,
        decimals: 18,
        contractType: 5,
      };
    case Token.ECOx:
      return {
        id: token,
        name: "ECOx",
        address: _isBaseChain ? ethers.constants.AddressZero : config.optimism.tokens.ecox,
        decimals: 18,
        contractType: 1,
      };
    case Token.USDC:
      return {
        id: token,
        name: "USDC",
        address: _isBaseChain ? config.base.tokens.usdc : config.optimism.tokens.usdc,
        decimals: 6,
        contractType: 1,
      };
    case Token.USDV:
      return {
        id: token,
        name: "USDV",
        address: _isBaseChain ? ethers.constants.AddressZero : config.optimism.tokens.usdv,
        decimals: 6,
        contractType: 1,
      };
    case Token.USDCe:
      return {
        id: token,
        name: "USDCe",
        address: _isBaseChain ? ethers.constants.AddressZero : config.optimism.tokens.usdce,
        decimals: 6,
        contractType: 1,
      };
    case Token.USDbC:
      return {
        id: token,
        name: "USDbC",
        address: _isBaseChain ? config.base.tokens.usdbc : ethers.constants.AddressZero,
        decimals: 6,
        contractType: 1,
      };
    case Token.USDT:
      return {
        id: token,
        name: "USDT",
        address: _isBaseChain ? ethers.constants.AddressZero : config.optimism.tokens.usdt,
        decimals: 6,
        contractType: 1,
      };
    case Token.OAK:
      return {
        id: token,
        name: "OAK",
        address: _isBaseChain ? config.base.tokens.oak : ethers.constants.AddressZero,
        decimals: 6,
        contractType: 1,
      };
    default:
      throw new Error("Unsupported token");
  }
}

export function getTokenByAddress(address: string, network: Network): Token {
  const token = TOKENS.find(token => getTokenInfo(token, network).address === address);
  if (!token) throw new Error("Unsupported token");
  return token;
}
