import { Form, FormProps, Input } from "antd";
import { Button } from "@components/button/Button";
import React, { useCallback } from "react";

interface PasswordFormProps {
  btnText?: string;
  loading?: boolean;
  hideConfirmation?: boolean;
  onSubmit(password: string): void;
}

export const PasswordForm: React.FC<PasswordFormProps> = ({
  btnText = "Set Beam Password",
  loading,
  hideConfirmation,
  onSubmit,
}) => {
  const [form] = Form.useForm();

  const handleKeyUp: FormProps["onKeyUp"] = event => {
    // Send form if `Enter` key is pressed
    if (event.key === "Enter") form.submit();
  };

  const handleSubmit = useCallback(
    ({ password }: { password: string }) => {
      onSubmit(password);
    },
    [onSubmit],
  );

  return (
    <Form
      form={form}
      disabled={loading}
      onKeyUp={handleKeyUp}
      onFinish={handleSubmit}
      layout="vertical"
      className="SaveAccessForm"
    >
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password",
          },
          { min: 8, message: "Minimum length: 8 characters" },
        ]}
        hasFeedback
      >
        <Input.Password placeholder="Enter password" className="input" />
      </Form.Item>
      {!hideConfirmation ? (
        <Form.Item
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The passwords you entered do not match."));
              },
            }),
          ]}
        >
          <Input.Password className="input" placeholder="Confirm password" />
        </Form.Item>
      ) : null}
      <Button key="save-access" type="primary" htmlType="submit" loading={loading} title={btnText} />
    </Form>
  );
};
