import React, { useEffect, useMemo, useRef } from "react";
import moment from "moment";
import { ethers } from "ethers";
import classNames from "classnames";
import QRCodeStyling from "qr-code-styling";
import { AnimatePresence, motion } from "framer-motion";

import { round } from "@helpers";
import { UserToken } from "@constants";
import { getTokenManager } from "@modules/token-managers/tokens";
import { useAccount } from "@contexts/AccountContext";
import { useMerchant } from "@redux/slides/merchant.slide";
import { animationCurveEaseOut } from "@constants/animations";
import { ReactComponent as Logo } from "@assets/images/beam_icon.svg";

import styles from "./BeamQR.module.css";

interface BeamQRCodeProps {
  value?: string;
  token: UserToken;
  amount?: ethers.BigNumber;
  username?: string;
  hideLogo?: boolean;
}

function formatAmount(amount: ethers.BigNumber, userToken: UserToken) {
  const tokenManager = getTokenManager(userToken);
  const plainNumber = parseFloat(ethers.utils.formatUnits(amount, tokenManager.decimals));
  return Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 0 }).format(
    round(plainNumber, 2),
  );
}

export const BeamQRCode: React.FC<BeamQRCodeProps> = ({ token, value = "", username, amount, hideLogo }) => {
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const tokenManager = getTokenManager(token);

  const { isMerchantModeEnabled } = useMerchant();
  const { beamname } = useAccount();
  const [glowCenter, setGlowCenter] = React.useState({ x: 0, y: 0 });

  const qrCode = useMemo(() => {
    const color = getComputedStyle(document.documentElement).getPropertyValue("--color-qr-foreground");

    return new QRCodeStyling({
      width: 300,
      height: 300,
      qrOptions: {
        errorCorrectionLevel: "M",
      },
      dotsOptions: {
        type: "dots",
        color: color,
      },
      cornersSquareOptions: {
        type: "dot",
      },
      cornersDotOptions: {
        type: "dot",
      },
    });
  }, [isMerchantModeEnabled]);

  useEffect(() => {
    qrCode.append(ref.current as HTMLElement);
  }, [isMerchantModeEnabled]);

  useEffect(() => {
    qrCode.update({ data: value });
  }, [value, isMerchantModeEnabled]);

  const truncatedUserName = username ? (username.length <= 10 ? username : username.slice(0, 9) + "…") : "You";

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!containerRef.current) return;
    const x = e.clientX - containerRef.current.getBoundingClientRect().left - 20;
    const y = e.clientY - containerRef.current.getBoundingClientRect().top - 20;
    setGlowCenter({ x, y });
  };

  return (
    <div
      ref={containerRef}
      onMouseMove={handleMouseMove}
      className={classNames(styles.BeamCode_wrapper, { [styles.loaded]: value })}
    >
      <div className={styles.BeamCode_placeholder} style={{ opacity: value ? 0 : 1, transition: ".3s ease opacity" }} />
      {!hideLogo ? (
        <div className={styles.BeamCode_logo}>
          <Logo />
        </div>
      ) : null}
      <div className={styles.BeamCode_rotationContainer}>
        <div className={styles.BeamCode} style={{ opacity: value ? 1 : 0, transform: `scale(${value ? 1 : 0.9})` }}>
          <div className={classNames(styles.BeamCode_url, styles.BeamCode_decoration)}>
            <span>beam.eco</span>
          </div>
          <div className={classNames(styles.BeamCode_date, styles.BeamCode_decoration)}>
            <span>{moment().format("MMM YYYY")}</span>
          </div>
          <div
            className={classNames(styles.BeamCode_currency, styles.BeamCode_decoration, {
              [styles.BeamCode_amount]: amount,
            })}
          >
            <span>{amount ? formatAmount(amount, token) + " " + tokenManager.symbol : tokenManager.symbol}</span>
          </div>
          <div className={classNames(styles.BeamCode_name, styles.BeamCode_decoration)} data-cy="beamcode-name">
            <AnimatePresence initial={false}>
              {!beamname ? (
                <motion.span
                  key="beamname-placeholder"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, transition: { duration: 0 } }}
                  transition={{ ease: animationCurveEaseOut }}
                  className={styles.beamnameLoader}
                />
              ) : (
                <motion.span
                  key="beamname-value"
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ ease: animationCurveEaseOut }}
                >
                  {beamname?.exists ? "@" + truncatedUserName : truncatedUserName}
                </motion.span>
              )}
            </AnimatePresence>
          </div>
          <div className={styles.BeamCode_qr}>
            <div ref={ref} />
          </div>
          <div className={styles.BeamCode_line} />
        </div>
      </div>
      <div className={styles.glowWrapper}>
        <div className={styles.glow} style={{ left: glowCenter.x, top: glowCenter.y }} />
      </div>
    </div>
  );
};
