import { Token, UserToken } from "@constants";
import { TokenManager } from "@modules/token-managers/TokenManager";

export class ECOxManager extends TokenManager {
  constructor() {
    super(UserToken.ECOx, {
      symbol: "ECOx",
      decimals: 18,
      composedBy: [Token.ECOx],
    });
  }
}
