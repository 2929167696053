import React from "react";
import classNames from "classnames";

import { BodyText, HeaderText } from "@components/text/Text";
import { IconCircle } from "@components/icon/IconCircle/IconCircle";

import "./AuthDrawerContent.css";
import { Spinner } from "@components/spinner/Spinner";

export interface AuthDrawerProps {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  input?: React.ReactNode;
  button?: React.ReactNode;
  loading?: boolean;
}

export const AuthDrawerContent: React.FC<AuthDrawerProps> = ({ icon, title, description, input, button, loading }) => {
  return (
    <div className={classNames("AuthDrawer_container", { AuthDrawer_loading: loading })}>
      <div className="AuthDrawer_loader">
        <Spinner />
      </div>
      <IconCircle className="AuthDrawer_icon" icon={icon} />
      <HeaderText centered style={{ fontSize: 18 }}>
        {title}
      </HeaderText>
      <BodyText centered light>
        {description}
      </BodyText>

      {input}

      {button}
    </div>
  );
};
