import React, { useEffect, useRef, useState } from "react";
import { Space, Spin } from "antd";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";
import { logError } from "@helpers";

import { notify } from "@helpers";

import Card from "@components/card/Card";
import { BodyText } from "@components/text/Text";
import { Button } from "@components/button/Button";
import ExpandSection from "@components/expand/ExpandSection";
import BottomSheet from "@components/bottomsheet/BottomSheet";

import { useAccount } from "@contexts/AccountContext";
import { SimpleAccountFactoryMigration } from "@modules/migration/SimpleAccountFactoryMigration";

import { ReactComponent as BeamIcon } from "@assets/images/beam_icon.svg";
import { ReactComponent as DropChevronIcon } from "@assets/icons/drop_chevron.svg";

import "./MigrationSheet.css";

const BeamRoundIcon = () => {
  return (
    <div className="MigrationSheet__beam-icon">
      <BeamIcon width="32" height="32" />
    </div>
  );
};

const Toggle = ({ title, isExpanded }: { title: string; isExpanded: boolean }) => (
  <div className="MigrationSheet__toggle">
    {title}
    <DropChevronIcon
      style={{ transform: isExpanded ? "rotate(0deg)" : "rotate(-90deg)", transition: ".2s ease transform" }}
    />
  </div>
);

const MigrationSheet: React.FC = () => {
  const { signer } = useAccount();

  const [loading, setLoading] = useState(false);
  const [needsMigration, setNeedsMigration] = useState<boolean>(false);
  const migration = useRef<SimpleAccountFactoryMigration>();

  useEffect(() => {
    SimpleAccountFactoryMigration.getSender(signer).then(async sender => {
      migration.current = new SimpleAccountFactoryMigration(signer, sender);
      migration.current.needsMigration().then(setNeedsMigration);
    });
  }, [signer.address]);

  const migrate = async () => {
    if (!migration.current) return;

    setLoading(true);
    try {
      await migration.current.migrate();

      notify({
        duration: 5_000,
        content: (
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <CheckCircleOutlined height={14} style={{ marginTop: -3, marginRight: 6 }} />
            <div>Update completed</div>
          </div>
        ),
      });

      setNeedsMigration(false);
    } catch (error) {
      logError("[migrate]", error);

      notify({
        duration: 5_000,
        type: "error",
        content: "Unexpected error during migration",
      });
    }
    setLoading(false);
  };

  return (
    <BottomSheet
      hideHeader
      isOpen={needsMigration}
      height="100%"
      desktopDisplay="overlay"
      footerStyle={{ border: 0 }}
      footer={
        <>
          {loading ? (
            <Space direction="vertical" align="center" size="small" style={{ width: "100%", justifyContent: "center" }}>
              <Space direction="horizontal" align="center" style={{ width: "100%", justifyContent: "center" }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 18, color: "black" }} spin />} />
                <BodyText bold>Updating...</BodyText>
              </Space>
              <h5 className="MigrationSheet__btn-text">This should only take a few seconds.</h5>
            </Space>
          ) : (
            <div style={{ display: "flex", flexDirection: "column", gap: 16, width: "100%", alignItems: "center" }}>
              <Button title="Continue" loading={loading} onClick={migrate} />
              <a href="mailto:support@beam.eco" className="MigrationSheet__contact-us">
                <BodyText light={loading} bold>
                  Contact Us
                </BodyText>
              </a>
            </div>
          )}
        </>
      }
    >
      <div className="MigrationSheet__content">
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <h3 className="MigrationSheet__title">Update required</h3>

          <div className="MigrationSheet__box">
            <BeamRoundIcon />
            <span>
              <CloseIcon />
            </span>
            <img src="/base_logo.png" alt="base logo" />
          </div>

          <Space direction="vertical" size={16}>
            <BodyText>Beam now supports Base, the blockchain incubated by Coinbase!</BodyText>
            <BodyText>
              With this update, <strong>your Beam deposit address will change,</strong> but you will still have all your
              assets and Beam Names. You don’t need to take any action.
            </BodyText>
          </Space>

          <ExpandSection
            toggleElement={isExpanded => <Toggle title="What is Base?" isExpanded={isExpanded} />}
            persistToggle
            defaultExpanded
          >
            <Card divStyle={{ display: "flex", flexDirection: "column", gap: 16, marginTop: 8 }}>
              <BodyText light>
                Base is a Layer 2 blockchain with low fees, incubated by Coinbase. But as always, it’s all behind the
                scenes — the way you use Beam remains the same!
              </BodyText>
              <BodyText light>If you have USDC on Base already, you can now deposit it to Beam.</BodyText>
            </Card>
          </ExpandSection>
        </Space>
      </div>
    </BottomSheet>
  );
};

export default MigrationSheet;
