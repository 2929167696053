import flags from "@constants/flags";

import { logError, notify } from "@helpers";
import auth0 from "@helpers/auth0";

import { AuthMethod, AuthType } from "@modules/web3auth/constants";
import { checkHasSavedAccessByEmail, checkHasSavedAccessByTwitterEmail } from "@modules/web3auth/helpers";
import { Web3AuthMultiFactor, Web3AuthSingleFactor } from "@modules/web3auth";

export const useEmailOTP = (authType: AuthType) => {
  const sendEmailOTP = async (opts: {
    email: string;
    action: "Save" | "Load";
    onCheckFailed(): void;
    onComplete(): void;
    onError(): void;
  }) => {
    const { action, email, onComplete, onError, onCheckFailed } = opts;
    try {
      if (flags.save_access.check_account_existence) {
        let hasSavedAccessAlready = false;
        try {
          hasSavedAccessAlready = await checkHasSavedAccessByEmail(email);
          if (action === "Save") {
            hasSavedAccessAlready = hasSavedAccessAlready || (await checkHasSavedAccessByTwitterEmail(email));
          }
        } catch (error) {
          logError("[email-send-otp:check-has-saved-access-by-email]", error);
        }

        if (hasSavedAccessAlready === (action === "Save")) {
          onCheckFailed();
          return;
        }
      }

      await auth0.passwordless.start({
        connection: "email",
        send: "code",
        email: email,
      });

      onComplete();
    } catch (error) {
      onError();
      notify({ type: "error", content: "Unexpected error while sending email" });
      logError("[email-send-otp]", error);
    }
  };

  const handleCodeClick = async (opts: {
    code: string;
    email: string;
    onComplete(): void;
    onError(): void;
    onInvalidCode?: () => void;
  }) => {
    const { email, code, onComplete, onInvalidCode, onError } = opts;
    try {
      const result = await auth0.passwordless.login({
        connection: "email",
        email: email,
        otp: code,
      });

      const web3auth = authType === AuthType.MULTI ? Web3AuthMultiFactor.get() : Web3AuthSingleFactor.get();
      await web3auth.loginWithToken(result.id_token, AuthMethod.EMAIL);

      onComplete();
    } catch (e) {
      const error = e as { error_description: string };
      const description = error?.error_description || "";

      if (
        description.startsWith("Incorrect email or verification code.") ||
        description.startsWith("Wrong email or verification code.")
      ) {
        onInvalidCode && onInvalidCode();
        notify({ type: "error", content: "Incorrect verification code." });
        return;
      }

      onError();

      if (description.startsWith("The verification code has expired. Please try to login again.")) {
        notify({ type: "error", content: "The verification code has expired. Please try to login again." });
      } else if (description.startsWith("You've reached the maximum number of attempts")) {
        notify({ type: "error", content: "You've reached the maximum number of attempts. Please try to login again." });
      } else if (error) {
        logError("[email-otp-login]", { error });
        notify({ type: "error", content: "Unknown error. Please try to login again." });
      }
    }
  };

  return { handleCodeClick, sendEmailOTP };
};
