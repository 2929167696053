import React, { SetStateAction, useState } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { logError, notify } from "@helpers";
import { useStackup } from "@contexts/StackupContext";

import { EmailAuthStep } from "@components/account/access/email/EmailAuthStep";
import BottomSheet, { BottomSheetProps } from "@components/bottomsheet/BottomSheet";
import { PasswordStep, StepAnimation, VerificationStep } from "@components/account/access/components";

import { AuthType } from "@modules/web3auth/constants";
import { useEmailOTP, useWeb3Auth } from "@modules/web3auth/hooks";
import { Web3AuthMultiFactor, Web3AuthSingleFactor } from "@modules/web3auth";

interface EmailDrawerProps {
  action: "Save" | "Load";
  open: BottomSheetProps["isOpen"];
  skipPasswordStep?: boolean;
  onComplete: (authType?: AuthType) => void;
  onCancel: BottomSheetProps["onClose"];
  setAccountWarning: React.Dispatch<SetStateAction<boolean>>;
}

enum EmailStepView {
  EMAIL,
  OTP,
  PASSWORD,
}

enum EmailStep {
  ENTER_EMAIL,
  SENDING_EMAIL,
  ENTER_OTP,
  PASSWORD,
}

function getViewForStep(step: EmailStep): EmailStepView {
  switch (step) {
    case EmailStep.ENTER_EMAIL:
    case EmailStep.SENDING_EMAIL:
      return EmailStepView.EMAIL;
    case EmailStep.ENTER_OTP:
      return EmailStepView.OTP;
    case EmailStep.PASSWORD:
      return EmailStepView.PASSWORD;
  }
}

export const EmailDrawer: React.FC<EmailDrawerProps> = ({
  open,
  action,
  skipPasswordStep,
  onComplete,
  onCancel,
  setAccountWarning,
}) => {
  const navigate = useNavigate();
  const { signer } = useStackup();
  const web3auth = useWeb3Auth();

  const otp = useEmailOTP(AuthType.SINGLE);

  const [step, setStep] = useState(EmailStep.ENTER_EMAIL);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>();

  const handleComplete = () => {
    setStep(EmailStep.ENTER_EMAIL);
    setEmail(undefined);
    onComplete && onComplete();
  };

  const handleCancel = () => {
    setStep(EmailStep.ENTER_EMAIL);
    onCancel && onCancel();
  };

  const handleSave = async () => {
    try {
      const web3authInstance = Web3AuthSingleFactor.get();
      await web3authInstance.save(signer);

      notify({
        duration: 5_000,
        content: (
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <CheckCircleOutlined height={14} style={{ marginTop: -3, marginRight: 6 }} />
            <div>Email connected</div>
          </div>
        ),
      });

      // trigger localStorage update for this tab
      window.dispatchEvent(new Event("storage"));
      handleComplete();
    } catch (error) {
      setStep(EmailStep.ENTER_OTP);
      logError("[email-save-access]", error);
      notify({
        duration: 5_000,
        type: "error",
        content: "Unexpected error while saving access",
      });
    }
  };

  const handleRecover = (password?: string) => {
    setLoading(true);
    return web3auth.recover({
      password,
      onComplete() {
        setLoading(false);
        navigate("/account");
        // trigger localStorage update for this tab
        window.dispatchEvent(new Event("storage"));
        handleComplete();
      },
      onError() {
        setStep(EmailStep.ENTER_OTP);
        setLoading(false);
      },
    });
  };

  const sendEmailOTP = (email: string) => {
    setStep(EmailStep.SENDING_EMAIL);
    return otp.sendEmailOTP({
      email,
      action,
      onComplete() {
        setEmail(email);
        setStep(EmailStep.ENTER_OTP);
      },
      onCheckFailed() {
        setAccountWarning(true);
        handleCancel();
      },
      onError() {
        setStep(EmailStep.ENTER_EMAIL);
      },
    });
  };

  const handleResend = () => email && sendEmailOTP(email);

  const handleCodeClick = async (code: string) => {
    if (!email) return;

    setLoading(true);
    return otp.handleCodeClick({
      code,
      email,
      onComplete() {
        const web3authInstance = Web3AuthSingleFactor.get();
        const authType = web3authInstance.isSFA() ? AuthType.SINGLE : AuthType.MULTI;

        if (action === "Save") {
          handleSave();
        } else if (authType === AuthType.MULTI) {
          const multiFactor = Web3AuthMultiFactor.get();
          multiFactor.setTKey(web3authInstance.getTKey());
          multiFactor.setLoginData(web3authInstance.getLoginData());

          if (skipPasswordStep) {
            onComplete && onComplete(authType);
          } else {
            setStep(EmailStep.PASSWORD);
          }
        } else {
          handleRecover();
        }
      },
      onInvalidCode() {
        setLoading(false);
      },
      onError() {
        setLoading(false);
        setStep(EmailStep.ENTER_OTP);
      },
    });
  };

  const view = getViewForStep(step);

  return (
    <BottomSheet closeOnShimTap hideLine isOpen={open} onClose={handleCancel} headerClass="AuthDrawer_header">
      {view === EmailStepView.EMAIL ? (
        <StepAnimation key="email-step-1">
          <EmailAuthStep loading={step === EmailStep.SENDING_EMAIL} action={action} onNext={sendEmailOTP} />
        </StepAnimation>
      ) : view === EmailStepView.OTP ? (
        <StepAnimation key="email-step-2">
          <VerificationStep
            type="email"
            loading={loading}
            destination={email!}
            onSubmit={handleCodeClick}
            onResend={handleResend}
          />
        </StepAnimation>
      ) : view === EmailStepView.PASSWORD ? (
        <StepAnimation key="auth-step-2">
          <PasswordStep action={action} loading={loading} onSubmit={handleRecover} />
        </StepAnimation>
      ) : null}
    </BottomSheet>
  );
};
