import React, { SetStateAction, useState } from "react";
import { Input } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

import { useStackup } from "@contexts/StackupContext"; // Components
import Row from "@components/row/Row";
import { BodyText } from "@components/text/Text";
import { Button } from "@components/button/Button"; // Hooks
import { getDisplayName, useDisplayName } from "@hooks/useDisplayName";
import { useBeamnameSearch } from "@hooks/useBeamnameSearch";
import { TransferCluster, useHistory } from "@hooks/useHistory";

import { notify } from "@helpers";
import { isSameAddress } from "@helpers/contracts";
import { isAddressBlacklisted } from "@helpers/ofacBlacklist";

import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";

import { BEAMNAMES_ADDRESS, FAUCET_ADDRESS, VENDING_MACHINE_ADDRESSES } from "@constants";
import { PEANUT_ADDRESSES } from "@modules/peanut/constants";

import "./AddressSearch.css";
import { Avatar } from "@components/avatar/Avatar";
import { Spinner } from "@components/spinner/Spinner";
import { IconButton } from "@components/iconbutton/IconButton";

interface AddressSearchProps {
  setAddress: React.Dispatch<SetStateAction<string>>;
}

const EXCLUDE_RECENTS_BY_ADDRESS: string[] = [
  BEAMNAMES_ADDRESS,
  FAUCET_ADDRESS,
  ...VENDING_MACHINE_ADDRESSES,
  ...PEANUT_ADDRESSES,
];

const ClearIcon = () => {
  return <IconButton size="small" icon={<CloseIcon />} className="AddressSearch_clear" />;
};

function getRecentAddress(clusters: TransferCluster[], address?: string) {
  const addresses = clusters
    .filter(
      transfer =>
        transfer.from.address === address?.toLowerCase() &&
        !EXCLUDE_RECENTS_BY_ADDRESS.find(address => isSameAddress(address, transfer.to.address)), // must be a send and not to a blacklisted address
    )
    .reduce(
      (acc, currTransfer) => {
        acc.add(currTransfer.to.address);
        return acc;
      }, // no duplicate recipients
      new Set<string>(),
    );
  return Array.from(addresses).slice(0, 5);
}

export const AddressSearch: React.FC<AddressSearchProps> = ({ setAddress }) => {
  const { address } = useStackup();

  const [sendInput, setSendInput] = useState("");

  const { data, getMore, loading: loadingBeamNames } = useBeamnameSearch(sendInput);
  const { transferClusters, loading: loadingHistory } = useHistory(address);

  const loading = loadingBeamNames || loadingHistory;

  const recents = getRecentAddress(transferClusters, address);

  const selectAddress = (address: string) => {
    if (isAddressBlacklisted(address)) {
      notify({ type: "error", content: "For regulatory reasons, this address cannot be sent to." });
    } else {
      setAddress(address);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        maxHeight: 680,
        minHeight: 420,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          minHeight: 0,
        }}
      >
        <Input
          styles={{ input: { background: "transparent" } }}
          value={sendInput}
          prefix={<SearchIcon />}
          placeholder="Search by name or address"
          onChange={event => setSendInput(event.target.value)}
          className="AddressSearch_input"
          spellCheck={false}
          autoCapitalize="false"
          autoCorrect="false"
          allowClear={{ clearIcon: <ClearIcon /> }}
        />
        <div className="AddressSearch_resultsScroll">
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center", paddingTop: 24 }}>
              <Spinner />
            </div>
          ) : sendInput.length > 0 && sendInput.length < 3 ? (
            <div style={{ display: "flex", justifyContent: "center", paddingTop: 24, opacity: 0.3 }}>
              <BodyText light>Search starts at 3 characters...</BodyText>
            </div>
          ) : !sendInput && recents.length ? (
            <div>
              <h4 className="AddressSearch_resultsTitle">Recents</h4>
              {recents.map(addr => (
                <SendRow key={`recent-${addr}`} address={addr} onClick={() => selectAddress(addr)} />
              ))}
            </div>
          ) : sendInput ? (
            <div className="AddressSearch_resultsList">
              {data.length === 0 ? (
                <p
                  style={{
                    textAlign: "center",
                    fontStyle: "normal",
                    fontSize: "var(--font-size-body)",
                    color: "var(--color-foreground-light)",
                  }}
                >
                  No Results
                </p>
              ) : (
                <InfiniteScroll dataLength={data.length} next={getMore} hasMore={true} loader={null}>
                  {data.map(identifier => (
                    <SendRow
                      key={["search", identifier.address].join("-")}
                      ens={identifier.ens}
                      address={identifier.address}
                      beamname={identifier.beamname}
                      onClick={() => selectAddress(identifier.address)}
                    />
                  ))}
                </InfiniteScroll>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const SendRow: React.FC<{ address: string; ens?: string; beamname?: string; onClick: () => void }> = ({
  ens,
  address,
  beamname,
  onClick,
}) => {
  const displayName = beamname || ens ? getDisplayName(address, ens, beamname) : useDisplayName(address);
  return (
    <Row
      onClick={onClick}
      hasHorizontalPadding={false}
      leadingContent={
        <div style={{ display: "flex", gap: "var(--size-small)", alignItems: "center" }}>
          <Avatar address={address} />
          <BodyText bold>{displayName}</BodyText>
        </div>
      }
      trailingContent={<Button type="primary" size="small" title="Select" onClick={onClick} />}
    />
  );
};
