import { useEffect, useState } from "react";
import { ethers } from "ethers";

import { useBurnerSigner } from "./useBurnerSigner";
import { getWalletFromLocalStorage } from "@modules/web3auth/helpers";

export const useBurnerWallet = (): ethers.Wallet | undefined => {
  const { signer: burnerSigner, loadOrGenerateBurner } = useBurnerSigner();

  const [signer, setSigner] = useState<ethers.Wallet>();

  useEffect(() => {
    if (!signer) loadOrGenerateBurner();
  }, [loadOrGenerateBurner, signer]);

  useEffect(() => {
    const getSigner = () => {
      const storedPK = getWalletFromLocalStorage();
      return storedPK || burnerSigner;
    };
    setSigner(getSigner());
  }, [burnerSigner]);

  return signer;
};
