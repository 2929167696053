import React from "react";
import { Row } from "antd";

import { BodyText, HeaderText } from "@components/text/Text";
import BottomSheet, { BottomSheetProps } from "@components/bottomsheet/BottomSheet";
import { Button } from "@components/button/Button";

import "./OnRampSheet.css";

interface OnRampSheetProps extends BottomSheetProps {
  name: string;
  title: string;
  content: React.ReactNode;
  icon?: React.ReactNode;
  recommended?: boolean;
  includePadding?: boolean;
}

export const OnRampSheet: React.FC<OnRampSheetProps> = ({
  name,
  title,
  icon,
  content,
  recommended,
  includePadding = false,
  ...props
}) => {
  return (
    <BottomSheet
      {...props}
      height="100%"
      includePadding={includePadding}
      desktopDisplay="side"
      title={
        <Row align="middle" style={{ gap: 8, margin: "0 auto" }}>
          {icon}
          <HeaderText size="small">{title}</HeaderText>
        </Row>
      }
    >
      {recommended ? (
        <div className="OnRampSheet__content OnRampSheet__content-grid">
          {content}
          <div className="OnRampSheet__popup">
            <BodyText small centered light>
              {name} is recommended by Beam based on your location and other factors.
            </BodyText>

            <div className="OnRampSheet__button">
              <Button secondary color="light" size="small" title="Use other method" onClick={props.onClose} />
            </div>
          </div>
        </div>
      ) : (
        <div className="OnRampSheet__content">{content}</div>
      )}
    </BottomSheet>
  );
};
