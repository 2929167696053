import { ethers } from "ethers";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Network, Token, TOKENS } from "@constants";

import { RootState } from "@redux/store";
import { BalancesState, BalancesStateItem } from "@redux/slides/balances/balances.types";

const initialBalances = TOKENS.reduce(
  (balances, token) => ({ ...balances, [token]: "0" }),
  {} as BalancesStateItem["balances"],
);

const initialState: BalancesState = {
  networks: {
    optimism: {
      state: "loading",
      balances: initialBalances,
    },
    base: {
      state: "loading",
      balances: initialBalances,
    },
  },
};

export const executeTransfer = createAsyncThunk(
  "balances/executeTransfer",
  async ({
    tx,
  }: {
    network: Network;
    tx: Promise<unknown>;
    token: Token;
    amount: string;
    type?: "in" | "out" | "replace";
  }) => {
    await tx;
  },
);

export const execTransferIn = (...params: Parameters<typeof executeTransfer>) =>
  executeTransfer({ ...params[0], type: "in" });

export const balancesSlice = createSlice({
  name: "balances",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<{ network: Network; balances: BalancesStateItem["balances"] }>) => {
      const { network, balances } = action.payload;
      state.networks[network].state = "fetched";
      state.networks[network].balances = balances;
    },
    increment: (state, action: PayloadAction<{ network: Network; token: Token; amount: string }>) => {
      const { network, token, amount } = action.payload;
      const { balances } = state.networks[network];
      balances[token] = ethers.BigNumber.from(balances[token]).add(amount).toString();
    },
    reset: state => {
      state = initialState;
      return state;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(executeTransfer.pending, (state, action) => {
        const { amount, network, token, type = "out" } = action.meta.arg;
        const { balances } = state.networks[network];
        if (type === "replace") {
          balances[token] = amount;
          return;
        }
        const operation = type === "out" ? "sub" : "add";
        balances[token] = ethers.BigNumber.from(balances[token])[operation](amount).toString();
      })
      .addCase(executeTransfer.rejected, (state, action) => {
        const { amount, network, token, type = "out" } = action.meta.arg;
        const { balances } = state.networks[network];
        const operation = type === "out" ? "add" : "sub";
        balances[token] = ethers.BigNumber.from(balances[token])[operation](amount).toString();
      });
  },
});

export const { set: setBalances, increment: incrementBalance, reset: resetBalances } = balancesSlice.actions;

export const getBalances = (state: RootState) => state.balances.networks;

export default balancesSlice.reducer;
