import React, { useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Form, Input, Skeleton, Space } from "antd";
import { logError } from "@helpers";

import { Button } from "@components/button/Button";
import { BodyText, TextBlock } from "@components/text/Text";

import { notify } from "@helpers";
import { RelayoorError, RelayoorErrorType } from "@helpers/relayoor";
import { useBeamnames } from "@hooks";
import { useAccount } from "@contexts/AccountContext";

import { useRegistrationFee } from "@hooks/useRegistrationFee";
import { useBeamname } from "@hooks/useBeamname";
import { Callout } from "@components/callout/Callout";

const emojisRegex = new RegExp(
  /^([a-z0-9]|(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]))*$/,
);

interface RegisterFormProps {
  onClose?: () => void;
}

export const RegisterForm: React.FC<RegisterFormProps> = ({ onClose }) => {
  const { balances } = useAccount();
  const { register } = useBeamnames();

  const [form] = Form.useForm();

  const [name, setName] = useState("");
  const { data } = useBeamname(name);

  const [loading, setLoading] = useState(false);

  const nonAvailableNames = useRef<string[]>([]);

  const registrationFee = useRegistrationFee();

  useEffect(() => {
    if (data && data.address) {
      nonAvailableNames.current = [...nonAvailableNames.current, data.beamname];
      form.validateFields();
    }
  }, [data]);

  const doRegister = async ({ name }: { name: string }) => {
    if (loading) return;

    setLoading(true);
    try {
      await register(name.toLowerCase());

      notify({
        duration: 5_000,
        content: (
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <CheckCircleOutlined height={14} style={{ marginTop: -3, marginRight: 6 }} />
            <div>Beam Name registered successfully!</div>
          </div>
        ),
      });

      form.resetFields();
      setLoading(false);
      if (onClose) {
        onClose();
      }
    } catch (error) {
      setLoading(false);
      const relayoorError = RelayoorError.get(error);

      if (relayoorError) {
        if (relayoorError.type === RelayoorErrorType.BEAMNAME_NOT_AVAILABLE) {
          nonAvailableNames.current = [...nonAvailableNames.current, name];
          form.validateFields();
        } else {
          notify({
            type: "error",
            duration: 5_000,
            content: relayoorError.message,
          });
        }
      } else {
        logError("[register-beamname]", error);

        notify({
          type: "error",
          duration: 5_000,
          content: "Unexpected error during Beam Name registration.",
        });
      }
    }
  };

  const hasEnoughEco = registrationFee && !registrationFee.isZero() ? balances.eco.optimism.gte(registrationFee) : true;

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <TextBlock>
        <BodyText>
          Beam Names are like domain names, but on the blockchain. Once you buy it, it&apos;s yours forever unless you
          want to transfer it to someone else.
        </BodyText>
      </TextBlock>
      <Form
        className="Form"
        form={form}
        onValuesChange={(_, values) => setName(values.name)}
        onFinish={doRegister}
        autoCapitalize="false"
        autoComplete="false"
        layout="vertical"
        disabled={loading}
        style={{ gap: "var(--size-medium)", display: "flex", flexDirection: "column" }}
      >
        <Form.Item
          className="form-item"
          name="name"
          rules={[
            {
              pattern: emojisRegex,
              message: "Beam Names can't have upper-case or special characters",
            },
            {
              required: true,
              message: "Please input your desired Beam Name.",
              validateTrigger: "onSubmit",
            },
            {
              min: 5,
              validateTrigger: "onSubmit",
              message: (
                <div style={{ padding: "var(--size-small) 0" }}>
                  <Callout>
                    <BodyText style={{ color: "var(--color-foreground)" }}>
                      This is a premium 1-4 character Beam Name. If you&apos;re interested in purchasing it, please
                      email{" "}
                      <a
                        href="#"
                        style={{ color: "var(--color-warning)" }}
                        onClick={evt => {
                          evt.preventDefault();
                          const name = form.getFieldValue("name");
                          window.open(
                            `mailto:support@beam.eco?subject=Interested%20in%20Beam%20Name%3A%20${name?.toLowerCase()}`,
                          );
                        }}
                      >
                        support@beam.eco
                      </a>{" "}
                      for more information.
                    </BodyText>
                  </Callout>
                </div>
              ),
            },
            () => ({
              validator(_, name) {
                if (!name || !nonAvailableNames.current.includes(name)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("This Beam Name is not available"));
              },
            }),
          ]}
        >
          <Input
            autoCapitalize="false"
            autoCorrect="false"
            spellCheck={false}
            autoComplete="off"
            type="text"
            className="input"
            onInput={event =>
              ((event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.toLowerCase())
            }
            prefix={
              <BodyText light style={{ color: "var(--color-foreground)" }}>
                @
              </BodyText>
            }
            placeholder="buterin"
          />
        </Form.Item>

        <Form.Item shouldUpdate className="submit" noStyle>
          {() => (
            <Button
              title={!hasEnoughEco ? "Not enough ECO" : "Register Beam Name"}
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={
                !hasEnoughEco ||
                !form.isFieldsTouched(true) ||
                form.getFieldsError().filter(({ errors }) => errors.length).length > 0
              }
            />
          )}
        </Form.Item>
        <BodyText small light centered style={{ color: "var(--color-foreground)" }}>
          Registration Fee (only charged once):{" "}
          {registrationFee ? (
            parseFloat(ethers.utils.formatEther(registrationFee)).toFixed(2) + " ECO"
          ) : (
            <Skeleton.Input size="small" />
          )}
        </BodyText>
      </Form>
    </Space>
  );
};
