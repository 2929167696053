import React from "react";
import { ReactComponent as XLogo } from "@assets/icons/x-logo.svg";
import { Button } from "./Button";
import styles from "./TwitterButton.module.css";

export const BeamTwitterButton = () => (
  <Button
    href="https://twitter.com/beam_eco"
    title="Follow Beam"
    size="small"
    secondary
    leadingIcon={<XLogo />}
    target="_blank"
    className={styles.twitterButton}
    trailingIcon={
      <div className={styles.twitterFollowers}>
        <span>8.2k</span>
      </div>
    }
  />
);

export const EcoTwitterButton = () => (
  <Button
    href="https://twitter.com/eco"
    title="Follow Eco"
    size="small"
    secondary
    leadingIcon={<XLogo />}
    target="_blank"
    className={styles.twitterButton}
    trailingIcon={
      <div className={styles.twitterFollowers}>
        <span>56.9k</span>
      </div>
    }
  />
);
