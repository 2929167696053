import { ethers } from "ethers";
import isEqual from "lodash.isequal";

import { PeanutV3__factory } from "@assets/contracts";
import { getNetwork, getNetworkByShort, INetwork, Network, Token, TokenInfo, USER_TOKENS, UserToken } from "@constants";

import { ClaimRequest } from "@modules/peanut/types";
import { getNetworkProvider } from "@modules/blockchain/providers";
import { getPeanutAddress, PEANUT_CLAIM_URL } from "@modules/peanut/constants";

export enum ClaimSearchParams {
  NETWORK = "c",
  VERSION = "v",
  PASSWORD = "p",
  DEPOSIT_ID = "i",
  TOKEN = "t",
}

interface PeanutOptions {
  peanutContract: string;
}

export function getParamsFromLink(link = window.location.href) {
  const url = new URL(link);

  let links: { network: INetwork; index: number }[] = [];

  // optional: handle legacy version
  let contractVersion = url.searchParams.get(ClaimSearchParams.VERSION);

  // get ID
  const rawId = url.searchParams.get(ClaimSearchParams.DEPOSIT_ID);

  if (!contractVersion && rawId) {
    contractVersion = "0";
    const index = parseInt(rawId);
    links.push({
      network: getNetwork("optimism"),
      index,
    });
  } else if (contractVersion === "1") {
    // parse ID into network/index pairs
    links = rawId
      ? rawId
          .split("-")
          .map(networkIndex => {
            const [networkShort, index] = networkIndex.split(";");
            return {
              network: getNetworkByShort(networkShort)!,
              index: parseInt(index),
            };
          })
          .filter(links => !!links.network)
      : [];
  }

  // get password
  const password = url.searchParams.get(ClaimSearchParams.PASSWORD);

  // get token
  const _token = url.searchParams.get(ClaimSearchParams.TOKEN);

  let token: UserToken | undefined;
  if (_token === Token.USDC) {
    token = UserToken.USD;
  } else if (_token && USER_TOKENS.includes(_token as never)) {
    token = _token as UserToken;
  }

  return { links, rawId, contractVersion, password, token };
}

export function generateKeysFromString(string: string) {
  const privateKey = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(string));
  const wallet = new ethers.Wallet(privateKey);
  const publicKey = wallet.publicKey;

  return {
    address: wallet.address,
    privateKey: privateKey,
    publicKey: publicKey,
  };
}

export function getRandomString(length = 32) {
  const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result_str = "";
  for (let i = 0; i < length; i++) {
    result_str += chars[Math.floor(Math.random() * chars.length)];
  }
  return result_str;
}

export function makeDeposit(
  token: TokenInfo,
  amount: ethers.BigNumber,
  password = getRandomString(),
  options: PeanutOptions,
) {
  const { peanutContract } = options;

  const wallet = generateKeysFromString(password);

  const peanutInterface = PeanutV3__factory.createInterface();
  const txData = peanutInterface.encodeFunctionData("makeDeposit", [
    token.address,
    token.contractType,
    amount,
    0,
    wallet.address,
  ]);

  return { password, transaction: { to: peanutContract, data: txData } };
}

export function createLink(token: UserToken, password: string, depositId: string): string {
  const url = new URL("/claim", window.location.origin);
  url.searchParams.set(ClaimSearchParams.VERSION, "1");
  url.searchParams.set(ClaimSearchParams.TOKEN, token);
  url.searchParams.set(ClaimSearchParams.PASSWORD, password);
  url.searchParams.set(ClaimSearchParams.DEPOSIT_ID, depositId.toString());

  return url.toString();
}

export function getDepositEvent(recipient: string, receipt: ethers.ContractReceipt, network: Network) {
  const chainId = getNetwork(network).chainId;
  const peanut = new ethers.Contract(getPeanutAddress(chainId), PeanutV3__factory.abi, getNetworkProvider(chainId));
  const event = peanut.filters.DepositEvent(null, null, null, recipient);
  const depositEvts = receipt.logs.filter(log => log.address === event.address && isEqual(log.topics, event.topics));

  if (!depositEvts.length) {
    throw new Error("Could not find deposit event");
  }

  return depositEvts.map(depositEvt => {
    const [id, type, amount, sender] = peanut.interface.decodeEventLog(
      "DepositEvent",
      depositEvt.data,
      depositEvt.topics,
    );

    return { id, type, amount, sender };
  });
}

export async function signClaimTx(password: string, recipient: string) {
  const keys = generateKeysFromString(password);

  const addressHash = ethers.utils.arrayify(ethers.utils.solidityKeccak256(["address"], [recipient]));
  const addressHashEIP191 = ethers.utils.hashMessage(addressHash);

  const signer = new ethers.Wallet(keys.privateKey);
  const signature = await signer.signMessage(addressHash);

  return { signature, addressHashEIP191 };
}

export async function sendClaimRequest({ password, depositIdx, recipient, network }: ClaimRequest) {
  const { signature, addressHashEIP191 } = await signClaimTx(password, recipient);

  const formData = {
    index: depositIdx.toString(),
    signature: signature,
    to_address: recipient,
    addressHashEIP191: addressHashEIP191,
  };

  return fetch(network ? `${PEANUT_CLAIM_URL}/${network}` : PEANUT_CLAIM_URL, {
    mode: "cors",
    method: "POST",
    redirect: "manual",
    body: JSON.stringify(formData),
    headers: { "Content-Type": "application/json" },
  }).then(async res => {
    const data = await res.json();
    if (data.error) throw new Error(data.error);
    return data as { receipt: { transactionHash: string } };
  });
}
