import { useEffect, useMemo, useState } from "react";
import { ethers } from "ethers";
import { useQuery } from "@tanstack/react-query";

import { useAccount } from "@contexts/AccountContext";
import { useStackup } from "@contexts/StackupContext";

import { useAppDispatch, useAppSelector } from "@redux";
import { useConfigFee } from "@redux/slides/config.slide";
import { execTransferIn, executeTransfer, getBalances } from "@redux/slides/balances/balances.slide";

import { AutoSwap } from "@modules/blockchain/AutoSwap";
import { getTokenManager } from "@modules/token-managers/tokens";

import { getUserTokenByToken, Token, UserToken } from "@constants";

import { getUsdAmount } from "@components/fund/helpers";
import { eco, network, tokenEco, tokenUsdc, vending } from "@components/fund/constants";

import { ERC20__factory } from "@assets/contracts";
import { VendingMachine } from "@modules/vending-machine/VendingMachine";
import config from "@constants/config";
import { getBeamWallet, getClient, getFlatPaymaster } from "@helpers/bundler";

// vending machine functionality
export const useVending = (active = true) => {
  const dispatch = useAppDispatch();
  const { signer } = useStackup();
  const { address, excludeAddress } = useAccount();
  const balancesByNetwork = useAppSelector(getBalances);

  //set state variables
  const [prices, setPrice] = useState<{ sell: ethers.BigNumber; buy: ethers.BigNumber } | null>(null);

  const flatFees = useConfigFee();

  const { data: autoSwap } = useQuery(
    [],
    async () => new AutoSwap(UserToken.USD, "optimism", balancesByNetwork.optimism.balances),
    {
      enabled: balancesByNetwork.optimism.state === "fetched",
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const vendingMachine = useMemo(() => {
    if (!autoSwap || !address || !flatFees || !prices) return;

    return new VendingMachine({
      network: "optimism",
      prices,
      balances: balancesByNetwork,
      fees: {
        buy: ethers.BigNumber.from(flatFees.perToken.usd),
        sell: ethers.BigNumber.from(flatFees.perToken.eco),
        recipient: flatFees.recipients.optimism,
      },
      tokens: {
        eco: Token.ECO,
        usd: tokenUsdc.id,
      },
      recipient: address,
    });
  }, [address, autoSwap, balancesByNetwork, address, flatFees, prices]);

  const { data } = useQuery(
    ["swappable-balance-usd"],
    async () => {
      if (!vendingMachine || !flatFees || !autoSwap) return { swappableBalance: ethers.constants.Zero };

      const tokenManager = getTokenManager(UserToken.USD);
      const balances = tokenManager.getBalances(autoSwap.network);

      try {
        const transferFee = vendingMachine.getTransferFee(
          ethers.BigNumber.from(flatFees.perToken.usd),
          vendingMachine.opts.tokens.usd,
        );

        balances[transferFee.token] = ethers.BigNumber.from(balances[transferFee.token])
          .sub(transferFee.amount)
          .toString();
      } catch (error) {
        console.warn("[swappable-balance-usd]", error);
        return { swappableBalance: ethers.constants.Zero, transferFee: undefined };
      }

      const quotes = await vendingMachine.getQuote(autoSwap, Token.USDCe, balances);
      let swappableBalance = Object.values(quotes).reduce((acc, quote) => acc.add(quote), ethers.constants.Zero);

      // If swaps are less than 1 cent, skip swaps
      if (swappableBalance.lt(ethers.utils.parseUnits("0.01", 6))) {
        swappableBalance = ethers.constants.Zero;
      }

      return { transferFee, swappableBalance };
    },
    {
      enabled: active && !!autoSwap,
      refetchInterval: 15_000,
      initialData: { swappableBalance: ethers.constants.Zero },
    },
  );

  const {
    swappableBalance,
    transferFee = {
      token: tokenUsdc.id,
      amount: flatFees?.perToken.usd ? ethers.BigNumber.from(flatFees.perToken.usd) : ethers.utils.parseUnits("0.5", 6),
    },
  } = data as {
    swappableBalance: ethers.BigNumber;
    transferFee?: { token: Token; amount: ethers.BigNumber };
  };

  // get current buy and sell prices
  useEffect(() => {
    if (!active) return;

    const fetchPrices = async () => {
      setPrice({ buy: await vending.getCurrentBuyPrice(), sell: await vending.getCurrentSellPrice() });
    };

    fetchPrices();

    // subscribe to events for new prices
    vending.on("NewBuyPrice", fetchPrices);
    vending.on("NewSellPrice", fetchPrices);
    eco.on("NewInflationMultiplier", fetchPrices);
  }, [active]);

  // this function manages buying and selling eco from the vending machine
  const buyEco = async (ecoAmount: ethers.BigNumber, expectedBuyPrice: ethers.BigNumber) => {
    if (!prices?.buy || !flatFees || !autoSwap || !vendingMachine) return;

    // check if buy price has changed
    if (expectedBuyPrice != prices.buy) {
      throw new Error("Buy price has changed. Please refresh the page and try again");
    }

    vendingMachine.setExcludeAddrHandler(addr => excludeAddress(addr));

    // transaction fee
    const feeAmount = ethers.BigNumber.from(flatFees.perToken[getUserTokenByToken(tokenUsdc.id)]);

    const simpleAccount = await getBeamWallet(signer, network, getFlatPaymaster(network));

    const { txs, transfersOut, transferFee } = await vendingMachine.buy(ecoAmount, feeAmount, {
      autoSwap,
    });

    //execute batch
    simpleAccount.executeBatch(
      txs.map(tx => tx.to!),
      txs.map(tx => tx.data!),
    );

    //send transaction
    const client = await getClient(network);

    //wait for stackup response
    const tx = await client.sendUserOperation(simpleAccount);

    //wait for transaction to complete
    const txWait = tx.wait();

    transfersOut.forEach(tramsfer => {
      dispatch(
        executeTransfer({
          network,
          tx: txWait,
          token: tramsfer.token,
          amount: tramsfer.amount.toString(),
        }),
      );
    });
    dispatch(
      executeTransfer({
        network,
        tx: txWait,
        token: transferFee.token,
        amount: transferFee.amount.toString(),
      }),
    );

    dispatch(execTransferIn({ network, tx: txWait, token: tokenEco.id, amount: ecoAmount.toString() }));

    return txWait;
  };

  const sellEco = async (amount: ethers.BigNumber, expectedSellPrice: ethers.BigNumber) => {
    if (!flatFees) return;

    // check if sell price has changed
    if (expectedSellPrice != prices?.sell) {
      throw new Error("Sell price has changed. Please refresh the page and try again");
    }

    // transaction fee
    const fee = flatFees.perToken[getUserTokenByToken(tokenEco.id)];

    const simpleAccount = await getBeamWallet(signer, network, getFlatPaymaster(network));

    // create approval data
    const approvalData = ERC20__factory.createInterface().encodeFunctionData("approve", [
      config.optimism.contracts.vendingMachine,
      amount,
    ]);

    // create sell data from vending machine
    const sellData = vending.interface.encodeFunctionData("sell", [amount, prices.sell]);

    // create fee data
    const feeData = ERC20__factory.createInterface().encodeFunctionData("transfer", [
      flatFees.recipients[network],
      fee,
    ]);

    // execute batch
    simpleAccount.executeBatch(
      [tokenEco.address, config.optimism.contracts.vendingMachine, tokenEco.address],
      [approvalData, sellData, feeData],
    );

    // send transaction
    const client = await getClient(network);

    // wait for stackup response
    const tx = await client.sendUserOperation(simpleAccount);

    // wait for transaction to complete
    const txWait = tx.wait();

    dispatch(executeTransfer({ network, tx: txWait, token: tokenEco.id, amount: amount.add(fee).toString() }));
    dispatch(
      execTransferIn({
        network,
        tx: txWait,
        token: tokenUsdc.id,
        amount: getUsdAmount(amount, prices.sell).toString(),
      }),
    );

    return txWait;
  };

  return { prices, transferFee, swappableBalance, buyEco, sellEco };
};
