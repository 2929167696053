/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query ACCOUNT($address: ID!, $string: String, $blacklist: [String!]) {\n    account(id: $address) {\n      beamnames(orderBy: createdAt, orderDirection: asc) {\n        id\n        name\n      }\n    }\n    lastTransfer: transfers(\n      first: 1\n      orderBy: timestamp\n      orderDirection: desc\n      where: { and: [{ to_not_in: $blacklist }, { from: $string }] }\n    ) {\n      ...Transfer\n    }\n  }\n": types.AccountDocument,
    "\n  query BEAMLINK($id: ID!) {\n    beamLink(id: $id) {\n      claimedAt\n      amount\n      token {\n        id\n      }\n    }\n  }\n": types.BeamlinkDocument,
    "\n  query BEAMNAME($name: String) {\n    beamnames(where: { name: $name }, first: 1) {\n      id\n      name\n      owner {\n        id\n      }\n    }\n  }\n": types.BeamnameDocument,
    "\n  query BEAMNAME_BATCH_QUERY($addresses: [String!]!) {\n    accounts(where: { id_in: $addresses }) {\n      ...ExternalAccount\n    }\n  }\n": types.Beamname_Batch_QueryDocument,
    "\n  query BEAMNAME_REVERSE($address: ID!) {\n    account(id: $address) {\n      ...ExternalAccount\n    }\n  }\n": types.Beamname_ReverseDocument,
    "\n  query BEAMNAME_SEARCH($input: String, $offset: Int) {\n    beamnames(where: { name_starts_with: $input }, first: 20, skip: $offset) {\n      name\n      owner {\n        id\n      }\n    }\n  }\n": types.Beamname_SearchDocument,
    "\n  fragment ExternalAccount on Account {\n    id\n    beamnames(orderBy: createdAt, orderDirection: asc, first: 1) {\n      id\n      name\n    }\n  }\n": types.ExternalAccountFragmentDoc,
    "\n  fragment Transfer on Transfer {\n    id\n    txHash\n    timestamp\n    from {\n      ...ExternalAccount\n    }\n    to {\n      ...ExternalAccount\n    }\n    amount\n  }\n": types.TransferFragmentDoc,
    "\n  fragment BeamLink on BeamLink {\n    id\n    sender {\n      ...ExternalAccount\n    }\n    createdAt\n    createdTxHash\n    recipient {\n      ...ExternalAccount\n    }\n    claimedAt\n    claimedTxHash\n    amount\n    token {\n      id\n    }\n  }\n": types.BeamLinkFragmentDoc,
    "\n  fragment InflationMultiplier on InflationMultiplier {\n    id\n    value\n    blockNumber\n    timestamp\n  }\n": types.InflationMultiplierFragmentDoc,
    "\n  query ALL_HISTORY($address: String!, $offset: Int!) {\n    transfers(\n      first: 100\n      skip: $offset\n      orderBy: timestamp\n      orderDirection: desc\n      where: { or: [{ to: $address }, { from: $address }] }\n    ) {\n      ...Transfer\n    }\n    beamLinks(\n      first: 100\n      skip: $offset\n      orderBy: createdAt\n      orderDirection: desc\n      where: { or: [{ sender: $address }, { recipient: $address }] }\n    ) {\n      ...BeamLink\n    }\n  }\n": types.All_HistoryDocument,
    "\n  query INFLATION_MULTIPLIER {\n    inflationMultiplier: inflationMultipliers(orderBy: blockNumber, orderDirection: desc, first: 20) {\n      id\n      value\n      blockNumber\n      timestamp\n    }\n  }\n": types.Inflation_MultiplierDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ACCOUNT($address: ID!, $string: String, $blacklist: [String!]) {\n    account(id: $address) {\n      beamnames(orderBy: createdAt, orderDirection: asc) {\n        id\n        name\n      }\n    }\n    lastTransfer: transfers(\n      first: 1\n      orderBy: timestamp\n      orderDirection: desc\n      where: { and: [{ to_not_in: $blacklist }, { from: $string }] }\n    ) {\n      ...Transfer\n    }\n  }\n"): (typeof documents)["\n  query ACCOUNT($address: ID!, $string: String, $blacklist: [String!]) {\n    account(id: $address) {\n      beamnames(orderBy: createdAt, orderDirection: asc) {\n        id\n        name\n      }\n    }\n    lastTransfer: transfers(\n      first: 1\n      orderBy: timestamp\n      orderDirection: desc\n      where: { and: [{ to_not_in: $blacklist }, { from: $string }] }\n    ) {\n      ...Transfer\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BEAMLINK($id: ID!) {\n    beamLink(id: $id) {\n      claimedAt\n      amount\n      token {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query BEAMLINK($id: ID!) {\n    beamLink(id: $id) {\n      claimedAt\n      amount\n      token {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BEAMNAME($name: String) {\n    beamnames(where: { name: $name }, first: 1) {\n      id\n      name\n      owner {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query BEAMNAME($name: String) {\n    beamnames(where: { name: $name }, first: 1) {\n      id\n      name\n      owner {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BEAMNAME_BATCH_QUERY($addresses: [String!]!) {\n    accounts(where: { id_in: $addresses }) {\n      ...ExternalAccount\n    }\n  }\n"): (typeof documents)["\n  query BEAMNAME_BATCH_QUERY($addresses: [String!]!) {\n    accounts(where: { id_in: $addresses }) {\n      ...ExternalAccount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BEAMNAME_REVERSE($address: ID!) {\n    account(id: $address) {\n      ...ExternalAccount\n    }\n  }\n"): (typeof documents)["\n  query BEAMNAME_REVERSE($address: ID!) {\n    account(id: $address) {\n      ...ExternalAccount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BEAMNAME_SEARCH($input: String, $offset: Int) {\n    beamnames(where: { name_starts_with: $input }, first: 20, skip: $offset) {\n      name\n      owner {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query BEAMNAME_SEARCH($input: String, $offset: Int) {\n    beamnames(where: { name_starts_with: $input }, first: 20, skip: $offset) {\n      name\n      owner {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ExternalAccount on Account {\n    id\n    beamnames(orderBy: createdAt, orderDirection: asc, first: 1) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment ExternalAccount on Account {\n    id\n    beamnames(orderBy: createdAt, orderDirection: asc, first: 1) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Transfer on Transfer {\n    id\n    txHash\n    timestamp\n    from {\n      ...ExternalAccount\n    }\n    to {\n      ...ExternalAccount\n    }\n    amount\n  }\n"): (typeof documents)["\n  fragment Transfer on Transfer {\n    id\n    txHash\n    timestamp\n    from {\n      ...ExternalAccount\n    }\n    to {\n      ...ExternalAccount\n    }\n    amount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BeamLink on BeamLink {\n    id\n    sender {\n      ...ExternalAccount\n    }\n    createdAt\n    createdTxHash\n    recipient {\n      ...ExternalAccount\n    }\n    claimedAt\n    claimedTxHash\n    amount\n    token {\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment BeamLink on BeamLink {\n    id\n    sender {\n      ...ExternalAccount\n    }\n    createdAt\n    createdTxHash\n    recipient {\n      ...ExternalAccount\n    }\n    claimedAt\n    claimedTxHash\n    amount\n    token {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment InflationMultiplier on InflationMultiplier {\n    id\n    value\n    blockNumber\n    timestamp\n  }\n"): (typeof documents)["\n  fragment InflationMultiplier on InflationMultiplier {\n    id\n    value\n    blockNumber\n    timestamp\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ALL_HISTORY($address: String!, $offset: Int!) {\n    transfers(\n      first: 100\n      skip: $offset\n      orderBy: timestamp\n      orderDirection: desc\n      where: { or: [{ to: $address }, { from: $address }] }\n    ) {\n      ...Transfer\n    }\n    beamLinks(\n      first: 100\n      skip: $offset\n      orderBy: createdAt\n      orderDirection: desc\n      where: { or: [{ sender: $address }, { recipient: $address }] }\n    ) {\n      ...BeamLink\n    }\n  }\n"): (typeof documents)["\n  query ALL_HISTORY($address: String!, $offset: Int!) {\n    transfers(\n      first: 100\n      skip: $offset\n      orderBy: timestamp\n      orderDirection: desc\n      where: { or: [{ to: $address }, { from: $address }] }\n    ) {\n      ...Transfer\n    }\n    beamLinks(\n      first: 100\n      skip: $offset\n      orderBy: createdAt\n      orderDirection: desc\n      where: { or: [{ sender: $address }, { recipient: $address }] }\n    ) {\n      ...BeamLink\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query INFLATION_MULTIPLIER {\n    inflationMultiplier: inflationMultipliers(orderBy: blockNumber, orderDirection: desc, first: 20) {\n      id\n      value\n      blockNumber\n      timestamp\n    }\n  }\n"): (typeof documents)["\n  query INFLATION_MULTIPLIER {\n    inflationMultiplier: inflationMultipliers(orderBy: blockNumber, orderDirection: desc, first: 20) {\n      id\n      value\n      blockNumber\n      timestamp\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;