const flags = {
  save_access: {
    check_account_existence: isEnvFlagActive("REACT_APP_CHECK_ACCOUNT_EXISTENCE_FLAG"),
  },
};

function isEnvFlagActive(name: string, defaultValue = false) {
  const value = process.env[name];
  if (!value) return defaultValue;
  return value === "1" || value === "true";
}

export default flags;
