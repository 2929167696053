import { useEffect, useRef, useState } from "react";
import { CBPayInstanceType, initOnRamp } from "@coinbase/cbpay-js";

import config from "@constants/config";
import { useAccount } from "@contexts/AccountContext";
import { logError } from "@helpers";

type InitOnRampOptions = Parameters<typeof initOnRamp>[0];

export const useCoinbasePay = (opts: Pick<InitOnRampOptions, "onSuccess" | "onExit" | "onEvent">) => {
  const { address } = useAccount();

  const instance = useRef<CBPayInstanceType>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!address) return;

    const options: InitOnRampOptions = {
      appId: config.coinbase.appId,
      experienceLoggedIn: "popup",
      experienceLoggedOut: "popup",
      closeOnExit: true,
      closeOnSuccess: true,
      embeddedContentStyles: {
        target: "#coinbase-widget",
        position: "99999",
      },
      widgetParameters: {
        defaultAsset: "USDC",
        defaultNetwork: "optimism",
        defaultExperience: "buy",
        destinationWallets: [{ address: address!, blockchains: ["optimism", "base"], assets: ["USDC"] }],
      },
      ...opts,
    };

    if (instance.current) {
      instance.current.destroy();
    }

    initOnRamp(options, (error, _instance) => {
      if (_instance) {
        instance.current = _instance;
        setLoading(false);
      } else {
        logError("[coinbase-pay]", error);
      }
    });
  }, [address]);

  return { instance, loading };
};
