import { cubicBezier, easeOut, motion } from "framer-motion";
import React from "react";

const easeOutQuart = cubicBezier(0.17, 0.84, 0.44, 1);

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? "20%" : "-20%",
      opacity: 0,
    };
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      x: direction > 0 ? "-20%" : "20%",
      opacity: 0,
    };
  },
};

export const StepAnimation: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, style }) => {
  return (
    <motion.div
      initial="enter"
      animate="center"
      exit="exit"
      variants={variants}
      custom={1}
      style={style}
      transition={{
        x: {
          ease: easeOutQuart,
          duration: 0.3,
        },
        opacity: { ease: easeOut, duration: 0.5 },
      }}
    >
      {children}
    </motion.div>
  );
};
