import React from "react";
import { ethers } from "ethers";

import { formatCurrency } from "@helpers";
import { BodyText } from "@components/text/Text";
import Card from "@components/card/Card";
import Row from "@components/row/Row";
import { useWithdrawContext } from "@components/account/withdraw/context/WithdrawContext";
import { Button } from "@components/button/Button";

interface WithdrawConfirmProps {
  amount: ethers.BigNumber;
}

export const WithdrawConfirm: React.FC<WithdrawConfirmProps> = ({ amount }) => {
  const { externalAccount } = useWithdrawContext();
  return (
    <Card style="muted">
      <Row
        title="Amount"
        compact
        hasBottomBorder={false}
        hasHorizontalPadding={false}
        trailingContent={<BodyText bold>{formatCurrency(amount)} USD</BodyText>}
      />
      <Row
        title="To"
        compact
        hasBottomBorder={false}
        hasHorizontalPadding={false}
        trailingContent={
          <div>
            <BodyText bold>
              {externalAccount?.account_name || externalAccount?.account_owner_name || "Bank Account"}
            </BodyText>
            <BodyText small light>
              **** **** {externalAccount?.last_4}
            </BodyText>
          </div>
        }
      />
    </Card>
  );
};

export const WithdrawConfirmFooter = ({ onConfirm, onCancel }: { onConfirm: () => void; onCancel: () => void }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "var(--size-small)" }}>
      <Button title="Confirm" onClick={onConfirm} />
      <Button secondary title="Edit" onClick={onCancel} />
    </div>
  );
};
