import { BEAMNAMES_ADDRESS, FAUCET_ADDRESS, FAUCET_HAPPY_HOUR_ADDRESS, Network } from "@constants";
import { BigNumber, ethers } from "ethers";
import { isSameAddress } from "./contracts";
import { TransferCluster } from "@hooks/useHistory";
import { PEANUT_ADDRESSES } from "@modules/peanut/constants";
import config from "@constants/config";

export type TransferAmount = { amount: BigNumber; fee: BigNumber };

export interface NetworkTransfer {
  network: Network;
  state: "loading" | "success" | "error";
  balance: ethers.BigNumber;
  amount: ethers.BigNumber;
  fee: ethers.BigNumber;
  userOpHash: null | string;
  txHash: null | string;
}

function getTransferAmount(
  amount: BigNumber,
  fee: BigNumber,
  balance: BigNumber,
): TransferAmount & { remaining: TransferAmount } {
  const total = amount.add(fee);
  if (balance.lt(fee)) {
    return { amount: balance, fee: ethers.constants.Zero, remaining: { fee, amount: amount.sub(balance) } };
  } else if (balance.lt(total)) {
    return {
      fee: fee,
      amount: balance.sub(fee),
      remaining: { fee: ethers.constants.Zero, amount: total.sub(balance) },
    };
  }
  return { amount, fee, remaining: { fee: ethers.constants.Zero, amount: ethers.constants.Zero } };
}

export function calculateTransferAmounts(total: BigNumber, fee: BigNumber, balances: BigNumber[]): TransferAmount[] {
  const transfers = [];
  let remainingData = { amount: total, fee: fee };

  for (let i = 0; i < balances.length; i++) {
    const { amount, fee, remaining } = getTransferAmount(remainingData.amount, remainingData.fee, balances[i]);
    remainingData = remaining;
    transfers.push({ amount, fee });
  }

  const remainingTotal = remainingData.amount.add(remainingData.fee);
  if (!remainingTotal.isZero()) throw new Error("Exceed balance");

  return transfers;
}

export function isBeamInternalTxn(transferCluster: TransferCluster): boolean {
  const isFaucet = isSameAddress(transferCluster.from.address, FAUCET_ADDRESS);
  const isFaucetHappyHour = isSameAddress(transferCluster.from.address, FAUCET_HAPPY_HOUR_ADDRESS);
  const isBeamNameRegistration = isSameAddress(transferCluster.to.address, BEAMNAMES_ADDRESS);

  return (
    isFaucet ||
    isFaucetHappyHour ||
    isBeamNameRegistration ||
    Boolean(transferCluster.vendingMachineSwap) ||
    transferCluster.isMigration ||
    transferCluster.beamlinks.length > 0 ||
    transferCluster.from.address === ethers.constants.AddressZero ||
    PEANUT_ADDRESSES.some(
      peanutAddress =>
        isSameAddress(peanutAddress, transferCluster.from.address) ||
        isSameAddress(peanutAddress, transferCluster.to.address),
    )
  );
}

export function isCoinbaseTransaction(address: string) {
  return isSameAddress(config.coinbase.deposit.addresses, address);
}
