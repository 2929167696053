import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import "react-awesome-animated-number/dist/index.css";

import { Header } from "@components";
import { IncreaseSecurityDrawer } from "@components/account/access/increase-security/IncreaseSecurityDrawer";
import { CurrencyDrawer, CurrencySelector, WelcomeSheet } from "@components/home";
import { RebaseSheet } from "@components/home/RebaseSheet/RebaseSheet";

import { UserToken } from "@constants";
import { useCurrentToken } from "@contexts/TokenContext";

import { showWelcomeSheet } from "@helpers/onboarding";

import { useAccount } from "@contexts/AccountContext";
import "./Home.css";

const INFLATION_MULTIPLIER_KEY = "inflation-multiplier";

export const Home: React.FC = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { token, setActiveToken } = useCurrentToken();

  const { balances, inflationMultiplier } = useAccount();

  const changeToken = (token: UserToken) => {
    setActiveToken(token);
  };

  const [currencyDrawerOpen, setCurrencyDrawerOpen] = useState(false);
  const [welcomeSheetOpen, setWelcomeSheetOpen] = useState(showWelcomeSheet());
  const [rebaseSheetOpen, setRebaseSheetOpen] = useState(false);

  useEffect(() => {
    if (params.token) {
      const path = location.pathname.split("/")[3];
      navigate(`${path ? "/" + path : ""}/?c=${params.token}`, { replace: true });
    }
  }, [params]);

  useEffect(() => {
    if (inflationMultiplier && token === UserToken.ECO && balances[UserToken.ECO].total.gt(0)) {
      const newValue = inflationMultiplier.value.toString();
      const currValue = localStorage.getItem(INFLATION_MULTIPLIER_KEY);

      if (currValue && currValue !== newValue) {
        setRebaseSheetOpen(true);
        localStorage.setItem(INFLATION_MULTIPLIER_KEY, newValue);
      } else if (!currValue) {
        localStorage.setItem(INFLATION_MULTIPLIER_KEY, newValue);
      }
    }
  }, [inflationMultiplier, token, balances]);

  const selectorRef = useRef<HTMLDivElement>(null);

  const handleSelectorClick: React.MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    setCurrencyDrawerOpen(!currencyDrawerOpen);
  };

  return (
    <div className="Home">
      <Header transparent={location.pathname === `/scan`}>
        <CurrencySelector value={token} innerRef={selectorRef} onClick={handleSelectorClick} />
      </Header>
      <Outlet />
      <CurrencyDrawer
        open={currencyDrawerOpen}
        selectedToken={token}
        selectorRef={selectorRef}
        onSelect={token => changeToken(token)}
        onClose={() => setCurrencyDrawerOpen(false)}
      />
      {welcomeSheetOpen ? null : <IncreaseSecurityDrawer />}
      <WelcomeSheet open={welcomeSheetOpen} onClose={() => setWelcomeSheetOpen(false)} />
      <RebaseSheet
        open={rebaseSheetOpen}
        onClose={() => setRebaseSheetOpen(false)}
        inflationMultiplier={inflationMultiplier}
      />
    </div>
  );
};
