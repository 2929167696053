import React from "react";

import { ReactComponent as KadoIcon } from "@assets/icons/kado_icon.svg";
import { ReactComponent as CoinbaseIcon } from "@assets/icons/coinbase_icon.svg";

export enum OnRampService {
  KADO = "KADO",
  BUY_ECO = "BUY_ECO",
  COINBASE = "COINBASE",
}

export interface IOnRampService {
  type: OnRampService;
  priority: number;
  supportedCountries?: string[];
  name: string;
  icon: React.FC;
  recommendedText: string;
}

export const ONRAMP_SERVICES: IOnRampService[] = [
  {
    type: OnRampService.KADO,
    priority: 0,
    name: "Kado",
    icon: () => <KadoIcon style={{ width: 24, height: 24 }} />,
    recommendedText: "Some fees, requires proof of identity",
  },
  {
    type: OnRampService.COINBASE,
    priority: 5,
    name: "Coinbase Pay",
    supportedCountries: ["US", "UK", "AU"],
    icon: () => <CoinbaseIcon />,
    recommendedText: "Low fees, recommended if you have a Coinbase account",
  },
];
