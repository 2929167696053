import React from "react";
import View from "@components/view/View";
import { BodyText, HeaderText, TextBlock } from "@components/text/Text";

export const PrivacyPolicy = () => {
  return (
    <View headerTitle="Privacy Policy" metaTitle="Privacy Policy">
      <div style={{ padding: 16 }}>
        <TextBlock>
          <HeaderText>BEAM WALLET PRIVACY POLICY</HeaderText>
          <BodyText>Last updated: December 12, 2023</BodyText>
          <BodyText>
            Beam Wallet LLC (together with its affiliates, “Beam” or “we” or “us”) respects and protects the privacy of
            visitors to our website and users of our services (collectively, “you” or “users”). This privacy policy
            (this “Privacy Policy”) describes the type of information that we collect from you as well as our practices
            for using, protecting, and disclosing that information. This Privacy Policy covers all of your interactions
            with Beam, whether through www.beam.eco or any other websites, pages, or content we own or operate
            (collectively, the “Site(s)”), our communications with you, and any other products or services provided to
            you by us (together with the Sites and our communications with you, the “Services”).
          </BodyText>
          <BodyText>
            Please read this Privacy Policy carefully before using the Site or accessing any of the Services. As a
            general matter, other than certain technical and financial information and Log Data (except as may be
            required by law), Beam does not collect or use your information unless you opt in to such collection or use,
            typically in connection with third-party services or your provision of it to us in a communication.
          </BodyText>
          <BodyText>
            If you have not done so already, please also review our Terms of Service available at www.beam.eco/terms.
            The Terms contain provisions that limit our liability to you and require you to resolve any dispute with us
            on an individual basis and not as part of any class or representative action. IF YOU DO NOT AGREE WITH ANY
            PART OF THIS PRIVACY POLICY OR OUR TERMS OF SERVICE, THEN PLEASE DO NOT USE ANY OF THE SERVICES.
          </BodyText>
          <HeaderText>Acceptance of this Privacy Policy</HeaderText>
          <BodyText>
            By accessing any of our Services, you are agreeing to the terms of this Privacy Policy. If you do not agree
            with the terms of this Privacy Policy, you should discontinue your use of the Site or any Services.
          </BodyText>
          <HeaderText>Changes to Privacy Policy</HeaderText>
          <BodyText>
            We may modify this Privacy Policy at any time at our sole discretion, with or without notice to you. The
            modified Privacy Policy will be effective at the time it is posted on the Site. It’s important that you
            review the Privacy Policy regularly for modifications because if you continue to use the Services after we
            have modified the Privacy Policy you are agreeing to be bound by the modified Privacy Policy. If you don’t
            agree to be bound by the modified Privacy Policy, then you may not use the Services. If we make a
            significant change, we may also attempt to, but are not required to, notify you via e-mail or other
            electronic communication.
          </BodyText>
          <HeaderText>Information We Collect</HeaderText>
          <BodyText>
            Beam collects information about your activities on the Site and while using the Services. As a general
            matter, other than certain technical and financial information and Log Data (except as may be required by
            law), Beam does not collect or use your information unless you opt in to such collection or use, typically
            in connection with third-party services or your provision of it to us in a communication. We describe each
            of these categories of information, and how we collect each of these categories, in more detail below.
          </BodyText>
          <ul>
            <li>
              Information You Provide Us. We collect information that you provide us, such as information provided as
              part of support correspondence for the Services, feedback surveys, and other interactions between you and
              us. This information may include:
              <ul>
                <li>
                  Identity information, such as your first name and last name, as well as usernames and identifiers from
                  external accounts you link to the Services.
                </li>
                <li>Contact information, such as your email address.</li>
                <li>
                  Your phone number, if you elect to provide it to us. In particular, when you first sign up for an
                  account on the Site, we may ask you if you would like to enter your telephone number in order to opt
                  in to receiving text messages so that you can authenticate your access to our Services using that
                  phone number. We will use that phone number as a method for us to communicate verification codes to
                  you to verify that it is you logging into your account. You are not required to provide your phone
                  number or opt-in to receiving text messages in order to use our Services. You may unsubscribe any time
                  by replying STOP. We do not use this two-factor authentication phone number for purposes other than
                  providing verification codes; however, if you have given us your phone number in another context, such
                  as in connection with getting support for the Services, we may contact you that way.
                </li>
                <li>Usage information, such as information about how you use the Service and interact with us.</li>
                <li>
                  Feedback and correspondence, such as information you provide in your responses to surveys, when you
                  participate in market research activities, report a problem with Service, receive customer support or
                  otherwise correspond with us.
                </li>
                <li>
                  Technical and financial information, such as your digital wallet address and blockchain network
                  information regarding transactions (but excluding your recovery phrase and private keys).
                </li>
              </ul>
            </li>
            <li>
              Information Collected Automatically. Certain other information will be collected automatically while you
              are using the Site or the Services, including things like:
            </li>
            <ul>
              <li>
                Log Data. We may record certain information about how you use our Sites (“Log Data“). Log Data may
                include information such as your Internet Protocol (“IP”) address, device, and browser type; operating
                system; the pages or features of our Sites to which a user browsed and the time spent on those pages or
                features; the frequency with which the Sites are used by a user; search terms; the links on our Sites
                that a user clicked on or used, and other statistics; location data; and device event information like
                Site crashes and the date and time of actions taken on the Site. We use this information to administer
                the Service and we analyze (and may engage third parties to analyze) this information to improve and
                enhance the Service by expanding its features and functionality and tailoring it to our users’ needs and
                preferences, as well as to keep our Site and Services secure and to make your experience on the Site
                more meaningful and intuitive (for example, by remembering your preferences or providing you with a
                localized experience). We also use this information to analyze trends, administer the Sites, track
                users’ movements around the Sites, and to gather demographic information about our user base as a whole.
              </li>
            </ul>
          </ul>
          <BodyText>We collect and process your information for a number of reasons, including:</BodyText>
          <ul>
            <li>
              To Provide the Site and Services. We use your information to enable you to access and use the Service;
              provide and deliver products and services that you may request; process and complete transactions, and
              send you related information; and to send information, including confirmations, technical notices,
              updates, security alerts, and support and administrative messages.
            </li>
            <li>
              To Comply with Legal Requirements. Beam is, or may become, subject to various legal and regulatory
              compliance obligations that may require us to collect information.
            </li>
            <li>
              To Communicate with You. In order to communicate with you and troubleshoot any potential issues or answer
              any questions you might have, we need to be able to access certain information you provide to us or that
              we collect through your use of the Site and the Services.
            </li>
            <li>
              To Improve the Services. In order to improve the Services, including the Site, we need to know what is
              working (and what isn’t) so that we can combine that feedback and information with that of other users to
              make changes.
            </li>
            <li>
              With Your Consent. We may use or share your information with your consent (such as when you consent to let
              us post your testimonials or endorsements on our Sites), you instruct us to take a specific action with
              respect to your personal information, or you opt into third-party marketing communications. For
              compliance, fraud prevention, and safety purposes, we may use your information to protect, investigate,
              and deter against fraudulent, unauthorized, or illegal activity.
            </li>
          </ul>
          <HeaderText>How We Share Your Information</HeaderText>
          <BodyText>
            Beam will never sell your information to a third party without your permission. We only share your
            information with third parties where we have a legitimate need to do so in order to provide you with the
            Services. We will only share your information for the following purposes:
          </BodyText>
          <ul>
            <li>
              Affiliates. We may disclose your information to our subsidiaries and corporate affiliates (i.e. our family
              of companies that are related by common ownership or control) for purposes consistent with this Privacy
              Policy.
            </li>
            <li>
              Business Transfers. We may share your information when we do a business deal, or negotiate a business
              deal, involving the sale or transfer of all or a part of our business or assets. These deals can include
              any merger, financing, acquisition, or bankruptcy transaction or proceeding.
            </li>
            <li>
              Compliance with Laws and Law Enforcement; Protection and Safety. We may share your information for legal,
              protection, and safety purposes.
            </li>
            <ul>
              <li>We may share information to comply with laws, including KYC and AML requirements.</li>
              <li>We may share information to respond to lawful requests and legal processes.</li>
              <li>
                We may share information to protect the rights and property of Beam, our agents, customers, and others.
                This includes enforcing our agreements, policies, and terms of use.
              </li>
              <li>
                We may share information in an emergency. This includes protecting the safety of our employees and
                agents, our users, or any person.
              </li>
            </ul>
            <li>
              Professional Advisors and Service Providers. We may share your information with those who need it to do
              work for us. These recipients may include third party companies and individuals to administer and provide
              services in connection with the Service (such as Remote Procedure Call node providers), as well as
              lawyers, bankers, auditors, and insurers.
            </li>
            <li>
              Aggregated/Anonymized Data. We may also share aggregated and/or anonymized data with others for their own
              uses.
            </li>
            <li>
              Other. You may permit us to share your information with other companies or entities of your choosing.
              Those uses will be subject to the privacy policies of the recipient entity or entities.
            </li>
          </ul>
          <HeaderText>How We Protect Your Information</HeaderText>
          <BodyText>
            Beam has implemented measures intended to secure your information from accidental loss and from unauthorized
            access, use, alteration, and disclosure. You also play an important role in maintaining the safety and
            security of your information. If we have given you access credentials, such as a username and password, you
            are responsible for keeping these credentials confidential. Unfortunately, the transmission of information
            via the Internet is not completely secure, and no company, including Beam, can fully eliminate security
            risks. Although we have implemented measures to protect your information, we cannot guarantee the security
            of your information transmitted to or from us, including via email, or the security of any links accessible
            from our Site.
          </BodyText>
          <HeaderText>How You Can Opt Out of Certain Practices</HeaderText>
          <BodyText>
            In addition to the circumstances described above, from time to time, we may request your permission to allow
            us to share your personal information with third parties. You may opt out of having your personal
            information shared with third parties, under certain circumstances, or from allowing us to use your personal
            information for a purpose that is incompatible with the purposes for which we originally collected it, or
            for which we subsequently obtained your authorization, by reaching out to legal@beam.eco. If you choose to
            limit the use of your personal information, certain features or services may not be available to you.
          </BodyText>
          <HeaderText>Third Party Materials</HeaderText>
          <BodyText>
            This Site may, from time to time, contain links to websites or services owned and operated by third parties
            (“Third-Party Materials”), such as Remote Procedure Call node providers. We encourage you to review the
            privacy policies of such Third-Party Materials so that you understand how those third parties collect, use,
            and share your information. We are not responsible for the privacy notices, practices, or other content
            relating to Third-Party Materials.
          </BodyText>
          <HeaderText>Children’s Information</HeaderText>
          <BodyText>Beam does not knowingly collect personal information from any person under the age of 18.</BodyText>
          <HeaderText>Contact Us</HeaderText>
          <BodyText>If you have any questions or concerns, please contact us at team@beam.eco.</BodyText>
          <HeaderText>Notice to California Residents</HeaderText>
          <BodyText>
            Under California Civil Code Section 1789.3, California users are entitled to the following consumer rights
            notice: California residents may reach the Complaint Assistance Unit of the Division of Consumer Services of
            the California Department of Consumer Affairs by mail at 1625 North Market Blvd., Sacramento, CA 95834, or
            by telephone at (916) 445-1254 or (800) 952-5210.
          </BodyText>
          <BodyText>
            This section provides additional details about the personal information we collect about California
            consumers and the rights afforded to them under the California Consumer Privacy Act or “CCPA.”
          </BodyText>
          <BodyText>
            For more details about the personal information we collect from you, please see the “How We Use Your
            Information” section above. We collect this information for the business and commercial purposes described
            in the “How We Use Your Personal Information” section above. We share this information with the categories
            of third parties described in the “How We Share Your Information” section above. Beam does not sell (as such
            term is defined in the CCPA) the personal information we collect (and will not sell it without providing a
            right to opt out).
          </BodyText>
          <BodyText>
            Subject to certain limitations, the CCPA provides California consumers the right to request to know more
            details about the categories or specific pieces of personal information we collect (including how we use and
            disclose this information), to delete their personal information, to opt out of any “sales” that may be
            occurring, and to not be discriminated against for exercising these rights.
          </BodyText>
          <BodyText>
            California consumers may make a request pursuant to their rights under the CCPA by contacting us at
            legal@beam.eco. Please note that you must verify your identity and request before further action is taken.
            As a part of this process, government identification may be required. Consistent with California law, you
            may designate an authorized agent to make a request on your behalf. In order to designate an authorized
            agent to make a request on your behalf, you must provide a valid power of attorney, the requester’s valid
            government issued identification, and the authorized agent’s valid government issued identification.
          </BodyText>
          <HeaderText>Notice to EU Data Subjects</HeaderText>
          <BodyText>Personal Information</BodyText>
          <BodyText>
            With respect to EU data subjects, “information” as used in this Privacy Policy, is equivalent to “personal
            data” as defined in the European Union General Data Protection Regulation (GDPR). As a general matter, other
            than certain technical and financial information and Log Data (except as may be required by law), Beam does
            not collect or use your information unless you opt in to such collection or use, typically in connection
            with third-party services or your provision of it to us in a communication.
          </BodyText>
          <BodyText>Sensitive Data</BodyText>
          <BodyText>
            Some of the information you provide us may constitute sensitive data as defined in the GDPR (also referred
            to as special categories of personal data), including identification of your race or ethnicity on
            government-issued identification documents.
          </BodyText>
          <BodyText>Legal Bases for Processing</BodyText>
          <BodyText>
            We only use your personal information as permitted by law. We are required to inform you of the legal bases
            of our processing of your personal information, which are described in the table below. If you have
            questions about the legal bases under which we process your personal information, contact us at
            legal@beam.eco.
          </BodyText>

          <table>
            <tr>
              <th>Processing Purpose</th>
              <th>Legal Basis</th>
            </tr>
            <tr>
              <td>To provide our service</td>
              <td>
                Our processing of your personal information is necessary to perform the contract governing our provision
                of the Services or to take steps that you request prior to signing up for the Service.
              </td>
            </tr>
            <tr>
              <td>
                To communicate with you To optimize our platform For compliance, fraud prevention, and safety To provide
                our service
              </td>
              <td>
                These processing activities constitute our legitimate interests. We make sure we consider and balance
                any potential impacts on you (both positive and negative) and your rights before we process your
                personal information for our legitimate interests. We do not use your personal information for
                activities where our interests are overridden by any adverse impact on you (unless we have your consent
                or are otherwise required or permitted to by law).
              </td>
            </tr>
            <tr>
              <td>To comply with applicable laws or regulations</td>
              <td>
                We use your personal information if necessary to comply with applicable laws and our legal obligations.
              </td>
            </tr>
            <tr>
              <td>With your consent</td>
              <td>
                Where our use of your personal information is based upon your consent, you have the right to withdraw it
                anytime in the manner indicated in the Service or by contacting us at legal@beam.eco.
              </td>
            </tr>
          </table>

          <BodyText>Use for New Purposes</BodyText>
          <BodyText>
            We may use your personal information for reasons not described in this Privacy Policy, where we are
            permitted by law to do so and where the reason is compatible with the purpose for which we collected it. If
            we need to use your personal information for an unrelated purpose, we will notify you and explain the
            applicable legal basis for that use. If we have relied upon your consent for a particular use of your
            personal information, we will seek your consent for any unrelated purpose.
          </BodyText>
          <BodyText>Your Rights</BodyText>
          <BodyText>
            Under the GDPR, you have certain rights regarding your personal information. You may ask us to take the
            following actions in relation to your personal information that we hold:
          </BodyText>
          <ul>
            <li>
              Opt-out. Stop sending you direct marketing communications which you have previously consented to receive.
              We may continue to send you Service-related and other non-marketing communications.
            </li>
            <li>
              Access. Provide you with information about our processing of your personal information and give you access
              to your personal information.
            </li>
            <li>Correct. Update or correct inaccuracies in your personal information.</li>
            <li>Delete. Delete your personal information.</li>
            <li>
              Transfer. Transfer a machine-readable copy of your personal information to you or a third party of your
              choice.
            </li>
            <li>Restrict. Restrict the processing of your personal information.</li>
            <li>
              Object. Object to our reliance on our legitimate interests as the basis of our processing of your personal
              information that impacts your rights.
            </li>
          </ul>
          <BodyText>
            You can submit these requests by email to legal@beam.eco. We may request specific information from you to
            help us confirm your identity and process your request. Applicable law may require or permit us to decline
            your request. If we decline your request, we will tell you why, subject to legal restrictions. If you would
            like to submit a complaint about our use of your personal information or response to your requests regarding
            your personal information, you may contact us at legal@beam.eco or submit a complaint to the data protection
            regulator in your jurisdiction. You can find your data protection regulator here.
          </BodyText>
          <BodyText>Cross-Border Data Transfer</BodyText>
          <BodyText>
            Please be aware that your personal data will be transferred to, processed, and stored in the United States.
            Data protection laws in the U.S. may be different from those in your country of residence. You consent to
            the transfer of your information, including personal information, to the U.S. as set forth in this Privacy
            Policy by visiting our Sites or using our Services.
          </BodyText>
          <BodyText>
            Whenever we transfer your personal information out of the EEA to the U.S. or countries not deemed by the
            European Commission to provide an adequate level of personal information protection, the transfer will be
            based on a data transfer mechanism recognized by the European Commission as providing adequate protection
            for personal information.
          </BodyText>
          <BodyText>
            Please contact us if you want further information on the specific mechanism used by us when transferring
            your personal information out of the EEA.
          </BodyText>
        </TextBlock>
      </div>
    </View>
  );
};
