import React, { useEffect, useState } from "react";
import View from "@components/view/View";
import { ReactComponent as Beam } from "@assets/icons/beam.svg";
import { ReactComponent as CircleCheck } from "@assets/icons/circle_check.svg";
import { useNavigate } from "react-router-dom";
import { useWeb3AuthState } from "@redux/slides/web3auth.slide";
import Card from "@components/card/Card";
import { Button } from "@components/button/Button";
import { Callout } from "@components/callout/Callout";
import styles from "@views/About/About.module.css";
import { BodyText, TextBlock } from "@components/text/Text";
import { useAccount } from "@contexts/AccountContext";
import { RegisterForm } from "@components/account/beamnames/RegisterForm";
import { VendingMachineCard } from "@components/fund/VendingMachineCard";
import { ReactComponent as XLogo } from "@assets/icons/x-logo.svg";
import { ReactComponent as WarpcastLogo } from "@assets/icons/warpcast.svg";
import { LoadAccountComponent, SaveAccessComponent } from "@views";
import { checkHasShoppingWaitListed } from "@modules/web3auth/helpers";
import { RELAYOOR_URL } from "@constants";
import axios from "axios";
import { useRegistrationFee } from "@hooks/useRegistrationFee";
import { isDesktop } from "react-device-detect";
import { ethers } from "ethers";
import { logError, notify } from "@helpers";

const FOLLOW_BEAM_X_QUEST_KEY = "beam-shop-x-followed";
const RETWEET_BEAM_X_KEY = "beam-shop-x-retweet";
const FOLLOW_BEAM_WARPCAST_KEY = "beam-shop-warpcast-follow";

function markQuestComplete(key: string) {
  window.localStorage.setItem(key, "true");
}

const LoginBox = () => {
  const navigate = useNavigate();
  const [loggingIn, setLoggingIn] = useState(false);
  const [signingUp, setSigningUp] = useState(false);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "var(--size-medium)" }}>
      {!loggingIn && !signingUp ? (
        <>
          <Card color="warning" title={<span className="Card_title-text">🛍️ Be the first to use Amazon checkout</span>}>
            <p>
              We&apos;re letting people off the waitlist in batches. Log in or create your Beam account to save your
              spot in line!
            </p>
            <Button color="warning" title="Create Account" onClick={() => setSigningUp(true)} />
            <br />
            <Button secondary title="Login" onClick={() => setLoggingIn(true)} />
          </Card>
          <Card title="What is Beam?" className={styles.card} titleIcon={<QuestIcon icon={<Beam />} />}>
            <TextBlock>
              <BodyText>
                Beam is a global, digital wallet that lets you send money to anyone, anywhere, instantly — and soon you
                can shop anywhere with it too.
              </BodyText>
            </TextBlock>
          </Card>
        </>
      ) : (
        <></>
      )}

      {loggingIn ? (
        <Card>
          <LoadAccountComponent fromShop onComplete={() => navigate("/shop")} />
        </Card>
      ) : (
        <></>
      )}

      {signingUp ? (
        <Card>
          <SaveAccessComponent onComplete={() => navigate("/shop")} />
        </Card>
      ) : (
        <></>
      )}
    </div>
  );
};

const QuestIcon = ({ icon }: { icon: React.ReactNode }) => <div className={styles.cardTitleIcon}>{icon}</div>;

export const BeamnamesCardQuest = () => {
  const { beamname, balances } = useAccount();
  const [isVendingMachineOpen, setVendingMachineOpen] = React.useState(false);

  const registrationFee = useRegistrationFee();
  const hasEnoughEco = registrationFee && !registrationFee.isZero() ? balances.eco.optimism.gte(registrationFee) : true;
  const buttonTitle = registrationFee
    ? isDesktop
      ? `${parseFloat(ethers.utils.formatEther(registrationFee)).toFixed(2)} ECO needed—Buy ECO`
      : "Buy Eco"
    : "";

  const openLink = () => {
    window.open(`https://${beamname!.displayName}.beam.eco`, "_newtab");
  };

  if (!beamname) {
    return <></>;
  }

  return (
    <>
      {!beamname.exists ? (
        <>
          <Card
            title={
              !beamname.exists ? (
                !isDesktop ? (
                  <BodyText style={{ fontWeight: "bold" }}>Register a Beam Name to skip the line</BodyText>
                ) : (
                  "Register a Beam Name to skip the line"
                )
              ) : undefined
            }
            titleIcon={<QuestIcon icon={<Beam />} />}
            onClick={beamname.exists ? openLink : undefined}
            titleTrailing={
              !hasEnoughEco &&
              registrationFee && (
                <Button
                  title={buttonTitle}
                  size="small"
                  secondary
                  onClick={() => {
                    setVendingMachineOpen(true);
                  }}
                />
              )
            }
          >
            {beamname.exists ? <></> : <RegisterForm />}
          </Card>
          <VendingMachineCard open={isVendingMachineOpen} onClose={() => setVendingMachineOpen(false)} />
        </>
      ) : (
        <Button
          title={"Register a Beam Name to skip the line"}
          secondary
          disabled={true}
          leadingIcon={<Beam />}
          style={{
            justifyContent: "flex-start",
            fontSize: "16px",
            textDecoration: "line-through",
          }}
        />
      )}
    </>
  );
};

const setWaitlist = (address: string) => {
  const url = new URL(`/api/v1/users/setShoppingWaitListed/${address}`, RELAYOOR_URL).toString();
  return axios.post(url);
};

const getTextDecoration = (key: string | null) => {
  if (key !== null) {
    return "line-through";
  } else {
    return "";
  }
};

const Quests = () => {
  const [beamXFollowed, setBeamXFollowed] = useState(window.localStorage.getItem(FOLLOW_BEAM_X_QUEST_KEY));
  const [beamXRetweet, setBeamXRetweet] = useState(window.localStorage.getItem(RETWEET_BEAM_X_KEY));
  const [beamWarpcastFollowed, setBeamWarpcastFollowed] = useState(
    window.localStorage.getItem(FOLLOW_BEAM_WARPCAST_KEY),
  );

  const { address } = useAccount();

  useEffect(() => {
    if (address) {
      checkHasShoppingWaitListed(address)
        .then(async inList => {
          if (!inList) {
            try {
              await setWaitlist(address);
            } catch (err) {
              logError(`setWaitlist - addr ${address}`, err);
              return;
            }
          }
          notify({
            duration: 2_000,
            content: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                <CircleCheck style={{ width: "24px", height: "24px", margin: "7px" }} />{" "}
                {inList ? `You're already on the list` : `You're on the list`}
              </div>
            ),
          });
        })
        .catch(err => logError("checkHasShoppingWaitListed", err));
    }
  }, [address]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "var(--size-medium)" }}>
      {beamXFollowed && beamXRetweet && beamWarpcastFollowed ? (
        <Callout style={"info"} includeIcon={false}>
          <div style={{ fontWeight: "bold", fontSize: "16px" }}>😎 You are at the top of the waiting list</div>
        </Callout>
      ) : (
        <Callout includeIcon={false}>
          <div style={{ fontWeight: "bold", fontSize: "16px" }}> 🔍 Complete quests to get priority access</div>
        </Callout>
      )}

      <BeamnamesCardQuest />

      <Button
        title="Follow @beam_eco on X"
        secondary
        disabled={beamXFollowed !== null}
        leadingIcon={<XLogo />}
        style={{ justifyContent: "flex-start", fontSize: "16px", textDecoration: getTextDecoration(beamXFollowed) }}
        onClick={() => {
          setBeamXFollowed("true");
          markQuestComplete(FOLLOW_BEAM_X_QUEST_KEY);
          open("https://twitter.com/beam_eco");
        }}
      />

      <Button
        title="Retweet the announcement"
        secondary
        disabled={beamXRetweet !== null}
        leadingIcon={<XLogo />}
        style={{ justifyContent: "flex-start", fontSize: "16px", textDecoration: getTextDecoration(beamXRetweet) }}
        onClick={() => {
          setBeamXRetweet("true");
          markQuestComplete(RETWEET_BEAM_X_KEY);
          open("https://twitter.com/beam_eco/status/1750524702089736241");
        }}
      />

      <Button
        title={"Follow @beam-eco on Warpcast"}
        secondary
        disabled={beamWarpcastFollowed !== null}
        leadingIcon={<WarpcastLogo />}
        style={{
          justifyContent: "flex-start",
          fontSize: "16px",
          textDecoration: getTextDecoration(beamWarpcastFollowed),
        }}
        onClick={() => {
          setBeamWarpcastFollowed("true");
          markQuestComplete(FOLLOW_BEAM_WARPCAST_KEY);
          open("https://warpcast.com/beam-eco");
        }}
      />
    </div>
  );
};

export const Waitlist = () => {
  const { hasSavedAccess } = useWeb3AuthState();

  return (
    <View headerTitle="Shopping" metaTitle={"Shopping"}>
      {!hasSavedAccess ? <LoginBox /> : <Quests />}
    </View>
  );
};
