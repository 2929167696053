import React from "react";

import BottomSheet from "./bottomsheet/BottomSheet";
import { BodyText, TextBlock } from "./text/Text";
import { isDesktop } from "react-device-detect";

export const WhyEmojiSheet = ({
  isOpen,
  onClose,
  emojis,
}: {
  isOpen: boolean;
  onClose: () => void;
  emojis: string;
}) => {
  return (
    <BottomSheet title={`What is ${emojis}?`} isOpen={isOpen} onClose={onClose} desktopDisplay="overlay">
      <TextBlock>
        <BodyText>Until you register a Beam Name, we use a unique set of emojis to represent your address.</BodyText>
        <BodyText>This makes it easy for your friends to know they’re sending money to the right person.</BodyText>
        <BodyText>
          If you ever need your wallet address, you can always find it in your profile or by{" "}
          {isDesktop ? "clicking" : "tapping"} your Beam Code from home.
        </BodyText>
      </TextBlock>
    </BottomSheet>
  );
};
