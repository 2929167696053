const getScrollParent = (node: HTMLElement): HTMLElement | null => {
  const isElement = node instanceof HTMLElement;
  const overflowY = isElement && window.getComputedStyle(node).overflowY;
  const isScrollable = overflowY !== "visible" && overflowY !== "hidden";

  if (!node) {
    return null;
  } else if (isScrollable && node.scrollHeight >= node.clientHeight) {
    return node;
  }

  return getScrollParent(node.parentNode as HTMLElement) || document.body;
};

export const scrollIntoView = (el: HTMLElement, topOffset = 0) => {
  let node: HTMLElement | Window | null = getScrollParent(el);

  if (!node) {
    node = document.body;
  }

  const offset = node.scrollTop + el.getBoundingClientRect().top - topOffset;
  node?.scrollTo({ top: offset, behavior: "smooth" });
};
