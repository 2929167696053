import React from "react";

import Card from "@components/card/Card";
import { Button } from "@components/button/Button";

import "./SaveAccessCard.css";
import { useNavigate } from "react-router-dom";
import { BodyText, TextBlock } from "@components/text/Text";

export const SaveAccessCard = () => {
  const navigate = useNavigate();

  return (
    <div style={{ marginTop: 1 }}>
      <Card color="warning" title={<span className="Card_title-text">Maintain access to your funds</span>}>
        <div style={{ display: "flex", flexDirection: "column", gap: "var(--size-medium)" }}>
          <TextBlock>
            <BodyText light>
              Create a Beam account to maintain access to your funds across devices or if you lose your phone. This
              takes only a few seconds.
            </BodyText>
          </TextBlock>
          <Button color="warning" title="Create account" onClick={() => navigate("/save-access")} />
        </div>
      </Card>
    </div>
  );
};
