import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AnimationSequence, easeOut, motion, useAnimate } from "framer-motion";

import BottomSheet from "@components/bottomsheet/BottomSheet";
import { BodyText, TextBlock } from "@components/text/Text";
import { OnboardingStep, useOnboarding } from "../Onboarding";

import { SaveAccessComponent } from "@views";
import { useWeb3AuthState } from "@redux/slides/web3auth.slide";

import styles from "./SaveAccess.module.css";

export const SaveAccess: React.FC = () => {
  const { hasSavedAccess, afterRedirect } = useWeb3AuthState();

  const [saveAccessSheetOpen, setSaveAccessSheetOpen] = useState(false);
  const onboardingContext = useOnboarding();

  const animationDuration = 2.3;

  useEffect(() => {
    if (hasSavedAccess) {
      onboardingContext.setIsLoading(false);
      setSaveAccessSheetOpen(false);
    }
  }, [hasSavedAccess]);

  useEffect(() => {
    onboardingContext.setButtonAction(() =>
      hasSavedAccess ? onboardingContext.nextStep() : setSaveAccessSheetOpen(true),
    );
    onboardingContext.setButtonTitle(hasSavedAccess ? "Next" : "Connect account");
  }, [hasSavedAccess]);

  useEffect(() => {
    setTimeout(() => {
      onboardingContext.setHasPlayedIntroAnim(true);
    }, animationDuration);
  }, []);

  useEffect(() => {
    if (afterRedirect) {
      setSaveAccessSheetOpen(true);
    }
  }, [afterRedirect]);

  return (
    <>
      <OnboardingStep
        isDone={hasSavedAccess}
        brand={
          <BeamBrandAnim shouldAnimate={!onboardingContext.hasPlayedIntroAnimation} duration={animationDuration} />
        }
        stepTitle={"Connect account"}
        postStepContent={
          <TextBlock>
            <BodyText>
              First, connect your email or X account and add a Beam-specific password. This ensures you can always log
              in and access your money.
            </BodyText>
          </TextBlock>
        }
      />
      <SaveAccessSheet
        isOpen={saveAccessSheetOpen}
        setIsOpen={setSaveAccessSheetOpen}
        title="Connect account"
        body={
          <>
            <BodyText centered>
              Select which account you want to connect. You will use this account to log in to Beam in the future.
            </BodyText>
          </>
        }
      />
    </>
  );
};

const BeamBrandAnim = ({ shouldAnimate = true, duration }: { shouldAnimate: boolean; duration: number }) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    const sequence: AnimationSequence = [
      [".letter", { y: 0 }, { duration: 0.5 }],
      [".burst-diag.tl", { x: 0, y: 0, opacity: 1 }],
      [".burst-diag.tr", { x: 0, y: 0, opacity: 1 }, { at: "-0.2" }],
      [".burst-diag.bl", { x: 0, y: 0, opacity: 1 }, { at: "-0.5" }],
      [".burst-diag.br", { x: 0, y: 0, opacity: 1 }, { at: "-0.35" }],
      [".burst-straight.vert", { scaleY: 1, opacity: 1 }, { ease: easeOut }],
      [".burst-straight.horiz", { scaleX: 1, opacity: 1 }, { ease: easeOut }],
      [".letter.b", { x: 0, y: 0, opacity: 1 }, { ease: easeOut, at: "-0.2" }],
      [".letter.e", { x: 0, y: 0, opacity: 1 }, { ease: easeOut, at: "-0.2" }],
      [".letter.a", { x: 0, y: 0, opacity: 1 }, { ease: easeOut, at: "-0.2" }],
      [".letter.m", { x: 0, y: 0, opacity: 1 }, { ease: easeOut, at: "-0.2" }],
      [".tail", { x: 0, opacity: 1 }],
      [".letter", { x: 0 }, { duration: 1 }],
    ];

    animate(sequence, {
      repeat: 0,
      duration: shouldAnimate ? duration : 0,
    });
  }, []);

  const svg = (
    <motion.svg
      width="115"
      height="30"
      viewBox="0 0 115 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ ease: easeOut, duration: shouldAnimate ? duration / 2 : 0 }}
    >
      <motion.path
        className="letter b"
        initial={{ x: -5, opacity: 0 }}
        d="M34.2683 23.6259C34.0138 23.6259 33.8865 23.4986 33.8865 23.2441V4.76869C33.8865 4.51412 34.0138 4.38684 34.2683 4.38684H40.4953C42.8647 4.38684 44.6467 4.77848 45.8411 5.56175C47.0552 6.34502 47.6622 7.52971 47.6622 9.11584C47.6622 10.2516 47.4077 11.1621 46.8986 11.8475C46.3894 12.5133 45.6062 13.042 44.5487 13.4336V13.5217C45.802 13.7567 46.7713 14.2365 47.4566 14.961C48.142 15.6855 48.4847 16.7038 48.4847 18.0157C48.4847 19.9935 47.8483 21.423 46.5755 22.3042C45.3222 23.1853 43.2857 23.6259 40.4659 23.6259H34.2683ZM38.0868 20.1893H40.6422C41.9346 20.1893 42.8647 20.0131 43.4326 19.6606C44.0005 19.2886 44.2844 18.6619 44.2844 17.7808C44.2844 16.9583 43.9907 16.3513 43.4032 15.9597C42.8353 15.568 41.915 15.3722 40.6422 15.3722H38.0868V20.1893ZM38.0868 12.2881H40.4072C41.4842 12.2881 42.2577 12.1021 42.7276 11.73C43.2172 11.3384 43.462 10.7509 43.462 9.96764C43.462 9.22354 43.1976 8.67525 42.6689 8.32278C42.1598 7.9703 41.3471 7.79407 40.231 7.79407H38.0868V12.2881Z"
        fill="black"
      />
      <motion.path
        className="letter e"
        initial={{ x: -5, opacity: 0 }}
        d="M51.7944 23.6259C51.5398 23.6259 51.4125 23.4986 51.4125 23.2441V4.76869C51.4125 4.51412 51.5398 4.38684 51.7944 4.38684H63.5141C63.7686 4.38684 63.8959 4.51412 63.8959 4.76869V7.67658C63.8959 7.91156 63.7686 8.02905 63.5141 8.02905H55.6128V12.2H62.8091C63.0637 12.2 63.191 12.3272 63.191 12.5818V15.2841C63.191 15.5191 63.0637 15.6366 62.8091 15.6366H55.6128V19.9837H63.5141C63.7686 19.9837 63.8959 20.1012 63.8959 20.3362V23.2441C63.8959 23.4986 63.7686 23.6259 63.5141 23.6259H51.7944Z"
        fill="black"
      />
      <motion.path
        className="letter a"
        initial={{ x: -5, opacity: 0 }}
        d="M65.7434 23.6259C65.4693 23.6259 65.3714 23.4889 65.4497 23.2147L71.6767 4.68057C71.755 4.48475 71.9019 4.38684 72.1173 4.38684H76.6113C76.8267 4.38684 76.9638 4.48475 77.0225 4.68057L83.3083 23.2147C83.3474 23.3322 83.3376 23.4301 83.2789 23.5084C83.2397 23.5868 83.1516 23.6259 83.0145 23.6259H79.2255C79.0101 23.6259 78.873 23.528 78.8142 23.3322L75.4364 11.965C75.2406 11.2992 75.0545 10.6334 74.8783 9.96764C74.7021 9.28228 74.5258 8.60671 74.3496 7.94093H74.2908C74.1146 8.60671 73.9384 9.28228 73.7621 9.96764C73.5859 10.6334 73.3999 11.2992 73.2041 11.965L69.885 23.3028C69.8262 23.5182 69.6891 23.6259 69.4737 23.6259H65.7434ZM70.2668 19.8956L70.9717 16.5471H77.5806L78.3443 19.8956H70.2668Z"
        fill="black"
      />
      <motion.path
        className="letter m"
        initial={{ x: -5, opacity: 0 }}
        d="M85.1739 23.6259C85.0564 23.6259 84.9585 23.5966 84.8801 23.5378C84.8214 23.4595 84.792 23.3616 84.792 23.2441L85.1739 4.76869C85.1739 4.51412 85.3012 4.38684 85.5557 4.38684H89.7854C89.9812 4.38684 90.1183 4.48475 90.1966 4.68057L94.955 14.8435L99.6546 4.68057C99.7525 4.48475 99.8994 4.38684 100.095 4.38684H104.295C104.53 4.38684 104.648 4.51412 104.648 4.76869L105.059 23.2441C105.059 23.3616 105.02 23.4595 104.942 23.5378C104.883 23.5966 104.795 23.6259 104.677 23.6259H101.329C101.074 23.6259 100.947 23.4986 100.947 23.2441L100.771 15.2253C100.751 14.4225 100.751 13.6001 100.771 12.758C100.81 11.8964 100.849 11.0642 100.888 10.2614H100.8C100.467 11.0446 100.115 11.8475 99.7427 12.6699C99.3707 13.4924 98.9888 14.2854 98.5972 15.0491L95.4249 21.4817C95.3466 21.6384 95.1997 21.7167 94.9843 21.7167H94.8375C94.6417 21.7167 94.5046 21.6384 94.4263 21.4817L91.2246 14.9904C90.8526 14.2267 90.4805 13.4434 90.1085 12.6406C89.7364 11.8181 89.3937 11.0251 89.0804 10.2614H88.9923C89.0315 11.0642 89.0609 11.8867 89.0804 12.7287C89.1 13.5707 89.1 14.3833 89.0804 15.1666L88.9042 23.2441C88.9042 23.4986 88.7769 23.6259 88.5224 23.6259H85.1739Z"
        fill="black"
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        initial={{ scaleY: 0, opacity: 0 }}
        className="burst-straight vert"
        d="M13.1311 28.1628L13.1311 0.625977L15.8848 0.625977L15.8848 28.1628H13.1311Z"
        fill="black"
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        initial={{ scaleX: 0, opacity: 0 }}
        className="burst-straight horiz"
        d="M0.739609 13.0176L35.6196 13.0176L35.6196 15.7713L0.739609 15.7713L0.739609 13.0176Z"
        fill="black"
      />
      <motion.path
        fillRule="evenodd"
        className="burst-diag tr"
        initial={{ x: -5, y: 5, opacity: 0 }}
        clipRule="evenodd"
        d="M18.1237 8.83133L23.27 3.68506L25.2172 5.63221L20.0709 10.7785L18.1237 8.83133Z"
        fill="black"
      />
      <motion.path
        fillRule="evenodd"
        className="burst-diag bl"
        initial={{ x: 5, y: -5, opacity: 0 }}
        clipRule="evenodd"
        d="M3.79854 23.1565L8.94482 18.0103L10.892 19.9574L5.74569 25.1037L3.79854 23.1565Z"
        fill="black"
      />
      <motion.path
        fillRule="evenodd"
        className="burst-diag br"
        initial={{ x: -5, y: -5, opacity: 0 }}
        clipRule="evenodd"
        d="M20.0708 18.0103L25.217 23.1566L23.2699 25.1038L18.1236 19.9575L20.0708 18.0103Z"
        fill="black"
      />
      <motion.path
        className="burst-diag tl"
        fillRule="evenodd"
        initial={{ x: 5, y: 5, opacity: 0 }}
        clipRule="evenodd"
        d="M5.74581 3.68502L10.8921 8.8313L8.94494 10.7784L3.79866 5.63217L5.74581 3.68502Z"
        fill="black"
      />
      <motion.path
        fillRule="evenodd"
        className="tail"
        initial={{ x: -3, opacity: 0 }}
        clipRule="evenodd"
        d="M107.215 13.0176L114.559 13.0176L114.559 15.7713L107.215 15.7713L107.215 13.0176Z"
        fill="black"
      />
    </motion.svg>
  );

  return (
    <div className={styles.beamBrand} ref={scope}>
      {svg}
    </div>
  );
};

interface SaveAccessSheetProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  body?: React.ReactNode;
}

export const SaveAccessSheet: React.FC<SaveAccessSheetProps> = ({ isOpen, setIsOpen, title, body }) => {
  return (
    <BottomSheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <SaveAccessComponent onComplete={() => setIsOpen(false)} onboarding title={title} body={body} />
    </BottomSheet>
  );
};
