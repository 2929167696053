import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Button, LinkButton } from "@components/button/Button";
import Card from "@components/card/Card";
import ExpandSection from "@components/expand/ExpandSection";
import { BodyText, TextBlock } from "@components/text/Text";
import View from "@components/view/View";

import styles from "./About.module.css";

import { ReactComponent as Beam } from "@assets/icons/beam.svg";
import { ReactComponent as Checkmark } from "@assets/icons/checkmark.svg";
import { ReactComponent as Eco } from "@assets/icons/eco.svg";
import { ReactComponent as FAQ } from "@assets/icons/faq.svg";
import { ReactComponent as Help } from "@assets/icons/help.svg";
import { ReactComponent as Chevron, ReactComponent as LinkChevron } from "@assets/icons/link_chevron.svg";
import { ReactComponent as Rocket } from "@assets/icons/rocket.svg";
import { BeamTwitterButton, EcoTwitterButton } from "@components/button/TwitterButton";
import { IconButton } from "@components/iconbutton/IconButton";
import { Spinner } from "@components/spinner/Spinner";
import { useAccount } from "@contexts/AccountContext";
import { scrollIntoView } from "@helpers/scrollIntoView";
import { useWeb3AuthState } from "@redux/slides/web3auth.slide";
import classNames from "classnames";

const Toggle = ({
  title,
  isExpanded,
  dataCy,
  includeBorder = true,
}: {
  title: string;
  isExpanded: boolean;
  dataCy?: string;
  includeBorder?: boolean;
}) => (
  <p
    className={`${styles.faqTitle} ${includeBorder && styles.hasBorder} ${isExpanded ? styles.isExpanded : null}`}
    {...(dataCy ? { "data-cy": dataCy } : {})}
  >
    {title}
    <IconButton
      icon={<Chevron />}
      size="small"
      style={{ transform: `rotate(${isExpanded ? "-90deg" : "90deg"})` }}
      className={styles.faqToggle}
    />
  </p>
);

interface StartStepProps {
  linkRoute: string;
  step: number;
  cyLabel: string;
  title: string;
  body: string | React.ReactNode;
  isOptional?: boolean;
  isComplete?: boolean;
}

const StartStep = ({
  linkRoute,
  cyLabel,
  step,
  title,
  body,
  isOptional = false,
  isComplete = false,
}: StartStepProps) => {
  return (
    <Link to={linkRoute} className={`${styles.startItem} ${isComplete && styles.startItemComplete}`} data-cy={cyLabel}>
      <div className={styles.startItemNumber}>{step}</div>
      <div className={styles.startItemDescription}>
        <div className={classNames(styles.startTitle, { [styles.isComplete]: isComplete })}>
          {isComplete ? <BodyText>{title}</BodyText> : <LinkButton title={title} href={linkRoute} />}
          {isOptional && <label className={styles.startOptionalLabel}>Optional</label>}
        </div>
        {!isComplete && body}
      </div>
      <div className={`${styles.startItemIcon}`}>{isComplete ? <Checkmark /> : <LinkChevron />}</div>
    </Link>
  );
};

const AboutIcon = ({ icon }: { icon: React.ReactNode }) => <div className={styles.cardTitleIcon}>{icon}</div>;

export const About = () => {
  const { pathname, hash, key } = useLocation();
  const { hasSavedAccess } = useWeb3AuthState();
  const { balances, lastTransfer, beamname } = useAccount();
  const hasSomeBalance = Object.values(balances).some(balance => !balance.total.isZero());
  const hasNUXStatus = true; // TODO: make this conditional once all the data is loaded
  const isNUXComplete = hasSomeBalance && !!lastTransfer && !!beamname && hasSavedAccess;
  const [focusedEl, setFocusedEl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const element = document.getElementById(hash.substring(1));
    if (element) {
      scrollIntoView(element, 60);
      setTimeout(() => {
        setFocusedEl(hash.substring(1));
      }, 300);
    }
  }, [pathname, hash, key, scrollIntoView, setFocusedEl]);

  useEffect(() => {
    setTimeout(() => {
      setFocusedEl(undefined);
    }, 3000);
  }, [setFocusedEl]);

  return (
    <View
      headerTitle="About"
      metaTitle="About"
      navbarItem={<Button size="small" title="Feedback" href="mailto:support@beam.eco" />}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "var(--size-medium)" }}>
        <div id="beam">
          <Card
            title="About Beam"
            data-cy="about-beam-card"
            className={styles.card}
            titleIcon={<AboutIcon icon={<Beam />} />}
            titleTrailing={<BeamTwitterButton />}
          >
            <TextBlock>
              <BodyText>
                Beam is a global, digital wallet that lets you send money to anyone, anywhere, instantly.
              </BodyText>
              <BodyText>
                You can send real money with a link over any messenger app, or you can scan someone&apos;s Beam Code and
                send that way.
              </BodyText>
              <BodyText>
                We call Beam &quot;digital payments that <em>just work.</em>&quot; It&apos;s as easy as cash, but over
                the internet.
              </BodyText>
              <ExpandSection scrollOnExpand={false}>
                <BodyText>
                  But it also means you need to be careful. Just like if you handed someone your physical wallet, giving
                  someone access to your Beam account means they can make irreversible transactions with your money.
                </BodyText>
                <BodyText>
                  If you have any feedback or questions for us, please don&apos;t hesitate to{" "}
                  <Link to="/support">reach out</Link>.
                </BodyText>
              </ExpandSection>
            </TextBlock>
          </Card>
        </div>

        <div id="eco">
          <Card
            title="About Eco"
            data-cy="about-eco-card"
            className={styles.card}
            titleIcon={<AboutIcon icon={<Eco />} />}
            highlight={focusedEl === "eco"}
            titleTrailing={<EcoTwitterButton />}
          >
            <TextBlock>
              <BodyText>
                Eco is a cryptocurrency designed to maintain its purchasing power over time. To help sustain its value,
                the Eco Currency (ECO) has an internal inflation rate set by governance, which may change every two
                weeks. The inflation rate may be positive or negative, and your ECO balance will automatically adjust
                when the rate changes.
              </BodyText>
              <BodyText>
                If you want to learn more about the Eco Currency system, we encourage you to read more at{" "}
                <Link to="https://eco.org">eco.org</Link>.
              </BodyText>
            </TextBlock>
          </Card>
        </div>
        {!hasNUXStatus ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Spinner />
          </div>
        ) : (
          !isNUXComplete && (
            <Card
              title="Get started with Beam"
              subtitle="With just a couple simple steps"
              data-cy="get-started-card"
              titleIcon={<AboutIcon icon={<Rocket />} />}
            >
              <div className={styles.start}>
                <StartStep
                  title="Create account"
                  linkRoute="/account"
                  step={1}
                  cyLabel="get-started-step1"
                  isComplete={hasSavedAccess}
                  body={
                    <>
                      <BodyText small light>
                        Connect your email or X account and add a Beam-specific password. This will ensure you can
                        always log in and access your money.
                      </BodyText>
                    </>
                  }
                />

                <StartStep
                  title="Need ECO or USDC?"
                  linkRoute="/deposit"
                  step={2}
                  cyLabel="get-started-step2"
                  isComplete={hasSomeBalance}
                  body={
                    <>
                      <BodyText small light>
                        You can <b>purchase USDC</b> and hold it or send it, and also can <b>convert USDC to ECO</b>.
                      </BodyText>
                      <BodyText small light>
                        Or ask a friend who already uses Beam to send you some!
                      </BodyText>
                    </>
                  }
                />

                <StartStep
                  title="Send someone money"
                  linkRoute="/t/usd/send"
                  isComplete={!!lastTransfer}
                  step={3}
                  cyLabel="get-started-step3"
                  body={
                    <>
                      <BodyText small light>
                        Scan a friend&apos;s Beam Code, or create a Beam Link and text it to someone who hasn&apos;t
                        even seen Beam yet.
                      </BodyText>
                      <BodyText small light>
                        You can send either ECO or USDC.
                      </BodyText>
                    </>
                  }
                />

                <StartStep
                  title="Register a Beam Name"
                  linkRoute="/account"
                  step={4}
                  isComplete={beamname?.exists}
                  cyLabel="get-started-step4"
                  isOptional
                  body={
                    <>
                      <BodyText small light>
                        Beam Names cost 600 ECO, and make it so your friends can easily find you and send you money.
                      </BodyText>
                      <BodyText small light>
                        And that name is yours forever. Nobody can take it away from you.
                      </BodyText>
                    </>
                  }
                />
              </div>
            </Card>
          )
        )}
        <div id="faq">
          <Card title="FAQ" data-cy="FAQ-card" titleIcon={<AboutIcon icon={<FAQ />} />}>
            <div className={styles.faq} data-cy="faq-emojis-section">
              <ExpandSection
                toggleElement={isExpanded => (
                  <Toggle
                    includeBorder={false}
                    title="What are those emojis?"
                    isExpanded={isExpanded}
                    dataCy="faq-emojis-header"
                  />
                )}
                persistToggle
              >
                <div className={styles.faqBody}>
                  <TextBlock>
                    <BodyText light data-cy="faq-emojis-text1">
                      Before you register a Beam Name, your address is identified by a string of 5 emojis. This helps
                      you make sure you&apos;re sending money to the right person — when you&apos;re sending to someone
                      who doesn&apos;t have a Beam Name, you can ask “okay, your emoji string is 🚖💤🗼🍊🏗️, right?”
                    </BodyText>
                    <BodyText light data-cy="faq-emojis-text2">
                      Most crypto payments products primarily use long, confusing “addresses” like
                      0xd8da6bf26964af9d7eed9e03e53415d37aa96045. We like emojis more.
                    </BodyText>
                    <BodyText light data-cy="faq-emojis-text3">
                      Note for advanced users: the emoji string is not unique among all Ethereum addresses — it only
                      encodes the first 6 and last 6 characters of your address. This is sufficient for typical purposes
                      of confirming a user is sending to the right person, but is insufficient for reproducing an
                      address from the emojis, or for guaranteeing uniqueness.
                    </BodyText>
                  </TextBlock>
                </div>
              </ExpandSection>
              <ExpandSection
                toggleElement={isExpanded => (
                  <Toggle title="What are the fees for using Beam?" isExpanded={isExpanded} dataCy="faq-fees-header" />
                )}
                persistToggle
              >
                <div className={styles.faqBody} data-cy="faq-fees-section">
                  <TextBlock>
                    <BodyText light data-cy="faq-fees-text1">
                      Beam passes through fees charged by networks like Optimism and Base for transactions. For ease of
                      use and simplicity these are passed through as fixed fees. It doesn&apos;t matter if you&apos;re
                      sending $10,000 or $10, network fees are the same.
                    </BodyText>
                    <BodyText light data-cy="faq-fees-text2">
                      Currently, the fee for sending an ECO transaction is 20 ECO (roughly $0.30 as of July 2023), and
                      the fee for sending a USDC transaction is 0.50 USDC ($0.50).
                    </BodyText>
                  </TextBlock>
                </div>
              </ExpandSection>
              <ExpandSection
                toggleElement={isExpanded => (
                  <Toggle
                    title="How secure are my funds in Beam?"
                    isExpanded={isExpanded}
                    dataCy="faq-security-header"
                  />
                )}
                persistToggle
                data-cy="faq-security-section"
              >
                <div className={styles.faqBody} data-cy="faq-security-section">
                  <TextBlock>
                    <BodyText light data-cy="faq-security-text1">
                      Beam is for payments, not for holding significant funds long-term. Treat it like a change purse —
                      a place you put cash that you plan to pay someone soon. We make Beam as secure as we can, but it’s
                      still a payments wallet.
                    </BodyText>
                    <BodyText light data-cy="faq-security-text2">
                      No Beam server ever has access to the key to your account, but that key is stored on your device.
                      That means that if someone has access to your device, they have access to the funds in your Beam
                      account.
                    </BodyText>
                    <BodyText light data-cy="faq-security-text3">
                      If you’re holding significant funds, we recommend using a more secure wallet — preferably a
                      hardware wallet like a Ledger, or even a generalized wallet like Metamask.
                    </BodyText>
                    <BodyText light data-cy="faq-security-text4">
                      Beam makes an intentional tradeoff: making it really easy to pay and transact, but at the cost of
                      some security.
                    </BodyText>
                  </TextBlock>
                </div>
              </ExpandSection>
              <ExpandSection
                toggleElement={isExpanded => (
                  <Toggle
                    title="What are Beam Names and why do they cost money?"
                    isExpanded={isExpanded}
                    dataCy="faq-beamnames-header"
                  />
                )}
                persistToggle
              >
                <div className={styles.faqBody} data-cy="faq-beam-names-section">
                  <TextBlock>
                    <BodyText light data-cy="faq-beam-names-text1">
                      Beam Names are our version of usernames — but instead of them being controlled by us, they&apos;re
                      truly ownable by you, on the blockchain. When you register a Beam Name, it&apos;s yours — we
                      can&apos;t ever take it away from you, even if we wanted to.
                    </BodyText>
                    <BodyText light data-cy="faq-beam-names-text2">
                      Think of Beam Names like domain names, in a sense — unique, valuable digital assets.
                    </BodyText>
                    <BodyText light data-cy="faq-beam-names-text3">
                      You can also transfer Beam Names, which means you could register one and sell it to someone else.
                    </BodyText>
                    <BodyText light data-cy="faq-beam-names-text4">
                      We believe Beam Names are a sort of “digital real estate,” and people should be able to pay for
                      ones they want.
                    </BodyText>
                  </TextBlock>
                </div>
              </ExpandSection>
              <ExpandSection
                toggleElement={isExpanded => (
                  <Toggle
                    title="Can the Beam team access my funds?"
                    isExpanded={isExpanded}
                    dataCy="faq-team-access-header"
                  />
                )}
                persistToggle
                data-cy="faq-team-access-section"
              >
                <div className={styles.faqBody}>
                  <TextBlock>
                    <BodyText light data-cy="faq-team-access-text1">
                      We cannot. Beam is not a custodial service, and so we have no way of accessing your account or
                      funds. You are entirely responsible for maintaining access to your Beam wallet and managing your
                      own funds.
                    </BodyText>
                    <BodyText light data-cy="faq-team-access-text2">
                      If you lose access, we can&apos;t do anything about it even if we wanted to. That&apos;s the
                      downside of building a service where you are truly in control of your money. Yes, we can&apos;t
                      help you rescue your funds — but your wallet is ultimately your own, and we can&apos;t freeze your
                      funds or prevent you from interacting with blockchain networks using your wallet.
                    </BodyText>
                  </TextBlock>
                </div>
              </ExpandSection>
            </div>
          </Card>
        </div>
        <Card title="Beam Support" titleIcon={<AboutIcon icon={<Help />} />}>
          <div style={{ display: "flex", flexDirection: "column", gap: "var(--size-medium)" }}>
            <TextBlock>
              <BodyText>If you have any questions or feedback, please send us an email.</BodyText>
            </TextBlock>
            <Button href="mailto:support@beam.eco" title="Contact Beam" />
          </div>
        </Card>
      </div>
    </View>
  );
};
