import { ethers } from "ethers";
import { CurrencyAmount, Percent, Token as UniToken, TradeType } from "@uniswap/sdk-core";
import {
  computePoolAddress,
  FeeAmount,
  Pool,
  Route,
  SwapOptions,
  SwapQuoter,
  SwapRouter,
  Trade,
} from "@uniswap/v3-sdk";
import * as Sentry from "@sentry/react";
import { isEqual } from "lodash";
import * as Batcher from "@yornaath/batshit";

import { getNetwork, getTokenInfo, MULTICALL3_ADDRESS, Network, Token, UserToken } from "@constants";

import { getNetworkProvider } from "@modules/blockchain/providers";
import { Multicall3__factory } from "@assets/contracts";
import { calculateTransferAmounts } from "@helpers/transfer";

import Quoter from "@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json";
import IUniswapV3PoolABI from "@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json";

interface ITransfer {
  token: Token;
  amount: ethers.BigNumber;
}

interface UniPool {
  tokenA: Token;
  tokenB: Token;
  fee: FeeAmount;
  network: Network;
}

export class AutoSwap {
  static readonly SWAPPABLE_TOKENS = [Token.USDC, Token.USDT, Token.USDCe, Token.USDV];

  static readonly POOLS: Record<string, Pool> = {};
  static UNI_POOLS: UniPool[] = [
    { network: "optimism", tokenA: Token.USDC, tokenB: Token.USDCe, fee: FeeAmount.LOWEST },
    { network: "optimism", tokenA: Token.USDC, tokenB: Token.USDT, fee: FeeAmount.LOWEST },
    { network: "optimism", tokenA: Token.USDCe, tokenB: Token.USDT, fee: FeeAmount.LOWEST },
    { network: "optimism", tokenA: Token.USDT, tokenB: Token.USDV, fee: FeeAmount.MEDIUM },
  ];
  private batcher: Batcher.Batcher<
    { success: boolean; returnData: string; key: string }[],
    ethers.PopulatedTransaction,
    { success: boolean; returnData: string; key: string }
  > = null!;

  constructor(
    public readonly userToken: UserToken,
    public readonly network: Network = "optimism",
    private readonly balances: Record<Token, string>,
  ) {}

  static getPoolAddress(uniPool: UniPool) {
    return computePoolAddress({
      fee: uniPool.fee,
      tokenA: AutoSwap.getUniswapToken(uniPool.network, uniPool.tokenA),
      tokenB: AutoSwap.getUniswapToken(uniPool.network, uniPool.tokenB),
      factoryAddress: AutoSwap.getPoolFactoryAddress(uniPool.network),
    });
  }

  private static getPoolFactoryAddress(network: Network) {
    switch (network) {
      case "base":
        return "0x33128a8fC17869897dcE68Ed026d694621f6FDfD";
      case "optimism":
        return "0x1F98431c8aD98523631AE4a59f267346ea31F984";
    }
  }

  private static getUniswapToken(network: Network, token: Token) {
    const { chainId } = getNetwork(network);
    const { address, decimals, name } = getTokenInfo(token, network);
    return new UniToken(chainId, address, decimals, name);
  }

  isEnabled() {
    return this.network === "optimism" && this.userToken === UserToken.USD;
  }

  async getPool(uniPool: UniPool) {
    const poolAddr = AutoSwap.getPoolAddress(uniPool);
    if (AutoSwap.POOLS[poolAddr]) return AutoSwap.POOLS[poolAddr];

    const poolInfo = await this.getPoolData(poolAddr);

    const pool = new Pool(
      AutoSwap.getUniswapToken(this.network, uniPool.tokenA),
      AutoSwap.getUniswapToken(this.network, uniPool.tokenB),
      uniPool.fee,
      poolInfo.sqrtPriceX96.toString(),
      poolInfo.liquidity.toString(),
      poolInfo.tick,
    );

    AutoSwap.POOLS[poolAddr] = pool;

    return pool;
  }

  getBatcher() {
    if (!this.batcher) {
      const genHash = (tx: ethers.PopulatedTransaction): string => [tx.data, tx.to].join("-");
      this.batcher = Batcher.create({
        fetcher: async (calls: ethers.PopulatedTransaction[]) => {
          const provider = getNetworkProvider(this.network);
          const multicall = Multicall3__factory.connect(MULTICALL3_ADDRESS, provider);
          return multicall.callStatic
            .aggregate3(
              calls.map(request => ({
                callData: request.data!,
                target: request.to!,
                allowFailure: false,
              })),
            )
            .then(results =>
              results.map((result, index) => ({
                success: result.success,
                returnData: result.returnData,
                key: genHash(calls[index]),
              })),
            );
        },
        resolver: (results, call) => results.find(result => result.key === genHash(call))!,
        scheduler: Batcher.windowScheduler(100),
      });
    }
    return this.batcher;
  }

  async getPoolData(poolAddress: string) {
    const provider = getNetworkProvider(this.network);
    const poolContract = new ethers.Contract(poolAddress, IUniswapV3PoolABI.abi, provider);

    const batcher = this.getBatcher();

    const response = await Promise.all([
      batcher.fetch(await poolContract.populateTransaction.fee()),
      batcher.fetch(await poolContract.populateTransaction.liquidity()),
      batcher.fetch(await poolContract.populateTransaction.slot0()),
    ]);

    const [fee, liquidity, slot0] = [
      poolContract.interface.decodeFunctionResult("fee", response[0].returnData),
      poolContract.interface.decodeFunctionResult("liquidity", response[1].returnData),
      poolContract.interface.decodeFunctionResult("slot0", response[2].returnData),
    ];

    return {
      fee,
      liquidity,
      sqrtPriceX96: slot0[0],
      tick: slot0[1],
    };
  }

  async getQuotePopulate(tokenA: Token, tokenB: Token, amount: ethers.BigNumberish) {
    const uniTokenA = AutoSwap.getUniswapToken(this.network, tokenA);
    const uniTokenB = AutoSwap.getUniswapToken(this.network, tokenB);

    const route = await this.getRoute(tokenA, tokenB);
    const pools = await Promise.all(route.map(this.getPool.bind(this)));
    const swapRoute = new Route(pools, uniTokenA, uniTokenB);

    const { calldata } = SwapQuoter.quoteCallParameters(
      swapRoute,
      CurrencyAmount.fromRawAmount(uniTokenA, amount.toString()),
      TradeType.EXACT_INPUT,
      { useQuoterV2: true },
    );

    return { data: calldata, to: this.getQuoterAddress() };
  }

  async createTrade(tokenA: Token, tokenB: Token, amount: ethers.BigNumberish, amountOut: ethers.BigNumberish) {
    const uniTokenA = AutoSwap.getUniswapToken(this.network, tokenA);
    const uniTokenB = AutoSwap.getUniswapToken(this.network, tokenB);

    const route = await this.getRoute(tokenA, tokenB);
    const pools = await Promise.all(route.map(this.getPool.bind(this)));
    const swapRoute = new Route(pools, uniTokenA, uniTokenB);

    return Trade.createUncheckedTrade({
      route: swapRoute,
      inputAmount: CurrencyAmount.fromRawAmount(uniTokenA, amount.toString()),
      outputAmount: CurrencyAmount.fromRawAmount(uniTokenB, amountOut.toString()),
      tradeType: TradeType.EXACT_INPUT,
    });
  }

  async swap(trades: Trade<UniToken, UniToken, TradeType.EXACT_INPUT>[], recipient: string) {
    const options: SwapOptions = {
      slippageTolerance: new Percent(10, 10_000), // 50 bips, or 0.50%
      deadline: Math.floor(Date.now() / 1000) + 60 * 3, // 3 minutes from the current Unix time
      recipient: recipient,
    };

    return trades.map(trade => {
      const methodParameters = SwapRouter.swapCallParameters(trade, options);
      return {
        to: this.getSwapRouterAddress(),
        data: methodParameters.calldata,
      };
    });
  }

  getSwapRouterAddress() {
    const { id } = getNetwork(this.network);
    switch (id) {
      case "optimism":
        return "0xE592427A0AEce92De3Edee1F18E0157C05861564";
      default:
        throw new Error(`quoter address for network ${id} not defined`);
    }
  }

  async getRoute(tokenA: Token, tokenB: Token, checkedPools: UniPool[] = []): Promise<UniPool[]> {
    const pools = AutoSwap.UNI_POOLS.filter(pool => pool.network === this.network).filter(
      pool => !checkedPools.some(checkedPool => isEqual(checkedPool, pool)),
    );

    // One pool route
    const exactPool = pools.find(pool => {
      const pair = [pool.tokenA, pool.tokenB];
      return pair.includes(tokenA) && pair.includes(tokenB);
    });
    if (exactPool) return [exactPool];

    const routes: UniPool[][] = [];
    for (const pool of pools) {
      const pair = [pool.tokenA, pool.tokenB];
      if (pair.includes(tokenA)) {
        const otherToken = pair[0] === tokenA ? pair[1] : pair[0];
        const innerRoute = await this.getRoute(otherToken, tokenB, [...checkedPools, pool]);
        if (innerRoute.length) {
          const route = [pool, ...innerRoute];
          routes.push(route);
        }
      }
    }

    if (!routes.length) {
      Sentry.captureMessage(`Swap route not defined for ${tokenA}/${tokenB}`, "error");
      console.warn(`Swap route not defined for ${tokenA}/${tokenB}`);
      throw new Error(`Route not found for ${tokenA}/${tokenB}`);
    }

    routes.sort((a, b) => (a.length > b.length ? 1 : -1));
    return routes[0];
  }

  getBalances() {
    return this.balances;
  }

  getSwappableTokenAmounts(token: Token, balances = this.getBalances()) {
    return this.getAvailableTokens(token, balances)
      .map((token): ITransfer => ({ token, amount: ethers.BigNumber.from(balances[token]) }))
      .sort((left, right) => (left.amount.lt(right.amount) ? 1 : -1));
  }

  async calculateSwapAmount(
    amount: ethers.BigNumberish,
    token: Token,
    opts: {
      maxSwapAmount?: ethers.BigNumber;
      bips?: number;
      retries?: number;
    } = {},
  ): Promise<{
    trades: Trade<UniToken, UniToken, TradeType.EXACT_INPUT>[];
    swapInAmounts: ITransfer[];
    swapOutAmounts: ITransfer[];
  }> {
    const { maxSwapAmount, bips = 14 } = opts;
    let { retries = 3 } = opts;

    // Add 0.014% to total to account for pool fee + slippage
    let swapAmount = ethers.BigNumber.from(amount)
      .mul(100_000 + bips)
      .div(100_000);

    if (maxSwapAmount && swapAmount.gt(maxSwapAmount)) {
      retries = 0;
      swapAmount = maxSwapAmount;
    }

    const trades = await this.getTrades(token, swapAmount);

    const swapOutAmount = trades.swapOutAmounts.reduce((acc, { amount }) => acc.add(amount), ethers.constants.Zero);

    if (swapOutAmount.lt(amount)) {
      if (!retries) throw new Error("Not enough USD after swaps");
      return this.calculateSwapAmount(amount, token, { ...opts, retries: retries - 1, bips: bips * 2 });
    }

    return trades;
  }

  async getTrades(tokenOut: Token, amount: ethers.BigNumber) {
    const balances = this.getBalances();
    const tokenBalances = this.getSwappableTokenAmounts(tokenOut, balances);

    const amounts = calculateTransferAmounts(
      amount,
      ethers.constants.Zero,
      tokenBalances.map(item => item.amount),
    ).map((transfer, index) => ({ token: tokenBalances[index].token, ...transfer }));

    const swapBalances = amounts.reduce((acc, swap) => {
      if (!swap.amount.isZero()) {
        acc[swap.token] = swap.amount.toString();
      }
      return acc;
    }, {} as Record<Token, string>);

    const quotes = await this.getQuote(tokenOut, swapBalances);

    const swapInAmounts: ITransfer[] = amounts.filter(swap => !swap.amount.isZero());

    const tradeRequests = swapInAmounts.map(swap =>
      this.createTrade(swap.token, tokenOut, swap.amount, quotes[swap.token]),
    );

    const trades = await Promise.all(tradeRequests);

    const swapOutAmounts = Object.keys(quotes).map(
      (tokenIn): ITransfer => ({
        token: tokenOut as Token,
        amount: quotes[tokenIn as Token],
      }),
    );

    return { trades, swapInAmounts, swapOutAmounts };
  }

  async getQuote(token: Token, balances: Partial<Record<Token, string>>): Promise<Record<Token, ethers.BigNumber>> {
    const provider = getNetworkProvider(this.network);
    const multicall = Multicall3__factory.connect(MULTICALL3_ADDRESS, provider);

    const quotesFor = this.getAvailableTokens(token, balances);

    const quoteCalls = await Promise.all(
      quotesFor.map(tokenFrom => this.getQuotePopulate(tokenFrom, token, balances[tokenFrom]!)),
    );
    const quotesResponses = await multicall.callStatic.aggregate3(
      quoteCalls.map(call => ({ callData: call.data!, target: call.to!, allowFailure: true })),
    );

    const quoterInterface = new ethers.utils.Interface(Quoter.abi);
    const quoteEntries = quotesResponses
      .map((response, index) => {
        if (!response.success) {
          console.warn(`quoteExactInputSingle unsuccessful for token ${quotesFor[index]} to token ${token}`);
          return ethers.constants.Zero;
        }
        return quoterInterface.decodeFunctionResult("quoteExactInputSingle", response.returnData).amountOut;
      })
      .map((quote, index) => [quotesFor[index], quote]);

    return Object.fromEntries(quoteEntries);
  }

  private getQuoterAddress() {
    const { id } = getNetwork(this.network);
    switch (id) {
      case "optimism":
        return "0x61fFE014bA17989E743c5F6cB21bF9697530B21e";
      default:
        throw new Error(`quoter address for network ${id} not defined`);
    }
  }

  private getAvailableTokens(token: Token, balances: Partial<Record<Token, string>>) {
    return AutoSwap.SWAPPABLE_TOKENS.filter(swappable => token !== swappable).filter(
      token => !ethers.BigNumber.from(balances[token] ?? 0).isZero(),
    );
  }
}
