import React, { ReactNode } from "react";

import { toast, TypeOptions, Id } from "react-toastify";

import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";

interface NotifyProps {
  content: ReactNode | string;
  isPersistent?: boolean;
  type?: TypeOptions;
  duration?: number;
  id?: Id;
  closeable?: boolean;
  onClose?: () => void;
}

export const notify = ({
  content,
  isPersistent = false,
  type = "success",
  duration = 1_500,
  id,
  closeable = false,
  onClose,
}: NotifyProps) => {
  const handleClose = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    closeToast: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ) => {
    if (onClose) {
      onClose();
    }
    closeToast(e);
  };

  return toast(content, {
    containerId: isPersistent ? "persistent" : "temporary",
    autoClose: isPersistent ? false : duration,
    closeButton: closeable
      ? ({ closeToast }) => (
          <div style={{ cursor: "pointer", display: "flex" }} onClick={e => handleClose(e, closeToast)}>
            <CloseIcon />
          </div>
        )
      : false,
    type,
    toastId: id,
  });
};
