import { BigNumberish, ethers } from "ethers";

import {
  IClient,
  IClientOpts,
  ISendUserOperationOpts,
  IUserOperationBuilder,
  UserOperationMiddlewareCtx,
} from "userop";
import { OpToJSON } from "userop/dist/utils";
import { ERC4337 } from "userop/dist/constants";
import { EntryPoint, EntryPoint__factory } from "userop/dist/typechain";

import { BundlerJsonRpcProvider } from "@modules/smart-wallet/BundlerJsonRpcProvider";

export class Client implements IClient {
  private provider: ethers.providers.JsonRpcProvider;

  public entryPoint: EntryPoint;
  public chainId: BigNumberish;
  public waitTimeoutMs: number;
  public waitIntervalMs: number;

  private constructor(rpcUrl: string, opts?: IClientOpts & { backupBundlerRpc?: string; chainId?: number }) {
    this.provider = new BundlerJsonRpcProvider(rpcUrl, opts?.chainId)
      .setBundlerRpc(opts?.overrideBundlerRpc)
      .setBackupBundlerRpc(opts?.backupBundlerRpc);
    this.entryPoint = EntryPoint__factory.connect(opts?.entryPoint || ERC4337.EntryPoint, this.provider);
    this.chainId = ethers.BigNumber.from(1);
    this.waitTimeoutMs = 60_000;
    this.waitIntervalMs = 2_000;
  }

  public static async init(rpcUrl: string, opts?: IClientOpts & { backupBundlerRpc?: string; chainId?: number }) {
    const instance = new Client(rpcUrl, opts);
    instance.chainId = ethers.BigNumber.from(
      opts?.chainId ?? (await instance.provider.getNetwork().then(network => network.chainId)),
    );

    return instance;
  }

  async buildUserOperation(builder: IUserOperationBuilder) {
    return builder.buildOp(this.entryPoint.address, this.chainId);
  }

  async sendUserOperation(builder: IUserOperationBuilder, opts?: ISendUserOperationOpts) {
    const dryRun = Boolean(opts?.dryRun);
    const op = await this.buildUserOperation(builder);
    opts?.onBuild?.(op);

    const userOpHash = dryRun
      ? new UserOperationMiddlewareCtx(op, this.entryPoint.address, this.chainId).getUserOpHash()
      : ((await this.provider.send("eth_sendUserOperation", [OpToJSON(op), this.entryPoint.address])) as string);
    builder.resetOp();

    return {
      userOpHash,
      wait: async () => {
        if (dryRun) {
          return null;
        }

        const end = Date.now() + this.waitTimeoutMs;
        const blockNumber = await this.provider.getBlockNumber();
        while (Date.now() < end) {
          const events = await this.entryPoint.queryFilter(
            this.entryPoint.filters.UserOperationEvent(userOpHash),
            Math.max(0, blockNumber - 100),
          );
          if (events.length > 0) {
            return events[0];
          }
          await new Promise(resolve => setTimeout(resolve, this.waitIntervalMs));
        }

        return null;
      },
    };
  }
}
