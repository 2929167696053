import React from "react";

import "./Badge.css";
import classNames from "classnames";

interface BadgeProps extends Omit<React.HTMLProps<HTMLDivElement>, "size" | "style"> {
  icon?: React.ReactNode;
  text: React.ReactNode;
  size?: "small" | "middle";
  style?: "outline";
}

export const Badge: React.FC<BadgeProps> = ({ icon, text, size = "middle", style, ...props }) => {
  return (
    <div
      className={classNames("Badge", {
        "Badge-small": size === "small",
        "Badge-middle": size === "middle",
        "Badge-outline": style === "outline",
      })}
      {...props}
    >
      {icon}
      {text}
    </div>
  );
};
