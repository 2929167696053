import { ethers } from "ethers";
import { cloneDeep } from "lodash";

import { BalancesState } from "@redux/slides/balances/balances.types";

import { getTokenInfo, Network, Token, UserToken } from "@constants";
import { TokenManager } from "@modules/token-managers/TokenManager";
import { getTokenManager } from "@modules/token-managers/tokens";
import { calculateTransferAmounts } from "@helpers/transfer";
import { ERC20__factory } from "@assets/contracts";

export interface TransferOpts<T = unknown> {
  from: string;
  fee?: ethers.BigNumberish;
  amount?: ethers.BigNumberish;

  userToken: UserToken;
  acceptedTokens?: Token[];
  acceptedTokensForFee?: Token[];
  destinationNetwork: Network;
  balances: BalancesState["networks"];

  transformations?: T;
}

export interface TokenTransfer {
  token: Token;
  network: Network;
  amount: ethers.BigNumberish;
}

export class Transfer<Transformations extends Record<string, unknown>> {
  protected readonly from: TransferOpts["from"];
  protected readonly tokenManager: TokenManager;
  protected readonly fee: ethers.BigNumberish;
  protected readonly userToken: TransferOpts["userToken"];
  protected readonly acceptedTokens: Token[];
  protected readonly acceptedTokensForFee: Token[];
  protected readonly destinationNetwork: TransferOpts["destinationNetwork"];
  protected readonly balances: BalancesState["networks"];

  protected amount: TransferOpts["amount"];

  protected readonly transformations: (() => Promise<unknown>)[] = [];
  protected readonly transformationTxs: { to: string; data: string }[] = [];

  constructor(opts: TransferOpts<Transformations>) {
    this.tokenManager = getTokenManager(opts.userToken);
    const {
      from,
      amount,
      fee = 0,
      userToken,
      destinationNetwork,
      acceptedTokens = this.tokenManager.composedBy,
      acceptedTokensForFee = this.tokenManager.composedBy,
      balances,
    } = opts;
    this.from = from;
    this.amount = amount;
    this.fee = fee;
    this.userToken = userToken;
    this.balances = cloneDeep(balances);
    this.acceptedTokens = acceptedTokens;
    this.acceptedTokensForFee = acceptedTokensForFee;
    this.destinationNetwork = destinationNetwork;
  }

  static getTransferTx(transfer: TokenTransfer, recipient: string): { to: string; data: string } {
    const data = ERC20__factory.createInterface().encodeFunctionData("transfer", [recipient, transfer.amount]);
    const to = getTokenInfo(transfer.token, transfer.network).address;
    return { to, data };
  }

  async run() {
    let fee: TokenTransfer | undefined;
    if (this.fee) {
      fee = this.getFeeTransfer(this.fee);
      this.subtractBalance(fee);
    }

    const transfers = await this.getTransfers();

    // Update local balances
    transfers.forEach(this.subtractBalance.bind(this));

    return { transfers, fee, transformations: this.transformationTxs };
  }

  getBalances() {
    const tokenManager = getTokenManager(this.userToken);
    const { balances } = this.balances[this.destinationNetwork];
    const pairs = Object.entries(balances).filter(pair => tokenManager.composedBy.includes(pair[0] as Token));
    return Object.fromEntries(pairs) as Record<Token, string>;
  }

  getAvailableBalance() {
    return this.formatBalances(this.acceptedTokens, this.balances).sort(this.sortByBalance);
  }

  setAmount(amount: ethers.BigNumberish) {
    this.amount = amount;
  }

  isBalanceEnough() {
    if (!this.amount) throw new Error("Amount is undefined");
    return this.sumAmounts(this.getAvailableBalance()).gte(this.amount);
  }

  /**
   * Perform transformation to meet transfer requirements
   */
  async executeTransformations() {
    for (const transformation of this.transformations) {
      if (this.isBalanceEnough()) break;
      await transformation();
    }
  }

  async getTransfers(): Promise<TokenTransfer[]> {
    await this.executeTransformations();

    // Check if balance is enough after transformations
    if (!this.isBalanceEnough()) throw new Error("Not enough balance");

    const balances = this.getAvailableBalance();
    const transfers = calculateTransferAmounts(
      ethers.BigNumber.from(this.amount),
      ethers.constants.Zero,
      balances.map(balance => ethers.BigNumber.from(balance.amount)),
    ).filter(transfer => !transfer.amount.isZero());

    return transfers.map((transfer, index) => ({
      token: balances[index].token,
      amount: transfer.amount,
      network: balances[index].network,
    }));
  }

  /**
   * Get fee operation
   * @param fee
   */
  getFeeTransfer(fee: ethers.BigNumberish): TokenTransfer {
    const balances = this.formatBalances(this.acceptedTokensForFee, this.balances)
      .sort(this.sortByBalance)
      .sort(this.sortByExclusion(this.acceptedTokens, transfer => transfer.token));

    const transfers = calculateTransferAmounts(
      ethers.constants.Zero,
      ethers.BigNumber.from(fee),
      balances.map(balance => ethers.BigNumber.from(balance.amount)),
    );
    const index = transfers.findIndex(transfer => !transfer.fee.isZero());
    if (index < 0) throw new Error("getFeeTransfer invalid amount");
    const transfer = transfers[index];
    const balance = balances[index];
    return {
      amount: transfer.fee,
      network: balance.network,
      token: balance.token,
    };
  }

  formatBalances(tokens: Token[], balances: TransferOpts["balances"]): TokenTransfer[] {
    return tokens.map(token => ({
      token,
      network: this.destinationNetwork,
      amount: balances![this.destinationNetwork].balances[token],
    }));
  }

  /**
   * Sort by amount
   * @param itemA - item A
   * @param itemB - item B
   */
  sortByBalance<T extends { amount: ethers.BigNumberish }>(itemA: T, itemB: T) {
    return ethers.BigNumber.from(itemA.amount).gt(itemB.amount) ? -1 : 1;
  }

  protected addBalance(transfer: TokenTransfer) {
    return this.modifyBalance(transfer, "add");
  }

  protected subtractBalance(transfer: TokenTransfer) {
    return this.modifyBalance(transfer, "sub");
  }

  /**
   * Return the listA sorted. An item not found in listB is moved to the beginning of the list
   * @param listB - list B of elements
   * @param convert - convert data to list item type
   * @private
   */
  private sortByExclusion<ListItem = unknown, T = unknown>(
    listB: T[],
    convert: (_: ListItem) => T = (_: ListItem) => _ as unknown as T,
  ) {
    return (itemA: ListItem, itemB: ListItem) => {
      const foundAInList = listB.includes(convert(itemA));
      const foundBInList = listB.includes(convert(itemB));
      if (foundAInList === foundBInList) return 0;
      if (foundAInList && !foundBInList) return 1;
      return -1;
    };
  }

  private sumAmounts<T extends { amount: ethers.BigNumberish }>(items: T[]): ethers.BigNumber {
    return items.reduce((acc, item) => acc.add(item.amount), ethers.constants.Zero);
  }

  /**
   * Modify balance
   * @param transfer - token transfer
   * @param action - subtract or add
   * @private
   */
  private modifyBalance(transfer: TokenTransfer, action: "sub" | "add") {
    const value = this.balances[transfer.network]!.balances[transfer.token];
    this.balances[transfer.network]!.balances[transfer.token] = ethers.BigNumber.from(value)
      [action](transfer.amount)
      .toString();
  }
}
