import React, { ReactNode } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { useSidebar } from "@components/sidebar/Sidebar";

import { ReactComponent as LinkChevron } from "@assets/icons/link_chevron.svg";

import { motion } from "framer-motion";

import "./Sidebar.css";
import { animationCurveEaseOut, animationDurationFastS } from "@constants/animations";
import Row from "@components/row/Row";

interface NavRowProps {
  to: string;
  title: string;
  icon: ReactNode;
  isSelected?: boolean;
  badge?: ReactNode;
}

export const SidebarLink: React.FC<NavRowProps> = ({ to, title, icon, isSelected, badge }) => {
  const navigate = useNavigate();
  const { setOpen } = useSidebar();
  const dataCyValue = `SidebarLink-${title.replace(/\s+/g, "-")}`;

  const onClick = () => {
    navigate(to);
    setOpen(false);
  };

  return (
    <motion.div
      layout
      transition={{
        ease: animationCurveEaseOut,
        duration: animationDurationFastS,
      }}
    >
      <Row
        dataCy={dataCyValue}
        onClick={onClick}
        className={classNames("Sidebar_menuItem", { selected: isSelected })}
        hasHorizontalPadding={false}
        leadingContent={<div className="Sidebar_menuIcon">{icon}</div>}
        trailingContent={
          <div className="Sidebar_menuItemDisclosure">
            {badge}
            <LinkChevron />
          </div>
        }
      >
        <label>{title}</label>
      </Row>
    </motion.div>
  );
};
