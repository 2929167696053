import { Space } from "antd";
import { ethers } from "ethers";
import React from "react";

import { RegisterForm } from "@components/account/beamnames/RegisterForm";
import Card from "@components/card/Card";
import { BodyText } from "@components/text/Text";

import { ReactComponent as LinkChevron } from "@assets/icons/link_chevron.svg";

import { Button } from "@components/button/Button";
import { useAccount } from "@contexts/AccountContext";

import { CopyButton } from "@components/button/CopyButton";
import { VendingMachineCard } from "@components/fund/VendingMachineCard";
import { useRegistrationFee } from "@hooks/useRegistrationFee";
import { isDesktop } from "react-device-detect";
import styles from "./BeamNamesCard.module.css";
import { Tooltip } from "@components/tooltip/Tooltip";

export const BeamnamesCard = () => {
  const { beamname, balances } = useAccount();
  const [isVendingMachineOpen, setVendingMachineOpen] = React.useState(false);

  const openLink = () => {
    window.open(`https://${beamname!.displayName}.beam.eco`, "_newtab");
  };

  const registrationFee = useRegistrationFee();
  const hasEnoughEco = registrationFee && !registrationFee.isZero() ? balances.eco.optimism.gte(registrationFee) : true;
  const buttonTitle = registrationFee
    ? isDesktop
      ? `${parseFloat(ethers.utils.formatEther(registrationFee)).toFixed(2)} ECO needed—Buy ECO`
      : "Buy Eco"
    : "";

  if (!beamname) {
    return <></>;
  }

  return (
    <>
      <Card
        title={!beamname.exists ? "Beam Name" : undefined}
        subtitle={!beamname.exists ? "Help others easily find you on Beam" : undefined}
        onClick={beamname.exists ? openLink : undefined}
        titleTrailing={
          !hasEnoughEco &&
          registrationFee && (
            <Button
              title={buttonTitle}
              size="small"
              secondary
              onClick={() => {
                setVendingMachineOpen(true);
              }}
            />
          )
        }
      >
        {beamname.exists ? (
          <Space direction="vertical" style={{ width: "100%" }}>
            <div
              style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "var(--size-medium)" }}
            >
              <div style={{ textAlign: "left", marginRight: "auto" }} data-cy="view-beam-profile-card">
                <BodyText bold>View Beam Profile</BodyText>
                <BodyText small light>
                  {beamname.displayName}.beam.eco
                </BodyText>
              </div>
              <Tooltip content="Copy your beam profile link">
                <CopyButton copyText={`${beamname?.displayName}.beam.eco`} />
              </Tooltip>
              <div className={styles.trailingChevron}>
                <LinkChevron />
              </div>
            </div>
          </Space>
        ) : (
          <RegisterForm />
        )}
      </Card>
      <VendingMachineCard open={isVendingMachineOpen} onClose={() => setVendingMachineOpen(false)} />
    </>
  );
};
