import { QuestionCircleFilled } from "@ant-design/icons";
import Upload from "antd/es/upload/Upload";
import type { RcFile, UploadProps } from "antd/es/upload/interface";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar } from "@components/avatar/Avatar";
import { LinkButton } from "@components/button/Button";
import { HeaderText } from "@components/text/Text";

import { useAccount } from "@contexts/AccountContext";
import { ProfileClient } from "@modules/profile/ProfileClient";

import { addressToEmoji, copy, logError, notify } from "@helpers";

import { WhyEmojiSheet } from "@components/WhyEmojiSheet";
import styles from "./Profile.module.css";

import { ReactComponent as MyCodeIcon } from "@assets/icons/my_code.svg";
import { BeamCodeSheet } from "@components/beamcodesheet/BeamCodeSheet";

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isJpgOrPng) {
    notify({ type: "error", content: "Only JPG or PNG images are allowed." });
  } else if (!isLt2M) {
    notify({ type: "error", content: "Image must smaller than 2 Megabytes." });
  }
  return isJpgOrPng && isLt2M;
};

interface ProfileProps {
  address?: string;
  name?: string;
  isProfilePictureSet?: boolean;
  canEdit?: boolean;
  nameIsEmoji: boolean;
  showQrHelper?: boolean;
}

export const Profile: React.FC<ProfileProps> = ({
  address,
  name,
  isProfilePictureSet = false,
  canEdit = false,
  nameIsEmoji,
  showQrHelper = true,
}) => {
  const { signer } = useAccount();
  const navigate = useNavigate();

  const [whyEmojisOpen, setWhyEmojisOpen] = useState(false);
  const [codeSheetOpen, setCodeSheetOpen] = useState(false);

  const copyName: React.MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    if (name) {
      copy(name, "Username copied.");
    }
  };

  const sendToS3: UploadProps["customRequest"] = async ({ file }) => {
    if (!address) return;

    const form = new FormData();

    try {
      const imageType = (file as RcFile).type === "image/jpeg" ? "JPEG" : "PNG";

      const profileClient = new ProfileClient(address, signer);
      const { data } = await profileClient.getProfilePictureSignedUrl(imageType);
      const { uploadUrl, postDataFields } = data;

      // append data to form
      Object.entries(postDataFields).forEach(([key, value]) => {
        form.append(key, value);
      });

      form.append("file", file);

      // upload file using presigned url
      await profileClient.setProfilePicture(uploadUrl, form);

      notify({ type: "success", content: "Profile Picture set." });
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.response || !error.request) {
        logError("[s3-profile-picture-upload]", error);
        notify({ type: "error", content: "Failed to upload profile picture." });
      } else {
        notify({ type: "success", content: "Profile Picture set." });
        setTimeout(() => navigate(0), 2000);
      }
    }
  };

  const nameElement = nameIsEmoji ? (
    <div onClick={copyName} style={{ cursor: "pointer" }} data-cy="emoji-name">
      <HeaderText centered>{name}</HeaderText>
    </div>
  ) : (
    <HeaderText centered>{name}</HeaderText>
  );

  const link = nameIsEmoji ? (
    <div
      className={styles.link}
      style={{ position: "absolute", top: -6, right: 0 }}
      onClick={() => setWhyEmojisOpen(true)}
    >
      <QuestionCircleFilled />
    </div>
  ) : null;

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.avatar} data-cy="avatar">
          <Avatar address={address || ""} size={80} className={styles.photo} />
          {showQrHelper && (
            <div
              className={styles.qr}
              data-cy="qr-helper"
              onClick={() => {
                setCodeSheetOpen(true);
              }}
            >
              <MyCodeIcon />
            </div>
          )}
        </div>
        <div className={styles.name}>
          {nameElement}
          {link}
        </div>
        {canEdit && !nameIsEmoji ? (
          <Upload customRequest={sendToS3} maxCount={1} showUploadList={false} beforeUpload={beforeUpload}>
            <div className={styles.actions}>
              <LinkButton size="small" title={`${isProfilePictureSet ? "Change" : "Set"} profile photo`} />
            </div>
          </Upload>
        ) : null}
      </div>
      <WhyEmojiSheet
        emojis={address ? addressToEmoji(address) : ""}
        isOpen={whyEmojisOpen}
        onClose={() => setWhyEmojisOpen(false)}
      />
      <BeamCodeSheet open={codeSheetOpen} onClose={() => setCodeSheetOpen(false)} address={address} />
    </>
  );
};
