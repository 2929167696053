import { graphql } from "@gql";

graphql(`
  fragment ExternalAccount on Account {
    id
    beamnames(orderBy: createdAt, orderDirection: asc, first: 1) {
      id
      name
    }
  }
`);

graphql(`
  fragment Transfer on Transfer {
    id
    txHash
    timestamp
    from {
      ...ExternalAccount
    }
    to {
      ...ExternalAccount
    }
    amount
  }
`);

graphql(`
  fragment BeamLink on BeamLink {
    id
    sender {
      ...ExternalAccount
    }
    createdAt
    createdTxHash
    recipient {
      ...ExternalAccount
    }
    claimedAt
    claimedTxHash
    amount
    token {
      id
    }
  }
`);

graphql(`
  fragment InflationMultiplier on InflationMultiplier {
    id
    value
    blockNumber
    timestamp
  }
`);

export const ALL_HISTORY_QUERY = graphql(`
  query ALL_HISTORY($address: String!, $offset: Int!) {
    transfers(
      first: 100
      skip: $offset
      orderBy: timestamp
      orderDirection: desc
      where: { or: [{ to: $address }, { from: $address }] }
    ) {
      ...Transfer
    }
    beamLinks(
      first: 100
      skip: $offset
      orderBy: createdAt
      orderDirection: desc
      where: { or: [{ sender: $address }, { recipient: $address }] }
    ) {
      ...BeamLink
    }
  }
`);
