import React, { useCallback } from "react";
import { Space } from "antd";

import { HeaderText } from "@components/text/Text";
import { BeamLogo } from "@components/commons/BeamLogo";
import { PasswordStep } from "@components/account/access/components";

import { useWeb3Auth } from "@modules/web3auth/hooks";

import "./LoadAccountView.css";

interface LoadAccountComponentProps {
  onComplete(): void;
}

export const PasswordFormView: React.FC<LoadAccountComponentProps> = ({ onComplete }) => {
  const web3Auth = useWeb3Auth();

  const recover = async ({ password }: { password: string }) => {
    return web3Auth.recover({ password, onComplete });
  };

  const handleComplete = useCallback((password: string) => {
    recover({ password });
  }, []);

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <BeamLogo size={60} />
      </div>
      <HeaderText centered>Welcome back</HeaderText>
      <PasswordStep hideIcon action="Load" onSubmit={handleComplete} />
    </Space>
  );
};
