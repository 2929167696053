import { ethers } from "ethers";

export async function getTransaction(
  provider: ethers.providers.JsonRpcProvider,
  txHash: string,
  retry = 3,
): Promise<ethers.providers.TransactionResponse> {
  // Wait 3s
  await new Promise(resolve => setTimeout(resolve, 3000));

  try {
    const tx = await provider.getTransaction(txHash);
    if (!tx) return getTransaction(provider, txHash, retry - 1);
    return tx;
  } catch (error) {
    if (!retry) throw error;
    return getTransaction(provider, txHash, retry - 1);
  }
}

export function isSameAddress(address: string | string[], address2: string | string[]): boolean {
  if (Array.isArray(address)) return address.some(addr => isSameAddress(addr, address2));
  if (Array.isArray(address2)) return address2.some(addr => isSameAddress(address, addr));

  try {
    return ethers.utils.getAddress(address) === ethers.utils.getAddress(address2);
  } catch (error) {
    console.warn("invalid address provided to `isSameAddress` function", error);
  }
  return false;
}
