import React, { useRef, useState } from "react";
import { Space } from "antd";
import { useNavigate } from "react-router-dom";

import View from "@components/view/View";
import Card from "@components/card/Card";
import { Button, LinkButton } from "@components/button/Button";
import { BeamLogo } from "@components/commons/BeamLogo";
import { BodyText, HeaderText, TextBlock } from "@components/text/Text";

import { XDrawer } from "@components/account/access/x/XDrawer";
import { EmailDrawer } from "@components/account/access/email/EmailDrawer";

import { useWeb3AuthState } from "@redux/slides/web3auth.slide";

import { AuthMethod } from "@modules/web3auth/constants";

import { ReactComponent as MailIcon } from "@assets/icons/mail-icon.svg";
import { ReactComponent as XLogo } from "@assets/icons/x-logo.svg";

import "./SaveAccessView.css";

export const SaveAccessView = () => {
  const navigate = useNavigate();

  return (
    <View headerTitle="Create account" metaTitle="Create account" headerProps={{ onBack: () => navigate("/account") }}>
      <Card data-cy="create-account-card">
        <SaveAccessComponent onComplete={() => navigate("/account")} />
      </Card>
    </View>
  );
};

interface SaveAccessComponentProps {
  onComplete: () => void;
  onboarding?: boolean;
  title?: string;
  body?: React.ReactNode;
}

export const SaveAccessComponent: React.FC<SaveAccessComponentProps> = ({
  onComplete,
  title,
  body,
  onboarding = false,
}) => {
  const { afterRedirect } = useWeb3AuthState();

  const xDrawerRef = useRef<{ login(): void }>();

  const [authMethod, setAuthMethod] = useState<AuthMethod | undefined>(afterRedirect ? AuthMethod.TWITTER : undefined);

  const handleCancel = () => {
    setAuthMethod(undefined);
  };

  const handleComplete = () => {
    onComplete();
    handleCancel();
  };

  const handleXLogin = () => {
    setAuthMethod(AuthMethod.TWITTER);
    xDrawerRef.current?.login();
  };

  const [hasSavedAccessAlready, setHasSavedAccessAlready] = useState(false);

  return (
    <Space direction="vertical" size="large">
      <div style={{ display: "flex", justifyContent: "center", marginTop: "var(--size-medium)" }}>
        <BeamLogo size={60} />
      </div>
      <TextBlock>
        <HeaderText centered>{title || "Create account"}</HeaderText>
        {body || (
          <>
            <BodyText>
              It&apos;s important to create an account so that you can access your funds from a new device or new
              browser.{" "}
            </BodyText>
            <BodyText>
              Beam uses a 2-step save process&mdash;first sign in with one of the options below and then enter a
              Beam-specific password.
            </BodyText>
          </>
        )}
      </TextBlock>

      <Space direction="vertical" className="SaveAccess-ButtonContainer">
        {hasSavedAccessAlready ? (
          <Card color="warning" className="LoadAccount__warning-card">
            <BodyText centered>
              An account is already associated with this email. Did you want to{" "}
              <LinkButton title="Log in" href="/load-account" /> to your account?
            </BodyText>
          </Card>
        ) : null}
        <Button
          title="Continue with email"
          leadingIcon={<MailIcon />}
          onClick={() => setAuthMethod(AuthMethod.EMAIL)}
          className="SaveAccess-Button"
        />
        <Button
          title="Continue with X account"
          leadingIcon={<XLogo />}
          onClick={handleXLogin}
          className="SaveAccess-Button SaveAccess-Button__X"
        />
      </Space>
      <XDrawer
        action="Save"
        ref={xDrawerRef}
        onCancel={handleCancel}
        onComplete={handleComplete}
        setAccountWarning={setHasSavedAccessAlready}
        onboarding={onboarding}
      />
      <EmailDrawer
        action="Save"
        open={authMethod === AuthMethod.EMAIL}
        onCancel={handleCancel}
        onComplete={handleComplete}
        setAccountWarning={setHasSavedAccessAlready}
      />
    </Space>
  );
};
