import { graphql } from "@gql";

export const BEAMLINK_QUERY = graphql(`
  query BEAMLINK($id: ID!) {
    beamLink(id: $id) {
      claimedAt
      amount
      token {
        id
      }
    }
  }
`);
