import axios from "axios";
import { ethers } from "ethers";

import CONFIG from "@constants/config";
import { RELAYOOR_URL } from "@constants/stackup";
import { RelayoorSignatureAuth } from "@modules/blockchain/RelayoorSignatureAuth";

interface UploadURLResponse {
  uploadUrl: string;
  downloadUrl?: string;
  downloadBaseUrl?: string;
  s3Key: string;
  expires: string;
  postDataFields: {
    [key: string]: string;
  };
}

export class ProfileClient {
  constructor(private readonly address: string, private readonly signer: ethers.Wallet) {}

  static getProfilePictureURL(address: string) {
    return `${CONFIG.s3.endpoint}/${address.toLowerCase()}`;
  }

  static getProfilePicture(address: string) {
    const endpoint = ProfileClient.getProfilePictureURL(address);
    return new Promise<string | null>(resolve => {
      axios
        .get(endpoint)
        .then(() => {
          resolve(endpoint);
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  async getProfilePictureSignedUrl(imageType: "PNG" | "JPEG") {
    const uploadUrlEndpoint = new URL(`/api/v1/profileImages/uploadurl`, RELAYOOR_URL);

    const signatureHeaders = await RelayoorSignatureAuth.getHeaders(this.signer, this.address);

    return axios.post<UploadURLResponse>(
      uploadUrlEndpoint.toString(),
      { imageType: imageType, address: this.address },
      { headers: signatureHeaders },
    );
  }

  setProfilePicture(presignedUrl: string, formData: FormData) {
    return axios.post(presignedUrl, formData, { validateStatus: status => status >= 200 && status <= 204 });
  }
}
