import { ethers } from "ethers";

import { useBeamnameReverse } from "@hooks/useBeamnameReverse";
import { useResolveEnsAddress } from "@hooks/useResolveEnsAddress";

import { isCoinbaseTransaction } from "@helpers/transfer";
import { addressToEmoji, isENS, isSameAddress } from "@helpers";

import { APOLLO_CLIENTS, BEAMNAMES_ADDRESS, FAUCET_ADDRESS, VENDING_MACHINE_ADDRESSES } from "@constants";

import { PEANUT_ADDRESSES } from "@modules/peanut/constants";
import { MAINNET_PROVIDER } from "@modules/blockchain/providers";

import { BEAMNAME_REVERSE_QUERY } from "@queries/BEAMNAME_REVERSE";

export function displayBeamName(beamName: string) {
  return beamName.replace(".isyourname.eth", "").replace(".beam.eco", "");
}

export function getDisplayName(address?: string, ensName?: string, beamName?: string) {
  if (!address) return "";

  const isPeanutContract = PEANUT_ADDRESSES.some(PEANUT_ADDRESS => isSameAddress(address, PEANUT_ADDRESS));

  if (isPeanutContract) return "Beam Link Contract";
  if (isSameAddress(address, FAUCET_ADDRESS)) return "Eco Faucet Contract";
  if (isSameAddress(address, VENDING_MACHINE_ADDRESSES)) return "Beam ECO Exchange";
  if (isSameAddress(address, BEAMNAMES_ADDRESS)) return "Beam Names Contract";
  if (isCoinbaseTransaction(address)) return "Deposit from Coinbase";
  if (isSameAddress(ethers.constants.AddressZero, address)) return "Deposit";

  if (beamName) return displayBeamName(beamName);
  if (ensName && isENS(ensName)) return ensName;

  return `${addressToEmoji(address)} (Temporary ID)`;
}

export function extractBeamname(beamnames?: { name: string }[]) {
  if (beamnames?.length) return beamnames[0].name;
}

export async function fetchDisplayName(address?: string) {
  if (!address) return "";

  let ensName, beamName;

  try {
    const result = await APOLLO_CLIENTS.optimism.query({
      query: BEAMNAME_REVERSE_QUERY,
      variables: { address: address.toLowerCase() },
    });

    beamName = extractBeamname(result.data.account?.beamnames);
  } catch (error) {
    console.warn("Error while resolving Beam names");
  }

  if (!beamName) {
    try {
      const ens = await MAINNET_PROVIDER.lookupAddress(address);
      if (ens) {
        ensName = ens;
      }
    } catch (error) {
      console.warn("error trying to lookup address");
    }
  }

  return getDisplayName(address, ensName, beamName);
}

export const useDisplayName = (address?: string) => {
  const { data: beamName, isLoading } = useBeamnameReverse(address);
  const { data: ensData } = useResolveEnsAddress(address, { enabled: !isLoading && !beamName });
  return getDisplayName(address, ensData?.ens, beamName);
};
