import React from "react";
import styles from "./Spinner.module.css";
import classNames from "classnames";

interface SpinnerProps extends Omit<React.HTMLProps<HTMLDivElement>, "size"> {
  label?: string;
  color?: string;
  size?: "normal" | "small" | "xsmall";
}

export const Spinner = ({ label, color, size, className, style, ...props }: SpinnerProps) => {
  const spinnerClass = classNames(className, styles.wrapper, size && styles[size]);
  return (
    <div className={spinnerClass} style={{ color, ...style }} {...props}>
      <div className={styles.spinner}>
        <div />
        <div />
        <div />
        <div />
      </div>
      {label && <label>{label}</label>}
    </div>
  );
};
