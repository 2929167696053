import { ethers } from "ethers";

/**
 * Takes an amount in ECO and the USDCe/ECO price.
 * @param amount ECO amount in wei
 * @param price USDCe/ECO price
 * @return USDC amount in wei
 */
export function getUsdAmount(amount: ethers.BigNumber, price: ethers.BigNumber) {
  const a = amount.mul(price).div(ethers.BigNumber.from(10).pow(18));
  const b = ethers.BigNumber.from(10).pow(18 - 6);

  const quotient = a.div(b);
  const remainder = a.mod(b);

  return !remainder.isZero() ? quotient.add(1) : quotient;
}

export function getBalance(balance: ethers.BigNumberish, fee: ethers.BigNumberish = ethers.constants.Zero) {
  if (ethers.BigNumber.from(balance).lt(fee)) return ethers.constants.Zero;
  return ethers.BigNumber.from(balance).sub(fee);
}
