import React, { useEffect, useState } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";

import BottomSheet from "@components/bottomsheet/BottomSheet";
import { StepAnimation } from "@components/account/access/components";
import { InitialStep } from "@components/account/access/increase-security/InitialStep";
import { ConfirmationStep, PasswordStep } from "@components/account/access/increase-security/confirmation-step";

import { useAppDispatch } from "@redux";
import { setProgressiveSecurityOpen, useWeb3AuthState } from "@redux/slides/web3auth.slide";

import { notify } from "@helpers";
import { useStackup } from "@contexts/StackupContext";
import { Web3AuthMultiFactor } from "@modules/web3auth";

export enum IncreaseSecurityStep {
  Init,
  Authentication,
  Password,
}

export const IncreaseSecurityDrawer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { signer } = useStackup();
  const { progressiveSecuritySheet, resumeProgressiveSecurity } = useWeb3AuthState();

  const { open, initialStep = IncreaseSecurityStep.Init } = progressiveSecuritySheet;

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(initialStep);

  const handleClose = () => {
    dispatch(setProgressiveSecurityOpen({ open: false }));
  };

  const increaseSecurity = async (password: string) => {
    try {
      setLoading(true);
      const web3Auth = Web3AuthMultiFactor.get();
      await web3Auth.save(signer, password, true);

      notify({
        duration: 5_000,
        content: (
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <CheckCircleOutlined height={14} style={{ marginTop: -3, marginRight: 6 }} />
            <div>Password added.</div>
          </div>
        ),
      });

      // trigger localStorage update for this tab
      window.dispatchEvent(new Event("storage"));

      handleClose();
    } catch (error) {
      console.error("increaseSecurity error", error);
      notify({
        duration: 5_000,
        type: "error",
        content: "Unexpected error while adding the password",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (resumeProgressiveSecurity) {
      setStep(IncreaseSecurityStep.Authentication);
    }
  }, [resumeProgressiveSecurity]);

  return (
    <BottomSheet isOpen={open} hideLine closeOnShimTap onClose={handleClose}>
      {step === IncreaseSecurityStep.Init ? (
        <StepAnimation key="increase-security-step-1">
          <InitialStep onNext={() => setStep(IncreaseSecurityStep.Authentication)} />
        </StepAnimation>
      ) : step === IncreaseSecurityStep.Authentication ? (
        <StepAnimation key="increase-security-step-2">
          <ConfirmationStep onNext={() => setStep(IncreaseSecurityStep.Password)} onClose={handleClose} />
        </StepAnimation>
      ) : step === IncreaseSecurityStep.Password ? (
        <StepAnimation key="increase-security-step-3">
          <PasswordStep loading={loading} onSubmit={increaseSecurity} />
        </StepAnimation>
      ) : null}
    </BottomSheet>
  );
};
