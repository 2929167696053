import { QuestionCircleOutlined } from "@ant-design/icons";
import { utils } from "ethers";
import React, { useState } from "react";

import { QRPunkBlockie } from "@components";
import { BreakdownSheet } from "@components/breakdownsheet/BreakdownSheet";
import { TokenBalance } from "@components/code/TokenBalance";
import { TokenIcon } from "@components/token";

import { getTokenManager } from "@modules/token-managers/tokens";

import { useAccount } from "@contexts/AccountContext";
import { useStackup } from "@contexts/StackupContext";
import { useCurrentToken } from "@contexts/TokenContext";

import { UserToken } from "@constants/tokens";

import { formatTokenAmount } from "@helpers";

import "react-awesome-animated-number/dist/index.css";

import { Avatar } from "@components/avatar/Avatar";
import { BodyText } from "@components/text/Text";
import { useMerchant } from "@redux/slides/merchant.slide";
import "./Code.css";

export const Code: React.FC = () => {
  const { address } = useStackup();
  const { balances, beamname } = useAccount();
  const { token } = useCurrentToken();
  const { decimals } = getTokenManager(token);
  const { isMerchantModeEnabled } = useMerchant();

  const [breakdownSheetOpen, setBreakdownSheetOpen] = useState(false);

  return (
    <>
      <div className="Code">
        {isMerchantModeEnabled && (
          <div className="Code_header">
            <Avatar address={address || ""} size={80} />
            <BodyText bold>@{beamname?.displayName}</BodyText>
          </div>
        )}
        {!isMerchantModeEnabled && (
          <div className={`Code_balance ${balances[token].total.isZero() ? "is-empty" : ""}`}>
            <TokenBalance
              balance={balances[token].total}
              decimals={decimals}
              icon={
                <div className="Code_balanceIcon">
                  <TokenIcon token={token} />
                </div>
              }
            />
            {token === UserToken.USD && balances[token].total.gt(0) ? (
              <div className="Code_messageContainer">
                <span onClick={() => setBreakdownSheetOpen(true)}>
                  {formatTokenAmount(utils.formatUnits(balances[token].total, decimals), 3)} digital dollars available
                  <QuestionCircleOutlined color={"#000"} style={{ cursor: "pointer" }} />
                </span>
              </div>
            ) : null}
          </div>
        )}

        <div className="Code_qr">
          <div className="Code_qrInner">
            <QRPunkBlockie address={address} />
          </div>
        </div>
        <div className="Code_footer">
          {isMerchantModeEnabled ? (
            <p>Scan the above code with Beam to send @{beamname?.displayName} money</p>
          ) : (
            <p>Others can scan your Beam Code to instantly send you money</p>
          )}
        </div>
      </div>
      <BreakdownSheet isOpen={breakdownSheetOpen} onClose={() => setBreakdownSheetOpen(false)} token={token} />
    </>
  );
};
