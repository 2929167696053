import { graphql } from "@gql";

export const ACCOUNT_QUERY = graphql(`
  query ACCOUNT($address: ID!, $string: String, $blacklist: [String!]) {
    account(id: $address) {
      beamnames(orderBy: createdAt, orderDirection: asc) {
        id
        name
      }
    }
    lastTransfer: transfers(
      first: 1
      orderBy: timestamp
      orderDirection: desc
      where: { and: [{ to_not_in: $blacklist }, { from: $string }] }
    ) {
      ...Transfer
    }
  }
`);
