import React, { useEffect, useMemo, useState } from "react";
import AuthCode from "react-auth-code-input";

import { Button } from "@components/button/Button";
import { AuthDrawerContent } from "@components/account/access/components";

import { ReactComponent as MessageIcon } from "@assets/icons/message-icon.svg";
import { ReactComponent as MailIcon } from "@assets/icons/mail-icon.svg";
import { BodyText } from "@components/text/Text";

import "./VerificationStep.css";
import moment from "moment";

interface SmsAuthStepProps {
  type: "email" | "sms";
  destination: string;
  loading?: boolean;
  onSubmit(code: string): void;
  onResend(): void;
}

const SEND_INTERVAL = 60_000; // can't send less than 60 seconds apart

export const VerificationStep: React.FC<SmsAuthStepProps> = ({ type, destination, loading, onSubmit, onResend }) => {
  const [code, setCode] = useState("");
  const [lastSendAt, setLastSendAt] = useState(Date.now());
  const [currTime, setCurrTime] = useState(Date.now());

  useEffect(() => {
    if (code.length === 6) onSubmit(code);
  }, [code]);

  const sendVerificationCode = () => {
    setLastSendAt(Date.now());
    onResend();
  };

  useEffect(() => {
    const countSeconds = setInterval(() => {
      setCurrTime(Date.now());
    }, 1_000);
    return () => clearInterval(countSeconds);
  }, []);

  const disabled = useMemo(() => currTime <= lastSendAt + SEND_INTERVAL, [currTime, lastSendAt]);

  return (
    <AuthDrawerContent
      loading={loading}
      icon={type === "sms" ? <MessageIcon /> : <MailIcon />}
      title={`Verify ${type}`}
      description={
        <>
          We sent an {type} to <b>{destination}</b>. Enter the code you received below to verify your {type}.
        </>
      }
      button={
        <>
          <Button title={`Resend ${type}`} disabled={disabled} onClick={sendVerificationCode} />
          <BodyText small light centered style={{ display: disabled ? "block" : "none" }}>
            Didn’t receive the code? Send again in{" "}
            {Math.round(moment(lastSendAt + SEND_INTERVAL).diff(moment(currTime), "seconds"))}...
          </BodyText>
        </>
      }
      input={
        <AuthCode
          allowedCharacters="numeric"
          onChange={setCode}
          containerClassName="input_container"
          inputClassName="input input_digit"
        />
      }
    />
  );
};
