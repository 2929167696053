import BottomSheet from "@components/bottomsheet/BottomSheet";
import React, { createContext, ReactElement, useCallback, useContext, useEffect, useState } from "react";

import styles from "./Onboarding.module.css";
import { LinkButton } from "@components/button/Button";
import { SaveAccess } from "./saveaccess/SaveAccess";
import { BodyText } from "@components/text/Text";
import { CheckCircleOutlined } from "@ant-design/icons";
import { FundAccount } from "./fundaccount/FundAccount";
import { RegisterBeamname } from "./registerbeamname/RegisterBeamname";
import { SendMoney } from "./sendmoney/SendMoney";
import { Button } from "@components/button/Button";
import { useAccount } from "@contexts/AccountContext";
import { ReactComponent as LinkChevron } from "@assets/icons/link_chevron.svg";

import { cubicBezier, easeOut, motion } from "framer-motion";
import { createPortal } from "react-dom";
import classNames from "classnames";

export enum OnboardStep {
  SaveAccess,
  FundAccount,
  RegisterBeamname,
  SendMoney,
}

type IOnboardingContext = {
  currStep: OnboardStep;
  direction: number;
  nextStep: () => void;
  prevStep: () => void;
  onClose: () => void;
  setButtonTitle: (_: string) => void;
  setButtonAction: (_: () => unknown) => void;
  setIsLoading: (_: boolean) => void;
  setButtonDisabled: (_: boolean) => void;
  setIsComplete: (_: boolean) => void;
  hasPlayedIntroAnimation: boolean;
  setHasPlayedIntroAnim: (_: boolean) => void;
};

export const OnboardingContext = createContext<IOnboardingContext>({
  currStep: OnboardStep.SaveAccess,
  direction: 0,
  nextStep: () => null,
  prevStep: () => null,
  onClose: () => null,
  setButtonTitle: () => null,
  setButtonAction: () => null,
  setIsLoading: () => null,
  setButtonDisabled: () => null,
  setIsComplete: () => null,
  hasPlayedIntroAnimation: false,
  setHasPlayedIntroAnim: () => null,
});

export const useOnboarding = () => useContext(OnboardingContext);

export const Onboarding: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const { beamname } = useAccount();

  const [step, setStep] = useState<OnboardStep>(OnboardStep.SaveAccess);
  const [direction, setDirection] = useState(0);
  const [tooltip, setTooltip] = useState<JSX.Element | null>(null);
  const [buttonTitle, setButtonTitle] = useState("");
  const [buttonAction, setButtonAction] = useState<() => unknown>();
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [hasPlayedIntroAnimation, setHasPlayedIntroAnim] = useState(false);

  const SecondTooltip = (
    <Tooltip
      position="right"
      title="Create a Beam Link to send money to someone far away"
      buttonTitle="Done"
      onClick={() => setTooltip(null)}
      target={'[data-tooltip-target="Send"]'}
    />
  );

  // when displayed links to second tooltip
  const FirstToolTip = (
    <Tooltip
      title="Scan a friend's Beam Code to send them money"
      buttonTitle="Next"
      onClick={() => setTooltip(SecondTooltip)}
      target="[data-tooltip-target='Scan']"
    />
  );

  const nextStep = () => {
    setDirection(1);
    setStep(step => step + 1);
  };

  const prevStep = () => {
    setDirection(-1);
    setStep(step => step - 1);
  };

  useEffect(() => {
    if (step > 3) {
      onClose();
      setTooltip(FirstToolTip);
    }
  }, [step]);

  // this could be a full page later instead of just a sheet
  return (
    <OnboardingContext.Provider
      value={{
        currStep: step,
        nextStep,
        prevStep,
        onClose,
        setButtonTitle,
        setButtonAction: (fn: unknown) => setButtonAction(() => fn),
        setIsLoading,
        setButtonDisabled,
        setIsComplete,
        direction,
        hasPlayedIntroAnimation,
        setHasPlayedIntroAnim,
      }}
    >
      <BottomSheet
        title={`Welcome to Beam${beamname?.exists ? `, @${beamname.displayName}` : ""}`}
        subtitle={`Step ${step + 1} of 4`}
        navItemLeading={
          step > 0 ? (
            <button className={styles.backButton} onClick={prevStep}>
              <LinkChevron />
            </button>
          ) : undefined
        }
        isOpen={open}
        onClose={onClose}
        height={"100%"}
        desktopDisplay="overlay"
        footer={
          <motion.div layout="position" className={styles.footer} transition={{ ease: easeOutQuart, duration: 0.15 }}>
            <NavDashes step={step} />
            <Button
              title={buttonTitle}
              onClick={buttonAction}
              disabled={buttonDisabled}
              style={{ pointerEvents: isLoading ? "none" : undefined }}
            />
            {step !== OnboardStep.SendMoney && !isComplete && <LinkButton title={"Next"} onClick={nextStep} />}
          </motion.div>
        }
      >
        <div className={styles.container}>
          {step === OnboardStep.SaveAccess ? (
            <SaveAccess key={"onboarding-1"} />
          ) : step === OnboardStep.FundAccount ? (
            <FundAccount key={"onboarding-2"} />
          ) : step === OnboardStep.RegisterBeamname ? (
            <RegisterBeamname key={"onboarding-3"} />
          ) : (
            <SendMoney key={"onboarding-4"} />
          )}
        </div>
      </BottomSheet>
      {tooltip}
    </OnboardingContext.Provider>
  );
};

// OnboardingStep

interface OnboardingStepProps {
  brand: ReactElement;
  preStepContent?: ReactElement;
  stepTitle: string;
  postStepContent: ReactElement;
  isDone: boolean;
}

const easeOutQuart = cubicBezier(0.17, 0.84, 0.44, 1);

export const OnboardingStep: React.FC<OnboardingStepProps> = ({
  brand,
  preStepContent = null,
  stepTitle,
  postStepContent,
  isDone,
}) => {
  const { currStep, direction, setIsComplete } = useOnboarding();

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? "20%" : "-20%",
        opacity: 0,
      };
    },
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        x: direction > 0 ? "-20%" : "20%",
        opacity: 0,
      };
    },
  };

  useEffect(() => {
    setIsComplete(isDone);
  }, [isDone]);

  return (
    <motion.div
      initial="enter"
      animate="center"
      exit="exit"
      custom={direction}
      variants={variants}
      className={styles.step_container}
      transition={{
        x: {
          ease: easeOutQuart,
          duration: 0.15,
        },
        opacity: { ease: easeOut, duration: 0.1 },
      }}
    >
      <div className={styles.brand}>{brand}</div>
      {preStepContent && <div className={styles.prepost_content}>{preStepContent}</div>}
      <div className={styles.step_content}>
        <div className={styles.step_number}>
          <BodyText>{currStep + 1}</BodyText>
        </div>
        <BodyText bold large light={isDone}>
          {isDone ? <s>{stepTitle}</s> : stepTitle}
        </BodyText>
        {isDone ? (
          <div className={styles.done}>
            <CheckCircleOutlined />
            <BodyText>Done</BodyText>
          </div>
        ) : null}
      </div>
      <div className={styles.prepost_content}>{postStepContent}</div>
    </motion.div>
  );
};

// NavDashes

const NavDashes: React.FC<{ step: OnboardStep }> = ({ step }) => {
  const container = React.useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = React.useState(0);

  useEffect(() => {
    if (container.current) {
      setContainerWidth(container.current.getBoundingClientRect().width);
    }
  }, []);

  return (
    <div className={styles.nav_dashes} ref={container}>
      <span className={styles.navLine} style={{ transform: `translateX(${(containerWidth / 4) * step}px)` }} />
      <NavDash />
      <NavDash />
      <NavDash />
      <NavDash />
    </div>
  );
};

const NavDash: React.FC = () => {
  return (
    <div className={styles.navDash}>
      <svg width="21" height="6" viewBox="0 0 21 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 0H0V6H21V0ZM3 1C1.89543 1 1 1.89543 1 3C1 4.10457 1.89543 5 3 5H18C19.1046 5 20 4.10457 20 3C20 1.89543 19.1046 1 18 1H3Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

// Tooltip

interface TooltipProps {
  title: string;
  buttonTitle: string;
  position?: "left" | "center" | "right";
  onClick: () => void;
  target: string;
}

const Tooltip: React.FC<TooltipProps> = ({ title, buttonTitle, position = "center", onClick, target }) => {
  const [[posX, posY], setPos] = React.useState([0, 0]);
  const bodyRef = React.useRef<HTMLDivElement>(null);
  const [shouldShow, setShouldShow] = React.useState(false);

  useEffect(() => {
    reposition();
  }, [target, bodyRef]);

  const tooltipClass = classNames({
    [styles.tooltip_mask]: true,
    [styles.isVisible]: shouldShow,
  });

  const reposition = useCallback(() => {
    const node = document.querySelector(`${target}`);
    const rect = node?.getBoundingClientRect();

    if (!rect || !bodyRef.current) {
      return;
    }

    const bodyRect = bodyRef.current.getBoundingClientRect();
    const x =
      position === "right"
        ? rect.x + rect.width / 2 - bodyRect.width + 16
        : rect.x - bodyRect.width / 2 + rect.width / 2;
    const y = rect.y - bodyRect.height + rect.height / 3;
    setPos([x, y]);
  }, [bodyRef, target, setPos]);

  useEffect(() => {
    setTimeout(() => {
      setShouldShow(true);
    }, 500);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", reposition);

    return () => {
      window.removeEventListener("resize", reposition);
    };
  }, [reposition]);

  return (
    <>
      {createPortal(
        <motion.div className={tooltipClass}>
          <div
            ref={bodyRef}
            style={{ left: posX, top: posY }}
            className={`${styles.tooltip_container} ${
              position === "right" ? styles.right : position === "left" ? styles.left : ""
            }`}
          >
            <motion.div
              className={styles.tooltipBody}
              layout="position"
              transition={{ duration: shouldShow ? 0.25 : 0, ease: easeOutQuart }}
            >
              <BodyText>{title}</BodyText>
              <Button title={buttonTitle} onClick={onClick} size="small" />
            </motion.div>
            <motion.div
              className={styles.tooltipStem}
              layout="position"
              transition={{ duration: shouldShow ? 0.25 : 0, ease: easeOutQuart }}
            >
              <div
                className={`${styles.tooltip_line} ${
                  position === "right" ? styles.right : position === "left" ? styles.left : ""
                }`}
              />
              <div
                className={`${styles.tooltip_dot} ${
                  position === "right" ? styles.right : position === "left" ? styles.left : ""
                }`}
              />
            </motion.div>
          </div>
        </motion.div>,
        document.body,
      )}
    </>
  );
};
