import { ReactComponent as CheckmarkIcon } from "@assets/icons/checkmark.svg";
import { ReactComponent as CopyIcon } from "@assets/icons/copy.svg";
import { IconButton } from "@components/iconbutton/IconButton";
import { copy } from "@helpers/copy";
import React, { ForwardedRef, forwardRef } from "react";
import { Button, ButtonProps } from "./Button";

interface CopyButtonProps extends Omit<ButtonProps, "title" | "secondary" | "onClick"> {
  iconOnly?: boolean;
  copyText: string;
  successText?: string;
}

export const CopyButton = forwardRef<HTMLButtonElement, CopyButtonProps>(
  ({ iconOnly, copyText, successText, size = "small", ...props }, ref) => {
    const [hasCopied, setHasCopied] = React.useState(false);
    const timeout = React.useRef<NodeJS.Timeout>();

    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();

      clearTimeout(timeout.current);
      copy(copyText, successText);
      setHasCopied(true);

      timeout.current = setTimeout(() => {
        setHasCopied(false);
      }, 2500);
    };

    if (iconOnly) {
      return <IconButton icon={hasCopied ? <CheckmarkIcon /> : <CopyIcon />} size={size} onClick={handleClick} />;
    }

    return (
      <Button
        title={hasCopied ? "Copied!" : "Copy"}
        leadingIcon={hasCopied ? <CheckmarkIcon /> : <CopyIcon />}
        secondary
        size={size}
        {...props}
        onClick={handleClick}
        ref={ref as ForwardedRef<HTMLButtonElement>}
      />
    );
  },
);
