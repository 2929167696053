import React from "react";
import { Space } from "antd";

import { Badge } from "@components/badge/Badge";
import { Button } from "@components/button/Button";
import { IconCircle } from "@components/icon/IconCircle/IconCircle";
import { BodyText, HeaderText, TextBlock } from "@components/text/Text";

import { ReactComponent as LockIcon } from "@assets/icons/lock_icon.svg";
import { ReactComponent as Checkmark } from "@assets/icons/checkmark.svg";

interface InitialStepProps {
  onNext?: () => void;
}

export const InitialStep: React.FC<InitialStepProps> = ({ onNext }) => {
  return (
    <Space size={16} direction="vertical">
      <Space size={16} direction="vertical" align="center" style={{ width: "100%" }}>
        <IconCircle icon={<LockIcon />} />
        <Badge icon={<Checkmark />} text="Recommended" />
      </Space>
      <HeaderText centered size="small">
        Increase your account security
      </HeaderText>

      <TextBlock>
        <BodyText centered>
          Now that you have funds in your Beam account, we recommend increasing your account security by adding a
          Beam-specific password.
        </BodyText>
        <BodyText centered light>
          Adding a password makes it even more difficult for others to gain access to your Beam account.
        </BodyText>
      </TextBlock>
      <Button key="save-access" type="primary" htmlType="submit" title="Add password" onClick={onNext} />
    </Space>
  );
};
