import { ethers } from "ethers";

import { BEAMNAMES_ADDRESS, getTokenInfo, Token } from "@constants";
import { OPTIMISM_PROVIDER } from "@modules/blockchain/providers";
import { Beamnames__factory, ERC20__factory } from "@assets/contracts";
import { useStackup } from "@contexts/StackupContext";

import { useAppDispatch } from "@redux";
import { executeTransfer } from "@redux/slides/balances/balances.slide";
import { useConfigFee } from "@redux/slides/config.slide";
import { getBeamWallet, getClient, getFlatPaymaster } from "@helpers/bundler";

const beamnames = Beamnames__factory.connect(BEAMNAMES_ADDRESS, OPTIMISM_PROVIDER);

export const useBeamnames = () => {
  const { address, signer } = useStackup();
  const dispatch = useAppDispatch();
  const flatFees = useConfigFee();

  const setPrimaryName = async (tokenId: ethers.BigNumber) => {
    const simpleAccount = await getBeamWallet(signer, "optimism");

    // Register Data
    const setPrimaryNameData = beamnames.interface.encodeFunctionData("setPrimaryName", [tokenId]);

    simpleAccount.execute(BEAMNAMES_ADDRESS, 0, setPrimaryNameData);

    const client = await getClient("optimism");
    const tx = await client.sendUserOperation(simpleAccount);
    return await tx.wait();
  };

  const register = async (name: string) => {
    if (!flatFees) return;

    const token = getTokenInfo(Token.ECO, "optimism");
    const simpleAccount = await getBeamWallet(signer, "optimism", getFlatPaymaster("optimism"));

    // Fetch registration fee
    const registrationFee = await beamnames.registrationFee();

    // ECO Approval Data
    const approvalData = ERC20__factory.createInterface().encodeFunctionData("approve", [
      BEAMNAMES_ADDRESS,
      registrationFee,
    ]);

    // Register Data
    const registrationData = beamnames.interface.encodeFunctionData("register", [address!, name]);

    simpleAccount.executeBatch([token.address, BEAMNAMES_ADDRESS], [approvalData, registrationData]);

    const client = await getClient("optimism");
    const tx = await client.sendUserOperation(simpleAccount);

    const txPromise = tx.wait();
    dispatch(
      executeTransfer({
        tx: txPromise,
        token: token.id,
        network: "optimism",
        amount: registrationFee.toString(),
      }),
    );
    return txPromise;
  };

  return { register, setPrimaryName };
};
