export const INFURA_ID = process.env.REACT_APP_INFURA_ID;

export const ENS_SUBGRAPH_URL = "https://api.thegraph.com/subgraphs/name/ensdomains/ens";

export type Network = "optimism" | "base";
export type AllNetwork = Network | "mainnet" | "goerli-optimism" | "base-goerli";

export interface INetwork {
  id: AllNetwork;
  short: string;
  network?: Network;
  name: string;
  color: string;
  rpcUrl: string;
  chainId: number;
  blockExplorer: string;
  subgraphUrl?: string;
  bundler: string;
  isTestnet?: boolean;
}

export const NETWORKS: Record<AllNetwork, INetwork> = {
  mainnet: {
    id: "mainnet",
    short: "m",
    name: "mainnet",
    color: "#ff8b9e",
    chainId: 1,
    blockExplorer: "https://etherscan.io/",
    bundler: "https://api.pimlico.io/v1/ethereum/rpc?apikey=c5c44203-a5cd-4d64-8ba2-55dcec5a220c",
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
  },
  optimism: {
    id: "optimism",
    short: "op",
    name: "Optimism",
    network: "optimism",
    color: "#f01a37",
    chainId: 10,
    blockExplorer: "https://optimistic.etherscan.io/",
    rpcUrl: `https://optimism-mainnet.infura.io/v3/${INFURA_ID}`,
    bundler: "https://api.pimlico.io/v1/optimism/rpc?apikey=c5c44203-a5cd-4d64-8ba2-55dcec5a220c",
    subgraphUrl: "https://graph-node.optimism.subgraph.beam.eco/subgraphs/name/ecographs/beam-subgraphs-optimism",
  },
  "goerli-optimism": {
    id: "goerli-optimism",
    short: "opg",
    name: "Optimism",
    network: "optimism",
    color: "#0975F6",
    chainId: 420,
    isTestnet: true,
    blockExplorer: "https://goerli-optimism.etherscan.io/",
    rpcUrl: "https://opt-goerli.g.alchemy.com/v2/Mv_9HRy6QVAXrrruYl9B2_tkEkjgbV8j",
    bundler: "https://api.pimlico.io/v1/optimism-goerli/rpc?apikey=c5c44203-a5cd-4d64-8ba2-55dcec5a220c",
    subgraphUrl: "https://api.thegraph.com/subgraphs/name/ecographs/beam-subgraphs-optimism-goerli",
  },
  base: {
    id: "base",
    short: "b",
    name: "Base",
    network: "base",
    color: "#f01a37",
    chainId: 8453,
    blockExplorer: "https://basescan.org/",
    rpcUrl: `https://base-mainnet.g.alchemy.com/v2/k1F5fFw_2Zysxq1-86JYbO8h9vJnb2sf`,
    bundler: "https://api.pimlico.io/v1/base/rpc?apikey=c5c44203-a5cd-4d64-8ba2-55dcec5a220c",
    subgraphUrl: "https://graph-node.base.subgraph.beam.eco/subgraphs/name/ecographs/beam-subgraphs-base",
  },
  "base-goerli": {
    id: "base-goerli",
    short: "bg",
    name: "Base",
    network: "base",
    color: "#f01a37",
    chainId: 84531,
    isTestnet: true,
    blockExplorer: "https://goerli.basescan.org/",
    rpcUrl: `https://base-goerli.g.alchemy.com/v2/NLL01YTvfySOzLisdii-D1iffeXzY0wm`,
    bundler: "https://api.pimlico.io/v1/base-goerli/rpc?apikey=c5c44203-a5cd-4d64-8ba2-55dcec5a220c",
    subgraphUrl: "https://graph-node.base.subgraph.staging.beam.eco/subgraphs/name/ecographs/beam-subgraphs",
  },
};

export function getSupportedNetworks() {
  // TODO iterate over supported networks comma separated ENV instead
  return [NETWORK, getNetwork("base")];
}

export function getNetworkById(chainId: number) {
  return Object.values(NETWORKS).find(network => network.chainId === chainId);
}

export function getNetworkByShort(short: string) {
  return Object.values(NETWORKS).find(network => network.short === short);
}

export const NETWORK = NETWORKS[process.env.REACT_APP_NETWORK as keyof typeof NETWORKS];

if (NETWORK === undefined) {
  throw new Error("Network not found, please change the `REACT_APP_NETWORK` ENV or add the network to the list");
}

if (NETWORK.subgraphUrl === undefined) {
  throw new Error("Subgraph URL not set for current network");
}

export function getNetwork(network?: Network) {
  if (network === "base") {
    return NETWORK.id.includes("goerli") ? NETWORKS["base-goerli"] : NETWORKS.base;
  }
  return NETWORK;
}

export const NETWORK_LIST: Network[] = ["optimism", "base"];
