import { round } from "@helpers/round";
import { ethers } from "ethers";
import { Token, TokenInfo } from "@constants";

export function shortAddr(address: string) {
  return address.substring(0, 10) + "..." + address.substring(34, 42);
}

export function shortHash(hash: string) {
  return hash.substring(0, 10) + "..." + hash.substring(58, 66);
}

export const formatTokenAmount = (amount: number | string, decimals = 2, options?: Intl.NumberFormatOptions) => {
  const _amount = typeof amount === "string" ? parseFloat(amount) : amount;
  return Intl.NumberFormat("en-US", {
    maximumFractionDigits: decimals,
    minimumFractionDigits: 2,
    ...options,
  }).format(round(_amount, decimals));
};

export const formatBigNumber = (
  amount: ethers.BigNumberish,
  units = 18,
  decimals = 2,
  options?: Intl.NumberFormatOptions,
) => {
  return formatTokenAmount(ethers.utils.formatUnits(amount, units), decimals, options);
};

export const formatFee = (fee: ethers.BigNumberish | undefined, token: Pick<TokenInfo, "id" | "decimals">) => {
  const shownDecimals = token.id === Token.USDC ? 3 : 2;
  return fee ? formatBigNumber(fee, token.decimals, shownDecimals) : "";
};

export function formatCurrency(amount: ethers.BigNumber | number, decimals = 6) {
  if (ethers.BigNumber.isBigNumber(amount)) {
    amount = round(parseFloat(ethers.utils.formatUnits(amount, decimals)), 2);
  }
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "usd",
    minimumFractionDigits: 0,
    signDisplay: "never",
  }).format(amount);
}
