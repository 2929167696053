import React, { useState } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";

import { Button } from "@components/button/Button";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { PasswordStep } from "@components/account/access/components";

import { logError, notify } from "@helpers";

import { Web3AuthMultiFactor } from "@modules/web3auth";
import { AuthType } from "@modules/web3auth/constants";

import { useStackup } from "@contexts/StackupContext";
import { useWeb3AuthState } from "@redux/slides/web3auth.slide";
import Row from "@components/row/Row";

export const ChangePasswordRow = () => {
  const { signer: stackupSigner } = useStackup();
  const { authType } = useWeb3AuthState();

  const [loading, setLoading] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);

  if (authType !== AuthType.MULTI) return null;

  const changePassword = async ({ password }: { password: string }) => {
    setLoading(true);
    try {
      const web3Auth = Web3AuthMultiFactor.get();
      await web3Auth.save(stackupSigner, password, true);

      notify({
        duration: 5_000,
        content: (
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <CheckCircleOutlined height={14} style={{ marginTop: -3, marginRight: 6 }} />
            <div>Password changed</div>
          </div>
        ),
      });
      setPasswordOpen(false);
      // trigger localStorage update for this tab
      window.dispatchEvent(new Event("storage"));
    } catch (error) {
      logError("[change-password]", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row
        title="Password"
        subtitle="You can change your Beam-specific password"
        hasHorizontalPadding={false}
        trailingContent={
          <Button size="small" title="Change" secondary onClick={() => setPasswordOpen(true)} loading={passwordOpen} />
        }
      />
      <BottomSheet
        title="Change Password"
        isOpen={passwordOpen}
        onClose={() => {
          setPasswordOpen(false);
          setLoading(false);
        }}
      >
        <PasswordStep action={"Save"} loading={loading} onSubmit={password => changePassword({ password })} />
      </BottomSheet>
    </>
  );
};
