import { ColorScheme, useColorScheme } from "@contexts/ColorSchemeContext";
import React, { useRef, useState } from "react";

import { ReactComponent as Auto } from "@assets/icons/circle_halffill.svg";
import { ReactComponent as Moon } from "@assets/icons/moon.svg";
import { ReactComponent as Sun } from "@assets/icons/sun.svg";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { Button } from "@components/button/Button";
import Card from "@components/card/Card";
import { Popover } from "@components/popover/Popover";
import Row from "@components/row/Row";
import { BodyText } from "@components/text/Text";
import { isDesktop } from "react-device-detect";
import { ChangePasswordRow } from "./ChangePassordRow";
import styles from "./SettingsCard.module.css";
import { CurrencySettingsRow } from "./CurrencySettingsRow";

export const SettingsCard = () => {
  const themePopoverTargetRef = useRef<HTMLDivElement>(null);
  const [themePopoverOpen, setThemePopoverOpen] = useState(false);
  const theme = useColorScheme();

  const handlePopoverClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    setThemePopoverOpen(!themePopoverOpen);
  };

  const handleThemeClick = (scheme: ColorScheme) => {
    theme.setScheme(scheme);
    setThemePopoverOpen(false);
  };

  const getThemeName = (theme?: ColorScheme) => {
    switch (theme) {
      case ColorScheme.dark:
        return "Dark";
      case ColorScheme.light:
        return "Light";
      case ColorScheme.auto:
        return "Auto";
      default:
        return "";
    }
  };

  const iconForScheme = (theme?: ColorScheme) => {
    switch (theme) {
      case ColorScheme.dark:
        return <Moon />;
      case ColorScheme.light:
        return <Sun />;
      case ColorScheme.auto:
        return <Auto />;
      default:
        return null;
    }
  };

  return (
    <>
      <Card title="Settings">
        <CurrencySettingsRow />
        <Row
          title="Theme"
          subtitle="How Beam appears in this browser"
          hasHorizontalPadding={false}
          trailingContent={
            <div ref={themePopoverTargetRef}>
              <Button
                size="small"
                title={getThemeName(theme.currentScheme)}
                secondary
                onClick={handlePopoverClick}
                leadingIcon={iconForScheme(theme.currentScheme)}
              />
            </div>
          }
        />
        <ChangePasswordRow />
      </Card>

      <ThemePopover
        targetRef={themePopoverTargetRef}
        onClose={() => setThemePopoverOpen(false)}
        open={themePopoverOpen}
        currentScheme={theme.currentScheme}
        onThemeClick={handleThemeClick}
      />
    </>
  );
};

interface ThemePopoverProps {
  targetRef: React.RefObject<HTMLDivElement>;
  open: boolean;
  onClose: () => void;
  currentScheme: ColorScheme | undefined;
  onThemeClick: (_: ColorScheme) => void;
}

const ThemePopover = ({ targetRef, open, onClose, currentScheme, onThemeClick }: ThemePopoverProps) => {
  const content = (
    <div className={`${styles.themeItemList} ${!isDesktop ? styles.inSheet : ""}`}>
      <Row
        className={styles.themeItem}
        selected={currentScheme === ColorScheme.light}
        onClick={onThemeClick.bind(null, ColorScheme.light)}
        leadingContent={
          <div className={styles.themeItemLeading}>
            <Sun />
            <BodyText bold>Light</BodyText>
          </div>
        }
      />
      <Row
        className={styles.themeItem}
        selected={currentScheme === ColorScheme.dark}
        leadingContent={
          <div className={styles.themeItemLeading}>
            <Moon />
            <BodyText bold>Dark</BodyText>
          </div>
        }
        onClick={onThemeClick.bind(null, ColorScheme.dark)}
      />
      <Row
        className={styles.themeItem}
        selected={currentScheme === ColorScheme.auto}
        onClick={onThemeClick.bind(null, ColorScheme.auto)}
        leadingContent={
          <div className={styles.themeItemLeading}>
            <Auto />
            <BodyText bold>Auto</BodyText>
          </div>
        }
      />
    </div>
  );

  if (!isDesktop) {
    return (
      <BottomSheet title="Select theme" isOpen={open} onClose={onClose}>
        {content}
      </BottomSheet>
    );
  }

  return (
    <Popover targetRef={targetRef} open={open} onClose={onClose} position="topRight">
      {content}
    </Popover>
  );
};
