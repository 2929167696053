import { ethers } from "ethers";
import { IUserOperation } from "userop";

import { store } from "@redux/store";
import { getBalances } from "@redux/slides/balances/balances.slide";

import { calculateTransferAmounts } from "@helpers/transfer";
import { getBeamWallet, getClient, getFlatPaymaster, getFreePaymaster } from "@helpers/bundler";

import { getNetwork, getTokenInfo, Network, Token, UserToken } from "@constants";

import { TokenBalances } from "@contexts/AccountContext";

import * as Peanut from "@modules/peanut";
import { getPeanutAddress } from "@modules/peanut/constants";
import { BeamWallet } from "@modules/smart-wallet/BeamWallet";

import { ERC20__factory } from "@assets/contracts";

interface TokenOpts {
  composedBy: Token[];
  symbol: string;
  decimals: number;
}

enum TransferType {
  FEE,
  LINK,
  TRANSFER,
}

interface Transfer {
  type?: TransferType;
  contract: string;
  token: Token;
  to?: string;
  amount: ethers.BigNumber;
}

interface SendOpts {
  network: Network;
  signer: ethers.Signer;
  transactions: { to: string; data: string }[];
  dryRun?: boolean;
  userOpWithFee?: IUserOperation;
  onUserOpWithFeeTx?: (txHash: string) => void;
}

interface CreateLinkOpts extends Omit<SendOpts, "transactions"> {
  transfers: Transfer[];
  password: string;
}

interface TransferOpts extends Omit<SendOpts, "transactions"> {
  to: string;
  transfers: Transfer[];
}

export abstract class TokenManager {
  constructor(public readonly userToken: UserToken, protected readonly opts: TokenOpts) {}

  get id() {
    return this.userToken;
  }

  get symbol() {
    return this.opts.symbol;
  }

  get decimals() {
    return this.opts.decimals;
  }

  get composedBy() {
    return this.opts.composedBy;
  }

  getBalance(balances = getBalances(store.getState())): TokenBalances {
    const networkBalances = Object.entries(balances).map(([network, data]) => {
      const balance = Object.entries(data.balances).filter(([token]) => this.composedBy.includes(token as Token));

      const total = balance.reduce((acc, item) => acc.add(item[1]), ethers.constants.Zero);
      return [network, total];
    });

    const total = networkBalances
      .map(balance => balance[1] as never)
      .reduce((acc, item) => acc.add(item), ethers.constants.Zero);

    networkBalances.push(["total", total]);

    return Object.fromEntries(networkBalances);
  }

  getTransferAmounts(amount: ethers.BigNumber, fee: ethers.BigNumber, network: Network): Transfer[] {
    const { config, balances } = store.getState();

    if (!config.fee) throw new Error("Fee is not defined");

    const tokenBalances = this.composedBy.map(token =>
      ethers.BigNumber.from(balances.networks[network].balances[token]),
    );

    const amounts = calculateTransferAmounts(amount, fee, tokenBalances);

    const createTransferTxFn = (amount: ethers.BigNumber, index: number) => {
      const transfers: Transfer[] = [];
      const tokenId = this.composedBy[index];

      const token = getTokenInfo(tokenId, network);

      if (!amount.isZero()) {
        transfers.push({
          token: tokenId,
          amount: amount,
          contract: token.address,
        });
      }

      return transfers;
    };

    const transferTxs = amounts.map(transfer => transfer.amount).flatMap(createTransferTxFn);
    const transferFeeTxs = amounts
      .map(transfer => transfer.fee)
      .flatMap(createTransferTxFn)
      .map(transfer => {
        transfer.type = TransferType.FEE;
        transfer.to = config.fee!.recipients[network];
        return transfer;
      });

    return [...transferTxs, ...transferFeeTxs];
  }

  async transfer(opts: TransferOpts) {
    const { to, transfers, ...sendOpts } = opts;
    const transactions = transfers.map(transfer => ({
      to: transfer.contract,
      data: ERC20__factory.createInterface().encodeFunctionData("transfer", [transfer.to || to, transfer.amount]),
    }));
    return this.send({ transactions, ...sendOpts });
  }

  async createLink(opts: CreateLinkOpts) {
    const { transfers, password, ...sendOpts } = opts;

    const { chainId } = getNetwork(sendOpts.network);
    const peanutContract = getPeanutAddress(chainId);

    const linkTransfers = transfers.filter(transfer => transfer.type !== TransferType.FEE);
    const feeTransfers = transfers.filter(transfer => transfer.type === TransferType.FEE);

    const depositTxs = linkTransfers.flatMap(transfer => {
      const token = getTokenInfo(transfer.token, sendOpts.network);
      const { transaction } = Peanut.makeDeposit(token, transfer.amount, password, { peanutContract });
      return [
        {
          to: token.address,
          data: ERC20__factory.createInterface().encodeFunctionData("approve", [peanutContract, transfer.amount]),
        },
        transaction,
      ];
    });

    const feeTxs = feeTransfers.map(transfer => ({
      to: transfer.contract,
      data: ERC20__factory.createInterface().encodeFunctionData("transfer", [transfer.to!, transfer.amount]),
    }));

    const transactions = [...depositTxs, ...feeTxs];

    return this.send({ transactions, ...sendOpts });
  }

  async send(options: SendOpts) {
    const { transactions, dryRun, signer, network, onUserOpWithFeeTx, userOpWithFee } = options || {};

    let wallet: BeamWallet;
    if (userOpWithFee && onUserOpWithFeeTx) {
      wallet = await getBeamWallet(signer, network, getFreePaymaster(network, userOpWithFee, onUserOpWithFeeTx));
    } else {
      wallet = await getBeamWallet(signer, network, getFlatPaymaster(network));
    }

    const client = await getClient(network);

    wallet.executeBatch(
      transactions.map(tx => tx.to),
      transactions.map(tx => tx.data),
    );

    if (dryRun) return client.buildUserOperation(wallet);
    return client.sendUserOperation(wallet);
  }

  getBalances(network: Network) {
    return { ...store.getState().balances.networks[network].balances };
  }
}
