import React from "react";
import { AnimatePresence, motion } from "framer-motion";

import Card, { CardProps } from "@components/card/Card";
import { BodyText } from "@components/text/Text";
import ExpandSection from "@components/expand/ExpandSection";

import { ReactComponent as LinkChevron } from "@assets/icons/link_chevron.svg";

import styles from "./OtherMethodsCard.module.css";
import { IconButton } from "@components/iconbutton/IconButton";
import Row from "@components/row/Row";
import { RowGroup } from "@components/row/RowGroup";

export const OtherMethodsCard: React.FC<React.PropsWithChildren & CardProps> = ({ children, ...rest }) => {
  return (
    <Card
      title="Other deposit methods"
      subtitle="These methods are not recommended for you because they are likely to fail based on your location or have high fees."
      {...rest}
    >
      <ExpandSection
        persistToggle
        toggleElement={isExpanded => {
          return (
            <AnimatePresence>
              {!isExpanded && (
                <motion.div
                  initial={{ height: "auto", opacity: 1 }}
                  exit={{ height: "0", opacity: 0 }}
                  transition={{
                    opacity: {
                      duration: 0.1,
                    },
                    height: {
                      duration: 0.15,
                    },
                  }}
                >
                  <RowGroup compact>
                    <Row
                      title={<BodyText className={styles.link}>View methods</BodyText>}
                      compact
                      onClick={() => {
                        return;
                      }}
                      dataCy="other-methods-overflow-row"
                      trailingContent={
                        <div className={styles.chevron}>
                          <IconButton icon={<LinkChevron />} size="small" />
                        </div>
                      }
                      hasHorizontalPadding={false}
                    />
                  </RowGroup>
                </motion.div>
              )}
            </AnimatePresence>
          );
        }}
      >
        {children}
      </ExpandSection>
    </Card>
  );
};
