import { ReactComponent as Chevron } from "@assets/icons/link_chevron.svg";
import { IconButton } from "@components/iconbutton/IconButton";
import { animationCurveEaseOut, animationDurationFastMs, animationDurationFastS } from "@constants/animations";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import styles from "./ExpandSection.module.css";
import { scrollIntoView } from "@helpers/scrollIntoView";

interface ExpandSectionProps {
  children: React.ReactNode;
  persistToggle?: boolean;
  toggleTitle?: string;
  toggleElement?: (isExpanded: boolean) => React.ReactElement;
  defaultExpanded?: boolean;
  scrollOnExpand?: boolean;
}

const ExpandSection = ({
  children,
  defaultExpanded = false,
  persistToggle = false,
  toggleTitle = "See all",
  toggleElement,
  scrollOnExpand = true,
}: ExpandSectionProps) => {
  const [isExpanded, setIsExpanded] = React.useState(defaultExpanded);
  const firstUpdate = useRef(true);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (!scrollOnExpand) {
      return;
    }

    if (isExpanded && !firstUpdate.current) {
      setTimeout(() => {
        if (ref.current) {
          scrollIntoView(ref.current, 80);
        }
      }, animationDurationFastMs);
    }
  }, [isExpanded, scrollOnExpand, ref, defaultExpanded, scrollIntoView]);

  return (
    <div className={styles.container} data-cy="expand-section-container" ref={ref}>
      {(!isExpanded || (isExpanded && persistToggle)) && (
        <>
          {toggleElement ? (
            <div onClick={handleToggle}>{toggleElement(isExpanded)}</div>
          ) : (
            <div
              className={`${styles.toggle} ${persistToggle ? styles.persists : null}`}
              onClick={handleToggle}
              data-cy="expand-section-default-toggle"
            >
              {toggleTitle}
              <IconButton
                icon={<Chevron />}
                size="small"
                style={{ transform: `rotate(${isExpanded ? "-90deg" : "90deg"})` }}
                className={styles.toggleIcon}
              />
            </div>
          )}
        </>
      )}
      <div className={styles.body} data-cy="expand-section-body">
        <AnimatePresence initial={false}>
          {isExpanded && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{
                opacity: {
                  duration: animationDurationFastS / 2,
                },
                height: {
                  duration: animationDurationFastS,
                },
                ease: animationCurveEaseOut,
              }}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default ExpandSection;
