import React from "react";

import { ReactComponent as LinkChevron } from "@assets/icons/link_chevron.svg";

import Row from "@components/row/Row";
import { IconButton } from "@components/iconbutton/IconButton";

interface DepositRowProps {
  title: string;
  subtitle?: string;
  icon: React.ReactNode;
  hideNavIcon?: boolean;
  className?: string;
  onClick?: () => void;
  trailingContent?: React.ReactNode;
}

export const DepositRow: React.FC<DepositRowProps> = ({
  title,
  subtitle,
  icon,
  hideNavIcon,
  onClick,
  trailingContent,
}) => {
  const chevron = <IconButton icon={<LinkChevron />} size="small" />;

  return (
    <Row
      onClick={onClick}
      trailingContent={trailingContent ? trailingContent : !hideNavIcon ? chevron : undefined}
      title={title}
      leadingContent={icon}
      hasHorizontalPadding={false}
      hasBottomBorder={false}
      subtitle={subtitle}
      compact
      dataCy={"deposit-row-" + title.replace(/\s+/g, "-").toLowerCase() + "-row"}
    />
  );
};
