import React from "react";

import Row from "@components/row/Row";
import Card from "@components/card/Card";
import { RowGroup } from "@components/row/RowGroup";
import { Callout } from "@components/callout/Callout";
import { LinkButton } from "@components/button/Button";
import { BodyText, TextBlock } from "@components/text/Text";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { NetworkChip } from "@components/network_chip/NetworkChip";
import { SupportedUSDStableCoinsSheet } from "@components/qr/SupportedUSDStableCoinsSheet/SupportedUSDStableCoinsSheet";

import { useDisplayName } from "@hooks";
import { copy, shortAddr } from "@helpers";
import { useAccount } from "@contexts/AccountContext";

import { ReactComponent as EcoIcon } from "@assets/icons/eco.svg";
import { ReactComponent as USDCIcon } from "@assets/icons/usdc.svg";
import { ReactComponent as BaseIcon } from "@assets/icons/base.svg";
import { ReactComponent as OptimismIcon } from "@assets/icons/optimism.svg";

interface CopyAddressSheetProps {
  address: string;
  open: boolean;
  onClose: () => void;
}

export const CopyAddressSheet = ({ open, onClose, address }: CopyAddressSheetProps) => {
  const [stableCoinSheetOpen, setStableCoinSheetOpen] = React.useState(false);
  const account = useAccount();
  const { beamname } = useAccount();
  const displayName = useDisplayName(address);

  const userIsViewer = account.address?.toLowerCase() === address.toLowerCase();
  const title = userIsViewer ? "Your Beam Address" : displayName ? `@${displayName}'s Beam Address` : "Beam Address";

  const networkChipRef = React.useRef(null);

  return (
    <>
      <BottomSheet title={title} onClose={onClose} closeOnShimTap isOpen={open} desktopDisplay="overlay">
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "var(--size-medium)",
              flexDirection: "column",
            }}
          >
            <TextBlock>
              <BodyText centered>
                {userIsViewer ? (
                  <>
                    The below address is your Beam wallet address. Others can send funds directly to this address. Only
                    use this if you know what you are doing.
                  </>
                ) : beamname?.exists ? (
                  <>
                    The below address is @{displayName}&apos;s Beam wallet address. You can funds directly to this
                    address. Only use this if you know what you are doing.
                  </>
                ) : (
                  <>You can send funds directly to this address. Only use this if you know what you are doing.</>
                )}
              </BodyText>
            </TextBlock>

            <Callout>
              <BodyText light>
                <b>Only send supported USD stablecoins or ECO, and only on Optimism or Base.</b> Sending on another
                network will result in loss of funds.
              </BodyText>
            </Callout>

            <div />
            <div />

            <Card style="muted" divStyle={{ width: "100%" }}>
              <RowGroup>
                <Row hasHorizontalPadding={false}>
                  <BodyText bold>Supported on Beam</BodyText>
                </Row>
                <Row
                  deemphasizeTitle
                  hasHorizontalPadding={false}
                  title="Networks"
                  trailingContent={
                    <div style={{ display: "flex", flexDirection: "row", gap: "var(--size-medium)" }}>
                      <NetworkChip title="Optimism" icon={<OptimismIcon />} />
                      <NetworkChip title="Base" icon={<BaseIcon />} />
                    </div>
                  }
                />
                <Row
                  deemphasizeTitle
                  hasHorizontalPadding={false}
                  title="Currencies"
                  trailingContent={
                    <div style={{ display: "flex", flexDirection: "row", gap: "var(--size-medium)" }}>
                      <div ref={networkChipRef}>
                        <NetworkChip
                          title="USD Stablecoins"
                          icon={<USDCIcon />}
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            setStableCoinSheetOpen(true);
                          }}
                        />
                      </div>
                      <NetworkChip title="ECO" icon={<EcoIcon />} />
                    </div>
                  }
                />
              </RowGroup>
            </Card>

            <Card style="muted" divStyle={{ width: "100%" }}>
              <RowGroup>
                <Row
                  deemphasizeTitle
                  hasHorizontalPadding={false}
                  title={"Address"}
                  trailingContent={
                    <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
                      <BodyText>
                        {shortAddr(address)} ·{" "}
                        <LinkButton
                          title="Copy"
                          onClick={() => copy(address, "Copied Beam address. Only send funds on Optimism or Base.")}
                        />
                      </BodyText>
                    </div>
                  }
                />
              </RowGroup>
            </Card>
          </div>
        </div>
      </BottomSheet>
      <SupportedUSDStableCoinsSheet
        open={stableCoinSheetOpen}
        targetRef={networkChipRef}
        onClose={() => {
          setStableCoinSheetOpen(false);
        }}
      />
    </>
  );
};
