import React, { useEffect } from "react";
import { OnboardingStep, useOnboarding } from "../Onboarding";

import styles from "./SendMoney.module.css";

import { ReactComponent as BeamBrand } from "@assets/images/beam_icon_accent.svg";
import { ReactComponent as Arrow } from "@assets/icons/arrow_right.svg";
import { ReactComponent as Profile } from "@assets/icons/profile-accent.svg";

import { BodyText, TextBlock } from "@components/text/Text";
import { useAccount } from "@contexts/AccountContext";

export const SendMoney: React.FC = () => {
  const { lastTransfer } = useAccount();
  const { nextStep, setButtonAction, setButtonTitle, onClose } = useOnboarding();

  useEffect(() => {
    const isDone = !!lastTransfer;

    setButtonAction(() => (isDone ? onClose() : nextStep()));
    setButtonTitle(isDone ? "Done" : "Get started");
  }, [lastTransfer]);

  return (
    <>
      <OnboardingStep
        isDone={!!lastTransfer}
        brand={
          <>
            <BeamBrand />
            <Arrow className={styles.arrow} />
            <Profile width={58} height={58} />
          </>
        }
        stepTitle="Send someone money"
        postStepContent={
          <TextBlock>
            <BodyText>
              Scan a friend’s Beam Code, or create a Beam Link and text it to someone who hasn’t even seen Beam yet.
            </BodyText>
            <BodyText>You can send either stablecoins or ECO.</BodyText>
          </TextBlock>
        }
      />
    </>
  );
};
