import React from "react";

import Card from "@components/card/Card";
import { Button } from "@components/button/Button";
import { useNavigate } from "react-router-dom";

import "../access/SaveAccessCard.css";

export const LoadAccountCard = () => {
  const navigate = useNavigate();
  return (
    <Card
      title={<span className="Card_title-text">Already have a Beam account?</span>}
      subtitle="Log in to restore access to your funds"
    >
      <Button secondary title="Log in" onClick={() => navigate("/load-account")} />
    </Card>
  );
};
