import React from "react";

import * as Onramp from "@helpers/onramp";
import { useAccount } from "@contexts/AccountContext";
import { OnRampSheet } from "@components/deposits/OnRampSheet/OnRampSheet";
import { ReactComponent as KadoIcon } from "@assets/icons/kado_icon.svg";
import { Spinner } from "@components/spinner/Spinner";
import { isDesktop } from "react-device-detect";

interface KadoOnRampSheetProps {
  open?: boolean;
  recommended?: boolean;
  renderAsSheet?: boolean;
  onClose?: () => void;
}

export const KadoOnRampSheet: React.FC<KadoOnRampSheetProps> = ({
  open,
  recommended,
  onClose,
  renderAsSheet = true,
}) => {
  const { address } = useAccount();

  if (!address) return null;

  const url = Onramp.getKadoUrl(address);

  const content = (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "calc(50% - 12px)",
          top: "calc(50% - 12px)",
          zIndex: 1,
        }}
      >
        <Spinner />
      </div>
      <iframe
        src={url}
        height="100%"
        width="100%"
        title="Onramper widget"
        allow="accelerometer; autoplay; camera; gyroscope; payment"
        id="onramper-widget"
        style={{
          zIndex: 2,
          position: "relative",
          ...(recommended
            ? {
                marginTop: -4,
                height: "calc(100% + 12px)",
              }
            : {}),
        }}
      />
    </div>
  );
  if (renderAsSheet) {
    return (
      <OnRampSheet
        isOpen={open}
        recommended={recommended}
        name="Kado"
        title="Deposit USDC via Kado"
        subtitle={
          isDesktop
            ? "This view will close automatically once funds are received"
            : "This will close once funds are received"
        }
        onClose={onClose}
        icon={<KadoIcon style={{ width: 24, height: 24 }} />}
        content={content}
      />
    );
  }

  return content;
};
