import * as T from "@radix-ui/react-tooltip";
import React from "react";
import styles from "./Tooltip.module.css";

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode | string;
}

export const Tooltip = ({ children, content }: TooltipProps) => {
  return (
    <T.Root>
      <T.Trigger asChild>{children}</T.Trigger>
      <T.Portal container={document.body}>
        <T.Content className={styles.wrapper} sideOffset={4}>
          {content}
        </T.Content>
      </T.Portal>
    </T.Root>
  );
};
