import React from "react";
import { Space } from "antd";

import Card from "@components/card/Card";

import { useWeb3AuthState } from "@redux/slides/web3auth.slide";
import { AuthMethod } from "@modules/web3auth/constants";

import { ReactComponent as MailIcon } from "@assets/icons/mail-icon.svg";
import { ReactComponent as XLogo } from "@assets/icons/x-logo.svg";

import "../access/SaveAccessCard.css";
import { BodyText } from "@components/text/Text";

const getIcon = (authMethod: AuthMethod) => {
  switch (authMethod) {
    case AuthMethod.EMAIL:
      return <MailIcon />;
    case AuthMethod.TWITTER:
      return <XLogo width={16} />;
  }
};

export const SavedAccountsCard = () => {
  const { userInfo } = useWeb3AuthState();

  if (!userInfo) return null;

  return (
    <Card title="Connected Account">
      <AuthRow authMethod={userInfo.typeOfLogin} username={userInfo.name} />
    </Card>
  );
};

interface AuthRowProps {
  authMethod: AuthMethod;
  username: string;
}

const AuthRow: React.FC<AuthRowProps> = ({ authMethod, username }) => {
  return (
    <Space size="small" content="center" className="SavedAccountsCard-row">
      <div className="SavedAccountsCard-icon">{getIcon(authMethod)}</div>
      <BodyText bold>{username}</BodyText>
    </Space>
  );
};
