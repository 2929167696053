import React, { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import { Link, LinkProps } from "react-router-dom";

import "./Button.css";
import { Spinner } from "@components/spinner/Spinner";

export interface ButtonProps {
  title?: string;
  size?: "normal" | "small" | "xsmall";
  color?: "success" | "warning" | "light";
  type?: "primary" | "secondary" | "invisible";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  disabled?: boolean;
  subtitle?: React.ReactNode | string;
  href?: string;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  htmlType?: "button" | "submit" | "reset" | undefined;
  secondary?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
  className?: string;
  style?: React.CSSProperties;
  backgroundColor?: string;
}

interface LinkButtonProps {
  title?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: "normal" | "small";
  href?: string;
  target?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const Button = forwardRef<HTMLButtonElement | LinkProps, ButtonProps>(
  (
    {
      title,
      type,
      color,
      subtitle,
      loading,
      secondary,
      href,
      disabled,
      onClick,
      leadingIcon,
      trailingIcon,
      htmlType,
      size,
      target,
      className,
      backgroundColor,
      ...rest
    },
    forwardedRef,
  ) => {
    const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = event => {
      if (loading || disabled) {
        return;
      }

      onClick?.(event);
    };

    const buttonClass = classNames("Button", {
      "is-disabled": disabled,
      [`color-${color}`]: color,
      [`Button-${size}`]: size,
      "Button-secondary": secondary,
      "Button-invisible": type === "invisible",
      [`${className}`]: className !== undefined,
    });

    const buttonBody = (
      <>
        {loading ? (
          <Spinner color="var(--color-button-foreground)" size={size} />
        ) : (
          <>
            {leadingIcon && <span className="Button_icon">{leadingIcon}</span>}
            {(title || subtitle) && (
              <span className="Button_text">
                {title && <span>{title}</span>}
                {subtitle}
              </span>
            )}
            {trailingIcon && <span className="Button_icon">{trailingIcon}</span>}
          </>
        )}
      </>
    );

    if (href) {
      return (
        <Link
          to={href}
          target={target}
          type={htmlType}
          className={buttonClass}
          aria-disabled={disabled}
          data-cy={title && `button-${title.toLowerCase().replace(/\s+/g, "-")}`}
          style={{
            backgroundColor,
          }}
          {...rest}
          ref={forwardedRef as ForwardedRef<HTMLAnchorElement>}
        >
          {buttonBody}
        </Link>
      );
    }

    return (
      <button
        type={htmlType}
        onClick={handleButtonClick}
        className={buttonClass}
        disabled={disabled}
        aria-disabled={disabled}
        data-cy={title && `button-${title.toLowerCase().replace(/\s+/g, "-")}`}
        style={{
          backgroundColor,
        }}
        {...rest}
        ref={forwardedRef as ForwardedRef<HTMLButtonElement>}
      >
        {buttonBody}
      </button>
    );
  },
);

export const LinkButton = forwardRef<HTMLButtonElement & LinkProps, LinkButtonProps>(
  ({ title, onClick, size, href, target, disabled, style, ...rest }, forwardedRef) => {
    if (href) {
      return (
        <Link
          aria-disabled={disabled}
          to={href}
          className={`LinkButton LinkButton-${size}`}
          target={target}
          data-cy={title && `link-button-${title.toLowerCase().replace(/\s+/g, "-")}`}
          style={style}
          {...rest}
          ref={forwardedRef}
        >
          <span>{title}</span>
        </Link>
      );
    }

    return (
      <button
        disabled={disabled}
        onClick={onClick}
        className={`LinkButton LinkButton-${size}`}
        data-cy={title && `link-button-${title.toLowerCase().replace(/\s+/g, "-")}`}
        style={style}
        {...rest}
        ref={forwardedRef}
      >
        <span>{title}</span>
      </button>
    );
  },
);
