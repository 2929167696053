import * as Sentry from "@sentry/react";
import { HttpClient } from "@sentry/integrations";

enum Environment {
  PRODUCTION = "production",
  PREPRODUCTION = "preproduction",
  STAGING = "staging",
  OTHER = "other",
}

function getEnvironment() {
  switch (window.location.host) {
    case "beam.eco":
      return Environment.PRODUCTION;
    case "preprod.beam.eco":
      return Environment.PREPRODUCTION;
    case "staging.beam.eco":
      return Environment.STAGING;
    default:
      return Environment.OTHER;
  }
}

export function configure() {
  const env = getEnvironment();

  if (env === Environment.OTHER) {
    // Disable sentry for other environments
    return;
  }

  Sentry.init({
    dsn: "https://abca86e4577d4a8087dda06b776f9b81@o4505604587061248.ingest.sentry.io/4505605079760896",
    environment: env,
    sendDefaultPii: true,
    replaysSessionSampleRate: env === Environment.PRODUCTION ? 0.1 : 1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay(), new HttpClient()],
    denyUrls: [/extensions\//i, /^chrome:\/\//i],
  });
}
