import { useStackup } from "@contexts/StackupContext";
import { SimpleAccountFactoryMigration } from "@modules/migration/SimpleAccountFactoryMigration";
import React, { useEffect, useState, useContext, createContext } from "react";
import { logError } from "@helpers";

const FactoryAddressContext = createContext<string | undefined>(undefined);

export const useFactoryAddress = () => useContext(FactoryAddressContext);

export const FactoryAddressProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { signer } = useStackup();

  const [oldFactory, setOldFactory] = useState<string>();

  useEffect(() => {
    const getOldFactory = async () => {
      if (!signer || oldFactory) return;
      try {
        const address = await SimpleAccountFactoryMigration.getSender(signer).then(wallet => wallet.getSender());
        setOldFactory(address);
      } catch (error) {
        logError("[useOldFactoryAddress]", error);
      }
    };
    getOldFactory();
  }, [signer]);

  return <FactoryAddressContext.Provider value={oldFactory}>{children}</FactoryAddressContext.Provider>;
};
