import React, { useState } from "react";

import Row from "@components/row/Row";
import Card from "@components/card/Card";
import { BodyText } from "@components/text/Text";
import { Button } from "@components/button/Button";
import { Callout } from "@components/callout/Callout";
import { ImportPrivateKeyPopup } from "@components/account/advanced/ImportPrivateKeyPopup";

import { useStackup } from "@contexts/StackupContext";

import { copy } from "@helpers/copy";
import { shortAddr } from "@helpers";

const hiddenText = "*".repeat(80);

export const AdvancedSettingsCard = () => {
  const { signer, address } = useStackup();

  const [show, setShow] = useState(false);
  const [showImportSheet, setShowImportSheet] = useState(false);

  return (
    <>
      <Card title="Advanced" isCollapsed>
        <Callout includeIcon={false}>
          Only send funds to this address if you know what you are doing. The Beam Wallet currently only supports USDC
          native, USDCe, USDT, USDV, USDbC and ECO tokens on the Optimism and Base networks.
        </Callout>

        {address && (
          <Row
            title="Address"
            hasHorizontalPadding={false}
            hasBottomBorder
            deemphasizeTitle
            trailingContent={
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                  alignItems: "center",
                  gap: "var(--size-small)",
                }}
              >
                <div
                  style={{
                    minWidth: 0,
                    flex: 1,
                  }}
                >
                  <BodyText>{shortAddr(address)}</BodyText>
                </div>
                <div style={{ flexShrink: 0 }}>
                  <Button title="Copy" size="small" secondary onClick={() => copy(address, "Copied Address.")} />
                </div>
              </div>
            }
          />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "var(--size-medium)",
            marginTop: "var(--size-medium)",
          }}
        >
          <Callout includeIcon={false}>
            <span>
              <p>
                <strong>Warning: Anyone who has the code below can access all of your funds.</strong> The Beam team will
                never ask you for this. Please do not give it to anyone else or store it in an insecure location.{" "}
                <strong>
                  Only reveal or copy it if you know what you are doing, and please be very careful of scams.
                </strong>
              </p>
              For advanced users: this is the private key for the <em>signer</em> address for your Beam smart contract
              wallet. It does not hold any funds itself. Importing it into another wallet will not straightforwardly
              show you your funds — you will need to use it to sign for the smart contract account.
            </span>
          </Callout>
          <Card style="muted">
            <div
              style={{
                display: "flex",
                gap: "var(--size-medium)",
                alignItems: "center",
                overflow: "hidden",
                wordBreak: "break-word",
              }}
            >
              <BodyText bold small>
                <p className="font-monospace" onClick={() => show && copy(signer.privateKey, "Copied Private Key.")}>
                  {show ? signer.privateKey : hiddenText}
                </p>
              </BodyText>
              <div style={{ flexShrink: 0 }}>
                <Button secondary size="small" onClick={() => setShow(!show)} title={show ? "Hide" : "Show"} />
              </div>
            </div>
          </Card>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "var(--size-medium)",
            marginTop: "var(--size-medium)",
          }}
        >
          <Callout includeIcon={false}>
            <span>
              <strong>Warning: importing a private key will overwrite your current wallet.</strong> Please make sure you
              have saved access to it before importing a different private key.
            </span>
          </Callout>
          <Button size="small" secondary onClick={() => setShowImportSheet(true)} title="Import Private Key" />
        </div>

        <ImportPrivateKeyPopup isOpen={showImportSheet} onClose={() => setShowImportSheet(false)} />
      </Card>
    </>
  );
};
