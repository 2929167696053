import { useMemo } from "react";
import { BigNumber, ethers } from "ethers";
import { QueryResult, useQuery } from "@apollo/client";

import { UNISWAP_POOLS } from "@constants/uniswap";
import { APOLLO_CLIENTS } from "@constants/subgraphs";
import { VENDING_MACHINE_ADDRESSES } from "@constants/contracts";
import { getTokenInfo, Token, TokenInfo } from "@constants/tokens";
import { getNetwork, getSupportedNetworks, Network } from "@constants/network";

import { All_HistoryQuery } from "@gql/graphql";
import { ALL_HISTORY_QUERY } from "@queries/HISTORY";

import { useStackup } from "@contexts/StackupContext";
import { useFactoryAddress } from "@contexts/FactoryAddressContext";

import { logError } from "@helpers";
import { isSameAddress } from "@helpers/contracts";

import { useConfigFee } from "@redux/slides/config.slide";

import { BeamLinkStorage } from "@modules/peanut";
import { getPeanutAddress } from "@modules/peanut/constants";

import { BeamLink } from "types/beamlink";
import { ExternalAccount, Transfer } from "types/transfer";
import { isUserTypeEqual } from "@modules/token-managers/tokens";
import { extractBeamname } from "@hooks/useDisplayName";

const { Zero } = ethers.constants;

export type VendingMachineSwap = {
  sent?: {
    token: TokenInfo;
    amount: BigNumber;
  };
  received?: {
    token: TokenInfo;
    amount: BigNumber;
  };
};

export type TransferCluster = {
  id: string;
  transfers: Transfer[]; // list of transfers and their networks
  beamlinks: BeamLink[]; // list of beamlinks associated with a transfer
  isMigration: boolean;
  vendingMachineSwap?: VendingMachineSwap;
  to: ExternalAccount;
  from: ExternalAccount;
  transactionHashes: Set<string>;
  timestamp: number; // timestamp from newest transaction
  amount: BigNumber; // total not including fee
  fee: BigNumber;
  token: TokenInfo;
};

const isVendingMachineSwap = (transfer: Transfer) => {
  return (
    isSameAddress(transfer.to.address, VENDING_MACHINE_ADDRESSES) ||
    isSameAddress(transfer.from.address, VENDING_MACHINE_ADDRESSES)
  );
};

const isBeamLink = ({ to, from, network }: Transfer) => {
  return (
    isSameAddress(to.address, getPeanutAddress(network.chainId)) ||
    isSameAddress(from.address, getPeanutAddress(network.chainId))
  );
};

const TRANSFER_FRAME = 30; // threshold for grouping transfers in seconds

export const useHistory = (address?: string) => {
  const fee = useConfigFee();

  const { address: walletAddress } = useStackup();
  const oldFactoryAddress = useFactoryAddress();

  const supportedNetworkData: Record<
    Network,
    QueryResult<All_HistoryQuery, { address: string; offset: number }> | undefined
  > = {
    base: undefined,
    optimism: undefined,
  };

  const isFee = (transfer: Transfer | TransferCluster) => {
    const feeRecipients = [fee?.recipients.base.toLowerCase(), fee?.recipients.optimism.toLowerCase()];
    return Boolean(transfer && fee && feeRecipients.includes(transfer.to.address));
  };

  const isMigration = (transfer: Transfer | TransferCluster) => {
    return Boolean(
      oldFactoryAddress &&
        walletAddress &&
        isSameAddress(transfer.from.address, oldFactoryAddress) &&
        isSameAddress(transfer.to.address, walletAddress),
    );
  };

  const skip = !address || address === ethers.constants.AddressZero;
  let loading = skip;

  for (const network of getSupportedNetworks()) {
    const client = APOLLO_CLIENTS[network.network!];
    if (!client) throw new Error("Apollo client not defined for network " + network.id);
    const result = useQuery(ALL_HISTORY_QUERY, {
      client,
      skip,
      variables: {
        offset: 0,
        address: address ? address.toLowerCase() : ethers.constants.AddressZero,
      },
    });
    loading = loading || result.loading;
    if (result.error) {
      logError("[history-query]", result.error);
    } else if (!result.loading && result.data) {
      result.startPolling(10_000);
      // concat to list of transfers
      supportedNetworkData[network.network!] = result;
    }
  }

  // fetch more transfers on every chain
  const getMore = () => {
    for (const network in supportedNetworkData) {
      const result = supportedNetworkData[network as Network];
      if (!result) continue;
      const { data, fetchMore } = result;
      fetchMore({
        variables: {
          offset: data?.transfers.length,
        },
      });
    }
  };

  const refetch = () => {
    for (const network in supportedNetworkData) {
      const result = supportedNetworkData[network as Network];
      if (!result) continue;
      result.refetch();
    }
  };

  const transferClusters = useMemo(() => {
    let transfers: Transfer[] = [];
    let beamlinks: BeamLink[] = [];

    const beamLinkStorage = BeamLinkStorage.init();

    // for now treat every transfer on every chain as unique
    for (const _network in supportedNetworkData) {
      const network = _network as Network;
      const result = supportedNetworkData[network];
      if (!result) continue;
      const { data } = result;

      if (data) {
        if (data.transfers.length) {
          transfers = transfers.concat(
            data.transfers.map(transfer => {
              const [tokenId] = transfer.id.split("-");
              return {
                id: transfer.id,
                txHash: transfer.txHash,
                timestamp: parseInt(transfer.timestamp),
                token: getTokenInfo(tokenId as Token, network),
                to: {
                  address: transfer.to.id,
                  beamname: extractBeamname(transfer.to.beamnames),
                },
                from: {
                  address: transfer.from.id,
                  beamname: extractBeamname(transfer.from.beamnames),
                },
                amount: BigNumber.from(transfer.amount),
                network: getNetwork(network),
              };
            }),
          );
        }
        if (data.beamLinks.length) {
          beamlinks = beamlinks.concat(
            data.beamLinks.map(beamlink => ({
              id: beamlink.id,
              createdAt: parseInt(beamlink.createdAt),
              createdTxHash: beamlink.createdTxHash,
              sender: {
                address: beamlink.sender.id,
                beamname: extractBeamname(beamlink.sender.beamnames),
              },
              recipient: beamlink.recipient
                ? {
                    address: beamlink.recipient.id,
                    beamname: extractBeamname(beamlink.recipient.beamnames),
                  }
                : null,
              claimedAt: beamlink.claimedAt ? parseInt(beamlink.claimedAt) : null,
              claimedTxHash: beamlink.claimedTxHash,
              amount: BigNumber.from(beamlink.amount),
              timestamp: beamlink.claimedAt ? parseInt(beamlink.claimedAt) : parseInt(beamlink.createdAt),
              token: getTokenInfo(beamlink.token.id as Token, network),
              network: getNetwork(network),
              url: beamLinkStorage.get(network, beamlink.id)?.toURL(),
            })),
          );
        }
      }
    }

    transfers = transfers.sort(({ timestamp: a }, { timestamp: b }) => b - a);
    beamlinks = beamlinks.sort(({ timestamp: a }, { timestamp: b }) => b - a);

    return transfers
      .filter(
        transfer =>
          !UNISWAP_POOLS.some(pool => isSameAddress(pool, transfer.to.address)) &&
          !UNISWAP_POOLS.some(pool => isSameAddress(pool, transfer.from.address)),
      )
      .reduce<TransferCluster[]>((arr, transfer) => {
        const beamlink = isBeamLink(transfer)
          ? beamlinks.find(
              beamlink => transfer.txHash === beamlink.createdTxHash || transfer.txHash === beamlink.claimedTxHash,
            )
          : undefined;

        if (isFee(transfer)) {
          // loop through past transfers
          for (let i = arr.length - 1; i >= 0; i--) {
            const currCluster = arr[i];
            // if curr cluster is a send without a fee
            if (
              currCluster.from.address === address?.toLowerCase() &&
              currCluster.fee.isZero() &&
              isUserTypeEqual(currCluster.token.id, transfer.token.id) &&
              Math.abs(transfer.timestamp - currCluster.timestamp) < TRANSFER_FRAME
            ) {
              // add fee to cluster and return
              currCluster.fee = transfer.amount;
              arr[i] = currCluster;
              return arr;
            }
          }
          // if fee wasn't added to any existing clusters, create new empty cluster with a fee
          const newCluster: TransferCluster = {
            id: transfer.id,
            transfers: [],
            beamlinks: [],
            transactionHashes: new Set([transfer.txHash]),
            from: transfer.from,
            to: transfer.to,
            isMigration: false,
            amount: Zero,
            timestamp: transfer.timestamp,
            fee: transfer.amount,
            token: transfer.token,
          };
          arr.push(newCluster);
        } else {
          for (let i = arr.length - 1; i >= 0; i--) {
            const currCluster = arr[i];

            // if the transferCluster is within the timeframe check
            if (Math.abs(transfer.timestamp - currCluster.timestamp) >= TRANSFER_FRAME) break;

            // if vending swap check if currCluster is also vending swap add this to the sent/received.
            if (isVendingMachineSwap(transfer)) {
              if (!currCluster.vendingMachineSwap) currCluster.vendingMachineSwap = {};
              const isReceivedTx = isSameAddress(transfer.from.address, VENDING_MACHINE_ADDRESSES);
              currCluster.vendingMachineSwap[isReceivedTx ? "received" : "sent"] = {
                amount: transfer.amount,
                token: transfer.token,
              };
              currCluster.to = isReceivedTx ? transfer.from : transfer.to;
              currCluster.from = isReceivedTx ? transfer.to : transfer.from;
              currCluster.transfers.push(transfer);
              arr[i] = currCluster;
              return arr;
            }

            const includesSameTokenTransfer = currCluster.transfers.some(
              _transfer => _transfer.token.id === transfer.token.id && _transfer.network.id === transfer.network.id,
            );

            if (
              currCluster.transactionHashes.has(transfer.txHash) ||
              (isUserTypeEqual(currCluster.token.id, transfer.token.id) &&
                !includesSameTokenTransfer &&
                (isFee(currCluster) ||
                  currCluster.transactionHashes.has(transfer.txHash) ||
                  beamlink ||
                  isSameAddress(currCluster.to.address, transfer.to.address)))
            ) {
              // add to this cluster and return
              if (beamlink) {
                currCluster.beamlinks.push(beamlink);
              } else if (oldFactoryAddress && walletAddress) {
                currCluster.isMigration = isMigration(currCluster);
              }
              currCluster.transactionHashes.add(transfer.txHash);
              currCluster.transfers.push(transfer);
              currCluster.to = transfer.to;
              currCluster.amount = currCluster.amount.add(transfer.amount);

              arr[i] = currCluster;
              return arr;
            }
          }

          // if no clusters were in range, create a new one
          const newCluster: TransferCluster = {
            id: transfer.id,
            to: transfer.to,
            from: transfer.from,
            fee: Zero,
            amount: transfer.amount,
            token: transfer.token,
            timestamp: transfer.timestamp,
            transfers: [transfer],
            beamlinks: beamlink ? [beamlink] : [],
            transactionHashes: new Set([transfer.txHash]),
            isMigration: Boolean(!beamlink && !isVendingMachineSwap(transfer) && isMigration(transfer)),
            vendingMachineSwap: isVendingMachineSwap(transfer)
              ? {
                  [isSameAddress(transfer.from.address, VENDING_MACHINE_ADDRESSES) ? "received" : "sent"]: {
                    token: transfer.token,
                    amount: transfer.amount,
                  },
                }
              : undefined,
          };
          arr.push(newCluster);
        }
        return arr;
      }, [])
      .filter(
        transferCluster =>
          !transferCluster.vendingMachineSwap ||
          (!!transferCluster.vendingMachineSwap.received && !!transferCluster.vendingMachineSwap.sent),
      )
      .sort(({ timestamp: a }, { timestamp: b }) => b - a);
  }, [...Object.values(supportedNetworkData)]);

  return {
    loading,
    transferClusters,
    getMore,
    refetch,
  };
};
