import React from "react";
import { ConfigProvider } from "antd";
import { createRoot } from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import App from "./App";
import { store } from "@redux/store";
import * as Sentry from "@modules/sentry";
import { Auth0CustomContext } from "@contexts/Auth0CustomContext";
import { APOLLO_CLIENTS, NETWORK } from "@constants";

const queryClient = new QueryClient();
const apolloClient = APOLLO_CLIENTS[NETWORK.network!];

Sentry.configure();

const root = createRoot(document.getElementById("root")!);
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apolloClient}>
        <ReduxProvider store={store}>
          <Auth0CustomContext>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#2F80ED",
                  colorIcon: "#FFFFFF",
                  colorLink: "#06153c",
                  fontFamily: "var(--font-stack-default)",
                },
              }}
            >
              <App />
            </ConfigProvider>
          </Auth0CustomContext>
        </ReduxProvider>
      </ApolloProvider>
    </QueryClientProvider>
  </BrowserRouter>,
);
