import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { logError } from "@helpers";
import { getBeamWallet } from "@helpers/bundler";

interface IStackupProvider {
  address?: string;
  signer: ethers.Wallet;
}
interface StackupProviderProps {
  signer: ethers.Wallet;
}

const StackupContext = React.createContext<IStackupProvider>({
  signer: {} as ethers.Wallet,
});

export const useStackup = () => React.useContext<IStackupProvider>(StackupContext);

export const StackupProvider: React.FC<React.PropsWithChildren<StackupProviderProps>> = ({ signer, children }) => {
  const [address, setAddress] = useState<string>();

  useEffect(() => {
    (async () => {
      // Init Simple Account
      try {
        const simpleAccount = await getBeamWallet(signer, "optimism");
        setAddress(simpleAccount.getSender());
      } catch (error) {
        logError("[create-simple-account]", error);
      }
    })();
  }, [signer]);

  return <StackupContext.Provider value={{ signer, address }}>{children}</StackupContext.Provider>;
};
