import { useQuery } from "@tanstack/react-query";

import config from "@constants/config";
import { eco, usdc } from "@components/fund/constants";

export const useVendingMachineBalance = () => {
  return useQuery(
    ["contract-balance"],
    async () => {
      return {
        eco: await eco.balanceOf(config.optimism.contracts.vendingMachine),
        usdc: await usdc.balanceOf(config.optimism.contracts.vendingMachine),
      };
    },
    {
      refetchInterval: 60_000, // 60s,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
  );
};
