import { notify, round } from "@helpers";
import { getNetwork, getUserTokenByToken, Network, Token, TOKENS, USER_TOKENS, UserToken } from "@constants";
import { isAddressBlacklisted } from "@helpers/ofacBlacklist";
import { getTokenManager } from "@modules/token-managers/tokens";
import { ethers } from "ethers";

export const ECONET_V1_REGEX = /econet:v0:[a-zA-Z]{2,15}:[a-zA-Z]{2,15}:0x[a-fA-F0-9]{40}:(\d*\.?\d*):(\d*\.?\d*)/;

export enum EconetVersion {
  V0 = "v0",
}

export class Econet {
  public readonly version: EconetVersion;
  public readonly network: Network;
  public readonly token: UserToken;
  public readonly merchant: string;
  public readonly amount: ethers.BigNumber;
  public readonly reward: ethers.BigNumber;

  constructor(opts: {
    version: EconetVersion;
    network: Network;
    token: UserToken;
    merchant: string;
    amount: ethers.BigNumber;
    reward: ethers.BigNumber;
  }) {
    this.version = opts.version;
    this.network = opts.network;
    this.token = opts.token;
    this.merchant = opts.merchant;
    this.amount = opts.amount;
    this.reward = opts.reward;
  }

  public static from(text: string): Econet {
    const [, version, _network, _token, merchant, merchantAmount, rewardAmount] = text.split(":");

    if (version !== EconetVersion.V0) {
      notify({
        content: "Unsupported QR code, please ask merchant to use a non-EcoNet code.",
        type: "error",
      });
      throw new Error("Invalid Econet version");
    }

    // Network defaults to Optimism if it is not found
    const network = getNetwork(_network as Network);
    if (network.network !== _network) {
      notify({ content: "Unsupported network.", type: "error" });
      throw new Error("Unsupported network");
    }

    let userToken: UserToken;
    if (USER_TOKENS.includes(_token as UserToken)) {
      userToken = _token as UserToken;
    } else if (TOKENS.includes(_token as Token)) {
      userToken = getUserTokenByToken(_token as Token);
    } else {
      notify({ content: "Unsupported token.", type: "error" });
      throw new Error("Unsupported token");
    }

    if (isAddressBlacklisted(merchant)) {
      notify({ type: "error", content: "For regulatory reasons, this address cannot be sent to." });
      throw new Error("Invalid QR code");
    }

    const tokenManager = getTokenManager(userToken);
    const EX = RegExp;
    const isTokenAmount = new EX(`^\\d*(\\.\\d{0,${tokenManager.decimals}})?$`);

    if (!ethers.utils.isAddress(merchant) || !isTokenAmount.test(merchantAmount) || !isTokenAmount.test(rewardAmount)) {
      notify({ content: "Invalid QR code, please ask merchant to use a non-EcoNet code.", type: "error" });
      throw new Error("Invalid QR code");
    }

    // TODO: need to set fees to 0 for merchant sponsorship, update confirmation sheet to show rebate too
    if (round(parseFloat(rewardAmount), 2)) {
      notify({
        duration: 10_000,
        content: `You're saving ${rewardAmount} ${tokenManager.symbol} by paying with Beam!`,
        type: "success",
        id: "discount",
      });
    }

    return new Econet({
      version: EconetVersion.V0,
      merchant: merchant,
      network: network.network,
      token: tokenManager.userToken,
      amount: ethers.utils.parseUnits(merchantAmount, tokenManager.decimals),
      reward: ethers.utils.parseUnits(rewardAmount, tokenManager.decimals),
    });
  }

  toString() {
    const tokenManager = getTokenManager(this.token);
    return [
      "econet",
      this.version,
      this.network,
      this.token,
      this.merchant,
      this.formatNumber(ethers.utils.formatUnits(this.amount, tokenManager.decimals)),
      this.formatNumber(ethers.utils.formatUnits(this.reward, tokenManager.decimals)),
    ].join(":");
  }

  private formatNumber(number: string): string {
    if (number.endsWith(".0")) return number.substring(0, number.length - 2);
    return number;
  }
}
