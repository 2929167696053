import React, { useEffect, useMemo, useRef, useState } from "react";
import { ethers } from "ethers";
import { useNavigate, useParams } from "react-router-dom";
import { AnimatePresence, cubicBezier, easeOut, motion } from "framer-motion";
import Helmet from "react-helmet";

import { Header, QRPunkBlockie } from "@components";
import Row from "@components/row/Row";
import Card from "@components/card/Card";
import { BodyText } from "@components/text/Text";
import { Button } from "@components/button/Button";
import { Avatar } from "@components/avatar/Avatar";
import { Profile } from "@components/profile/Profile";
import { NumberPad } from "@components/numberpad/NumberPad";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { CurrencyDrawer, CurrencySelector } from "@components/home";
import { ConfirmSendSheet } from "@components/confirmsendsheet/ConfirmSendSheet";

import { useAppSelector } from "@redux";
import { logError, notify } from "@helpers";
import { useAccount } from "@contexts/AccountContext";
import { useCurrentToken } from "@contexts/TokenContext";
import { getTokenManager } from "@modules/token-managers/tokens";

import { useBeamname } from "@hooks/useBeamname";
import { getOperationFee } from "@hooks/useOperationFee";

import { ReactComponent as LinkChevron } from "@assets/icons/link_chevron.svg";
import { ReactComponent as BackArrow } from "@assets/icons/arrow_back.svg";

import styles from "./BeamProfile.module.css";

export const BeamProfile: React.FC = () => {
  const navigate = useNavigate();
  const { beamname } = useParams();

  const { token, setActiveToken } = useCurrentToken();
  const [currencyDrawerOpen, setCurrencyDrawerOpen] = useState(false);

  const { data, loading: beamnameLoading, error } = useBeamname(beamname);

  const [sending, setSending] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const selectorRef = useRef<HTMLDivElement>(null);

  const configFee = useAppSelector(state => state.config.fee);

  const tokenManager = useMemo(() => getTokenManager(token), [token]);

  const { balances } = useAccount();

  const [amount, setAmount] = useState("0");
  const [sendingToDetailsSheetOpen, setSendingToDetailsSheetOpen] = useState(false);

  const feeRaw = getOperationFee(token, configFee);
  const fee = feeRaw || ethers.constants.Zero;

  const maxSend = balances[token].total.sub(fee);
  const aboveMaxSend = ethers.utils.parseUnits(amount, tokenManager.decimals).gt(maxSend);

  useEffect(() => {
    if (error) {
      logError("[beam-profile-beamname-query]", error);
    } else if (beamname && !beamnameLoading && !data) {
      navigate("/");
      notify({ type: "error", content: "This Beam Name doesn't exist." });
    }
  }, [error, data, beamnameLoading, beamname]);

  useEffect(() => {
    if (!sending) {
      setAmount("0");
    }
  }, [sending]);

  const handleSelectorClick: React.MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    setCurrencyDrawerOpen(!currencyDrawerOpen);
  };

  return (
    <>
      <Helmet>
        <title>@{beamname} · Beam</title>
      </Helmet>
      <Header transparent={location.pathname === `/t/${token}/scan`}>
        <CurrencySelector value={token} onClick={handleSelectorClick} innerRef={selectorRef} />
      </Header>
      <div className={styles.wrapper}>
        <div className={styles.profile}>
          <Profile name={`@${beamname}`} address={data?.address} nameIsEmoji={false} showQrHelper={false} />
        </div>
        <div className={styles.qr}>
          <div className="Code_qr">
            <div className="Code_qrInner">
              <QRPunkBlockie address={data?.address} displayBeamname={beamname} />
            </div>
          </div>
          <BodyText>Scan the above Beam Code to send @{beamname} money</BodyText>
        </div>
        <div className={styles.button}>
          <Button title={`Send to @${beamname}`} onClick={() => setSending(true)} loading={sending} />
        </div>
      </div>
      <AnimatePresence>
        {sending && (
          <motion.div
            className={styles.overlay}
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 1.1 }}
            transition={{
              opacity: { ease: easeOut, duration: 0.1 },
              scale: { ease: cubicBezier(0.17, 0.84, 0.44, 1), duration: 0.15 },
            }}
          >
            <NumberPad
              className={styles.overlayNumberpad}
              fee={fee}
              token={token}
              value={amount}
              setValue={setAmount}
              keyboardDisabled={confirmationOpen}
              header={
                <div className={styles.headerActions}>
                  <button
                    className={styles.cancel}
                    onClick={() => {
                      setSending(false);
                    }}
                  >
                    <BackArrow />
                    Go back
                  </button>
                  <div
                    className={styles.sendTo}
                    onClick={() => {
                      setSendingToDetailsSheetOpen(true);
                    }}
                  >
                    <label>
                      Sending to <Avatar address={data?.address || ""} size={14} /> @{beamname}
                    </label>
                    <LinkChevron />
                  </div>
                </div>
              }
            >
              <div className={styles.overlayActions}>
                <Button
                  type="primary"
                  title="Continue"
                  disabled={aboveMaxSend || parseFloat(amount) === 0}
                  onClick={() => setConfirmationOpen(true)}
                />
              </div>
            </NumberPad>
          </motion.div>
        )}
      </AnimatePresence>
      <BottomSheet
        title="Sending to"
        closeOnShimTap
        isOpen={sendingToDetailsSheetOpen}
        onClose={() => setSendingToDetailsSheetOpen(false)}
      >
        <Card style="muted">
          <Row
            deemphasizeTitle
            title="To"
            trailingContent={
              <div className={styles.drawerUserRow}>
                <Avatar address={data?.address || ""} size={18} />
                <BodyText bold>@{beamname}</BodyText>
              </div>
            }
            hasHorizontalPadding={false}
          />
          <Row
            deemphasizeTitle
            title="Address"
            hasHorizontalPadding={false}
            trailingContent={
              <BodyText bold>{data?.address.substring(0, 10) + "..." + data?.address.substring(34, 42)}</BodyText>
            }
          />
        </Card>
      </BottomSheet>
      <ConfirmSendSheet
        title="Confirm Send"
        fee={fee}
        isOpen={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onReset={() => {
          setConfirmationOpen(false);
          setAmount("0");
          setSending(false);
        }}
        amount={amount}
        toAddress={data ? data.address : ""}
      />
      <CurrencyDrawer
        selectedToken={token}
        onSelect={token => setActiveToken(token)}
        open={currencyDrawerOpen}
        selectorRef={selectorRef}
        onClose={() => {
          setCurrencyDrawerOpen(false);
        }}
      />
    </>
  );
};
