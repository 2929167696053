import React from "react";

import { Spinner } from "@components/spinner/Spinner";
import { BodyText } from "@components/text/Text";

export const ProcessingView = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: `calc(-1 * var(--size-medium))`,
        left: `calc(-1 * var(--size-medium))`,
        right: `calc(-1 * var(--size-medium))`,
        bottom: `calc(-1 * var(--size-medium))`,
        background: "var(--color-background)",
        zIndex: 2,
      }}
    >
      <Spinner style={{ marginBottom: "var(--size-small)" }} />
      <BodyText large bold centered>
        Processing
      </BodyText>
      <BodyText small light centered>
        This can take a few seconds
      </BodyText>
    </div>
  );
};
