import { useEffect } from "react";
import { useAppDispatch } from "@redux";
import { updateHasSavedAccess } from "@redux/slides/web3auth.slide";

export const useLocalStorageListener = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleStorageUpdate = () => {
      dispatch(updateHasSavedAccess());
    };
    window.addEventListener("storage", handleStorageUpdate);
    return () => window.removeEventListener("storage", handleStorageUpdate);
  }, []);
};
