import * as Tooltip from "@radix-ui/react-tooltip";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { Navigate, Route, useLocation } from "react-router-dom";
import { ToastContainer, cssTransition } from "react-toastify";

require("./polyfills");

import { useBurnerWallet, useConfigFeeInit } from "@hooks";
import {
  About,
  Account,
  BeamContests,
  BeamProfile,
  Claim,
  CookiePolicy,
  Deposit,
  History,
  Home,
  LoadAccountView,
  PrivacyPolicy,
  SaveAccessView,
  TermsOfService,
  RequestView,
} from "@views";
import { Waitlist } from "@views/Shop/Waitlist";

import { AccountProvider } from "@contexts/AccountContext";
import { ColorSchemeProvider } from "@contexts/ColorSchemeContext";
import { FactoryAddressProvider } from "@contexts/FactoryAddressContext";
import { StackupProvider } from "@contexts/StackupContext";

import { useLocalStorageListener, useWeb3AuthInit } from "@modules/web3auth/hooks";

import { useAppDispatch } from "@redux";
import { setWeb3AuthSigner, useWeb3AuthSigner } from "@redux/slides/web3auth.slide";

import { BottomBar } from "@components/bottombar/BottomBar";
import { Code } from "@components/code/Code";
import MigrationSheet from "@components/migration/MigrationSheet";
import { FadeTransitionRoutes } from "@components/routes/FadeTransitionRoutes";
import { Scan } from "@components/scan/Scan";
import { Send } from "@components/send/Send";
import { Sidebar } from "@components/sidebar/Sidebar";

import { TokenProvider } from "@contexts/TokenContext";
import "antd/dist/reset.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./toast.css";

const slide = cssTransition({
  enter: "slide-in-top",
  exit: "slide-out-top",
  collapse: false,
});

function App() {
  const location = useLocation();
  const burnerSigner = useBurnerWallet();
  const authSigner = useWeb3AuthSigner();
  const dispatch = useAppDispatch();

  const wallet = authSigner || burnerSigner;

  useEffect(() => {
    if (wallet) dispatch(setWeb3AuthSigner(wallet.privateKey));
  }, [wallet?.address]);

  if (!wallet) return null;

  const routes = (
    <FadeTransitionRoutes>
      {/* deprecated route recovery */}
      <Route path="/t/:token" element={<Home />}>
        <Route index />
        <Route path="scan" />
        <Route path="send" />
      </Route>

      <Route path="/" element={<Home />}>
        <Route index element={<Code />} />
        <Route path="/scan" element={<Scan />} />
        <Route path="/send" element={<Send />} />
      </Route>

      <Route path="/u/:beamname" element={<BeamProfile />} />

      {/*--- Redirect Existing Links to new claim route ---*/}
      <Route path="/claim" element={<Claim />} />

      <Route path="/history" element={<History />} />
      <Route path="/about" element={<About />} />
      <Route path="/account" element={<Account />} />
      <Route path="/deposit" element={<Deposit />} />
      <Route path="/terms" element={<TermsOfService />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/cookies" element={<CookiePolicy />} />
      <Route path="/contests" element={<BeamContests />} />

      <Route path="/request" element={<RequestView />} />

      <Route path="/save-access" element={<SaveAccessView />} />
      <Route path="/load-account" element={<LoadAccountView />} />

      <Route path="/shop" element={<Waitlist />} />
      <Route path="*" element={<Navigate to="/" state={{ redirect: true }} />} />
    </FadeTransitionRoutes>
  );

  return (
    <StackupProvider signer={wallet}>
      <FactoryAddressProvider>
        <AccountProvider>
          <ColorSchemeProvider>
            <TokenProvider>
              <InitialHooks />
              <div className="App">
                <Tooltip.Provider>
                  <ToastContainer
                    position="top-center"
                    containerId="persistent"
                    hideProgressBar
                    enableMultiContainer
                    draggable={false}
                    closeOnClick={false}
                    icon={false}
                    closeButton={false}
                    transition={slide}
                    style={{ zIndex: 100 }}
                  />
                  <ToastContainer
                    position="top-center"
                    enableMultiContainer
                    containerId="temporary"
                    draggable
                    closeOnClick
                    hideProgressBar
                    icon={false}
                    closeButton={false}
                    transition={slide}
                    className="Toastify__toast-container-temporary"
                  />
                  <div className="tooltip-container" />
                  <Sidebar isHiddenDesktop={["claim", "u"].indexOf(location.pathname.split("/")[1]) > -1}>
                    <Helmet>
                      <title>Beam</title>
                    </Helmet>
                    {routes}
                    <BottomBar />
                    <MigrationSheet />
                  </Sidebar>
                </Tooltip.Provider>
              </div>
            </TokenProvider>
          </ColorSchemeProvider>
        </AccountProvider>
      </FactoryAddressProvider>
    </StackupProvider>
  );
}

const InitialHooks: React.FC = () => {
  useWeb3AuthInit();
  useConfigFeeInit();
  useLocalStorageListener();

  return null;
};

export default App;
