import React from "react";
import classNames from "classnames";

import { ReactComponent as Logo } from "@assets/images/beam_icon.svg";

import styles from "./BeamLogo.module.css";

export const BeamLogo = ({
  size = 32,
  className,
  style,
  ...props
}: React.HTMLProps<HTMLDivElement> & { size?: number }) => {
  return (
    <div
      className={classNames(styles.BeamCode_logo, className)}
      style={{ ...style, width: size, height: size }}
      {...props}
    >
      <Logo />
    </div>
  );
};
