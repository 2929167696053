import React, { useEffect, useMemo, useState } from "react";
import { ethers } from "ethers";
import classNames from "classnames";
import moment from "moment";

import Row from "@components/row/Row";
import Card from "@components/card/Card";
import { TokenIcon } from "@components/token";
import { BodyText } from "@components/text/Text";
import { Avatar } from "@components/avatar/Avatar";
import { RowGroup } from "@components/row/RowGroup";
import { TokenBalance } from "@components/code/TokenBalance";
import { Button, LinkButton } from "@components/button/Button";
import { StepAnimation } from "@components/account/access/components";
import BottomSheet, { BottomSheetProps } from "@components/bottomsheet/BottomSheet";

import { Network, UserToken } from "@constants";

import { copy } from "@helpers/copy";
import { formatCurrency, shortAddr } from "@helpers/format";

import styles from "@views/Request/Request.module.css";
import { QREconet } from "@views/Request/QREconet/QREconnet";

import { useAccount } from "@contexts/AccountContext";

import { getTokenManager } from "@modules/token-managers/tokens";
import { Econet, EconetVersion } from "@modules/econet/Econet";
import { BalanceHandler, TransferInCallbacksProps } from "@modules/blockchain/BalanceHadler";

import { useDisplayName } from "@hooks";

import { ReactComponent as CheckIcon } from "@assets/icons/checkmark.svg";

interface RequestSheetProps {
  address: string;
  beamname?: string;

  token: UserToken;
  amount: ethers.BigNumber;
  reward?: ethers.BigNumber;
  network?: Network;
  version?: EconetVersion;
}

export const RequestSheet = ({
  isOpen,
  amount,
  address,
  beamname,
  token,
  onClose,
  version = EconetVersion.V0,
  reward = ethers.constants.Zero,
  network = "optimism",
  ...props
}: BottomSheetProps & RequestSheetProps) => {
  const { balanceHandlers } = useAccount();
  const econet = new Econet({ network, amount, reward, token, version, merchant: address });

  const [transferReceived, setTransferReceived] = useState<TransferInCallbacksProps | undefined>(undefined);

  useEffect(() => {
    if (transferReceived || !isOpen) return;

    const fn: BalanceHandler["transferInCallbacks"][0] = async transfer => {
      if (econet.token === transfer.userToken && econet.amount.eq(transfer.amount)) {
        setTransferReceived(transfer);
      }
    };

    balanceHandlers.forEach(handler => {
      handler.setPollingInterval(3_000);
      handler.addTransferInCallback(fn);
    });

    return () => {
      balanceHandlers.forEach(handler => {
        handler.setPollingInterval(3_000);
        handler.removeTransferInCallback(fn);
      });
    };
  }, [transferReceived, econet.toString(), isOpen, balanceHandlers]);

  const handleClose = () => {
    onClose && onClose();
    setTransferReceived(undefined);
  };

  return (
    <BottomSheet
      {...props}
      isOpen={isOpen}
      desktopDisplay="side"
      height="100%"
      onClose={handleClose}
      title={
        !transferReceived ? (
          <div className={styles.sheetTitle}>
            <Avatar size={32} address={address} />
            {`@${beamname}`}
          </div>
        ) : (
          "Transaction complete"
        )
      }
      footer={
        transferReceived ? (
          <div>
            <Button title="Done" onClick={handleClose} />
          </div>
        ) : undefined
      }
    >
      {!transferReceived ? (
        <StepAnimation key="step-1" style={{ height: "100%" }}>
          <QRBody econet={econet} />
        </StepAnimation>
      ) : (
        <StepAnimation key="step-2" style={{ height: "100%" }}>
          <ConfirmationBody transfer={transferReceived} />
        </StepAnimation>
      )}
    </BottomSheet>
  );
};

interface QRBodyProps {
  econet: Econet;
}

const QRBody: React.FC<QRBodyProps> = ({ econet }) => {
  const tokenManager = getTokenManager(econet.token);
  const displayName = useDisplayName(econet.merchant);

  return (
    <div className={styles.qr}>
      <div className={styles.amountWrapper}>
        <BodyText>Amount due</BodyText>
        <TokenBalance
          balance={econet.amount}
          decimals={tokenManager.decimals}
          icon={
            <div className={classNames("Code_balanceIcon", styles.amountIcon)}>
              <TokenIcon token={econet.token} />
            </div>
          }
        />
      </div>
      <div className="Code_qr">
        <div className="Code_qrInner">
          <QREconet address={econet.merchant} econet={econet} name={displayName} />
        </div>
      </div>
      <div className={styles.sheetCopy}>
        <BodyText>Scan the above Beam Code to complete your transaction with @{displayName}.</BodyText>
      </div>
    </div>
  );
};

interface ConfirmationBodyProps {
  transfer: TransferInCallbacksProps;
}

const ConfirmationBody: React.FC<ConfirmationBodyProps> = ({ transfer }) => {
  const receivedAt = useMemo(() => Date.now(), [transfer]);
  const tokenManager = getTokenManager(transfer.userToken);
  const name = useDisplayName(transfer.from);

  return (
    <div className={styles.completeWrapper}>
      <div className={styles.checkmark}>
        <CheckIcon />
      </div>
      <Card style="muted">
        <RowGroup>
          <Row
            deemphasizeTitle
            hasHorizontalPadding={false}
            title="Amount"
            trailingContent={
              <div>
                <BodyText bold>{formatCurrency(transfer.amount, tokenManager.decimals)}</BodyText>
              </div>
            }
          />
          <Row
            deemphasizeTitle
            hasHorizontalPadding={false}
            title="Date"
            trailingContent={
              <BodyText bold>
                <>{moment(receivedAt).format("LT · MMM Do, YYYY")}</>
              </BodyText>
            }
          />
          <Row
            hasHorizontalPadding={false}
            deemphasizeTitle
            title="From"
            trailingContent={
              <div className="ConfirmSend_RowTrailing">
                <div className="ConfirmSend_UserRow">
                  <Avatar address={transfer.from} size={18} /> {name}
                </div>
                <div className="ConfirmSend_RowDetails">
                  {shortAddr(transfer.from)}
                  <>&nbsp;·&nbsp;</>
                  <LinkButton title="Copy" size="small" onClick={() => copy(transfer.from, "Copied Address.")} />
                </div>
              </div>
            }
          />
        </RowGroup>
      </Card>
    </div>
  );
};
