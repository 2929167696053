import { useQuery } from "@apollo/client";
import { NETWORK } from "@constants/network";

import { BEAMNAME_QUERY } from "@queries/BEAMNAME";

/**
 * returns an address/beamname for a specified beam name
 */
export const useBeamname = (name?: string) => {
  const { data, ...args } = useQuery(BEAMNAME_QUERY, {
    variables: name
      ? { name: `${name.toLowerCase()}.${NETWORK.chainId === 10 ? "beam.eco" : "isyourname.eth"}` }
      : undefined,
  });

  return {
    data:
      data?.beamnames && data.beamnames.length ? { address: data.beamnames[0].owner.id, beamname: name! } : undefined,
    ...args,
  };
};
