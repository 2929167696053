import React from "react";
import "./Text.css";
import classNames from "classnames";

interface TextProps {
  children: React.ReactNode;
  centered?: boolean;
  light?: boolean;
  style?: React.CSSProperties;
}

interface BodyTextProps {
  bold?: boolean;
  small?: boolean;
  large?: boolean;
}

interface TextBlockProps {
  children: React.ReactNode;
}

interface HeaderTextProps extends TextProps {
  className?: string;
  size?: "small" | "medium";
}

interface UpcaseTextProps extends TextProps {
  className?: string;
}

export const HeaderText: React.FC<HeaderTextProps> = ({ style, children, centered, className, size }) => (
  <h2
    style={style}
    className={classNames("Text-header", className, {
      "Text-centered": centered,
      [`Text-header-size-${size}`]: size,
    })}
  >
    {children}
  </h2>
);

export const UpcaseText: React.FC<UpcaseTextProps> = ({ style, children, centered, className, light }) => (
  <h2
    style={style}
    className={classNames("Text-upcase", className, {
      "Text-centered": centered,
      "Text-light": light,
    })}
  >
    {children}
  </h2>
);

export const BodyText = ({
  children,
  bold,
  small,
  centered,
  large,
  light,
  style,
  className,
  ...props
}: React.HTMLProps<HTMLParagraphElement> & TextProps & BodyTextProps) => (
  <p
    style={style}
    className={classNames("Text-body", className, {
      "Text-bold": bold,
      "Text-small": small,
      "Text-centered": centered,
      "Text-large": large,
      "Text-light": light,
    })}
    {...props}
  >
    {children}
  </p>
);

export const TextBlock = ({ children }: TextBlockProps) => <div className="Text-block">{children}</div>;
