import React, { useEffect } from "react";
import { Form, FormProps, Input } from "antd";

import { useWithdrawContext } from "../../context/WithdrawContext";

export interface BankForm {
  bankName: string;
  accountNumber: string;
  routingNumber: string;
  ownerName: string;
}

export const AddBankForm: React.FC<FormProps<BankForm>> = props => {
  const [form] = Form.useForm<BankForm>();
  const { shouldSubmitForm, setShouldSubmitForm } = useWithdrawContext();

  const handleKeyUp: FormProps["onKeyUp"] = event => {
    // Send form if `Enter` key is pressed
    if (event.key === "Enter") form.submit();
  };

  useEffect(() => {
    if (shouldSubmitForm) {
      form.submit();
      setShouldSubmitForm(false);
    }

    return () => {
      setShouldSubmitForm(false);
    };
  }, [shouldSubmitForm]);

  return (
    <Form
      form={form}
      onKeyUp={handleKeyUp}
      layout="vertical"
      className="Form"
      style={{
        height: "100%",
        flexDirection: "column",
        display: "flex",
      }}
      {...props}
    >
      <Form.Item name="bankName" rules={[{ required: true, message: "Required" }]}>
        <Input placeholder="Bank name" className="input" />
      </Form.Item>
      <Form.Item name="ownerName" rules={[{ required: true, message: "Required" }]}>
        <Input placeholder="Legal name of account holder" className="input" />
      </Form.Item>
      <Form.Item
        name="accountNumber"
        rules={[
          { required: true, message: "Required" },
          {
            pattern: /^[0-9]*$/,
            message: "Account number is not a valid",
          },
        ]}
      >
        <Input placeholder="Account number" className="input" />
      </Form.Item>
      <Form.Item
        name="routingNumber"
        rules={[
          { required: true, message: "Required" },
          {
            pattern: /^[0-9]*$/,
            message: "Routing number is not a valid",
          },
        ]}
      >
        <Input placeholder="Routing number" className="input" />
      </Form.Item>
      <div style={{ flex: 1 }} />
    </Form>
  );
};
