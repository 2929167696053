import { ApolloClient, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { ENS_SUBGRAPH_URL, getSupportedNetworks, Network } from "@constants/network";

export const APOLLO_CLIENTS = Object.fromEntries(
  getSupportedNetworks()
    .filter(network => network.subgraphUrl)
    .map(network => [network.network, new ApolloClient({ uri: network.subgraphUrl, cache: new InMemoryCache() })]),
) as Record<Network, ApolloClient<NormalizedCacheObject>>;

export const APOLLO_CLIENT_ENS = new ApolloClient({
  uri: ENS_SUBGRAPH_URL,
  cache: new InMemoryCache(),
});
