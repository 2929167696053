import React from "react";
import * as FormPrimitive from "@radix-ui/react-form";
import styles from "./Input.module.css";

interface InputProps {
  required?: boolean;
  placeholder?: string;
  label?: string;
  name: string;
  type?: string;
  disabled?: boolean;
  pattern?: string;
  patternError?: string;
}

type Option = {
  value: string;
  label: string;
};

interface SelectProps {
  options: Option[];
}

export const Input = ({
  required,
  placeholder,
  label,
  name,
  type = "text",
  disabled,
  pattern,
  patternError,
}: InputProps) => {
  return (
    <FormPrimitive.Field name={name} className={styles.wrapper}>
      {label && <FormPrimitive.Label className={styles.label}>{label}</FormPrimitive.Label>}
      <FormPrimitive.Control
        placeholder={placeholder}
        required={required}
        className={styles.input}
        type={type}
        disabled={disabled}
        pattern={pattern}
      />
      <div className={styles.messageContainer}>
        {required && <FormPrimitive.Message match="valueMissing" />}
        {pattern && <FormPrimitive.Message match="patternMismatch">{patternError}</FormPrimitive.Message>}
      </div>
    </FormPrimitive.Field>
  );
};

export const Select = ({
  required,
  placeholder,
  label,
  name,
  type = "text",
  options,
  disabled,
}: InputProps & SelectProps) => {
  return (
    <FormPrimitive.Field name={name} className={styles.wrapper}>
      {label && <FormPrimitive.Label className={styles.label}>{label}</FormPrimitive.Label>}
      <FormPrimitive.Control
        placeholder={placeholder}
        required={required}
        className={styles.input}
        type={type}
        asChild
        disabled={disabled}
      >
        <select>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </FormPrimitive.Control>
      <div className={styles.messageContainer}>{required && <FormPrimitive.Message match="valueMissing" />}</div>
    </FormPrimitive.Field>
  );
};
