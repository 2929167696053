import { FetchQueryOptions, QueryClient } from "@tanstack/react-query";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { persistQueryClient as _persistQueryClient } from "@tanstack/react-query-persist-client";

// 5 Days
const cacheTime = 1_000 * 60 * 60 * 24 * 5;

const persistQueryClient = new QueryClient({
  defaultOptions: {
    queries: { cacheTime },
  },
});

const localStoragePersistor = createSyncStoragePersister({
  storage: window.localStorage,
});

const [, ready] = _persistQueryClient({
  queryClient: persistQueryClient,
  persister: localStoragePersistor,
});

export const fetchQuery: typeof persistQueryClient.fetchQuery = async (options: FetchQueryOptions) => {
  await ready;
  return persistQueryClient.fetchQuery(options);
};
