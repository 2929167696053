import { BigNumber } from "ethers";
import React from "react";

import { getTokenManager } from "@modules/token-managers/tokens";

import { getNetwork, Network, NETWORK_LIST } from "@constants";
import { getTokenInfo, TokenInfo, UserToken } from "@constants/tokens";
import { formatBigNumber } from "@helpers";

import BottomSheet from "@components/bottomsheet/BottomSheet";
import Card from "@components/card/Card";
import Row from "@components/row/Row";
import { BodyText, TextBlock } from "@components/text/Text";
import styles from "./BreakdownSheet.module.css";

import { Callout } from "@components/callout/Callout";
import { RowGroup } from "@components/row/RowGroup";
import { useAppSelector } from "@redux";
import { getBalances } from "@redux/slides/balances/balances.slide";

interface BreakdownSheetProps {
  isOpen: boolean;
  onClose: () => void;
  token: UserToken;
  warning?: boolean; // optional warning that only optimism USDC is supported by the vending machine (temporary)
}

export const BreakdownSheet: React.FC<BreakdownSheetProps> = ({ isOpen, onClose, token, warning = false }) => {
  const balances = useAppSelector(getBalances);
  const { composedBy } = getTokenManager(token);

  const tokens = composedBy.reduce<{ network: Network; tokenInfo: TokenInfo; balance: BigNumber }[]>((arr, token) => {
    NETWORK_LIST.forEach(network => {
      // for each network, get token info:
      const tokenInfo = getTokenInfo(token, network);
      const balance = BigNumber.from(balances[network].balances[token]);
      if (!balance.isZero()) {
        arr.push({ network, tokenInfo, balance });
      }
    });
    return arr;
  }, []);

  return (
    <BottomSheet desktopDisplay="overlay" isOpen={isOpen} title="Digital dollar balance" onClose={onClose}>
      <div className={styles.wrapper}>
        <TextBlock>
          <BodyText centered>
            Beam supports multiple networks and digital dollars, but you can send them without worrying about what
            happens behind the scenes.
          </BodyText>
        </TextBlock>
        {warning ? (
          <Callout>
            <TextBlock>
              <BodyText small>
                Right now, you can only use <b>USD on Optimism</b> to purchase ECO. We’re working on making it so that
                all of your other digital dollars can be used as well!
              </BodyText>
            </TextBlock>
          </Callout>
        ) : null}

        {tokens.length > 0 ? (
          <Card style="muted">
            <RowGroup compact>
              {tokens.map(({ network, tokenInfo, balance }) => (
                <Row
                  compact
                  key={"balance-" + tokenInfo.name + "-" + network}
                  title={`${tokenInfo.name} on ${getNetwork(network).name}`}
                  hasHorizontalPadding={false}
                  deemphasizeTitle
                  trailingContent={
                    <BodyText bold>
                      {formatBigNumber(balance, tokenInfo.decimals, 3)} {tokenInfo.name}
                    </BodyText>
                  }
                />
              ))}
            </RowGroup>
          </Card>
        ) : (
          <BodyText small centered light>
            You don&apos;t have any USD in Beam yet.
          </BodyText>
        )}
      </div>
    </BottomSheet>
  );
};
