import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";

import { animationCurveEaseOut, animationDurationFastS } from "@constants/animations";

import styles from "./Toggle.module.css";

interface ToggleProps {
  enabled: boolean;
  onToggle: (_: boolean) => void;
}

export const Toggle = ({ enabled, onToggle }: ToggleProps) => {
  const handleClick = () => {
    onToggle(enabled);
  };

  return (
    <motion.div className={classNames(styles.wrapper, { [`${styles.enabled}`]: enabled })} onClick={handleClick}>
      <motion.div
        className={styles.thumb}
        layout
        transition={{
          ease: animationCurveEaseOut,
          duration: animationDurationFastS,
        }}
      >
        <div className={styles.thumbInner} />
      </motion.div>
    </motion.div>
  );
};
