import React, { useMemo } from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";

import { useSidebar } from "@components/sidebar/Sidebar";
import { useWeb3AuthState } from "@redux/slides/web3auth.slide";

import { ReactComponent as MenuIcon } from "@assets/icons/menu.svg";
import { ReactComponent as ArrowBack } from "@assets/icons/arrow_back.svg";
import { ReactComponent as BeamBrand } from "@assets/images/beam_brand.svg";

import { ReactComponent as ExclamationCircle } from "@assets/icons/exclamation_circle.svg";

import "./Header.css";
import { useAccount } from "@contexts/AccountContext";

export interface HeaderProps {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  transparent?: boolean;
  onBack?(): void;
}

export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  title,
  subtitle,
  transparent = false,
  onBack,
  children,
}) => {
  const { hasSavedAccess } = useWeb3AuthState();
  const location = useLocation();
  const { setOpen, isHiddenDesktop } = useSidebar();
  const { balances } = useAccount();
  const hasSomeBalance = useMemo(() => {
    return Object.values(balances).some(balance => !balance.total.isZero());
  }, [balances]);

  const showWarningBadge = location.pathname !== "/account" && !hasSavedAccess && !onBack && hasSomeBalance;

  return (
    <div
      className={classNames("Header", {
        "Header-transparent": transparent,
        "Header-isSidebarHiddenDesktop": isHiddenDesktop,
      })}
    >
      <button
        className={`Header_button Header_iconButton ${onBack ? "Header_backButton" : "Header_menuButton"}`}
        onClick={onBack || (() => setOpen(true))}
      >
        {onBack ? <ArrowBack className="Header_menu-icon" /> : <MenuIcon className="Header_menu-icon" />}
        {showWarningBadge && (
          <div className="Header_buttonBadge">
            <ExclamationCircle width={16} />
          </div>
        )}
      </button>
      {title ? (
        <div className="Header_title--container">
          <div className="Header_title">{title}</div>
          {subtitle ? <div className="Header_subtitle">{subtitle}</div> : null}
        </div>
      ) : (
        <Link to="/" className="Header_logo">
          <BeamBrand />
        </Link>
      )}
      <div>{children}</div>
    </div>
  );
};
