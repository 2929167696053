import React from "react";
import View from "@components/view/View";
import { BodyText, HeaderText, TextBlock } from "@components/text/Text";

export const BeamContests = () => {
  return (
    <View headerTitle="Beam Contests Official Rules">
      <div style={{ padding: 16 }}>
        <TextBlock>
          <HeaderText>Beam Contests Official Rules</HeaderText>
          <BodyText>Last updated: August 17, 2023</BodyText>
          <BodyText>NO PURCHASE NECESSARY TO ENTER OR WIN.</BodyText>
          <BodyText>
            ENTRY ONLY VIA TWITTER (ALSO KNOWN AS “X”). A TWITTER ACCOUNT, BEAM WALLET, BEAM NAME, AND INTERNET
            CONNECTION ARE REQUIRED FOR ENTRY. A “PROTECTED” OR “PRIVATE” TWITTER ACCOUNT ACCOUNT MAY NOT BE ABLE TO
            SEND ENTRIES. THE SWEEPSTAKES IS IN NO WAY SPONSORED, ENDORSED OR ADMINISTERED BY TWITTER OR X CORP.
          </BodyText>
          <BodyText>
            BY ENTERING THE SWEEPSTAKES YOU AGREE TO THESE OFFICIAL RULES, WHICH ARE A CONTRACT, SO READ THEM CAREFULLY
            BEFORE ENTERING. WITHOUT LIMITATION, THIS CONTRACT INCLUDES INDEMNITIES TO THE SPONSOR FROM YOU AND A
            LIMITATION OF YOUR RIGHTS AND REMEDIES.
          </BodyText>
          <BodyText>
            1. SPONSOR: The Sweepstakes is sponsored by Beam Wallet LLC (the “Sponsor”), 2261 Market Street #4348, San
            Francisco, CA 94114.
          </BodyText>
          <BodyText>
            2. ELIGIBILITY: Beam Contests (the “Sweepstakes”) is open to anyone who is at least eighteen (18) years of
            age and the age of majority in their state or country of residence on the date of entry and who have a Beam
            cryptocurrency wallet (“Beam Wallet”) with a registered Beam Name through www.beam.eco. Employees, officers,
            directors, members, managers, agents, and representatives and family members of such individuals (or people
            living in the same household whether related or not) of the Sponsor, or their corporate partners, parent
            companies, divisions, subsidiaries, affiliates, successors in interest, advertising, promotion, and public
            relations agencies (collectively, the “Sweepstakes Entities”) are not eligible. For the purposes of the
            Sweepstakes, family members are defined as spouse, partner, mother, father, legal guardian, in-laws,
            grandmother, grandfather, brother, sister, children and grandchildren (including “step” as they may apply).
            The Sweepstakes Announcement (as defined below) may alter, remove, or add eligibility requirements for a
            specific Sweepstakes.
          </BodyText>
          <BodyText>
            3. SWEEPSTAKES PERIOD: The “Sweepstakes Period” begins when the official Beam Wallet account on Twitter
            (@beam_eco) announces that submissions for this Sweepstakes will be accepted and will continue until 11:59
            pm Eastern Time that same calendar day (the “Sweepstakes Announcement”) unless specified otherwise by the
            Sweepstakes Announcement. The Sweepstakes Announcement will be accompanied by details of the Prize or Prizes
            (as defined below) as well as the number of potential Prize Winners (as defined below). Prize Winners will
            be selected immediately following the Sweepstakes Period. It is tentatively planned that there will be a
            separate Sweepstakes with its own associated Sweepstakes Period on at least one day of each calendar week,
            although Sponsor may change, cancel, or amend any planned Sweepstakes in its absolute discretion without
            notice to you.
          </BodyText>
          <BodyText>
            4. HOW TO ENTER: To enter this Sweepstakes, you will need a Beam Name, a Beam Wallet, and a Twitter account.
            If you don’t already have a Beam Wallet or a Beam Name, go to www.beam.eco and follow the instructions there
            to obtain one; you will be required to agree to the Beam terms of use and privacy policy. If you don’t
            already have a Twitter account, visit www.twitter.com to create one; you will be required to agree to the
            Twitter terms of service and privacy notice.
          </BodyText>
          <BodyText>
            To enter the Sweepstakes, eligible individuals (“Entrants”) must follow the official Beam account on
            Twitter, retweet, Beam’s Sweepstakes Announcement, and reply to the Sweepstakes Announcement with Entrant’s
            Beam Name (an “Entry”) unless a different method of Entry is set forth in the Sweepstakes Announcement. You
            may only make one Entry per Sweepstakes, and you may only be a Prize Winner (as defined below) for a
            Sweepstakes once; for avoidance of doubt, if you become a Prize Winner, you are not eligible to submit
            Entries for future Sweepstakes.
          </BodyText>
          <BodyText>
            Sponsor may disqualify entries if the Entry and related tag includes inappropriate, offensive, or other
            derogatory language or information. Sponsor reserves the right to remove, reject, or disqualify (along with
            the entrants who submitted them) any Entry or related tag which, in its opinion, (a) violates any of these
            Official Rules, the terms of service and privacy policy of Sponsor, (b) infringes, misappropriates, or
            violates any rights of any third party including, without limitation, patent, copyright, trademark, trade
            secret, or right of privacy or publicity, or (c) is otherwise inappropriate for inclusion in the
            Sweepstakes. For purposes of this Sweepstakes, only Entries that are recorded through Twitter’s servers will
            be considered. Other proof of submitting an Entry (such as a printed or copied screenshot or message) does
            not constitute proof of actual receipt of the Entry for purposes of this Sweepstakes. The Twitter database
            clock will be the official timekeeper for this Sweepstakes. Sponsor may not receive entries from Twitter
            users with “protected” or “private” updates due to the way Twitter operates its service. Twitter posts not
            received by Sponsor will not be entered into the Sweepstakes. Multiple entrants are not permitted to share
            the same Twitter account. Attempts made by an individual to submit Entries in violation of these Official
            Rules by using multiple or false contact information or otherwise may be disqualified. Entries that are
            generated by a macro, bot, or other automated means will not be accepted and will be void. Entries made by
            any other individual or any entity or group, or originating at any website other than as set forth
            specifically above, including, without limitation, through commercial sweepstakes subscription notification
            or entering services, will be declared invalid and disqualified for this Sweepstakes. As a condition of
            entering the Sweepstakes, without limiting any other provision in these Official Rules, each entrant gives
            consent for Sponsor and its agents to obtain and deliver his or her name, address and other information to
            third parties for the purpose of administering this Sweepstakes and complying with applicable laws,
            regulations, and rules. Such third parties may use your information for their own independent purposes in
            accordance with their own independent privacy practices. Except as otherwise contemplated in these Official
            Rules, Sponsor will use personal information collected in connection with the Sweepstakes in accordance with
            its online privacy policy, located at https://beam.eco/privacy.
          </BodyText>
          <BodyText>
            Sweepstakes Entities are not responsible for any changes or unavailability of the Beam Wallet, Beam Name, or
            Twitter services that may interfere with the Sweepstakes (including any limitations, any restrictions, or
            any conditions on Sponsor’s ability to use Twitter for the Sweepstakes as set forth herein that are not
            acceptable to Sponsor) or ability of entrant to timely enter, receive notices or communicate with Sponsor
            via Twitter, in which case Sponsor, in its sole discretion, may terminate or modify the Sweepstakes. It is a
            potential winner’s responsibility to set their Twitter account as applicable, to accept contacts by Sponsor,
            and to timely check such account for any Sponsor direct messages.
          </BodyText>
          <BodyText>
            5. WINNER SELECTION: For each Sweepstakes, Prize Winners will be chosen at random or in the manner set forth
            in the Sweepstakes Announcement.
          </BodyText>
          <BodyText>
            6. WINNER NOTIFICATION: The Prize Winner(s) will be notified by receiving a direct message from Sponsor to
            their Twitter account with a request for their full name and address and confirmation of their Beam Wallet
            and Beam Name (a “Winner Notification”). Once the Prize Winner provides such information they will receive
            the prize in the Beam Wallet associated with their Beam Name. You must be following Beam on Twitter
            (@beam_eco) in order to receive a Winner Notification via direct message.
          </BodyText>
          <BodyText>
            If any Winner Notification or other Sweepstakes communication is rejected or returned as undeliverable, if a
            potential Winner cannot be reached after a reasonable attempt has been made by Sponsor (as determined by
            Sponsor in its sole discretion), if a Winner cannot attend or participate in any portion of the applicable
            Prize, or if the potential Winner fails to respond to a Winner Notification and/or to sign and return the
            Affidavit (if required) within the time periods set forth herein, the prize will be forfeited in Sponsor’s
            sole discretion and an alternate winner may be selected (time permitting) based on the original method of
            winner selection in Sponsor’s sole discretion. Sponsor may only look to one (1) alternate winner for the
            applicable Prize, at which point the applicable Prize may be deemed unclaimed and Sponsor will have no
            further obligation with respect to such Prize. Sponsor reserves the right to modify the notification
            procedures in connection with the selection of any alternate potential Winner. If legitimately claimed in
            compliance with these Official Rules, the Prize will be awarded. To claim a Prize, a Winner must follow the
            directions in his or her Winner Notification.
          </BodyText>
          <BodyText>
            7. PRIZE(S): For each Sweepstakes, one or more persons (the “Prize Winner(s)”) will each win a prize of a
            specified number of tokens of the ECO cryptocurrency (the “Prize(s)”) on one or more blockchain networks
            supported by Beam Wallet. The Sweepstakes Announcement may, but is not required to, set forth the number of
            Prizes and potential Prize Winners as well as how the Prizes may differ from each other (if at all). The
            choice of the blockchain networks will be at the discretion of Sponsor. Please see www.eco.org for
            additional information regarding the ECO cryptocurrency. United States federal, state, and local taxes on
            prizes are the sole responsibility of the Prize Winner. No substitution or transfer of prizes is permitted.
            The Prize Winner will receive their Prize in their Beam Wallet at any time throughout, or immediately
            following, the Sweepstakes Period.
          </BodyText>
          <BodyText>
            All Prize details not specified in these Official Rules will be determined in Sponsor’s sole discretion.
            Sponsor is not responsible for and will not replace any lost or stolen Prize or any Prize that is
            undeliverable or does not reach the Prize Winner because of incorrect or changed contact information. If the
            Prize Winner does not accept or use the entire Prize, the unaccepted or unused part of the Prize will be
            forfeited and Sponsor will have no further obligation with respect to that Prize or portion of the Prize.
            Sponsor is not responsible for any inability of the Prize Winner to accept or use any Prize (or portion
            thereof) for any reason. No transfers or prize substitutions will be made, except at Sponsor’s sole
            discretion. No more than the stated prizes will be awarded. Participants waive the right to assert as a cost
            of winning any Prize, any and all costs of verification and redemption or travel to claim or use a Prize and
            any liability and publicity which might arise from claiming, seeking to claim, or using a Prize.
          </BodyText>
          <BodyText>
            8. LICENSES AND APPROVALS: Entrants may not submit an Entry on behalf of someone else. By submitting an
            Entry, Entrant represents and warrants that Entrant meets all eligibility criteria for participation in the
            Sweepstakes. In addition, by submitting an Entry in this Sweepstakes, Entrant grants the Sweepstakes
            Entities an irrevocable, perpetual, non-exclusive worldwide license to publish, post, display, copy,
            distribute, transmit, edit, use alone, together or with other works, and otherwise exploit the Entry and
            Entrant’s tweet Entry, in any manner whatsoever throughout the world, including on the Internet, and at any
            time or times, in connection with the Sweepstakes, in any and all forms of media, now known or hereafter
            discovered, without additional compensation, review or approval rights, notification or permission, except
            where prohibited by law, and Entrant releases all Releasees from any and all liability related thereto.
            ENTRANT FURTHER ACKNOWLEDGES THAT IF ENTRANT IS CHOSEN AS A WINNER, ENTRANT’S TWITTER USERNAME AND BEAM
            NAME, MAY BE DISCLOSED TO THIRD PARTIES INCLUDING, WITHOUT LIMITATION, BEING PLACED ON A WINNERS’ LIST.
          </BodyText>
          <BodyText>
            9. LIMITATION OF LIABILITY: The Releasees (as defined below) are not responsible or liable for any of the
            following: (a) stolen, lost, late, misdirected, garbled, mutilated, damaged, incomplete, inaccurate, or
            illegible Entries, e-mail, mail, Sweepstakes-related correspondence or postage-due mail or any technical,
            computer, mechanical, printing, typographical, human or other errors relating to or in connection with the
            Sweepstakes, including, without limitation, errors which may occur in connection with the administration of
            the Sweepstakes, the mailing or transmission of notification or correspondence, the processing of Entries,
            the announcement of any prize or in any Sweepstakes related materials; (b) technical failures of any kind;
            (c) unauthorized human and/or mechanical intervention; (d) human error; (e) any error, omission,
            interruption, defect or delay in transmission or communication; (f) viruses or mechanical malfunctions; (g)
            errors, typos, or misprints in these Official Rules, in any Sweepstakes related advertisements or other
            materials; or (h) lost or unavailable network connections. Sponsor is not responsible for electronic
            communications that are undeliverable as a result of any form of filtering or insufficient space in
            Entrant’s email account. Sponsor is not responsible, and may disqualify Entrant, if his or her email
            address, telephone, or other contact information does not work or if it is changed without Entrant giving
            prior written notice to Sponsor. If for any reason an Entry is confirmed to have been erroneously deleted,
            lost or otherwise destroyed, corrupted or for any other reason not accepted as an entry into the
            Sweepstakes, Entrant’s sole remedy is another Entry in the Sweepstakes.
          </BodyText>
          <BodyText>
            AS A CONDITION OF PARTICIPATING IN THIS SWEEPSTAKES, EACH ENTRANT AGREES TO RELEASE, INDEMNIFY AND HOLD
            HARMLESS SPONSOR, SWEEPSTAKES ENTITIES, TWITTER, AND EACH OF THEIR RESPECTIVE PARENT COMPANIES,
            SUBSIDIARIES, AFFILIATES, DIVISIONS AND ADVERTISING, AND PUBLIC RELATIONS AGENCIES, AND EACH OF THEIR
            RESPECTIVE AGENTS, REPRESENTATIVES, OFFICERS, DIRECTORS, MEMBERS, SHAREHOLDERS AND EMPLOYEES (COLLECTIVELY,
            “RELEASEES”) FROM AND AGAINST ANY LIABILITY WHATSOEVER FOR INJURIES OR DAMAGES OF ANY KIND SUSTAINED IN
            CONNECTION WITH THE USE, ACCEPTANCE, POSSESSION, MISUSE OR AWARDING OF ANY PRIZE. EACH WINNER AGREES THAT
            THE PRIZE IS PROVIDED AS-IS WITHOUT ANY WARRANTY, REPRESENTATION OR GUARANTEE, EXPRESS OR IMPLIED, IN FACT
            OR IN LAW, WHETHER NOW KNOWN OR HEREINAFTER ENACTED, RELATIVE TO THE USE OR ENJOYMENT OF THE PRIZE. FURTHER,
            THE RELEASEES ARE NOT RESPONSIBLE IN ANY WAY FOR ANY ADDITIONAL EXPENSES, OMISSIONS, DELAYS, OR RE-ROUTING
            RESULTING FROM ANY ACTS OF ANY GOVERNMENT OR AUTHORITY.
          </BodyText>
          <BodyText>
            EACH ENTRANT UNDERSTANDS AND AGREES THAT ALL RIGHTS UNDER SECTION 1542 OF THE CIVIL CODE OF CALIFORNIA AND
            ANY SIMILAR LAW OF ANY STATE OR TERRITORY OF THE UNITED STATES ARE HEREBY EXPRESSLY WAIVED BY HIM/HER.
            SECTION 1542 READS AS FOLLOWS:
          </BodyText>
          <BodyText>
            “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT
            TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, AND THAT IF KNOWN BY HIM OR HER, WOULD
            HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.”
          </BodyText>
          <BodyText>
            10. ADDITIONAL DISCLAIMERS: Without limiting any other provision in these Official Rules, Releasees are not
            responsible or liable to any entrant or Prize Winner or any person claiming through such entrant or Winner
            for failure to supply the prize or any part thereof in the event that any of the Sweepstakes activities or
            the Releasees’ operations or activities are affected, as determined by the Sponsor in its sole discretion,
            including, without limitation, by reason of any acts of God, any action, regulation, equipment failure,
            threatened terrorist acts, terrorist acts, air raid, blackout, act of public enemy, earthquake, war, fire,
            flood, epidemic, explosion, unusually severe weather, hurricane, embargo, labor dispute or strike labor or
            material shortage, transportation interruption of any kind, civil disturbance, insurrection, riot, or any
            law, rule, regulation, order or other action adopted or taken by any governmental, federal, state or local
            government authority, or any other cause, whether or not specifically mentioned above.
          </BodyText>
          <BodyText>
            11. DISPUTES AND GOVERNING LAW: BY PARTICIPATING IN THE SWEEPSTAKES, EACH ENTRANT AGREES THAT TO THE EXTENT
            PERMITTED BY APPLICABLE LAW: (1) ANY AND ALL DISPUTES, CLAIMS AND CAUSES OF ACTION THAT CANNOT BE RESOLVED
            BETWEEN ENTRANT AND ANY RELEASEE ARISING OUT OF OR CONNECTED WITH THE SWEEPSTAKES, OR ANY PRIZE AWARDED,
            WILL BE RESOLVED INDIVIDUALLY, WITHOUT RESORT TO ANY FORM OF CLASS ACTION; (2) ANY AND ALL CLAIMS, JUDGMENTS
            AND AWARDS WILL BE LIMITED TO ACTUAL THIRD-PARTY, OUT-OF-POCKET COSTS INCURRED, (IF ANY), NOT TO EXCEED TEN
            DOLLARS ($10.00), BUT IN NO EVENT WILL ATTORNEYS’ FEES BE AWARDED OR RECOVERABLE; (3) UNDER NO CIRCUMSTANCES
            WILL ANY ENTRANT BE PERMITTED TO OBTAIN ANY AWARD FOR, AND ENTRANT HEREBY KNOWINGLY AND EXPRESSLY WAIVES ALL
            RIGHTS TO SEEK, PUNITIVE, INCIDENTAL, CONSEQUENTIAL OR SPECIAL DAMAGES, LOST PROFITS AND/OR ANY OTHER
            DAMAGES, OTHER THAN ACTUAL OUT-OF-POCKET EXPENSES NOT TO EXCEED TEN DOLLARS ($10.00), AND/OR ANY RIGHTS TO
            HAVE DAMAGES MULTIPLIED OR OTHERWISE INCREASED; AND (4) ENTRANT’S REMEDIES ARE LIMITED TO A CLAIM FOR MONEY
            DAMAGES (IF ANY) AND ENTRANT IRREVOCABLY WAIVES ANY RIGHT TO SEEK INJUNCTIVE OR EQUITABLE RELIEF.
          </BodyText>
          <BodyText>
            Any dispute arising under or related hereto (whether for breach of contract, tortious conduct or otherwise)
            will be governed by the laws of the State of California, without reference to its conflicts of law
            principles and will be brought exclusively in the state and federal courts located in San Francisco,
            California, and each entrant accepts and submits to the personal jurisdiction of these California courts
            with respect to any legal actions, suits or proceedings arising from or relating to this Sweepstakes or
            these Official Rules.
          </BodyText>
          <BodyText>
            12. GENERAL RULES: Sponsor’s failure to enforce any term of these Official Rules shall not constitute a
            waiver of that provision. Sponsor’s decisions will be final in all matters relating to the Sweepstakes,
            Sponsor reserves the right to restrict or void online Entries or participation from any IP address if any
            suspicious Entry and/or participation is detected. Sponsor reserves the right, in its sole discretion, to
            void Entries of any Entrants who Sponsor believes has attempted to tamper with or impair the administration,
            security, fairness or proper play of the Sweepstakes. In the event there is an alleged or actual ambiguity,
            discrepancy or inconsistency between disclosures or other statements contained in any Sweepstakes-related
            materials and/or these Official Rules (including any alleged discrepancy or inconsistency in these Official
            Rules), it will be resolved in Sponsor’s sole discretion. Entrants waive any right to claim ambiguity in the
            Sweepstakes or these Official Rules. If Sponsor determines at any time in its sole discretion that Entrant
            is disqualified, ineligible, or in violation of these Official Rules, or engaging in behavior that Sponsor
            deems obnoxious, inappropriate, threatening, illegal or that is intended to annoy, abuse, threaten or harass
            any other person, Sponsor reserves the right in its sole discretion to select an alternate winner. The
            invalidity or unenforceability of any provision of these Official Rules will not affect the validity or
            enforceability of any other provision. If the Sweepstakes is not capable of running as planned for any
            reason, Sponsor reserves the right, in its sole discretion, to cancel, modify or suspend the Sweepstakes and
            award the prize from eligible entries received prior to cancellation, modification, or suspension or as
            otherwise deemed fair and appropriate by Sponsor. In the event of a dispute concerning who submitted an
            Entry, the participant will be declared to be the registered account holder of the Twitter account as
            applicable, of record for the account submitting an Entry during the Sweepstakes Period, but only if that
            person meets all other eligibility criteria, otherwise the Entry may be disqualified and any potential Prize
            won will be forfeited in Sponsor’s sole discretion. Each potential Winner may be required to provide Sponsor
            with proof that he or she is the authorized account holder. If a dispute cannot be resolved to Sponsor’s
            satisfaction, the Entry and individual(s) may be deemed ineligible in Sponsor’s sole discretion. CAUTION:
            ANY ATTEMPT TO DAMAGE ANY ONLINE SERVICE OR WEB SITE OR UNDERMINE THE LEGITIMATE OPERATIONS OF THE
            SWEEPSTAKES VIOLATES CRIMINAL AND CIVIL LAWS. IF SUCH AN ATTEMPT IS MADE, SPONSOR MAY SEEK DAMAGES TO THE
            FULLEST EXTENT PERMITTED BY LAW AND MAY DISQUALIFY ANY PARTICIPANT MAKING SUCH ATTEMPT.
          </BodyText>
        </TextBlock>
      </div>
    </View>
  );
};
