import { ethers } from "ethers";
import { InMemoryCache, ApolloClient, useQuery } from "@apollo/client";

import { INFLATION_MULTIPLIER_QUERY } from "@queries/INFLATION_MULTIPLIER";
import { InflationMultiplier } from "../types/inflationMultiplier";
import { useMemo } from "react";

function calcRebasePercentage(numerator: ethers.BigNumber, base: ethers.BigNumber) {
  const _numerator = parseFloat(ethers.utils.formatEther(numerator));
  const _base = parseFloat(ethers.utils.formatEther(base));
  return (_numerator / _base - 1) * 100;
}

const tempApolloClient = new ApolloClient({
  uri: "https://api.thegraph.com/subgraphs/name/ecographs/beam-subgraphs-optimism",
  cache: new InMemoryCache(),
});

export const useInflationMultipliers = () => {
  const { data, loading, called } = useQuery(INFLATION_MULTIPLIER_QUERY, {
    client: tempApolloClient,
  });

  const inflationMultipliers: InflationMultiplier[] = useMemo(
    () =>
      data?.inflationMultiplier.length
        ? data.inflationMultiplier.map((inflationMultiplier, index) => {
            const prevInflationMultiplier =
              index + 1 < data.inflationMultiplier.length
                ? data.inflationMultiplier[index + 1].value
                : ethers.constants.WeiPerEther;

            return {
              id: inflationMultiplier.id,
              value: ethers.BigNumber.from(inflationMultiplier.value),
              blockNumber: parseInt(inflationMultiplier.blockNumber),
              timestamp: parseInt(inflationMultiplier.timestamp),
              percentDifference: calcRebasePercentage(prevInflationMultiplier, inflationMultiplier.value),
            };
          })
        : [],
    [data],
  );

  return { inflationMultipliers, loading, called };
};
