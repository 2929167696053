import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { useAppSelector } from "@redux";

export interface MerchantState {
  isMerchant: boolean;
  isMerchantModeEnabled: boolean;
}

const initialState: MerchantState = {
  isMerchant: false,
  isMerchantModeEnabled: false,
};

export const merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    activateMerchant: state => {
      state.isMerchant = true;
    },
    enableMerchantMode: (state, action: PayloadAction<{ enabled: boolean }>) => {
      state.isMerchantModeEnabled = action.payload.enabled;
      document.documentElement.dataset.merchantMode = `${action.payload.enabled}`;
      localStorage.setItem("merchant-mode-enabled", `${action.payload.enabled}`);
    },
  },
});

export const { activateMerchant, enableMerchantMode } = merchantSlice.actions;

export default merchantSlice.reducer;

export const useMerchant = () => useAppSelector(state => state.merchant);
