import { USER_TOKENS, UserToken } from "@constants";
import { ethers } from "ethers";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Balances, useAccount } from "./AccountContext";

interface ITokenContext {
  token: UserToken;
  balance?: ethers.BigNumber;
  baseBalance?: ethers.BigNumber;
  optimismBalance?: ethers.BigNumber;
  setActiveToken: (_: UserToken) => void;
}

export const TokenContext = React.createContext<ITokenContext>({
  token: UserToken.USD,
  setActiveToken: () => {
    return;
  },
});

export const DEFAULT_USER_TOKEN = UserToken.USD;
export const DEFAULT_CURRENCY_KEY = "defaultCurrency";

export const TokenProvider = ({ children }: { children: React.ReactNode }) => {
  const [activeToken, setActiveToken] = useState<UserToken>(DEFAULT_USER_TOKEN);
  const storedBalances = useRef<Balances>();
  const { balances } = useAccount();
  const [URLSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  // set token based on url param
  useEffect(() => {
    if (URLSearchParams.get("c") === "eco") {
      setActiveToken(UserToken.ECO);
    } else if (URLSearchParams.get("c") === "usd") {
      setActiveToken(UserToken.USD);
    }
  }, [URLSearchParams]);

  // set active token to default currency on balance change if the active currency isn't the default,
  // and user has no balance in active currency
  useEffect(() => {
    if (!localStorage.getItem(DEFAULT_CURRENCY_KEY)) {
      localStorage.setItem(DEFAULT_CURRENCY_KEY, DEFAULT_USER_TOKEN);
    }

    const defaultCurrency = localStorage.getItem(DEFAULT_CURRENCY_KEY) as UserToken;

    if (USER_TOKENS.indexOf(defaultCurrency) < 0) {
      localStorage.removeItem(DEFAULT_CURRENCY_KEY);
      return;
    }

    // only do this on home
    if (pathname !== "/") {
      return;
    }

    if (!storedBalances.current) {
      storedBalances.current = balances;
    }

    if (balances[defaultCurrency].total.gt(0) && balances[activeToken].total.eq(0) && activeToken !== defaultCurrency) {
      if (storedBalances.current[defaultCurrency].total.eq(balances[defaultCurrency].total)) {
        // no change in balance of default, don't do anything
        return;
      }

      // change in balance of default, switch to default
      setActiveToken(defaultCurrency);
      storedBalances.current = balances;
    }
  }, [balances]);

  // if no token in params, and a default currency set, set to default
  useEffect(() => {
    if (localStorage.defaultCurrency && !URLSearchParams.get("c")) {
      setActiveToken(localStorage.defaultCurrency as UserToken);
    }
  }, []);

  return <TokenContext.Provider value={{ token: activeToken, setActiveToken }}>{children}</TokenContext.Provider>;
};

export const useCurrentToken = () => useContext(TokenContext);
