import { ethers } from "ethers";
import { useQuery } from "@tanstack/react-query";

import { MAINNET_PROVIDER } from "@modules/blockchain/providers";

export const useResolveReverseEns = (_address: string | undefined) => {
  return useQuery(["useResolveReverseEns", _address], async keys => {
    const address = keys.queryKey[1];
    if (address && ethers.utils.isAddress(address)) {
      const resolved = await MAINNET_PROVIDER.lookupAddress(address);
      if (resolved) {
        return { address: ethers.utils.getAddress(address), ens: resolved };
      }
    }
    return { address: ethers.constants.AddressZero, ens: undefined };
  });
};
