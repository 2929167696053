import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAccount } from "@contexts/AccountContext";
import { useMerchant } from "@redux/slides/merchant.slide";

import { Button } from "@components/button/Button";
import { NumberPad } from "@components/numberpad/NumberPad";
import View from "@components/view/View";
import { UserToken } from "@constants/tokens";

import { RequestSheet } from "@views/Request/RequestSheet/RequestSheet";

import styles from "./Request.module.css";

import { ReactComponent as PlusIcon } from "@assets/icons/plus.svg";
import { TokenIcon } from "@components/token";
import { getTokenManager } from "@modules/token-managers/tokens";
import { useRecents } from "@views/Request/hooks/useRecents";
import classNames from "classnames";
import { ethers } from "ethers";
import { AnimatePresence, LayoutGroup, MotionConfig, motion } from "framer-motion";

import { round } from "@helpers";
import { formatCurrency } from "@helpers/format";
import { v4 as uuidv4 } from "uuid";
import { animationCurveEaseOut, animationDurationFastS } from "@constants/animations";

interface RequestViewProps {
  token?: UserToken;
}

interface AmountItemProps {
  amount: number;
  id: string;
}

export const RequestView: React.FC<RequestViewProps> = ({ token = UserToken.USD }) => {
  const { isMerchantModeEnabled } = useMerchant();
  const navigate = useNavigate();
  const account = useAccount();

  const [value, setValue] = useState<string>("0");
  const [sheetOpen, setSheetOpen] = useState(false);
  const [adding, setAdding] = useState(false);
  const [amounts, setAmounts] = useState<AmountItemProps[]>([]);
  const amountScroll = useRef<HTMLDivElement>(null);

  const recents = useRecents();

  const tokenManager = getTokenManager(token);

  useEffect(() => {
    if (!isMerchantModeEnabled) {
      navigate("/");
    }
  }, [isMerchantModeEnabled]);

  const handleRecentClick = (value: number) => {
    setValue(`${value}`);
  };

  const handleAmountClick = (index: number) => {
    const clonedAmounts = amounts;
    clonedAmounts.splice(index, 1);
    if (clonedAmounts.length === 0) {
      handleClear();
    } else {
      setAmounts([...clonedAmounts]);
    }
  };

  const handleAddClick = () => {
    setAdding(true);

    if (value === "0") {
      return;
    }

    setAmounts([...amounts, { amount: Number(value), id: uuidv4() }]);

    setValue("0");

    setTimeout(() => {
      if (amountScroll.current) {
        amountScroll.current.scrollTo(9999, 0);
      }
    }, 0);
  };

  const getTotalAmount = (includeCurrentValue = false) => {
    let total = 0;
    amounts.forEach(amount => (total += amount.amount));
    if (includeCurrentValue) total += Number(value);
    return round(total, tokenManager.decimals);
  };

  useEffect(() => {
    setTimeout(() => {
      if (amountScroll.current) {
        amountScroll.current.scrollTo(9999, 0);
      }
    }, 0);
  }, [value, getTotalAmount]);

  const amount = ethers.utils.parseUnits(
    amounts.length > 0 ? getTotalAmount(true).toString() : value,
    tokenManager.decimals,
  );

  if (!account.address) return null;

  const handleContinue = () => {
    setSheetOpen(true);
    setTimeout(() => {
      recents.add(parseFloat(ethers.utils.formatUnits(amount, tokenManager.decimals)));
    }, 300);
  };

  const handleClear = () => {
    setValue("0");
    setAdding(false);
    setAmounts([]);
  };

  return (
    <View
      headerTitle="Charge"
      navbarItem={<Button title="Clear" disabled={getTotalAmount(true) <= 0} size="small" onClick={handleClear} />}
    >
      <div className={styles.body}>
        <div />
        <MotionConfig transition={{ ease: animationCurveEaseOut, duration: animationDurationFastS }}>
          <LayoutGroup>
            <NumberPad
              value={value}
              setValue={setValue}
              token={UserToken.USD}
              amountView={
                <motion.div
                  className={styles.amountWrapper}
                  animate={{
                    y: amounts.length > 0 && recents.list.length > 0 ? 16 : 0,
                  }}
                >
                  <div className={styles.multiAmountView}>
                    <div className={styles.multiAmountScrollWrapper}>
                      <motion.div
                        className={styles.multiAmountScroll}
                        ref={amountScroll}
                        animate={{
                          height: amounts.length > 0 ? "auto" : 0,
                          opacity: amounts.length > 0 ? 1 : 0,
                        }}
                      >
                        <AnimatePresence>
                          {amounts.map((amt, index) => {
                            return (
                              <motion.div
                                className={styles.multiAmountWrapper}
                                key={amt.id}
                                initial={{
                                  opacity: 0,
                                  y: 20,
                                }}
                                animate={{
                                  opacity: 1,
                                  y: 0,
                                }}
                                exit={{
                                  y: 20,
                                  opacity: 0,
                                }}
                              >
                                <button
                                  key={amt.id}
                                  className={styles.amount}
                                  onClick={handleAmountClick.bind(null, index)}
                                >
                                  {`${formatCurrency(amt.amount)}`}
                                </button>
                                {index < amounts.length && (
                                  <motion.div
                                    className={styles.plus}
                                    transition={{
                                      delay: 0.1,
                                    }}
                                    initial={{
                                      opacity: 0,
                                      y: 20,
                                    }}
                                    animate={{
                                      opacity: 1,
                                      y: 0,
                                    }}
                                  >
                                    +
                                  </motion.div>
                                )}
                              </motion.div>
                            );
                          })}
                        </AnimatePresence>
                      </motion.div>
                    </div>
                    <div className={styles.multiAmountCurrent}>
                      <div className={classNames(styles.currentAmount, { [styles.hasValue]: value !== "0" })}>
                        <div className={styles.tokenIcon}>
                          <TokenIcon token={UserToken.USD} />
                        </div>
                        <span>{value}</span>
                      </div>
                    </div>
                  </div>
                  {recents.list.length > 0 && (
                    <motion.div
                      className={styles.recents}
                      animate={{
                        height: adding ? 0 : undefined,
                        opacity: adding ? 0 : 1,
                      }}
                    >
                      <label>Recent</label>
                      {recents.list.map(recent => {
                        return (
                          <button
                            key={recent}
                            className={styles.recent}
                            onClick={handleRecentClick.bind(null, recent)}
                          >{`${formatCurrency(recent)}`}</button>
                        );
                      })}
                    </motion.div>
                  )}
                </motion.div>
              }
              message={<></>}
            />
          </LayoutGroup>
        </MotionConfig>
        <div className={styles.buttonWrapper}>
          <Button
            secondary
            onClick={handleAddClick}
            disabled={Number(value) === 0}
            leadingIcon={<PlusIcon />}
            className={styles.buttonAdd}
          />
          <Button
            title={`Charge${amounts.length > 0 ? ` ${formatCurrency(getTotalAmount(true))}` : ""}`}
            disabled={Number(value) <= 0 && getTotalAmount() <= 0}
            onClick={handleContinue}
          />
        </div>
      </div>
      <RequestSheet
        isOpen={sheetOpen}
        token={UserToken.USD}
        amount={amount}
        address={account.address}
        beamname={account.beamname?.displayName}
        onClose={() => setSheetOpen(false)}
      />
    </View>
  );
};
