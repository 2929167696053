import React from "react";
import { Space } from "antd";

import { BodyText, TextBlock } from "@components/text/Text";
import { IconCircle } from "@components/icon/IconCircle/IconCircle";
import { PasswordForm } from "@components/account/access/components/PasswordStep/PasswordForm";

import { ReactComponent as LockIcon } from "@assets/icons/lock_icon.svg";
import { Callout } from "@components/callout/Callout";

export interface PasswordDrawerProps {
  action: "Save" | "Load";
  loading?: boolean;
  hideIcon?: boolean;

  onSubmit(password: string): void;
}

export const PasswordStep: React.FC<PasswordDrawerProps> = ({ action, hideIcon, loading, onSubmit }) => {
  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      {!hideIcon ? <IconCircle style={{ margin: "0 auto" }} icon={<LockIcon />} /> : null}
      <TextBlock>
        {action === "Save" ? (
          <>
            <BodyText centered>
              To ensure your account security, set a Beam-specific password which will be required to log in to this
              account in the future.
            </BodyText>
            <Callout>
              <BodyText centered>
                Save this password somewhere secure. Beam is not able to recover this password if lost.
              </BodyText>
            </Callout>
          </>
        ) : (
          <>
            <BodyText centered>Enter your Beam-specific password to load your account.</BodyText>
          </>
        )}
      </TextBlock>

      <PasswordForm
        loading={loading}
        onSubmit={onSubmit}
        hideConfirmation={action !== "Save"}
        btnText={action === "Save" ? "Set Beam Password" : "Log in"}
      />
    </Space>
  );
};
