import { useEffect, useState } from "react";
import { BigNumber } from "ethers";
import { useQuery } from "@apollo/client";

import { BEAMNAMES_ADDRESS, getNetwork, getTokenInfo, Network, Token } from "@constants";
import { APOLLO_CLIENTS } from "@constants/subgraphs";

import { useStackup } from "@contexts/StackupContext";
import { ACCOUNT_QUERY } from "@queries/ACCOUNT";

import { Transfer } from "../types/transfer";
import { extractBeamname } from "@hooks/useDisplayName";

export interface QueryData {
  lastTransfer?: Transfer;
  beamname?: {
    name: string;
    tokenId: BigNumber;
  };
}

const EXCLUDE_TRANSFERS_BY_ADDRESS: string[] = [BEAMNAMES_ADDRESS.toLowerCase()];

export const useBeamSubgraph = (_network: Network) => {
  const network = getNetwork(_network);
  const apolloClient = APOLLO_CLIENTS[_network];
  const { address } = useStackup();

  if (!apolloClient) throw new Error("Missing apollo client for network " + _network);

  const [queryData, setQueryData] = useState<QueryData | undefined>();

  const { data, loading, called, startPolling, stopPolling } = useQuery(ACCOUNT_QUERY, {
    skip: !address,
    client: apolloClient,
    variables: address
      ? { address: address.toLowerCase(), string: address.toLowerCase(), blacklist: EXCLUDE_TRANSFERS_BY_ADDRESS }
      : undefined,
  });

  useEffect(() => {
    if (!loading && data) {
      let beamname: QueryData["beamname"];
      if (data.account?.beamnames.length) {
        beamname = {
          name: data.account.beamnames[0].name,
          tokenId: BigNumber.from(data.account.beamnames[0].id),
        };
      }

      let lastTransfer: QueryData["lastTransfer"] = undefined;
      if (data.lastTransfer.length) {
        const [tokenId] = data.lastTransfer[0].id.split("-");
        lastTransfer = {
          id: data.lastTransfer[0].id,
          to: {
            address: data.lastTransfer[0].to.id,
            beamname: extractBeamname(data.lastTransfer[0].to.beamnames),
          },
          from: {
            address: data.lastTransfer[0].from.id,
            beamname: extractBeamname(data.lastTransfer[0].from.beamnames),
          },
          txHash: data.lastTransfer[0].txHash,
          timestamp: parseInt(data.lastTransfer[0].timestamp),
          token: getTokenInfo(tokenId as Token, network.id as Network),
          amount: BigNumber.from(data.lastTransfer[0].amount),
          network,
        };
      }
      setQueryData({ beamname, lastTransfer });
    }
  }, [data, loading]);

  useEffect(() => {
    startPolling(5_000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  return { data: queryData, loading, called };
};
