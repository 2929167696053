import { getNetwork, getNetworkByShort, INetwork, Network, Token, USER_TOKENS, UserToken } from "@constants";

import { ClaimSearchParams, createLink } from "@modules/peanut/utils";
import { BeamLinkStorage } from "@modules/peanut/BeamLinkStorage";

export interface BeamLinkDeposit {
  id: string;
  amount?: string;
  network: Network;
}

export interface BeamLinkFlat {
  token: UserToken;
  password: string;
  deposits: BeamLinkDeposit[];
}

export class BeamLink {
  constructor(
    public readonly token: BeamLinkFlat["token"],
    public readonly password: BeamLinkFlat["password"],
    public readonly deposits: BeamLinkFlat["deposits"] = [],
  ) {}

  static from(data: BeamLinkFlat) {
    return new BeamLink(data.token, data.password, data.deposits);
  }

  static validate(item: unknown): item is BeamLinkFlat {
    return (
      typeof item === "object" &&
      item !== null &&
      "password" in item &&
      "deposits" in item &&
      Array.isArray(item.deposits) &&
      item.deposits.every(
        (deposit: unknown) =>
          typeof deposit === "object" &&
          deposit !== null &&
          "id" in deposit &&
          "amount" in deposit &&
          "network" in deposit,
      )
    );
  }

  static fromURL(link: string) {
    try {
      const url = new URL(link);

      let links: { network: INetwork; index: number }[] = [];

      // optional: handle legacy version
      let contractVersion = url.searchParams.get(ClaimSearchParams.VERSION);

      // get ID
      const rawId = url.searchParams.get(ClaimSearchParams.DEPOSIT_ID);

      if (!contractVersion && rawId) {
        contractVersion = "0";
        const index = parseInt(rawId);
        links.push({
          network: getNetwork("optimism"),
          index,
        });
      } else if (contractVersion === "1") {
        // parse ID into network/index pairs
        links = rawId
          ? rawId
              .split("-")
              .map(networkIndex => {
                const [networkShort, index] = networkIndex.split(";");
                return {
                  network: getNetworkByShort(networkShort)!,
                  index: parseInt(index),
                };
              })
              .filter(links => !!links.network)
          : [];
      }

      // get password
      const password = url.searchParams.get(ClaimSearchParams.PASSWORD);

      // get token
      const _token = url.searchParams.get(ClaimSearchParams.TOKEN);

      let token: UserToken;
      if (_token === Token.USDC) {
        token = UserToken.USD;
      } else if (_token && USER_TOKENS.includes(_token as never)) {
        token = _token as UserToken;
      } else {
        throw new Error("Invalid token");
      }

      if (!password) {
        throw new Error("Invalid password");
      }

      return new BeamLink(
        token,
        password,
        links.map(({ network, index }) => ({ id: index.toString(), network: network.network! })),
      );
    } catch (e) {
      console.warn("Unable to parse BeamLink", e);
      return null;
    }
  }

  addDeposit(deposit: BeamLinkDeposit) {
    this.deposits.push(deposit);
  }

  store() {
    const storage = BeamLinkStorage.init();
    storage.add(this);
    storage.store();
  }

  toURL(): string {
    return createLink(
      this.token,
      this.password,
      this.deposits
        .map(deposit => {
          const network = getNetwork(deposit.network);
          return [network.short, deposit.id].join(";");
        })
        .join("-"),
    );
  }
}
