const ONBOARDING_COMPLETED_KEY = "beam-welcome-sheet-shown";

export function markOnboardingAsCompleted() {
  window.localStorage.setItem(ONBOARDING_COMPLETED_KEY, "true");
}

export function showWelcomeSheet(): boolean {
  const hasBeenShown = window.localStorage.getItem(ONBOARDING_COMPLETED_KEY);
  markOnboardingAsCompleted();
  return !hasBeenShown;
}
