import { ethers } from "ethers";
import { useQuery } from "@tanstack/react-query";
import { create, windowScheduler } from "@yornaath/batshit";

import { isSameAddress } from "@helpers";
import { APOLLO_CLIENTS } from "@constants";
import { extractBeamname } from "@hooks/useDisplayName";
import { BEAMNAME_BATCH_QUERY } from "@queries/BEAMNAME_BATCH";

const beamnames = create({
  fetcher: async (addresses: string[]) => {
    return APOLLO_CLIENTS.optimism
      .query({ query: BEAMNAME_BATCH_QUERY, variables: { addresses: addresses.map(addr => addr.toLowerCase()) } })
      .then(response =>
        response.data.accounts.map(item => ({ address: item.id, name: extractBeamname(item.beamnames) })),
      );
  },
  resolver: (items, key) => items.find(item => isSameAddress(item.address, key)),
  scheduler: windowScheduler(100), // Default and can be omitted.
});

export const useBeamnameReverse = (address?: string) => {
  const { data, ...args } = useQuery(
    ["beamname-lookup", address],
    async ctx => {
      const addr = ctx.queryKey[1]!;
      const response = await beamnames.fetch(addr);
      return response?.name || null;
    },
    {
      enabled: Boolean(address && ethers.utils.isAddress(address)),
    },
  );

  return { data: data || undefined, ...args };
};
