import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { useAppSelector } from "@redux";
import { Network, UserToken } from "@constants";

export interface ConfigState {
  fee?: {
    recipients: Record<Network, string>;
    perToken: Record<UserToken, string>;
  };
}

const initialState: ConfigState = {};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setFeeConfig: (state, action: PayloadAction<ConfigState["fee"]>) => {
      state.fee = action.payload;
    },
  },
});

export const { setFeeConfig } = configSlice.actions;

export default configSlice.reducer;

export const useConfig = () => useAppSelector(state => state.config);
export const useConfigFee = () => useConfig().fee;
