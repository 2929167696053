import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { v4 as uuidv4 } from "uuid";
import { ethers } from "ethers";
import * as Sentry from "@sentry/react";

import { RELAYOOR_URL } from "@constants";
import { logError } from "@helpers";
import {
  IBridgeTransfer,
  ICreateTransfer,
  ICustomer,
  IExternalAccount,
  IRegisterCustomer,
  IRegisterExternalAccount,
} from "@modules/bridge/types";
import { RelayoorSignatureAuth } from "@modules/blockchain/RelayoorSignatureAuth";

enum BRIDGE_URL {
  GET_TOS = "/api/v1/offramp/bridge/tos",
  GET_USER = "/api/v1/offramp/bridge/user",
  CUSTOMER = "/api/v1/offramp/bridge/customers",
  TRANSFER = "/api/v1/offramp/bridge/transfers",
  GET_TRANSFER = "/api/v1/offramp/bridge/transfers/{transfer_id}",
  GET_CUSTOMER = "/api/v1/offramp/bridge/customers/{customer_id}",
  EXTERNAL_ACCOUNT = "/api/v1/offramp/bridge/customers/{customer_id}/external_accounts",
  GET_EXTERNAL_ACCOUNT = "/api/v1/offramp/bridge/customers/{customer_id}/external_accounts/{external_account_id}",
}

interface IBridgeOpts {
  walletAddr: string;
  signer: ethers.Wallet;
  customerId?: string;
  transferId?: string;
  idempotencyKey?: string;
  signedAgreementId?: string;
  externalAccountId?: string;
}

interface BridgeStringify {
  customerId?: string;
  transferId?: string;
  signedAgreementId?: string;
  externalAccountId?: string;
}

export class Bridge {
  public customerId?: string;
  public transferId?: string;
  public idempotencyKey?: string;
  public signedAgreementId?: string;
  public externalAccountId?: string;

  private signer: ethers.Wallet;
  private walletAddr: string;

  constructor(public readonly opts: IBridgeOpts) {
    this.walletAddr = opts.walletAddr;
    this.signer = opts.signer;
    if (opts.signedAgreementId) this.setSignedAgreementId(opts.signedAgreementId);
    if (opts.customerId) this.setCustomerId(opts.customerId);
    if (opts.externalAccountId) this.setExternalAccountId(opts.externalAccountId);
    if (opts.transferId) this.setTransferId(opts.transferId);
  }

  private static getStorageKey(walletAddr?: string): string {
    if (!walletAddr) return "BRIDGE_OFFRAMP";
    return `BRIDGE_OFFRAMP_${walletAddr.substring(0, 10).toLowerCase()}`;
  }

  private static recover(walletAddr?: string): BridgeStringify {
    const raw = window.localStorage.getItem(Bridge.getStorageKey(walletAddr));
    if (!raw) return {};
    return JSON.parse(raw);
  }

  static init(walletAddr: string, signer: ethers.Wallet) {
    return new Bridge({
      signer,
      walletAddr,
      // ...Bridge.recover(walletAddr),
    });
  }

  setIdempotencyKey(key = uuidv4()) {
    this.idempotencyKey = key;
  }

  setSignedAgreementId(signedAgreementId: string) {
    this.signedAgreementId = signedAgreementId;
  }

  setCustomerId(customerId: string) {
    this.customerId = customerId;
    this.store();
  }

  setTransferId(transferId: string) {
    this.transferId = transferId;
  }

  /**
   * CUSTOMERS API
   */

  getTOSLink() {
    this.setIdempotencyKey(); // Reset Idempotency Key
    return this.request<{ url: string }>(BRIDGE_URL.GET_TOS, {
      method: "POST",
    });
  }

  async registerCustomer(customerData: IRegisterCustomer) {
    this.setIdempotencyKey(); // Reset Idempotency Key
    const response = await this.request<ICustomer>(BRIDGE_URL.CUSTOMER, { method: "POST", data: customerData });
    this.setCustomerId(response.id);
    return response;
  }

  getCustomer(customerId: string) {
    return this.request<ICustomer>(BRIDGE_URL.GET_CUSTOMER.replace("{customer_id}", customerId), {
      method: "GET",
    }).catch(error => {
      this.createErrorBreadcrumb("bridge get customer", error);
      logError("bridge-get-customer", error);
      throw error;
    });
  }

  /**
   * EXTERNAL ACCOUNT
   */
  async registerExternalAccount(customerId: string, externalAccount: IRegisterExternalAccount) {
    this.setIdempotencyKey(); // Reset Idempotency Key
    const response = await this.request<IExternalAccount>(
      BRIDGE_URL.EXTERNAL_ACCOUNT.replace("{customer_id}", customerId),
      { method: "POST", data: externalAccount },
    );
    this.setExternalAccountId(response.id);
    return response;
  }

  async getExternalAccount(customerId: string, externalAccountId: string) {
    this.setIdempotencyKey(); // Reset Idempotency Key
    const response = await this.request<IExternalAccount>(
      BRIDGE_URL.GET_EXTERNAL_ACCOUNT.replace("{customer_id}", customerId).replace(
        "{external_account_id}",
        externalAccountId,
      ),
      { method: "GET" },
    ).catch(error => {
      this.createErrorBreadcrumb("bridge get external account", error);
      logError("bridge-get-external-account", error);
      throw error;
    });
    this.setExternalAccountId(response.id);
    return response;
  }

  public setExternalAccountId(externalAccountId: string) {
    this.externalAccountId = externalAccountId;
    this.store();
  }

  /**
   * TRANSFERS
   */

  async createTransfer(transfer: ICreateTransfer) {
    this.setIdempotencyKey(); // Reset Idempotency Key
    const response = await this.request<IBridgeTransfer>(BRIDGE_URL.TRANSFER, { method: "POST", data: transfer });
    this.setTransferId(response.id);
    return response;
  }

  getTransfer(transferId: string) {
    return this.request<IBridgeTransfer>(BRIDGE_URL.GET_TRANSFER.replace("{transfer_id}", transferId), {
      method: "GET",
    });
  }

  async getUser() {
    const user = await this.request<{ customerId?: string; externalAccountId?: string }>(BRIDGE_URL.GET_USER, {
      method: "GET",
    }).catch(error => {
      this.createErrorBreadcrumb("bridge get user", error);
      logError("bridge-get-user", error);
      throw error;
    });

    if (user.customerId) this.setCustomerId(user.customerId);
    if (user.externalAccountId) this.setExternalAccountId(user.externalAccountId);

    return user;
  }

  private createErrorBreadcrumb(type: string, error: AxiosError) {
    Sentry.addBreadcrumb({
      level: "error",
      type: type,
      data: {
        message: error.message,
        response: error.response,
        data: error.response?.data,
        address: this.walletAddr,
      },
    });
  }

  public toJSON(): BridgeStringify {
    return {
      customerId: this.customerId,
      transferId: this.transferId,
      signedAgreementId: this.signedAgreementId,
      externalAccountId: this.externalAccountId,
    };
  }

  store() {
    window.localStorage.setItem(Bridge.getStorageKey(this.walletAddr), JSON.stringify(this.toJSON()));
  }

  private async request<T>(endpoint: string, opts: AxiosRequestConfig) {
    opts.url = new URL(endpoint, RELAYOOR_URL).toString();
    opts.headers = { ...opts.headers };
    if (opts.method !== "GET" && this.idempotencyKey) opts.headers["x-idempotent-id"] = this.idempotencyKey;
    const signatureHeaders = await RelayoorSignatureAuth.getHeaders(this.signer, this.walletAddr);
    opts.headers = { ...opts.headers, ...signatureHeaders };

    const response = await axios.request<T>(opts);
    return response.data;
  }
}
