import { ethers } from "ethers";

import { getTokenInfo } from "@constants";
import { ERC20__factory } from "@assets/contracts";

import { AutoSwap } from "@modules/blockchain/AutoSwap";
import { TokenTransfer, Transfer, TransferOpts } from "@modules/transfer/Transfer";

interface AutoSwapOpts {
  bips?: number;
  retries?: number;
}

interface AutoSwapOpts {
  bips?: number;
  retries?: number;
}

type AutoSwapTransformation = { autoSwap?: false | AutoSwapOpts };

interface AutoSwapTransferOpts extends TransferOpts<AutoSwapTransformation> {
  oneOut?: boolean; // Only send out one token, instead of multiple token transfers
}

export class AutoSwapTransfer extends Transfer<AutoSwapTransformation> {
  private readonly oneOut: boolean;

  constructor(opts: AutoSwapTransferOpts) {
    super(opts);
    const { oneOut = false, transformations } = opts;

    this.oneOut = oneOut;

    if (transformations?.autoSwap !== false) {
      this.transformations.push(this.executeSwaps.bind(this, transformations?.autoSwap));
    }
  }

  isBalanceEnough() {
    const amount = this.amount;
    if (!amount) throw new Error("Amount is undefined");

    if (this.oneOut) {
      // If only one token transfer is allowed, a token balance needs to be equal or greater than the total amount
      return this.getAvailableBalance().some(balance => ethers.BigNumber.from(balance.amount).gte(amount));
    }
    return super.isBalanceEnough();
  }

  async getTransfers(): Promise<TokenTransfer[]> {
    return super.getTransfers().then(transfers => {
      if (this.oneOut && transfers.length !== 1) {
        throw new Error("More than one token is transferred");
      }
      return transfers;
    });
  }

  /**
   * Auto Swap Transformation
   */
  async executeSwaps(opts: AutoSwapOpts = {}) {
    if (!this.oneOut && this.tokenManager.composedBy.length === this.acceptedTokens.length && !this.oneOut) {
      // If all available tokens are accepted skip swaps
      return;
    }
    const autoSwap = new AutoSwap(
      this.userToken,
      this.destinationNetwork,
      this.balances[this.destinationNetwork].balances,
    );
    if (!autoSwap.isEnabled()) return;

    // The main token is the one with the largest balance, and it's in the available token list
    const [mainToken] = this.getAvailableBalance();

    const maxSwapAmount = autoSwap
      .getSwappableTokenAmounts(mainToken.token)
      .reduce((acc, { amount }) => acc.add(amount), ethers.constants.Zero);

    // Skip execution is max swap amount is zero
    if (maxSwapAmount.isZero()) return;

    const swapAmount = ethers.BigNumber.from(this.amount).sub(mainToken.amount);
    const { swapInAmounts, swapOutAmounts, trades } = await autoSwap.calculateSwapAmount(swapAmount, mainToken.token, {
      maxSwapAmount,
      retries: opts.retries,
      bips: opts.bips,
    });

    const swapTxs = await autoSwap.swap(trades, this.from);
    const approvalTxs = swapInAmounts.map(swap => ({
      to: getTokenInfo(swap.token, this.destinationNetwork).address,
      data: ERC20__factory.createInterface().encodeFunctionData("approve", [
        autoSwap.getSwapRouterAddress(),
        swap.amount,
      ]),
    }));

    swapInAmounts.map(swapIn =>
      this.subtractBalance({
        token: swapIn.token,
        network: this.destinationNetwork,
        amount: swapIn.amount,
      }),
    );
    swapOutAmounts.map(swapOut =>
      this.addBalance({
        token: swapOut.token,
        network: this.destinationNetwork,
        amount: swapOut.amount,
      }),
    );

    this.transformationTxs.push(...approvalTxs, ...swapTxs);
  }
}
