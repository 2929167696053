import axios from "axios";
import { ethers } from "ethers";
import { ERC4337 } from "userop/dist/constants";
import { EntryPoint__factory } from "userop/dist/typechain";
import { logError } from "@helpers";

import { BeamWallet } from "@modules/smart-wallet/BeamWallet";
import { OPTIMISM_PROVIDER } from "@modules/blockchain/providers";

import { fetchQuery } from "@helpers/persistQueryClient";
import { Beamnames__factory, ERC20__factory, Multicall3__factory } from "@assets/contracts";
import {
  APOLLO_CLIENTS,
  BEAMNAMES_ADDRESS,
  getNetwork,
  getTokenInfo,
  MULTICALL3_ADDRESS,
  NETWORK,
  Token,
} from "@constants";

import { store } from "@redux/store";
import { incrementBalance } from "@redux/slides/balances/balances.slide";

import { ACCOUNT_QUERY } from "@queries/ACCOUNT";
import { getClient, getFlatPaymaster, getStackupRpcUrl } from "@helpers/bundler";

export class SimpleAccountFactoryMigration {
  static readonly NEW_FACTORY_ADDRESS = ethers.utils.getAddress("0x3fB7476CA9b424cFf1156267EcA49eb878dB0B80");
  static readonly OLD_FACTORY_ADDRESS = ethers.utils.getAddress("0xE8Df82fA4E10e6A12a9Dab552bceA2acd26De9bb");

  private static readonly START_FROM_BLOCK = NETWORK.isTestnet ? 12_065_014 : 106_979_699;
  private static readonly TO_BLOCK = NETWORK.isTestnet ? 13_000_000 : 108_000_000;

  private static readonly TOKENS = [Token.ECO, Token.USDC];

  private readonly address: string;
  private _newWalletAddress: string | undefined;
  private oldWalletData: Awaited<ReturnType<typeof SimpleAccountFactoryMigration._getWalletData>> | undefined;

  constructor(private readonly signer: ethers.Wallet, private readonly wallet: BeamWallet) {
    this.address = wallet.getSender();
  }

  static async getSender(signer: ethers.Wallet) {
    const network = getNetwork("optimism");
    return BeamWallet.init(signer, network.rpcUrl, {
      network: network.chainId,
      factory: SimpleAccountFactoryMigration.OLD_FACTORY_ADDRESS,
      overrideBundlerRpc: getStackupRpcUrl(network.network!),
      paymasterMiddleware: getFlatPaymaster("optimism"),
    });
  }

  async needsMigration(): Promise<boolean> {
    if (this.hasMigratedAlready()) return false;

    const { balances } = await this.getWalletData();

    if (this.hasTokens(balances.map(token => token.balance))) {
      return true;
    }

    const beamnames = await SimpleAccountFactoryMigration.getBeamnames(this.address);
    return !!beamnames.length;
  }

  async migrate() {
    const newAddress = await this.getNewWalletAddress();

    const [{ balances }, beamnames] = await Promise.all([
      this.getWalletData(),
      SimpleAccountFactoryMigration.getBeamnames(this.address),
    ]);

    const transfers = balances
      .filter(({ balance }) => !balance.isZero())
      .map(({ token, balance }) => ({
        target: getTokenInfo(token, "optimism").address,
        callData: ERC20__factory.createInterface().encodeFunctionData("transfer", [newAddress, balance]),
      }));

    const beamnamesTransfers = beamnames.map(tokenId => ({
      target: BEAMNAMES_ADDRESS,
      callData: Beamnames__factory.createInterface().encodeFunctionData("transferFrom", [
        this.address,
        newAddress,
        tokenId,
      ]),
    }));

    const calls = [...transfers, ...beamnamesTransfers];

    const client = await getClient("optimism");
    this.wallet.executeBatch(
      calls.map(call => call.target),
      calls.map(call => call.callData),
    );

    const tx = await client.sendUserOperation(this.wallet);
    const receipt = await tx.wait();

    if (!receipt) throw new Error("UserOp was not sent to bundler");

    this.logMigration();

    // Update token balances after migration
    balances
      .filter(({ balance }) => !balance.isZero())
      .forEach(data => {
        store.dispatch(incrementBalance({ network: "optimism", token: data.token, amount: data.balance.toString() }));
      });

    return receipt;
  }

  private async getNewWalletAddress() {
    if (!this._newWalletAddress) {
      this._newWalletAddress = await SimpleAccountFactoryMigration.getNewWallet(this.signer.address);
    }
    return this._newWalletAddress;
  }

  private async getWalletData() {
    if (!this.oldWalletData) {
      this.oldWalletData = await SimpleAccountFactoryMigration._getWalletData(this.address);
    }
    return this.oldWalletData;
  }

  private hasTokens(balances: ethers.BigNumber[]) {
    return balances.some(balance => !balance.isZero());
  }

  private getMigrationKey() {
    return ["simple_account_factory_migration-v1", this.address].join("-");
  }

  private hasMigratedAlready() {
    return !!window.localStorage.getItem(this.getMigrationKey());
  }

  private logMigration() {
    this.logToDb();

    // Store in local storage
    window.localStorage.setItem(this.getMigrationKey(), Date.now().toString());
  }

  async logToDb() {
    try {
      return await axios.post(
        "https://api.retool.com/v1/workflows/cff79d19-7456-4614-a309-8c50d1050dfa/startTrigger?workflowApiKey=retool_wk_15bbfdd1e5594b91b20be3dbe5c0ab24",
        {
          old_address: this.address,
          new_address: await this.getNewWalletAddress(),
        },
      );
    } catch (error) {
      logError("SimpleAccountFactoryMigration:logToDb]", error);
    }
  }

  private static async _getWalletData(address: string) {
    const tokens = SimpleAccountFactoryMigration.TOKENS;
    const balanceCalls = tokens.map(token => ({
      allowFailure: false,
      target: getTokenInfo(token).address,
      callData: ERC20__factory.createInterface().encodeFunctionData("balanceOf", [address]),
    }));

    const multicall = Multicall3__factory.connect(MULTICALL3_ADDRESS, OPTIMISM_PROVIDER);
    const balancesResult = await multicall.callStatic.aggregate3(balanceCalls);

    const balances = balancesResult.map((response, index) => ({
      token: tokens[index],
      balance: ethers.BigNumber.from(response.returnData),
    }));

    return {
      balances,
    };
  }

  private static async getWalletFactoryFromEntryPoint(address: string) {
    return fetchQuery({
      staleTime: Infinity,
      queryKey: ["SimpleAccountFactoryMigration", "getWalletFactoryFromEntryPoint", address],
      queryFn: async context => {
        const entryPoint = EntryPoint__factory.connect(ERC4337.EntryPoint, OPTIMISM_PROVIDER);

        const events = await entryPoint.queryFilter(
          entryPoint.filters.AccountDeployed(undefined, context.queryKey[2]),
          SimpleAccountFactoryMigration.START_FROM_BLOCK,
          SimpleAccountFactoryMigration.TO_BLOCK,
        );

        const [accountDeployedEvent] = events;
        if (!accountDeployedEvent) {
          return null;
        }

        const { factory } = entryPoint.interface.decodeEventLog("AccountDeployed", accountDeployedEvent.data);
        return factory as string;
      },
    });
  }

  private static async getBeamnames(address: string) {
    const response = await APOLLO_CLIENTS.optimism.query({
      query: ACCOUNT_QUERY,
      variables: { address: address.toLowerCase(), string: address.toString(), blacklist: [] },
    });

    return (
      response.data.account?.beamnames.map(value => {
        return ethers.BigNumber.from(value.id);
      }) || []
    );
  }

  private static async getNewWallet(signerAddr: string) {
    return fetchQuery({
      staleTime: Infinity,
      queryKey: ["SimpleAccountFactoryMigration", "getNewWallet", signerAddr],
      queryFn: async context => {
        return BeamWallet.getSenderAddress(
          EntryPoint__factory.connect(ERC4337.EntryPoint, OPTIMISM_PROVIDER),
          BeamWallet.getInitCode(SimpleAccountFactoryMigration.NEW_FACTORY_ADDRESS, context.queryKey[2]),
        );
      },
    });
  }
}
