import { animationCurveEaseOut, animationDurationFastS } from "@constants/animations";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import useMeasure from "react-use-measure";
import styles from "./Popover.module.css";

interface PopoverProps {
  targetRef: React.RefObject<HTMLDivElement | HTMLElement>;
  open: boolean;
  children: React.ReactNode;
  position?: "topLeft" | "top" | "topRight" | "bottomLeft" | "bottom" | "bottomRight" | "center";
  onClose?: () => void;
  dataCy?: string;
}

const noop = () => {
  return false;
};

export const Popover = ({
  targetRef,
  open,
  children,
  position = "bottomLeft",
  onClose = noop,
  dataCy,
}: PopoverProps) => {
  const [[posX, posY], setPos] = React.useState([0, 0]);
  const [bodyRef, bodyRect] = useMeasure();

  const isRight = ["topRight", "bottomRight"].indexOf(position) > -1;
  const isLeft = ["topLeft", "bottomLeft"].indexOf(position) > -1;
  // const isTop = ["topLeft", "topRight", "top"].indexOf(position) > -1;
  const isBottom = ["bottomLeft", "bottomRight", "bottom"].indexOf(position) > -1;
  const isCenter = position === "center";
  const scaleAmount = isCenter ? 0.7 : 0.97;

  const handleClose = (e: MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  const setPosition = useCallback(() => {
    const node = targetRef.current;
    const rect = node?.getBoundingClientRect();

    if (!rect) {
      return;
    }

    const padding = 8;

    let x, y;

    if (isCenter) {
      x = rect.x - (bodyRect.width - rect.width) / 2;
      y = rect.y - (bodyRect.height - rect.height) / 2;
    } else {
      x = isRight ? rect.x + rect.width - bodyRect.width : rect.x;
      y = isBottom ? rect.y + rect.height + padding : rect.y - bodyRect.height - padding;
    }

    setPos([x, y]);
  }, [setPos, bodyRect, targetRef]);

  useEffect(() => {
    setPosition();
  }, [bodyRect, targetRef]);

  useEffect(() => {
    if (open) {
      document.addEventListener("click", handleClose);
    } else {
      document.removeEventListener("click", handleClose);
    }

    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, [open]);

  useEffect(() => {
    if (open) {
      window.addEventListener("resize", setPosition);
    } else {
      window.removeEventListener("resize", setPosition);
    }

    return () => {
      window.removeEventListener("resize", setPosition);
    };
  }, [open, setPosition]);

  const wrapperClass = classNames({
    [styles.wrapper]: true,
  });

  const handleBodyClick: React.MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
  };

  return (
    <>
      {createPortal(
        <AnimatePresence>
          {open && (
            <div className={wrapperClass} ref={bodyRef} style={{ left: posX, top: posY }} onClick={handleBodyClick}>
              <motion.div
                initial={{
                  opacity: 0,
                  scale: scaleAmount,
                  y: isCenter ? 0 : isBottom ? -8 : 8,
                  x: isCenter ? 0 : isLeft ? -4 : 4,
                }}
                animate={{ opacity: 1, y: 0, x: 0, scale: 1 }}
                exit={{
                  opacity: 0,
                  y: isCenter ? 0 : isBottom ? -8 : 8,
                  x: isCenter ? 0 : isLeft ? -4 : 4,
                  scale: scaleAmount,
                }}
                transition={{ ease: animationCurveEaseOut, duration: animationDurationFastS }}
                className={styles.inner}
                data-cy={dataCy}
              >
                {children}
              </motion.div>
            </div>
          )}
        </AnimatePresence>,
        document.body,
      )}
    </>
  );
};
