import React from "react";

import { Button } from "@components/button/Button";
import { OnRampSheet } from "@components/deposits/OnRampSheet/OnRampSheet";
import { useCoinbasePay } from "@components/deposits/OnRampSheet/coinbase/hooks/useCoinbasePay";
import { BodyText } from "@components/text/Text";
import { notify } from "@helpers";

import { ReactComponent as CoinbaseIcon } from "@assets/icons/coinbase_icon.svg";

import { Spinner } from "@components/spinner/Spinner";
import "./CoinbaseOnRampSheet.css";
import { isDesktop } from "react-device-detect";

interface CoinbaseOnRampSheetProps {
  open?: boolean;
  recommended?: boolean;
  onClose?: () => void;
  onSuccess(): void;
  renderAsSheet?: boolean;
}

export const CoinbaseOnRampSheet: React.FC<CoinbaseOnRampSheetProps> = ({
  open,
  recommended,
  onClose,
  onSuccess,
  renderAsSheet = true,
}) => {
  const { loading, instance } = useCoinbasePay({
    onSuccess: () => {
      // handle navigation when user successfully completes the flow
      notify({ type: "success", content: "Deposit successful! Your funds are on the way", duration: 5_000 });
      onSuccess();
    },
    onExit: () => {
      // handle navigation from dismiss / exit events due to errors
      console.log("exit");
      onClose?.();
    },
    onEvent: event => {
      // event stream
      console.log("event", { event });
    },
  });

  const handleClose = () => {
    onClose?.();
    instance.current?.destroy();
  };

  const content = (
    <div id="coinbase-widget">
      <div className="coinbase-info">
        <div className="coinbase-loader">
          <Spinner />
          <BodyText centered light>
            {loading
              ? "Connecting to Coinbase. This usually takes only a few seconds."
              : `Waiting for Coinbase transaction to complete. If Coinbase didn't open, ${
                  isDesktop ? "click" : "tap"
                } the button below.`}
          </BodyText>
        </div>

        <div className="coinbase-button" style={loading ? { pointerEvents: "none" } : undefined}>
          <Button
            disabled={loading}
            title="Open Coinbase Pay"
            leadingIcon={<CoinbaseIcon className="avoid-fill" />}
            onClick={() => {
              instance.current?.open();
            }}
            backgroundColor={!loading ? "rgb(0, 82, 255)" : undefined}
          />
          <BodyText small centered light>
            This may open a new window. If so, return to this window once the transaction with Coinbase is complete.
          </BodyText>
        </div>
      </div>
    </div>
  );

  if (renderAsSheet) {
    return (
      <OnRampSheet
        isOpen={open}
        recommended={recommended}
        name="Coinbase"
        title="Deposit USDC via Coinbase"
        subtitle={
          isDesktop
            ? "This view will close automatically once funds are received"
            : "This view will close once funds are received"
        }
        icon={<CoinbaseIcon />}
        onClose={handleClose}
        content={content}
      />
    );
  }

  return content;
};
