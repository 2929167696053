import React from "react";
import classNames from "classnames";

import "./IconCircle.css";

interface IconCircleProps extends React.HTMLProps<HTMLDivElement> {
  icon: React.ReactNode;
}

export const IconCircle: React.FC<IconCircleProps> = ({ icon, ...props }) => {
  return (
    <div {...props} className={classNames("IconCircle", props.className)}>
      {icon}
    </div>
  );
};
