import { ReactNode } from "react";
import { toast, Id as ToastId } from "react-toastify";

import { notify } from "./notify";

function legacyCopy(str: string) {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}

let prevToastId: ToastId;
export async function copy(str: string, successMessage?: ReactNode | string) {
  const successMsg = () => {
    // Avoid displaying many toast
    if (!prevToastId || (prevToastId && !toast.isActive(prevToastId)))
      prevToastId = notify({ content: successMessage, type: "success", duration: 1500 });
  };

  try {
    await navigator.clipboard.writeText(str);
    successMsg();
    return;
  } catch (error) {
    console.warn("[copy]", error);
  }

  legacyCopy(str);
  if (successMessage) {
    successMsg();
  }
}
