import { Form, FormProps, FormRow, FormTitle } from "@components/form/Form";
import React, { useEffect, useRef } from "react";

import { US_STATES } from "@constants/states";

import { useWithdrawContext } from "@components/account/withdraw/context/WithdrawContext";
import { Button } from "@components/button/Button";
import { Input, Select as Select2 } from "@components/form/Input";

export interface CustomerForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dob: string;
  ssn: string;

  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
}

export type CreateCustomerFormProps = Omit<FormProps, "children">;

export const CreateCustomerForm: React.FC<CreateCustomerFormProps> = props => {
  const form = useRef<HTMLFormElement>(null);
  const { shouldSubmitForm, setShouldSubmitForm } = useWithdrawContext();

  useEffect(() => {
    if (shouldSubmitForm) {
      form.current?.requestSubmit();
      setShouldSubmitForm(false);
    }

    return () => {
      setShouldSubmitForm(false);
    };
  }, [shouldSubmitForm]);

  return (
    <Form innerRef={form} submitOnEnter {...props}>
      <FormTitle title="Personal information" />
      <FormRow>
        <Input name="firstName" placeholder="John" required label="First name" />
        <Input name="lastName" placeholder="Smith" required label="Last name" />
      </FormRow>
      <FormRow>
        <Input name="email" placeholder="hello@eco.com" type="email" required label="Email" />
      </FormRow>
      <FormRow>
        <Input name="phone" placeholder="5551231234" type="phone" required label="Phone" />
      </FormRow>
      <FormRow>
        <Input name="dob" type="date" required label="Date of birth" />
        <Input
          name="ssn"
          required
          label="SSN"
          placeholder="123456789"
          pattern="\d{9}"
          patternError="Enter a valid SSN"
        />
      </FormRow>
      <FormTitle title="Address" />
      <FormRow>
        <Input name="address1" placeholder="123 Middlefield Rd" required label="Address line 1" />
      </FormRow>
      <FormRow>
        <Input name="address2" placeholder="Unit 3" label="Address line 2" />
      </FormRow>
      <FormRow>
        <Input name="city" placeholder="Palo Alto" label="City" required />
        <Select2
          name="state"
          required
          type="select"
          label="State"
          options={US_STATES.map(state => ({ value: state.abbrev, label: state.name }))}
        />
      </FormRow>
      <FormRow>
        <Input name="zipcode" placeholder="12345" label="Zip code" required />
        <Select2 disabled name="country" type="select" label="Country" options={[{ value: "USA", label: "USA" }]} />
      </FormRow>
      <div />
    </Form>
  );
};

export const CreateCustomerFooter = () => {
  const { loadingForm, setShouldSubmitForm } = useWithdrawContext();
  return (
    <div>
      <Button title="Continue" disabled={loadingForm} onClick={() => setShouldSubmitForm(true)} />
    </div>
  );
};
