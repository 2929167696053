import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { useBridge } from "@components/account/withdraw/hooks/useBridge";
import { ProcessingView } from "@components/account/withdraw/bridge/common/ProcessingView";
import {
  CreateCustomerForm,
  CreateCustomerFormProps,
  CustomerForm,
} from "@components/account/withdraw/bridge/customer/CreateCustomerForm";

import { IRegisterCustomer } from "@modules/bridge/types";
import { logError, notify } from "@helpers";
import { AxiosError } from "axios";
import { useWithdrawContext, WithdrawActionType } from "@components/account/withdraw/context/WithdrawContext";
import { BodyText } from "@components/text/Text";
import { Callout } from "@components/callout/Callout";

interface CreateCustomerStepProps {
  onComplete(): void;
}

export const CreateCustomerStep: React.FC<CreateCustomerStepProps> = ({ onComplete }) => {
  const bridge = useBridge();
  const queryClient = useQueryClient();
  const { dispatch, loadingForm, setLoadingForm } = useWithdrawContext();

  const [customerId, setCustomerId] = useState<string>();

  const handleSubmit: CreateCustomerFormProps["onSubmit"] = async _values => {
    const values = _values as unknown as CustomerForm;
    setLoadingForm(true);

    const data: IRegisterCustomer = {
      type: "individual",
      first_name: values.firstName,
      last_name: values.lastName,
      birth_date: values.dob,
      email: values.email,
      phone: values.phone,
      signed_agreement_id: bridge.signedAgreementId!,
      tax_identification_number: values.ssn,
      address: {
        street_line_1: values.address1,
        street_line_2: values.address2,
        city: values.city,
        country: values.country || "USA",
        state: values.state,
        postal_code: values.zipcode,
      },
    };

    try {
      const customer = await bridge.registerCustomer(data);

      dispatch({ type: WithdrawActionType.SET_CUSTOMER, customer });
      queryClient.setQueryData(["bridge-customer", customer.id], customer);

      setCustomerId(customer.id);
    } catch (_error) {
      const error = _error as AxiosError<{ details: { additionalData: { source: { key: Record<string, string> } } } }>;
      logError("bridge-register-customer", error);
      const message = Object.values(error?.response?.data?.details?.additionalData?.source?.key || {})[0];
      notify({ type: "error", content: message || "Unexpected error while registering" });
      setLoadingForm(false);
    }
  };

  const [refetchCustomer, setRefetchCustomer] = useState(true);
  const queryOpts = refetchCustomer
    ? {
        refetchInterval: 5_000,
        refetchIntervalInBackground: true,
      }
    : {};
  const { data: customer } = useQuery({
    queryKey: ["bridge-customer", customerId],
    queryFn: () => bridge.getCustomer(customerId!),
    enabled: Boolean(customerId),
    retryDelay: 2_000,
    ...queryOpts,
  });

  useEffect(() => {
    if (customer) {
      switch (customer.status) {
        case "active":
          onComplete();
          setLoadingForm(false);
          setRefetchCustomer(false);
          break;
        case "rejected":
          setLoadingForm(false);
          setRefetchCustomer(false);
          notify({ type: "error", content: "The KYC verification has failed" });
          break;
      }
      return () => {
        setRefetchCustomer(true);
      };
    }
  }, [customer]);

  return (
    <div style={{ height: "100%", position: "relative" }}>
      {!loadingForm && (
        <div style={{ marginBottom: "var(--size-large)" }}>
          <Callout style="info">
            <BodyText centered style={{ width: "100%" }} small>
              Make sure you put in your legal information that matches your government ID. We require this information
              to verify your identity. Beam doesn&apos;t store this data and it&apos;s sent directly to our partner,
              Bridge. You only need to do this once.
            </BodyText>
          </Callout>
        </div>
      )}
      <CreateCustomerForm onSubmit={handleSubmit} />
      {loadingForm ? <ProcessingView /> : null}
    </div>
  );
};
