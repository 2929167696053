import * as Sentry from "@sentry/react";

import { Web3AuthCore, Web3AuthMultiFactor, Web3AuthSingleFactor } from "@modules/web3auth";
import { logError, notify } from "@helpers";
import { AuthMethod } from "@modules/web3auth/constants";

export const useWeb3Auth = () => {
  const recover = async ({
    password,
    onComplete,
    onError,
  }: {
    password?: string;
    onComplete?: () => void;
    onError?: (error: unknown) => void;
  }) => {
    let web3Auth: Web3AuthCore | undefined = undefined;
    try {
      web3Auth = password ? Web3AuthMultiFactor.get() : Web3AuthSingleFactor.get();
      if (password) await web3Auth.answerSecurityQuestions(password);
      await web3Auth.recover();
      notify({
        content: `👋 Welcome back!`,
      });
      onComplete && onComplete();
    } catch (error) {
      onError && onError(error);
      // eslint-disable-next-line
      const message = (error as any).message;
      if (message === "Incorrect answer") {
        notify({ content: "Invalid Password", type: "error", duration: 5_000 });
      } else if (message === "security questions might not exist/be setup") {
        notify({
          content:
            web3Auth?.getLoginData()?.basicData.typeOfLogin === AuthMethod.TWITTER
              ? "No account found matching this X account"
              : "No account found matching this email",
          type: "error",
          duration: 5_000,
        });
        Sentry.addBreadcrumb({
          message: "User has not saved access yet",
          data: web3Auth && web3Auth?.getLoginData()?.basicData,
        });
        Sentry.captureMessage(message, "info");
      } else {
        notify({ content: "Unexpected error during recovery", type: "error", duration: 5_000 });
        logError("[recover]", error);
      }
    }
  };

  return { recover };
};
