import React from "react";
import { Space } from "antd";

import { BodyText, HeaderText } from "@components/text/Text";
import { PasswordForm } from "@components/account/access/components/PasswordStep/PasswordForm";
import { Callout } from "@components/callout/Callout";

export interface PasswordDrawerProps {
  loading?: boolean;

  onSubmit(password: string): void;
}

export const PasswordStep: React.FC<PasswordDrawerProps> = ({ loading, onSubmit }) => {
  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <HeaderText centered size="small">
        Add a password
      </HeaderText>

      <BodyText centered>Add a Beam-specific password to increase the security of your Beam account.</BodyText>

      <Callout>
        <BodyText>
          Save this password somewhere secure. Beam is not able to recover this password if lost. This password will be
          required for all future Beam access.
        </BodyText>
      </Callout>

      <PasswordForm loading={loading} onSubmit={onSubmit} btnText="Add Beam Password" />
    </Space>
  );
};
