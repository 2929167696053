import React from "react";
import View from "@components/view/View";
import { BodyText, HeaderText, TextBlock } from "@components/text/Text";

export const CookiePolicy = () => {
  return (
    <View headerTitle="Cookie Policy" metaTitle="Cookie Policy">
      <div style={{ padding: 16 }}>
        <TextBlock>
          <HeaderText>BEAM WALLET COOKIE POLICY</HeaderText>
          <BodyText>Last updated: July 18, 2023</BodyText>
          <BodyText>
            This Cookie Policy, together with our Privacy Policy, describes how Beam Wallet LLC (together with its
            affiliates, “Beam” or “we” or “us”) collects, uses, shares, and stores “cookies” and similar technologies
            such as “local storage” (collectively, “Cookies”) in relation to our top level domain website, www.beam.eco,
            as well as sub-domains for our products or services (the top level domain together with the sub-domains,
            collectively, the “Site”. This Policy applies to the Site, applications, products, and services
            (collectively, “Services”) on or in which it is posted, linked, or referenced.
          </BodyText>
          <BodyText>
            By visiting and continuing to access the Services, you are consenting to our use of such Cookies to provide
            our Services. If you do not consent to our use of such Cookies, your only option is to stop using the
            Services, including our Site. You may also disable Cookies in your web browser, but doing so may interfere
            with the functionality of our Services.
          </BodyText>
          <HeaderText>What is a Cookie?</HeaderText>
          <BodyText>
            Cookies are small text files placed in visitors’ device browsers to store their preferences. Cookies are
            widely used in order to make websites work or work more efficiently, as well as to provide reporting
            information. A cookie may have unique identifiers and reside, among other places, on your device, in email,
            on the Services, and on third-party sites using our plug-ins, widgets, embedded content, and pixels. Cookies
            set by us are called “first-party cookies.”
          </BodyText>
          <BodyText>
            Cookies set by parties other than us are known as third-party cookies. Third-party cookies enable features
            or functionality provided by third parties in connection with the Services. For example, we may use
            third-party cookies to allow you to associate your account with a different service, such as Twitter, with
            your Wallet (as defined in our Terms of Use) in order to access your Wallet through our Site. The parties
            that set these third-party cookies can recognize your device both when it visits the Site and also when it
            visits certain other websites in their networks.
          </BodyText>
          <BodyText>
            There are also technologies similar to cookies, such as local storage, pixel tags, and web beacons, that are
            covered by this Cookie Policy. Local storage, including Javascript-enabled localStorage, is a typical way
            for a website to store a small file of letters and numbers in your browser. We use local storage in
            connection with our Services to provide important functionality for our Services, such as to allow you to
            access your Wallet through the Site.
          </BodyText>
          <BodyText>
            A pixel tag (also known as a web beacon) is a piece of code embedded on a website that collects information
            about users’ engagement on that page or feature. The use of a pixel allows us to record, for example, that a
            user has visited a particular web page or clicked on a particular advertisement.
          </BodyText>
          <BodyText>
            We may also include web beacons in e-mails to understand whether messages have been opened, acted on, or
            forwarded.
          </BodyText>
          <BodyText>
            As mentioned above, in this Cookie Policy we refer to cookies, local storage, pixel tags, and similar
            technologies collectively as “Cookies.”
          </BodyText>
          <HeaderText>Do We Use Cookies?</HeaderText>
          <BodyText>
            Yes. We and our partners and service providers use Cookies in connection with the Services.
          </BodyText>
          <HeaderText>Why We Use Cookies</HeaderText>
          <BodyText>We use Cookies for several reasons, including:</BodyText>
          <ul>
            <li>
              Functionality. These cookies enable the functionality of our Services, including enabling us to recognize
              new or past users, allowing you to use your Access Methods (as defined in our Terms of Service) to access
              your Wallet through the Site, as well as to enable our Services to work properly.
            </li>
            <li>
              Usability. These cookies remember you when you return to the Services and tailor your experience. For
              example, we can remember your preferred display mode.
            </li>
            <li>
              Analysis. These cookies allow us to measure the performance of the Services, such as different pages of
              the Site, including things like number of page visits. That way, we can understand which pages are most
              and least popular so that we can improve Site performance and navigation.
            </li>
          </ul>
          <BodyText>
            Without such Cookies, we would not be able to provide many services that you need to properly use the Site.
            These Cookies, for example, allow us to operate our Site so you may access it as you have requested and let
            us recognize that you have created a Wallet and have used our Site to access your Wallet. They also include
            Cookies that enable us to remember your previous actions within the same browsing session and secure our
            Site.
          </BodyText>
          <BodyText>
            We also use Cookies from third parties to enable you to use their services in connection with our own. For
            example, we allow you to associate your Twitter account with your Wallet in order to access your Wallet
            through our Site. Please refer to the privacy and/or Cookie policies of these third parties for information
            on how they may use their Cookies.
          </BodyText>
          <HeaderText>How to Disable Cookies</HeaderText>
          <BodyText>
            You can manage your Cookies (including the enabling or disabling of Cookies) on our Site by using your
            browser. For example, you can block Cookies by activating the setting on your browser that allows you to
            refuse the setting of all or some cookies. However, if you use your browser settings to block all Cookies
            (including Cookies that are strictly necessary) you may not be able to access all or parts of our Site or
            Services.
          </BodyText>
          <BodyText>
            Each browser is different and as such we suggest that you check the ‘Help’ menu on your particular browser
            to learn how to change your cookie preferences.
          </BodyText>
          <BodyText>
            We may link the information collected by Cookies with other information we collect from you pursuant to our
            Privacy Policy and use the combined information as set forth herein. Similarly, the third parties who serve
            cookies on our Site may link your name or email address to other information they collect, which may include
            past purchases made offline or online, or your online usage information. If you are located in the European
            Economic Area, you have certain rights that are described above under the header “Notice to EU Data
            Subjects”, including the right to inspect and correct or delete the data that we have about you.
          </BodyText>
          <HeaderText>Contact Us</HeaderText>
          <BodyText>If you have any questions or concerns, please contact us at support@beam.eco.</BodyText>
        </TextBlock>
      </div>
    </View>
  );
};
