import React, { CSSProperties } from "react";
import classNames from "classnames";

import ExpandSection from "@components/expand/ExpandSection";
import { ReactComponent as LinkChevron } from "@assets/icons/link_chevron.svg";

import "./Card.css";
import { AnimatePresence, motion } from "framer-motion";

export interface CardProps {
  children: React.ReactElement | string | React.ReactFragment;
  color?: "success" | "warning" | "accent";
  style?: "normal" | "muted";
  title?: React.ReactNode;
  footer?: React.ReactNode;
  subtitle?: React.ReactNode;
  isCollapsed?: boolean;
  titleTrailing?: React.ReactNode;
  titleIcon?: React.ReactNode;
  divStyle?: CSSProperties;
  dataCy?: string;
  className?: string;
  onClick?: () => void;
  highlight?: boolean;
}

interface TitleProps extends Pick<CardProps, "title" | "subtitle" | "titleTrailing"> {
  isExpanded?: boolean;
  canExpand?: boolean;
  icon?: React.ReactNode;
  dataCy?: string;
}

const Title: React.FC<TitleProps> = ({
  title,
  subtitle,
  titleTrailing,
  icon,
  isExpanded = false,
  canExpand = false,
  dataCy,
}) => {
  // Generate a default data-cy value from title if dataCy is not provided
  const dataCyValue = dataCy || (typeof title === "string" ? title.replace(/\s+/g, "") : undefined);

  return (
    <div
      className={classNames("Card_title", "Card_titleContainer", { "is-expanded": isExpanded })}
      {...(dataCyValue && { "data-cy": dataCyValue })}
    >
      {icon && <div className="Card_titleIcon">{icon}</div>}
      <div className="Card_titleInner">
        {typeof title === "string" ? <span className="Card_title-text">{title}</span> : title}
        {subtitle && <span className="Card_subtitle">{subtitle}</span>}
      </div>
      {titleTrailing}
      {canExpand && <LinkChevron />}
    </div>
  );
};

const Card = ({
  children,
  color,
  style = "normal",
  title,
  footer,
  subtitle,
  isCollapsed,
  titleTrailing,
  divStyle,
  className,
  titleIcon,
  onClick,
  dataCy,
  highlight,
  ...restProps
}: CardProps) => {
  const cardClass = classNames("Card", className, {
    [`Card-${style}`]: style,
    [`color-${color}`]: color,
    "Card-isCollapsable": isCollapsed,
    "Card-isClickable": onClick,
    "Card-isHighlighted": highlight,
  });

  return (
    <div className={cardClass} style={divStyle} onClick={onClick} {...restProps}>
      <div className="Card_content">
        {isCollapsed ? (
          <ExpandSection
            persistToggle
            toggleElement={isExpanded => (
              <Title
                title={title}
                subtitle={subtitle}
                titleTrailing={titleTrailing}
                isExpanded={isExpanded}
                canExpand
              />
            )}
          >
            <>
              {children}
              {footer && <div className="Card_footer">{footer}</div>}
            </>
          </ExpandSection>
        ) : (
          <>
            {title && (
              <Title title={title} subtitle={subtitle} titleTrailing={titleTrailing} icon={titleIcon} dataCy={dataCy} />
            )}
            {children}
            {footer && <div className="Card_footer">{footer}</div>}
          </>
        )}
      </div>
      <AnimatePresence>
        {highlight && (
          <motion.div
            className="Card_highlight"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Card;
