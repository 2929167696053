export function getKadoUrl(address: string) {
  const kadoApi = "80db0d76-a195-4d73-bf32-3d484d9f694c";

  const url = new URL("https://app.kado.money");

  url.searchParams.append("apiKey", kadoApi);
  url.searchParams.append("onToAddress", address);
  url.searchParams.append("cryptoList", "USDC,USDC.e");
  url.searchParams.append("onRevCurrency", "USDC");
  url.searchParams.append("network", "OPTIMISM");
  url.searchParams.append("networkList", "OPTIMISM,BASE");
  url.searchParams.append("productList", "BUY");

  return url.toString();
}

export function getRecommendedService<T extends { priority: number; supportedCountries?: string[] }>(
  country: string,
  list: T[],
): T[] {
  return [...list]
    .sort((a, b) => {
      if (a.priority === b.priority) return 0;
      return a.priority > b.priority ? -1 : 1;
    })
    .filter(item => {
      // return item if `supportedCountries` is not defined
      if (!item.supportedCountries) return true;
      return item.supportedCountries.includes(country);
    });
}
