import React from "react";
import { ethers } from "ethers";

import Card from "@components/card/Card";
import Row from "@components/row/Row";
import { BodyText } from "@components/text/Text";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { NumberPadProps } from "@components/numberpad/NumberPad";

import { UserToken } from "@constants";
import { formatBigNumber } from "@helpers";
import { getTokenManager } from "@modules/token-managers/tokens";

interface BalanceAvailableSheetProps extends Pick<NumberPadProps, "action"> {
  open?: boolean;
  token: UserToken;
  fee: ethers.BigNumberish;
  balance: ethers.BigNumberish;
  onClose?: () => void;
}

export const BalanceAvailableSheet: React.FC<BalanceAvailableSheetProps> = ({
  action,
  open,
  balance,
  fee,
  token,
  onClose,
}) => {
  const tokenManager = getTokenManager(token);
  const total = ethers.BigNumber.from(fee).gte(balance) ? 0 : ethers.BigNumber.from(balance).sub(fee);
  const decimals = tokenManager.decimals === 6 ? 2 : 3;
  return (
    <BottomSheet title={`Available to ${action}`} isOpen={open} onClose={onClose} desktopDisplay="overlay">
      <Card style="muted">
        <>
          <Row
            title="Total available"
            hasHorizontalPadding={false}
            trailingContent={
              <BodyText bold>
                {formatBigNumber(balance, tokenManager.decimals, decimals)} {tokenManager.symbol}
              </BodyText>
            }
          />
          <Row
            title="Expected fee"
            hasHorizontalPadding={false}
            trailingContent={
              <BodyText bold>
                {formatBigNumber(fee, tokenManager.decimals, decimals)} {tokenManager.symbol}
              </BodyText>
            }
          />
          <Row
            title={`Available to ${action}`}
            hasHorizontalPadding={false}
            trailingContent={
              <BodyText bold>
                {formatBigNumber(total, tokenManager.decimals, decimals)} {tokenManager.symbol}
              </BodyText>
            }
          />
        </>
      </Card>
    </BottomSheet>
  );
};
