import { BodyText } from "@components/text/Text";
import React, { ReactNode } from "react";
import { ReactComponent as Chevron } from "@assets/icons/link_chevron.svg";

import styles from "./NetworkChip.module.css";

export const NetworkChip = ({
  title,
  icon,
  onClick,
}: {
  title: string;
  icon: ReactNode;
  onClick?: (_: React.MouseEvent) => void;
}) => {
  return (
    <div className={`${styles.wrapper} ${onClick && styles.clickable}`} onClick={onClick}>
      {icon}
      <BodyText small>{title}</BodyText>
      {onClick && (
        <div className={styles.chevron}>
          <Chevron />
        </div>
      )}
    </div>
  );
};
