import React, { useMemo } from "react";

import { addressToEmoji, isSameAddress } from "@helpers";
import { useAccount } from "@contexts/AccountContext";
import { useCurrentToken } from "@contexts/TokenContext";
import { getTokenManager } from "@modules/token-managers/tokens";
import { QRBody } from "@components/qr/QRBody/QRBody";
import { getQrCodeUrl } from "@helpers/qrCode";

interface QRPunkBlockieProps {
  address?: string;
  copyDisabled?: boolean;
  displayBeamname?: string;
}

export const QRPunkBlockie: React.FC<QRPunkBlockieProps> = ({ address, copyDisabled = false, displayBeamname }) => {
  const account = useAccount();
  const { token: userToken } = useCurrentToken();
  const token = getTokenManager(userToken);

  const qrValue = useMemo(() => {
    if (!address) return;
    const url = new URL("/", getQrCodeUrl());
    url.searchParams.set("addr", address);
    return url.toString();
  }, [address]);

  return (
    <QRBody
      value={qrValue}
      address={address}
      userToken={token.id}
      copyDisabled={copyDisabled}
      username={
        displayBeamname ||
        (address && account.address && isSameAddress(address, account.address) && account.beamname?.displayName) ||
        (address && addressToEmoji(address)) ||
        "....."
      }
    />
  );
};
