import classNames from "classnames";
import { ethers } from "ethers";
import React from "react";

import { USER_TOKENS, UserToken } from "@constants";
import { useAccount } from "@contexts/AccountContext";
import { formatBigNumber } from "@helpers";

import BottomSheet from "@components/bottomsheet/BottomSheet";
import Row from "@components/row/Row";
import { TokenIcon } from "@components/token";

import { Popover } from "@components/popover/Popover";
import { BodyText } from "@components/text/Text";
import { getTokenManager } from "@modules/token-managers/tokens";
import { isDesktop } from "react-device-detect";
import "./CurrencyDrawer.css";

interface CurrencyDrawerProps {
  open: boolean;
  onClose: () => void;
  selectedToken: UserToken;
  onSelect: (token: UserToken) => void;
  selectorRef: React.RefObject<HTMLDivElement>;
}

function displayToken(token: UserToken, balance: ethers.BigNumber) {
  if (token === UserToken.OAK || token === UserToken.ECOx) {
    // Display OAK token if there is a balance
    return !balance.isZero();
  }
  return true;
}

const CurrencyItem: React.FC<{
  token: UserToken;
  balance: ethers.BigNumber;
  isSelected?: boolean;
  onClick?: () => void;
}> = ({ token, balance, isSelected, onClick }) => {
  const tokenManager = getTokenManager(token);
  const isSelectedClass = classNames({ "is-selected": isSelected });

  return (
    <Row key={token} onClick={onClick} className={isSelectedClass} hasBottomBorder={false}>
      <div className={classNames("CurrencyDrawer_row", isSelectedClass)}>
        <div className="CurrencyDrawer_icon">
          <TokenIcon token={token} />
        </div>
        <div className="CurrencyDrawer_name">
          <BodyText large bold>
            {tokenManager.symbol}
          </BodyText>
          {isSelected && (
            <BodyText small light>
              Active
            </BodyText>
          )}
        </div>
        <BodyText light>{formatBigNumber(balance, tokenManager.decimals)}</BodyText>
      </div>
    </Row>
  );
};

export const CurrencyDrawer = ({ open, selectedToken, onClose, onSelect, selectorRef }: CurrencyDrawerProps) => {
  const { balances } = useAccount();

  const content = (
    <>
      {USER_TOKENS.map(token => {
        const balance = balances[token].total;

        if (!displayToken(token, balance)) return null;

        return (
          <CurrencyItem
            key={token}
            token={token}
            balance={balance}
            isSelected={selectedToken === token}
            onClick={() => {
              onSelect(token);
              onClose();
            }}
          />
        );
      })}
    </>
  );

  if (isDesktop) {
    return (
      <Popover
        targetRef={selectorRef}
        open={open}
        position="bottomRight"
        onClose={onClose}
        dataCy="popover-currency-select"
      >
        {content}
      </Popover>
    );
  }

  return (
    <BottomSheet title="Active Currency" onClose={onClose} closeOnShimTap isOpen={open}>
      {content}
    </BottomSheet>
  );
};
