import { ethers } from "ethers";
import { NETWORK } from "@constants/network";
import config from "@constants/config";

export const MULTICALL3_ADDRESS = ethers.utils.getAddress("0xcA11bde05977b3631167028862bE2a173976CA11");

export const SIMPLE_ACCOUNT_FACTORY_ADDRESS = ethers.utils.getAddress("0x3fB7476CA9b424cFf1156267EcA49eb878dB0B80");

export const VENDING_MACHINE_ADDRESSES = [
  config.optimism.contracts.vendingMachine,
  config.optimism.contracts.vendingMachineHelper,
];

export const FAUCET_ADDRESS = ethers.utils.getAddress(process.env.REACT_APP_FAUCET_ADDRESS!);

// Faucet for Permissionless
export const FAUCET_HAPPY_HOUR_ADDRESS = ethers.utils.getAddress("0x1A5150F7f86EF1E84B54BCF85c7c44D893DBb3Ba");

export const BEAMNAMES_ADDRESS =
  process.env.REACT_APP_BEAMNAMES_ADDRESS ||
  (NETWORK.chainId === 420
    ? "0x4de462e7f85b7b6125144e5eb78f0fdb05e759ba"
    : "0x45CDFA14dBEBE732b40183f683D434c27ec2E9D4");
