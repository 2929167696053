import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, LinkButton } from "@components/button/Button";
import { BodyText, TextBlock } from "@components/text/Text";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { Onboarding } from "@components/onboarding/Onboarding";

import { useWeb3AuthState } from "@redux/slides/web3auth.slide";

import "./WelcomeSheet.css";

interface WelcomeSheetProps {
  open: boolean;
  onClose: () => void;
}

export const WelcomeSheet = ({ open, onClose }: WelcomeSheetProps) => {
  const [onboardingOpen, setOnboardingOpen] = useState(false);
  const navigate = useNavigate();
  const { afterRedirect, resumeOnboarding } = useWeb3AuthState();

  useEffect(() => {
    if (afterRedirect && resumeOnboarding) {
      setOnboardingOpen(true);
    }
  }, [afterRedirect]);

  return (
    <>
      <BottomSheet hideHeader desktopDisplay="overlay" onClose={onClose} closeOnShimTap isOpen={open}>
        <div className="WelcomeSheet">
          <div className="WelcomeBody">
            <TextBlock>
              <BodyText bold large>
                <span className="WelcomeWave">👋</span> &nbsp;Welcome to Beam
              </BodyText>
              <BodyText light>
                Beam is the easiest way on the planet to send anyone money, whether you&apos;re in person or miles
                apart.
              </BodyText>
              <BodyText light>We&apos;ll give you some ECO to try it out.</BodyText>
            </TextBlock>
            <Button
              title="Create an account"
              onClick={() => {
                onClose();
                setOnboardingOpen(true);
              }}
            />
            <Button
              secondary
              title="Continue as guest"
              onClick={() => {
                onClose();
              }}
            />
            <div className="WelcomeLogin">
              <LinkButton
                title="Already have an account? Log in"
                onClick={() => navigate("/load-account")}
                size="small"
              />
            </div>
          </div>
          <div className="WelcomeNote">
            <TextBlock>
              <BodyText small>Beam is in beta. Treat it the same way you&apos;d treat cash — very carefully.</BodyText>
            </TextBlock>
          </div>
        </div>
      </BottomSheet>
      <Onboarding open={onboardingOpen} onClose={() => setOnboardingOpen(false)} />
    </>
  );
};
