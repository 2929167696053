import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useStackup } from "@contexts/StackupContext";

import Card from "@components/card/Card";
import { Button } from "@components/button/Button";
import { RowGroup } from "@components/row/RowGroup";
import { DepositRow } from "@components/deposits";
import { CopyAddressSheet } from "@components/qr/CopyAddressSheet/CopyAddressSheet";

import { ReactComponent as DepositIcon } from "@assets/icons/deposit.svg";
import { ReactComponent as ProfileIcon } from "@assets/icons/profile-accent.svg";

import "./NoRecommendationsCard.css";

const IconBox: React.FC<{ icon: React.ReactNode }> = ({ icon }) => {
  return <div className="NoRecommendationsCard__iconBox">{icon}</div>;
};

export const NoRecommendationsCard: React.FC = () => {
  const [addressSheetOpen, setAddressSheetOpen] = useState(false);
  const { address } = useStackup();
  const navigate = useNavigate();

  return (
    <>
      <Card
        className="NoRecommendationsCard"
        title="No recommended deposit methods"
        subtitle="Based on your location and other factors, depositing through one of our supported partners is likely to fail. Consider adding funds to your account through an alternative method."
      >
        <RowGroup compact>
          <DepositRow
            hideNavIcon
            title="Ask a friend to send you money"
            subtitle="Friends can scan your Beam code or send you a Beam Link"
            icon={<IconBox icon={<ProfileIcon />}></IconBox>}
            trailingContent={
              <Button
                size="small"
                secondary
                title="View code"
                onClick={() => {
                  navigate("/");
                }}
              />
            }
          />
          <DepositRow
            hideNavIcon
            title="Deposit funds on Optimism or Base"
            subtitle="You can send them directly to your Beam address"
            icon={<IconBox icon={<DepositIcon />}></IconBox>}
            trailingContent={
              <Button
                size="small"
                secondary
                title="View address"
                onClick={() => {
                  setAddressSheetOpen(true);
                }}
              />
            }
          />
        </RowGroup>
      </Card>
      {address ? (
        <CopyAddressSheet
          address={address}
          open={addressSheetOpen}
          onClose={() => {
            setAddressSheetOpen(false);
          }}
        />
      ) : null}
    </>
  );
};
