import React from "react";

import { ReactComponent as MyCodeIcon } from "@assets/icons/my_code.svg";
import { ReactComponent as ScanIcon } from "@assets/icons/scan.svg";
import { ReactComponent as SendIcon } from "@assets/icons/send.svg";
import { ReactComponent as SpendIcon } from "@assets/icons/spend.svg";

import "./BottomBar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useSidebar } from "@components/sidebar/Sidebar";
import { useMerchant } from "@redux/slides/merchant.slide";

interface BottomBarItemProps {
  onClick: () => void;
  isSelected: boolean;
  icon: React.ReactElement;
  title: string;
}

const BottomBarItem = ({ onClick, isSelected, icon, title }: BottomBarItemProps) => {
  return (
    <button
      className={`BottomBarItem ${isSelected ? "BottomBarItem-selected" : ""}`}
      onClick={onClick}
      data-tooltip-target={title}
    >
      {icon}
      <span className="BottomBarItem_title">{title}</span>
    </button>
  );
};

export const BottomBar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isHiddenDesktop } = useSidebar();
  const { isMerchantModeEnabled } = useMerchant();

  return (
    <div
      className={`BottomBar ${location.pathname === `/scan` ? "BottomBar-transparent" : ""} ${
        isHiddenDesktop && "BottomBar-omitOffsetDesktop"
      }`}
      data-cy="bottom-bar"
    >
      {isMerchantModeEnabled ? (
        <>
          <BottomBarItem
            onClick={() => navigate(`/`)}
            icon={<MyCodeIcon />}
            isSelected={location.pathname === `/`}
            title="Home"
          />

          <BottomBarItem
            onClick={() => navigate(`/request`)}
            icon={<SpendIcon />}
            isSelected={location.pathname === `/request`}
            title="Charge"
          />
        </>
      ) : (
        <>
          <BottomBarItem
            onClick={() => navigate(`/`)}
            icon={<MyCodeIcon width={180} height={74} />}
            isSelected={location.pathname === `/`}
            title="Home"
          />

          <BottomBarItem
            onClick={() => navigate(`/scan`)}
            icon={<ScanIcon width={180} height={74} />}
            isSelected={location.pathname === `/scan`}
            title="Scan"
          />

          <BottomBarItem
            onClick={() => navigate(`/send`)}
            icon={<SendIcon width={180} height={74} />}
            isSelected={location.pathname === `/send`}
            title="Send"
          />
        </>
      )}
    </div>
  );
};
