import React from "react";
import { Form, Input } from "antd";

import { Button } from "@components/button/Button";
import { AuthDrawerContent } from "@components/account/access/components";

import { ReactComponent as MailIcon } from "@assets/icons/mail-icon.svg";

interface EmailAuthStepProps {
  action: "Save" | "Load";
  loading?: boolean;

  onNext(email: string): void;
}

export const EmailAuthStep: React.FC<EmailAuthStepProps> = ({ loading, action, onNext }) => {
  const [form] = Form.useForm();

  const handleSubmit = ({ email }: { email: string }) => {
    onNext(email);
  };

  return (
    <Form form={form} onFinish={handleSubmit} className="Form">
      <AuthDrawerContent
        loading={loading}
        icon={<MailIcon />}
        title={`${action} account access with email`}
        description="Enter your email below. We’ll send you a link to confirm your email."
        button={<Button title="Send email" />}
        input={
          <Form.Item
            className="form-item"
            name="email"
            style={{ margin: 0 }}
            rules={[
              { type: "email", message: "Please input a valid email." },
              {
                required: true,
                message: "Please input your email.",
              },
            ]}
          >
            <Input
              type="text"
              spellCheck={false}
              className="input"
              autoCorrect="false"
              autoCapitalize="false"
              placeholder="Enter your email"
              onInput={event =>
                ((event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.toLowerCase())
              }
            />
          </Form.Item>
        }
      />
    </Form>
  );
};
