import React from "react";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { BodyText, TextBlock } from "@components/text/Text";
import { QRPunkBlockie } from "..";
import { ShowBeamAddress } from "@components/onboarding/fundaccount/FundAccount";
import styles from "../onboarding/fundaccount/FundAccount.module.css";

interface BeamCodeProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  address?: string;
  body?: string;
}

export const BeamCodeSheet = ({
  open,
  onClose,
  title = "Your Beam Code",
  address,
  body = "Others can scan your Beam Code below to send you funds.",
}: BeamCodeProps) => {
  return (
    <BottomSheet title={title} isOpen={open} height="100%" onClose={onClose} desktopDisplay="overlay">
      <div className={styles.method_container}>
        <div className={styles.methodDescription}>
          <TextBlock>
            <BodyText centered light>
              {body}
            </BodyText>
          </TextBlock>
        </div>
        <div className="Code_qr">
          <div className="Code_qrInner">
            <QRPunkBlockie address={address} />
          </div>
        </div>
        <div className={styles.address}>
          <ShowBeamAddress address={address} />
        </div>
      </div>
    </BottomSheet>
  );
};
