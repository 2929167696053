import React, { CSSProperties, PropsWithChildren, ReactNode } from "react";
import { Drawer } from "antd";

import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";
import "./BottomSheet.css";

import { isDesktop } from "react-device-detect";
import Modal from "antd/es/modal/Modal";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";

export interface BottomSheetProps {
  isOpen?: boolean;
  onClose?: () => void;
  title?: ReactNode | string;
  className?: string;
  subtitle?: ReactNode | string;
  closeOnShimTap?: boolean;
  height?: number | "auto" | "100%";
  hideLine?: boolean;
  hideHeader?: boolean;
  headerClass?: string;
  desktopDisplay?: "overlay" | "side";
  footer?: ReactNode;
  footerStyle?: CSSProperties;
  navItemLeading?: ReactNode;
  onAnimationEnd?: (isOpen: boolean) => void;
  includePadding?: boolean;
  contentClassName?: string;
}

const BottomSheet: React.FC<PropsWithChildren<BottomSheetProps>> = ({
  isOpen,
  onClose,
  onAnimationEnd,
  className,
  title,
  subtitle = null,
  children,
  closeOnShimTap = false,
  height = "auto",
  hideHeader,
  hideLine,
  headerClass,
  desktopDisplay,
  footer,
  footerStyle,
  navItemLeading,
  includePadding = true,
  contentClassName,
}) => {
  React.useEffect(() => {
    const anotherSheetOpen = document.querySelectorAll(".ant-drawer-open").length > 0;
    if (isOpen) {
      document.documentElement.classList.add("bottom-sheet-open");
    } else if (!isOpen && !anotherSheetOpen) {
      document.documentElement.classList.remove("bottom-sheet-open");
    }

    return () => {
      if (document.querySelectorAll(".ant-drawer-open").length > 0) {
        return;
      }

      document.documentElement.classList.remove("bottom-sheet-open");
    };
  }, [isOpen]);

  const hasTitle = Boolean(title || subtitle);
  const isOverlay = desktopDisplay === "overlay" && isDesktop;
  const isSideDrawer = desktopDisplay === "side" && isDesktop;

  const bodyContent = (
    <div className={classNames("BottomSheet", className, { "BottomSheet-noPadding": !includePadding })}>
      {!hideHeader && (
        <div
          className={classNames("BottomSheet_header", headerClass, {
            "BottomSheet_header--line": !hideLine,
            "BottomSheet_header--minHeight": hasTitle,
          })}
        >
          <AnimatePresence initial={false}>
            {navItemLeading && (
              <motion.div
                className="BottomSheet_headerNavLeading"
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                key="navItemLeading"
                transition={{ duration: 0.15 }}
              >
                {navItemLeading}
              </motion.div>
            )}
          </AnimatePresence>
          {onClose ? (
            <button onClick={onClose} className="BottomSheet_close">
              <CloseIcon />
            </button>
          ) : null}
          {hasTitle ? (
            <div className="BottomSheet_titleWrapper">
              {typeof title === "string" ? <h3>{title}</h3> : title}
              {subtitle ? <label>{subtitle}</label> : null}
            </div>
          ) : null}
        </div>
      )}
      <div className={classNames(`BottomSheet_content`, contentClassName)}>{children}</div>
      {footer && (
        <div className="BottomSheet_footer" style={footerStyle}>
          {footer}
        </div>
      )}
    </div>
  );

  if (isOverlay) {
    return (
      <Modal
        open={isOpen}
        className="BottomSheet_wrapper"
        rootClassName="BottomSheet_overlayWrapper"
        closable={false}
        prefixCls="BottomSheet-Overlay"
        footer={null}
        onCancel={onClose}
        maskClosable={closeOnShimTap}
        title={null}
        afterOpenChange={onAnimationEnd}
      >
        {bodyContent}
      </Modal>
    );
  }

  if (isSideDrawer) {
    return (
      <Drawer
        className={`BottomSheet_wrapper`}
        open={isOpen}
        placement="right"
        rootClassName="BottomSheet_root"
        onClose={onClose}
        title={null}
        closable={false}
        maskClosable={closeOnShimTap}
        afterOpenChange={onAnimationEnd}
        style={{
          borderRadius: "var(--border-radius-large)",
        }}
        bodyStyle={{ padding: 0 }}
        contentWrapperStyle={{
          boxShadow: "none",
          transitionTimingFunction: "var(--anim-ease-out)",
          padding: "var(--size-small)",
          width: "44vw",
          maxWidth: 600,
          minWidth: 430,
        }}
      >
        {bodyContent}
      </Drawer>
    );
  }

  return (
    <Drawer
      className={`BottomSheet_wrapper`}
      open={isOpen}
      placement="bottom"
      rootClassName="BottomSheet_root"
      onClose={onClose}
      title={null}
      closable={false}
      maskClosable={closeOnShimTap}
      afterOpenChange={onAnimationEnd}
      style={{
        borderRadius: "var(--border-radius-large) var(--border-radius-large) 0 0",
        maxWidth: 600,
        margin: "0 auto",
      }}
      bodyStyle={{ padding: 0 }}
      contentWrapperStyle={{
        boxShadow: "none",
        transitionTimingFunction: "var(--anim-ease-out)",
        maxHeight: "100%",
        paddingTop: "var(--size-small)",
      }}
      width={"100%"}
      height={height}
    >
      {bodyContent}
    </Drawer>
  );
};

export default BottomSheet;
