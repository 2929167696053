import { useAuth0 } from "@auth0/auth0-react";

export const useAuth0Custom = () => {
  const { loginWithRedirect } = useAuth0();

  const loginWithTwitter = async (opts: {
    returnTo: string;
    resumeOnboarding?: boolean;
    resumeProgressiveSecurity?: boolean;
    resumeShopping?: boolean;
  }) => {
    await loginWithRedirect({
      authorizationParams: { connection: "twitter" },
      appState: {
        returnTo: opts.returnTo,
        resumeOnboarding: opts.resumeOnboarding,
        resumeProgressiveSecurity: opts.resumeProgressiveSecurity,
        resumeShopping: opts.resumeShopping,
      },
    });
  };

  return { loginWithTwitter };
};
