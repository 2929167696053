import React, { useContext, useEffect } from "react";

export enum ColorScheme {
  light,
  dark,
  auto,
}

const ColorSchemeContext = React.createContext<{
  currentScheme: ColorScheme | undefined;
  setScheme: (scheme: ColorScheme) => void;
}>({
  currentScheme: undefined,
  setScheme: () => {
    return;
  },
});

export const useColorScheme = () => useContext(ColorSchemeContext);

const windowMatchMedia = window.matchMedia("(prefers-color-scheme: dark)");

// eslint-disable-next-line
const handleWindowChange = (e: any) => {
  const colorScheme = e.matches ? "dark" : "light";

  if (colorScheme === "dark") {
    document.documentElement.dataset.theme = "dark";
  } else {
    document.documentElement.dataset.theme = "light";
  }
};

export const ColorSchemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [colorScheme, setColorScheme] = React.useState<ColorScheme | undefined>(undefined);

  useEffect(() => {
    if (localStorage.theme) {
      if (localStorage.theme === "light") {
        setColorScheme(ColorScheme.light);
      } else if (localStorage.theme === "dark") {
        setColorScheme(ColorScheme.dark);
      } else if (localStorage.theme === "auto") {
        setColorScheme(ColorScheme.auto);
      }
    } else {
      setColorScheme(ColorScheme.auto);
    }
  }, []);

  useEffect(() => {
    if (colorScheme === ColorScheme.dark) {
      document.documentElement.dataset.theme = "dark";
      localStorage.setItem("theme", "dark");
      windowMatchMedia.removeEventListener("change", handleWindowChange);
    } else if (colorScheme === ColorScheme.light) {
      document.documentElement.dataset.theme = "light";
      localStorage.setItem("theme", "light");
      windowMatchMedia.removeEventListener("change", handleWindowChange);
    } else if (colorScheme === ColorScheme.auto) {
      document.documentElement.dataset.theme = windowMatchMedia.matches ? "dark" : "light";
      localStorage.setItem("theme", "auto");
      windowMatchMedia.addEventListener("change", handleWindowChange);
    }
  }, [colorScheme]);

  return (
    <ColorSchemeContext.Provider value={{ currentScheme: colorScheme, setScheme: setColorScheme }}>
      {children}
    </ColorSchemeContext.Provider>
  );
};
