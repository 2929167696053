import React from "react";
import { Space } from "antd";

import Card from "@components/card/Card";
import { Badge } from "@components/badge/Badge";
import { BodyText, HeaderText } from "@components/text/Text";

import { setProgressiveSecurityOpen, useWeb3AuthState } from "@redux/slides/web3auth.slide";

import { AuthType } from "@modules/web3auth/constants";

import "../access/SaveAccessCard.css";
import { Button } from "@components/button/Button";
import {
  IncreaseSecurityDrawer,
  IncreaseSecurityStep,
} from "@components/account/access/increase-security/IncreaseSecurityDrawer";
import { useAppDispatch } from "@redux";

export const IncreaseSecurityCard = () => {
  const dispatch = useAppDispatch();
  const { progressiveSecuritySheet, authType } = useWeb3AuthState();

  if (authType !== AuthType.SINGLE) return null;

  const handleOpen = () => {
    dispatch(setProgressiveSecurityOpen({ open: true, initialStep: IncreaseSecurityStep.Authentication }));
  };

  const { open } = progressiveSecuritySheet;

  return (
    <Card>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <div>
          <Badge size="small" text="Recommended" />
        </div>
        <div>
          <HeaderText size="small">Increase your account security</HeaderText>
          <BodyText small light>
            We recommend increasing your account security by adding a Beam-specific password. This makes it more
            difficult for others to gain access to your account.
          </BodyText>
        </div>

        <div style={{ borderBottom: "1px solid var(--color-foreground-xlight)" }} />

        <Button secondary title="Add password" onClick={handleOpen} />
      </Space>
      {open ? <IncreaseSecurityDrawer /> : null}
    </Card>
  );
};
