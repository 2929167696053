import { ethers } from "ethers";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { MAINNET_PROVIDER } from "@modules/blockchain/providers";

export const useResolveEnsAddress = (
  ensName: string | undefined,
  opts?: Omit<
    UseQueryOptions<unknown, unknown, { address: string; ens?: string }, (string | undefined)[]>,
    "initialData" | "queryFn" | "queryKey"
  > & { initialData?: (() => undefined) | undefined },
) => {
  return useQuery(
    ["useResolveEnsAddress", ensName],
    async keys => {
      const ensName = keys.queryKey[1];
      if (ensName && !ethers.utils.isAddress(ensName)) {
        const resolved = await MAINNET_PROVIDER.resolveName(ensName);
        if (resolved && ethers.utils.isAddress(resolved)) {
          return { address: ethers.utils.getAddress(resolved), ens: ensName };
        }
      }
      return { address: ethers.constants.AddressZero, ens: ensName };
    },
    opts,
  );
};
