import { ethers, TypedDataDomain } from "ethers";

import { MAINNET_PROVIDER } from "@modules/blockchain/providers";

export class RelayoorSignatureAuth {
  static SIGNATURE_EXPIRATION_TIMEOUT = 1000 * 60 * 2; // 2 min

  static readonly MESSAGE_DOMAIN: TypedDataDomain = {
    name: "beam",
    version: "1",
    chainId: 10,
  };

  static readonly MESSAGE_TYPES = {
    ProfileImage: [
      { name: "address", type: "address" },
      { name: "expire", type: "uint256" },
    ],
  };

  static generate(signer: ethers.Wallet, address: string) {
    const instance = new RelayoorSignatureAuth(address, signer);
    return instance.signMessage();
  }

  static async getHeaders(signer: ethers.Wallet, address: string) {
    const instance = await RelayoorSignatureAuth.generate(signer, address);
    return {
      "x-beam-sig": instance.signature,
      "x-beam-sig-address": instance.address,
      "x-beam-sig-expire": instance.expire,
    };
  }

  constructor(private readonly address: string, private readonly signer: ethers.Wallet) {}

  getAddress() {
    return this.address.toLowerCase();
  }

  async signMessage() {
    const address = this.getAddress();
    const expire = Date.now() + RelayoorSignatureAuth.SIGNATURE_EXPIRATION_TIMEOUT;
    const signature = await this.signer
      .connect(MAINNET_PROVIDER)
      ._signTypedData(RelayoorSignatureAuth.MESSAGE_DOMAIN, RelayoorSignatureAuth.MESSAGE_TYPES, {
        address,
        expire,
      });
    return { signature, address, expire };
  }
}
