import { BigNumberish, BytesLike, ethers } from "ethers";
import { ERC4337 } from "userop/dist/constants";
import { UserOperationBuilder } from "userop/dist/builder";
import { EOASignature, estimateUserOperationGas, getGasPrice } from "userop/dist/preset/middleware";
import { logError } from "@helpers/logError";
import {
  EntryPoint,
  EntryPoint__factory,
  SimpleAccountFactory,
  SimpleAccountFactory__factory,
  SimpleAccount as SimpleAccountImpl,
  SimpleAccount__factory,
} from "userop/dist/typechain";
import { IPresetBuilderOpts, UserOperationMiddlewareFn } from "userop/dist/types";

import { BundlerJsonRpcProvider } from "@modules/smart-wallet/BundlerJsonRpcProvider";

export class BeamWallet extends UserOperationBuilder {
  private signer: ethers.Signer;
  private provider: ethers.providers.JsonRpcProvider;
  private entryPoint: EntryPoint;
  private factory: SimpleAccountFactory;
  private initCode: string;
  proxy: SimpleAccountImpl;

  private constructor(
    signer: ethers.Signer,
    rpcUrl: string,
    opts?: IPresetBuilderOpts & {
      backupBundlerRpc?: string;
      network?: ethers.providers.Networkish;
    },
  ) {
    super();
    this.signer = signer;
    this.provider = new BundlerJsonRpcProvider(rpcUrl, opts?.network)
      .setBundlerRpc(opts?.overrideBundlerRpc)
      .setBackupBundlerRpc(opts?.backupBundlerRpc);
    this.entryPoint = EntryPoint__factory.connect(opts?.entryPoint || ERC4337.EntryPoint, this.provider);
    this.factory = SimpleAccountFactory__factory.connect(opts?.factory || ERC4337.SimpleAccount.Factory, this.provider);
    this.initCode = "0x";
    this.proxy = SimpleAccount__factory.connect(ethers.constants.AddressZero, this.provider);
  }

  private resolveAccount: UserOperationMiddlewareFn = async ctx => {
    ctx.op.nonce = await this.entryPoint.getNonce(ctx.op.sender, 0);
    ctx.op.initCode = ctx.op.nonce.eq(0) ? this.initCode : "0x";
  };

  public static async init(
    signer: ethers.Signer,
    rpcUrl: string,
    opts?: IPresetBuilderOpts & {
      backupBundlerRpc?: string;
      network?: ethers.providers.Networkish;
    },
  ): Promise<BeamWallet> {
    const instance = new BeamWallet(signer, rpcUrl, opts);

    const signerAddr = await instance.signer.getAddress();
    let walletAddr = BeamWallet.getSimpleAccountAddress(signerAddr, instance.factory.address);

    instance.initCode = BeamWallet.getInitCode(instance.factory.address, signerAddr);

    if (!walletAddr) {
      walletAddr = await BeamWallet.fetchAddress(instance);
      BeamWallet.storeSimpleAccountAddress(signerAddr, instance.factory.address, walletAddr);
    }

    instance.proxy = SimpleAccount__factory.connect(walletAddr!, instance.provider);

    return BeamWallet.resetMiddlewares(instance, opts?.paymasterMiddleware);
  }

  static getInitCode(factory: string, signerAddr: string) {
    return ethers.utils.hexConcat([
      factory,
      SimpleAccountFactory__factory.createInterface().encodeFunctionData("createAccount", [
        signerAddr,
        ethers.BigNumber.from(0),
      ]),
    ]);
  }

  static async fetchAddress(wallet: BeamWallet): Promise<string> {
    return BeamWallet.getSenderAddress(wallet.entryPoint, wallet.initCode);
  }

  static async getSenderAddress(entryPoint: EntryPoint, initCode: string, retries = 15): Promise<string> {
    try {
      await entryPoint.callStatic.getSenderAddress(initCode);
      // eslint-disable-next-line
    } catch (error: any) {
      const walletAddr = error?.errorArgs?.sender;
      if (!walletAddr) {
        if (retries > 0) {
          // Wait 1s
          await new Promise(resolve => setTimeout(resolve, 1_000));
          return BeamWallet.getSenderAddress(entryPoint, initCode, retries - 1);
        }
        throw error;
      }
      return walletAddr;
    }
    throw new Error("getSenderAddress: unexpected result");
  }

  private static generateLocalStorageKey(addr: string, factoryAddress: string): string {
    return `sim-acc-addr-${addr.toLowerCase()}-${factoryAddress.toLowerCase()}`;
  }

  static getSimpleAccountAddress(addr: string, factoryAddress: string): string | undefined {
    try {
      const key = BeamWallet.generateLocalStorageKey(addr, factoryAddress);
      const storedAddr = window.localStorage.getItem(key);
      if (storedAddr) return ethers.utils.getAddress(storedAddr);
    } catch (error) {
      logError("[getSimpleAccountAddress]", error);
    }
  }

  private static storeSimpleAccountAddress(addr: string, factoryAddress: string, beamWalletAddr: string) {
    try {
      const key = BeamWallet.generateLocalStorageKey(addr, factoryAddress);
      window.localStorage.setItem(key, beamWalletAddr);
    } catch (error) {
      logError("[storeSimpleAccountAddress]", error);
    }
  }

  public static async resetMiddlewares(simpleAccount: BeamWallet, paymasterMiddleware?: UserOperationMiddlewareFn) {
    const base = simpleAccount
      .useDefaults({
        sender: simpleAccount.proxy.address,
        signature: await simpleAccount.signer.signMessage(ethers.utils.arrayify(ethers.utils.keccak256("0xdead"))),
      })
      .useMiddleware(simpleAccount.resolveAccount)
      .useMiddleware(getGasPrice(simpleAccount.provider));

    const withPM = paymasterMiddleware
      ? base.useMiddleware(paymasterMiddleware)
      : base.useMiddleware(estimateUserOperationGas(simpleAccount.provider));

    return withPM.useMiddleware(EOASignature(simpleAccount.signer));
  }

  execute(to: string, value: BigNumberish, data: BytesLike) {
    return this.setCallData(this.proxy.interface.encodeFunctionData("execute", [to, value, data]));
  }

  executeBatch(to: Array<string>, data: Array<BytesLike>) {
    return this.setCallData(this.proxy.interface.encodeFunctionData("executeBatch", [to, data]));
  }
}
