import { useEffect, useState } from "react";

import { useAppDispatch } from "@redux";
import { setAuthType, setProgressiveSecurityOpen, setUserData, useWeb3AuthState } from "@redux/slides/web3auth.slide";

import { Web3AuthSingleFactor } from "@modules/web3auth";
import { AuthType } from "@modules/web3auth/constants";
import { getProgressiveSecuritySheetState } from "@modules/web3auth/helpers/progressiveSecurity";
import { useAccount } from "@contexts/AccountContext";
import { getPersistentData } from "@modules/web3auth/helpers";

export const useWeb3AuthInit = () => {
  const dispatch = useAppDispatch();
  const { address, signer: wallet, balances, loadingBalances } = useAccount();
  const { userInfo, authType, resumeProgressiveSecurity } = useWeb3AuthState();

  const [onFirstLoad, setOnFirstLoad] = useState(true);

  const hasSomeBalance = Object.values(balances).some(balance => !balance.total.isZero());

  useEffect(() => {
    if (userInfo) {
      (async () => {
        try {
          const singleAuth = Web3AuthSingleFactor.get();
          await singleAuth.init();
          const question = singleAuth.getSecurityQuestionFromModule();
          if (question) {
            const authType = question === Web3AuthSingleFactor.SECURITY_QUESTION ? AuthType.SINGLE : AuthType.MULTI;
            dispatch(setAuthType(authType));
          }
        } catch (e) {
          console.error("useWeb3AuthInit error", e);
        }
      })();
    }
  }, [userInfo]);

  useEffect(() => {
    if (wallet) {
      const userInfo = getPersistentData(wallet);
      dispatch(setUserData({ userInfo, hasSavedAccessBefore: Boolean(userInfo) }));
    }
  }, [wallet.address]);

  useEffect(() => {
    if (resumeProgressiveSecurity) {
      setOnFirstLoad(false);
      return;
    }

    if (address && !loadingBalances && onFirstLoad) {
      const open = Boolean(authType === AuthType.SINGLE && hasSomeBalance && getProgressiveSecuritySheetState(address));
      dispatch(setProgressiveSecurityOpen({ open }));
      setOnFirstLoad(false);
    }
  }, [onFirstLoad, loadingBalances, resumeProgressiveSecurity, authType, address, hasSomeBalance]);
};
