import { Header, HeaderProps } from "@components/header/Header";
import React, { useEffect } from "react";
import Helmet from "react-helmet";

import "./View.css";

interface ViewProps {
  children: React.ReactNode;
  headerTitle?: React.ReactNode;
  navbarItem?: React.ReactElement;
  headerProps?: Omit<HeaderProps, "title">;
  metaTitle?: string;
  metaDescription?: string;
}

const View = ({ children, headerTitle, navbarItem, headerProps, metaTitle, metaDescription }: ViewProps) => {
  useEffect(() => {
    document.body.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      {(metaDescription || metaTitle) && (
        <Helmet>
          {metaTitle && <title>{metaTitle} · Beam</title>}
          {metaDescription && <meta name="description" content={metaDescription} />}
        </Helmet>
      )}

      <div className="View">
        <div className="View_header">
          <Header title={headerTitle} {...headerProps}>
            {navbarItem}
          </Header>
        </div>
        <div className="View_body">{children}</div>
      </div>
    </>
  );
};

export default View;
