import React, { useRef, useState } from "react";
import { Space } from "antd";
import { useNavigate } from "react-router-dom";

import View from "@components/view/View";
import Card from "@components/card/Card";
import { Button, LinkButton } from "@components/button/Button";
import { BeamLogo } from "@components/commons/BeamLogo";
import { BodyText, HeaderText, TextBlock } from "@components/text/Text";

import { XDrawer } from "@components/account/access/x/XDrawer";
import { EmailDrawer } from "@components/account/access/email/EmailDrawer";

import { AuthMethod, AuthType } from "@modules/web3auth/constants";

import { ReactComponent as XLogo } from "@assets/icons/x-logo.svg";
import { ReactComponent as MailIcon } from "@assets/icons/mail-icon.svg";

import "./LoadAccountView.css";
import { PasswordFormView } from "@views/LoadAccount/PasswordFormView";

export const LoadAccountView = () => {
  const navigate = useNavigate();

  return (
    <View headerTitle="Log in" metaTitle="Log in" headerProps={{ onBack: () => navigate("/account") }}>
      <Card data-cy="log-in-card">
        <LoadAccountComponent onComplete={() => navigate("/account")} />
      </Card>
    </View>
  );
};

interface LoadAccountComponentProps {
  onComplete: () => void;
  fromShop?: boolean;
}

export const LoadAccountComponent: React.FC<LoadAccountComponentProps> = ({ onComplete, fromShop }) => {
  const xDrawerRef = useRef<{ login(): void }>();

  const [authMethod, setAuthMethod] = useState<AuthMethod>();
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [hasNotSavedAccess, setHasNotSavedAccess] = useState(false);

  const handleCancel = () => {
    setAuthMethod(undefined);
    setShowPasswordForm(false);
  };

  const handleComplete = () => {
    onComplete();
    handleCancel();
  };

  const handleXLogin = () => {
    setAuthMethod(AuthMethod.TWITTER);
    xDrawerRef.current?.login();
  };

  if (showPasswordForm) {
    return <PasswordFormView onComplete={handleComplete} />;
  }

  const handleCompleteAuth = (authType?: AuthType) => {
    if (authType === AuthType.MULTI) {
      setShowPasswordForm(true);
    } else {
      handleComplete();
    }
  };

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <BeamLogo size={60} />
      </div>
      <TextBlock>
        <HeaderText centered>Log in to Beam</HeaderText>
        <BodyText>
          If you previously created an account on another device or browser, you can log in to restore access.
        </BodyText>
        <BodyText>
          Start by selecting the option below which you previously used when creating an account. We’ll also ask you for
          your Beam-specific password.
        </BodyText>
      </TextBlock>

      <Space direction="vertical" className="LoadAccount-ButtonContainer">
        {hasNotSavedAccess ? (
          <Card color="warning" className="LoadAccount__warning-card">
            <BodyText centered>
              No account associated with this email. Did you want to{" "}
              <LinkButton title="create an account" href="/save-access" />?
            </BodyText>
          </Card>
        ) : null}

        <Button
          title="Continue with email"
          leadingIcon={<MailIcon />}
          onClick={() => setAuthMethod(AuthMethod.EMAIL)}
          className="LoadAccount-Button"
        />
        <Button
          title="Continue with X account"
          leadingIcon={<XLogo />}
          onClick={handleXLogin}
          className="LoadAccount-Button LoadAccount-Button__X"
        />
      </Space>
      <XDrawer
        action="Load"
        skipPasswordStep
        ref={xDrawerRef}
        onCancel={handleCancel}
        onComplete={handleCompleteAuth}
        setAccountWarning={setHasNotSavedAccess}
        shopWaitlist={fromShop}
      />
      <EmailDrawer
        action="Load"
        skipPasswordStep
        open={authMethod === AuthMethod.EMAIL}
        onCancel={handleCancel}
        onComplete={handleCompleteAuth}
        setAccountWarning={setHasNotSavedAccess}
      />
    </Space>
  );
};
