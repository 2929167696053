import React, { useMemo } from "react";

import { QRBody } from "@components/qr/QRBody/QRBody";

import { Econet } from "@modules/econet/Econet";
import { getTokenManager } from "@modules/token-managers/tokens";
import { addressToEmoji } from "@helpers";
import { getQrCodeUrl } from "@helpers/qrCode";

interface QREconetProps {
  econet: Econet;
  address?: string;
  name?: string;
}

export const QREconet: React.FC<QREconetProps> = ({ address, name, econet }) => {
  const token = getTokenManager(econet.token);

  const qrValue = useMemo(() => {
    const url = new URL(`/t/${econet.token}/send`, getQrCodeUrl());
    return url.toString() + "?" + econet.toString();
  }, [econet]);

  return (
    <QRBody
      hideLogo
      copyDisabled
      value={qrValue}
      amount={econet.amount}
      address={address}
      userToken={token.id}
      username={name || (address && addressToEmoji(address)) || "...."}
    />
  );
};
