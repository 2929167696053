import React from "react";
import moment from "moment";

import { TokenIcon } from "@components/token";
import { Button } from "@components/button/Button";
import BottomSheet from "@components/bottomsheet/BottomSheet";
import { BodyText, TextBlock } from "@components/text/Text";

import { UserToken } from "@constants/tokens";

import styles from "./RebaseSheet.module.css";
import { InflationMultiplier } from "../../../types/inflationMultiplier";

interface RebaseSheetProps {
  open: boolean;
  onClose: () => void;
  inflationMultiplier?: InflationMultiplier;
}

export const RebaseSheet = ({ open, onClose, inflationMultiplier }: RebaseSheetProps) => {
  return (
    <BottomSheet hideHeader desktopDisplay="overlay" onClose={onClose} closeOnShimTap isOpen={open}>
      <div style={{ padding: "var(--size-medium)" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "var(--size-medium)" }}>
          <div className={styles.token}>
            <TokenIcon token={UserToken.ECO} />
          </div>
          <TextBlock>
            <BodyText bold large centered>
              ECO supply change notice
            </BodyText>
            <BodyText light centered>
              There was a change to the supply of ECO on{" "}
              {moment((inflationMultiplier?.timestamp || 0) * 1000).format("MMMM Do")}.
              <br />
              <br />
              This is integral to the currency design and occurs to help maintain ECO&apos;s purchasing power over time.
            </BodyText>
          </TextBlock>
          <Button title="Got it" onClick={() => onClose()} />
          <Button secondary title="Learn more" href="/about#eco" />
        </div>
      </div>
    </BottomSheet>
  );
};
