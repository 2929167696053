import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import { useBridge } from "@components/account/withdraw/hooks/useBridge";

import "./BridgeTOSConfirmation.css";
import { Spinner } from "@components/spinner/Spinner";

interface BridgeTOSConfirmationProps {
  onComplete(signedAgreementId: string): void;
}

const BRIDGE_DASHBOARD_URL = "https://dashboard.bridge.xyz";

export const BridgeTOSConfirmation: React.FC<BridgeTOSConfirmationProps> = ({ onComplete }) => {
  const bridge = useBridge();

  const { data: link } = useQuery(["bridge-tos-link"], () => bridge.getTOSLink());

  useEffect(() => {
    const listener = (event: MessageEvent<{ signedAgreementId: string }>) => {
      if (event.origin === BRIDGE_DASHBOARD_URL && "signedAgreementId" in event.data) {
        bridge.setSignedAgreementId(event.data.signedAgreementId);
        onComplete(event.data.signedAgreementId);
      }
    };

    window.addEventListener("message", listener, false);
    return () => {
      window.removeEventListener("message", listener, false);
    };
  }, []);

  if (!link) {
    return (
      <div className="TOSConfirmation__spinner">
        <Spinner />
      </div>
    );
  }

  return <iframe src={link.url} frameBorder={0} className="TOSConfirmation__iframe" />;
};
