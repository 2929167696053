import React from "react";

import { TokenIcon } from "@components/token";
import { UserToken } from "@constants";

import { ReactComponent as DropdownIcon } from "@assets/icons/dropdown_indicator.svg";

import "./CurrencySelector.css";
import classNames from "classnames";

interface CurrencySelectorProps extends React.HTMLProps<HTMLDivElement> {
  value: UserToken;
  innerRef: React.RefObject<HTMLDivElement>;
}

export const CurrencySelector = ({ value, innerRef, ...props }: CurrencySelectorProps) => {
  return (
    <div {...props} className={classNames("CurrencySelector", "Header_button", props.className)} ref={innerRef}>
      <TokenIcon token={value} />
      <span>{value.toUpperCase()}</span>
      <DropdownIcon />
    </div>
  );
};
