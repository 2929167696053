export enum RelayoorErrorType {
  BEAMNAME_NOT_AVAILABLE,
  FEE_NOT_PAID,
}

const RelayoorErrorMsg: Record<RelayoorErrorType, string> = {
  [RelayoorErrorType.FEE_NOT_PAID]: "Transaction fee is not paid",
  [RelayoorErrorType.BEAMNAME_NOT_AVAILABLE]: "Beamname is not available",
};

interface RpcError {
  error: {
    data: string;
  };
}

export class RelayoorError extends Error {
  public readonly type;
  constructor(type: RelayoorErrorType) {
    super(RelayoorErrorMsg[type]);
    this.type = type;
  }

  static get(error: unknown) {
    try {
      const data = (error as RpcError).error.data;
      switch (data) {
        case "name is already registered":
          return new RelayoorError(RelayoorErrorType.BEAMNAME_NOT_AVAILABLE);
        case "Flat fee transfer required":
          return new RelayoorError(RelayoorErrorType.FEE_NOT_PAID);
      }
    } catch (error) {
      // Not a Relayoor error
    }
  }
}
