import { ethers } from "ethers";
import isEqual from "lodash.isequal";
import { ShareStore } from "@tkey/common-types";

import { logError } from "@helpers/logError";

import { AuthMethod } from "@modules/web3auth/constants";
import {
  BURNER_WALLET_STORAGE_KEY,
  PRIVATE_KEY_LOCAL_STORAGE_KEY,
  SAVED_ACCESS_STORAGE_KEY,
  WEB3AUTH_PRIVATE_KEY_LOCAL_STORAGE_KEY,
} from "@modules/web3auth/constants/storage";

export type BasicUserInfo = { name: string; profileImage: string; typeOfLogin: AuthMethod };
type ServiceProviderPersistentData = BasicUserInfo & { postboxKey: string };

function getWeb3AuthStorageKey(wallet: ethers.Wallet) {
  return `${WEB3AUTH_PRIVATE_KEY_LOCAL_STORAGE_KEY}-${wallet.address.toLowerCase().substring(0, 10)}`;
}

export function getSavedAccessKey(pk: string) {
  const wallet = new ethers.Wallet(pk);
  return SAVED_ACCESS_STORAGE_KEY + wallet.address.toLowerCase().substring(0, 10);
}

export function getPersistentData(wallet = getWalletFromLocalStorage()) {
  if (!wallet) return;

  try {
    const storedOAuthShareOld = window.localStorage.getItem(WEB3AUTH_PRIVATE_KEY_LOCAL_STORAGE_KEY);
    if (storedOAuthShareOld) {
      window.localStorage.setItem(getWeb3AuthStorageKey(wallet), storedOAuthShareOld);
      window.localStorage.removeItem(WEB3AUTH_PRIVATE_KEY_LOCAL_STORAGE_KEY);
    }
  } catch (error) {
    logError("getPersistentData migration error", error);
  }

  try {
    const storedOAuthShare = window.localStorage.getItem(getWeb3AuthStorageKey(wallet));
    if (storedOAuthShare) {
      return JSON.parse(storedOAuthShare) as ServiceProviderPersistentData;
    }
  } catch (error) {
    logError("[getPersistentData]", error);
  }
  return undefined;
}

export function storePersistentData(wallet: ethers.Wallet, data: ServiceProviderPersistentData) {
  window.localStorage.setItem(getWeb3AuthStorageKey(wallet), JSON.stringify(data));
}

export function setSavedAccessCookie(pk: string, value = Date.now().toString()) {
  window.localStorage.setItem(getSavedAccessKey(pk), value);
}

export function hasSavedAccessCookie(pk: string): boolean {
  const oldKey = SAVED_ACCESS_STORAGE_KEY + pk.substring(0, 10);
  const oldKeyValue = window.localStorage.getItem(oldKey);
  if (oldKeyValue) {
    setSavedAccessCookie(pk, oldKeyValue);
    window.localStorage.removeItem(oldKey);
  }
  const newKey = window.localStorage.getItem(getSavedAccessKey(pk));

  return Boolean(newKey || oldKeyValue);
}

export function getPkFromLocalStorage() {
  return window.localStorage.getItem(PRIVATE_KEY_LOCAL_STORAGE_KEY);
}

export function getWalletFromLocalStorage() {
  const storedPK = getPkFromLocalStorage();
  if (storedPK) {
    try {
      return new ethers.Wallet(storedPK);
    } catch (error) {
      console.warn("stored PK is invalid, using new burner wallet");
    }
  }
}

export function saveToLocalstorage(privateKey: string) {
  const currentPrivateKey = getPkFromLocalStorage();
  if (currentPrivateKey) {
    window.localStorage.setItem(`${PRIVATE_KEY_LOCAL_STORAGE_KEY}_backup` + Date.now(), currentPrivateKey);
  }

  try {
    window.localStorage.setItem(PRIVATE_KEY_LOCAL_STORAGE_KEY, privateKey);
  } catch (e) {
    console.log("[saveToLocalstorage:error]", e);
  }
}

const PASSWORD_SHARE_LOCAL_KEY = "web3auth-password-share";

export function getStoredPasswordShares() {
  try {
    const shareString = window.localStorage.getItem(PASSWORD_SHARE_LOCAL_KEY);
    if (shareString) {
      const sharesArray = JSON.parse(shareString) as never[];
      return sharesArray.map(share => ShareStore.fromJSON(share));
    }
  } catch (error) {
    logError("[getStoredPasswordShares]", error);
  }
  return [];
}

export function savePasswordShare(share: ShareStore) {
  try {
    const shareStores = getStoredPasswordShares();

    const shareJson = share.toJSON();
    const storedSharesJson = shareStores.map(share => share.toJSON());

    const isDuplicated = storedSharesJson.some(shareJson => isEqual(share.toJSON(), shareJson));
    if (!isDuplicated) {
      window.localStorage.setItem(PASSWORD_SHARE_LOCAL_KEY, JSON.stringify([...storedSharesJson, shareJson]));
    }
  } catch (error) {
    logError("[savePasswordShare]", error);
  }
}

export function removeAllLocalStorage(wallet: ethers.Wallet) {
  window.localStorage.removeItem(getWeb3AuthStorageKey(wallet));
  window.localStorage.removeItem(getSavedAccessKey(wallet.privateKey));
  window.localStorage.removeItem(PASSWORD_SHARE_LOCAL_KEY);
  window.localStorage.removeItem(BURNER_WALLET_STORAGE_KEY);
  window.localStorage.removeItem(PRIVATE_KEY_LOCAL_STORAGE_KEY);
}
