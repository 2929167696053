import React from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Form, Input, Space } from "antd";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";

import BottomSheet, { BottomSheetProps } from "@components/bottomsheet/BottomSheet";
import { Button } from "@components/button/Button";

import { notify } from "@helpers";

import { useAppDispatch } from "@redux";
import { resetBalances } from "@redux/slides/balances/balances.slide";
import { setWeb3AuthSigner } from "@redux/slides/web3auth.slide";

import { Callout } from "@components/callout/Callout";
import { BodyText } from "@components/text/Text";
import { saveToLocalstorage } from "@modules/web3auth/helpers/storage";

type ImportPrivateKeyPopupProps = Pick<BottomSheetProps, "isOpen" | "onClose">;

export const ImportPrivateKeyPopup = (props: ImportPrivateKeyPopupProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const importPk = ({ pk }: { pk: string }) => {
    const wallet = new ethers.Wallet(pk);
    saveToLocalstorage(pk);
    dispatch(setWeb3AuthSigner(wallet.privateKey));
    dispatch(resetBalances());

    notify({
      duration: 5_000,
      content: (
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <CheckCircleOutlined height={14} style={{ marginTop: -3, marginRight: 6 }} />
          <div>Private key imported successfully</div>
        </div>
      ),
    });

    navigate("/");
  };

  return (
    <BottomSheet title="Import Private Key" {...props}>
      <Form
        form={form}
        onFinish={importPk}
        layout="vertical"
        style={{ color: "var(--color-foreground)" }}
        className="Form"
      >
        <Space direction="vertical" size={16}>
          <div style={{ textAlign: "right" }}>
            <div style={{ fontSize: 13 }}>
              <Form.Item
                name="pk"
                className="form-item"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Please input a private key",
                  },
                  () => ({
                    validator(_, pk) {
                      if ((typeof pk === "string" && pk.length === 64) || pk.length === 66)
                        try {
                          new ethers.Wallet(pk);
                          return Promise.resolve();
                        } catch (err) {
                          return Promise.reject(new Error("This is not a valid PK"));
                        }
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Private Key" className="input" />
              </Form.Item>
            </div>
          </div>
          <Callout>
            <BodyText>
              <strong>Warning: importing a private key will overwrite your current wallet.</strong> Please make sure you
              have saved access to it before importing a different private key.
            </BodyText>
          </Callout>
          <Button title="Import" htmlType="submit" />
        </Space>
      </Form>
    </BottomSheet>
  );
};
