import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { ethers } from "ethers";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { BEAMNAMES_ADDRESS, FAUCET_ADDRESS, FAUCET_HAPPY_HOUR_ADDRESS, Token } from "@constants";

import { isSameAddress } from "@helpers";
import { formatBigNumber, formatTokenAmount } from "@helpers/format";
import { isBeamInternalTxn, isCoinbaseTransaction } from "@helpers/transfer";

import { useStackup } from "@contexts/StackupContext";
import { useFactoryAddress } from "@contexts/FactoryAddressContext";

import { PEANUT_ADDRESSES } from "@modules/peanut/constants";
import { getTokenManagerByToken } from "@modules/token-managers/tokens";

import { getDisplayName, useDisplayName } from "@hooks/useDisplayName";
import { TransferCluster, useHistory } from "@hooks/useHistory";

import Row from "@components/row/Row";
import View from "@components/view/View";
import Card from "@components/card/Card";
import { Avatar } from "@components/avatar/Avatar";
import { Button } from "@components/button/Button";
import { Spinner } from "@components/spinner/Spinner";
import { BodyText, TextBlock } from "@components/text/Text";
import BottomSheet from "@components/bottomsheet/BottomSheet";

import { ReactComponent as ArrowUp } from "@assets/icons/arrow_up.svg";
import { ReactComponent as ChangeArrow } from "@assets/icons/change_arrow.svg";

import { InflationMultiplier } from "../../types/inflationMultiplier";
import { TransferDetails } from "./TransferDetails";
import styles from "./History.module.css";
import { RowGroup } from "@components/row/RowGroup";
import config from "@constants/config";
import { AnimatePresence, motion } from "framer-motion";
import { useAccount } from "@contexts/AccountContext";

const sortTransfers = (t1: TransferCluster, t2: TransferCluster) => (t1.timestamp - t2.timestamp > 0 ? -1 : 1);

export const History = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { inflationMultipliers: allInflationMultipliers } = useAccount();
  const { address } = useStackup();
  const oldFactoryAddress = useFactoryAddress();

  const [isDetailsSheetOpen, setDetailsSheetOpen] = useState(false);
  const [selectedTransferCluster, setSelectedTransferCluster] = useState<TransferCluster | null>(null);
  const [selectedInflationMultiplier, setSelectedInflationMultiplier] = useState<InflationMultiplier>();

  const { transferClusters: newTransferClusters, getMore, refetch, loading: loadingHistory } = useHistory(address);
  const { transferClusters: oldTransferClusters, loading: loadingOldHistory } = useHistory(oldFactoryAddress);

  const loading = loadingHistory || loadingOldHistory;

  const transferClusters = useMemo(() => {
    if (loading) return [];
    else return [...newTransferClusters, ...oldTransferClusters].sort(sortTransfers);
  }, [loading, newTransferClusters, oldTransferClusters]);

  const inflationMultipliers = useMemo(() => {
    if (loading) return [];

    return allInflationMultipliers.filter(inflationMultiplier =>
      transferClusters.findLast(
        transferCluster =>
          transferCluster.token.id === Token.ECO && transferCluster.timestamp < inflationMultiplier.timestamp,
      ),
    );
  }, [loading, allInflationMultipliers, transferClusters]);

  useEffect(() => {
    if (location.state && location.state.txHash) {
      refetch();
    }
  }, [location.state?.txHash]);

  useEffect(() => {
    if (selectedTransferCluster) {
      setSelectedInflationMultiplier(undefined);
    }
    setDetailsSheetOpen(!!selectedTransferCluster);
  }, [selectedTransferCluster]);

  useEffect(() => {
    if (selectedInflationMultiplier) {
      setSelectedTransferCluster(null);
    }
    setDetailsSheetOpen(!!selectedInflationMultiplier);
  }, [selectedInflationMultiplier]);

  useEffect(() => {
    const getTransferCluster = () => {
      let cluster: TransferCluster | null = null;
      if (location.state) {
        cluster =
          transferClusters.find(transferCluster =>
            transferCluster.transfers.find(transfer => transfer.txHash === location.state.txHash),
          ) || null;
      }
      return cluster;
    };
    if (location.state && location.state.txHash) {
      // there is an transfer in state
      const transfer = getTransferCluster();
      if (transfer) {
        // it exists, so select it
        setSelectedTransferCluster(transfer);
      } else {
        // it does not exist, don't select and remove state
        navigate("/history", { state: null });
      }
    }
  }, [transferClusters.length]);

  useEffect(() => {
    // selected transfer, no need to keep state
    if (selectedTransferCluster && location.state && location.state.txHash) {
      navigate("/history", { state: null });
    }
  }, [selectedTransferCluster?.id, location.state?.txHash]);

  return (
    <View headerTitle="History" metaTitle="History">
      <div>
        <AnimatePresence>
          {loading ? (
            <div
              style={{
                display: "flex",
                height: "var(--safe-area-height)",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner label="Loading history..." />
            </div>
          ) : transferClusters.length === 0 ? (
            <div
              style={{
                height: "var(--safe-area-height)",
                color: "var(--color-foreground-medium)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BodyText centered>Nothing yet</BodyText>
            </div>
          ) : (
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }}>
              <InfiniteScroll
                dataLength={transferClusters.length + inflationMultipliers.length}
                next={getMore}
                hasMore={true}
                loader={null}
                style={{ overflow: "visible" }}
              >
                {[
                  ...transferClusters
                    .filter(transferCluster => {
                      // Filter out migration from history
                      return !transferCluster.isMigration;
                    })
                    .map(transferCluster => ({
                      timestamp: transferCluster.timestamp,
                      render: (
                        <TransferRow
                          key={`transfer-cluster-${transferCluster.id}`}
                          address={address}
                          oldFactoryAddress={oldFactoryAddress}
                          transferCluster={transferCluster}
                          setSelectedTransferCluster={setSelectedTransferCluster}
                        />
                      ),
                    })),
                  ...inflationMultipliers.map(inflationMultiplier => ({
                    timestamp: inflationMultiplier.timestamp,
                    render: (
                      <RebaseRow
                        inflationMultiplier={inflationMultiplier}
                        setSelectedInflationMultiplier={setSelectedInflationMultiplier}
                      />
                    ),
                  })),
                ]
                  .sort(({ timestamp: a }, { timestamp: b }) => b - a)
                  .map(({ render }) => render)}
              </InfiniteScroll>
            </motion.div>
          )}
        </AnimatePresence>
        <BottomSheet
          isOpen={isDetailsSheetOpen}
          closeOnShimTap
          desktopDisplay="side"
          onAnimationEnd={isOpen => {
            if (!isOpen) {
              setSelectedTransferCluster(null);
              setSelectedInflationMultiplier(undefined);
            }
          }}
          footer={
            <div className={styles.buttonWrapper}>
              <Button
                title="Done"
                type="primary"
                onClick={() => {
                  setDetailsSheetOpen(false);
                }}
              />
              {selectedInflationMultiplier ? (
                <Button title="Learn more" href="/about#eco" secondary className={styles.rebaseLearnMore} />
              ) : null}
            </div>
          }
          onClose={() => {
            setDetailsSheetOpen(false);
          }}
          title={selectedTransferCluster ? "Transaction details" : "Details"}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "var(--size-medium)" }}>
            {selectedTransferCluster ? (
              <TransferDetails transferCluster={selectedTransferCluster} />
            ) : selectedInflationMultiplier ? (
              <RebaseDetails inflationMultiplier={selectedInflationMultiplier} />
            ) : null}
          </div>
        </BottomSheet>
      </div>
    </View>
  );
};

const RebaseRow: React.FC<{
  inflationMultiplier: InflationMultiplier;
  setSelectedInflationMultiplier: (_: InflationMultiplier) => void;
}> = ({ inflationMultiplier, setSelectedInflationMultiplier }) => {
  return (
    <Row
      className={styles.rebaseRow}
      key={`inflation-multiplier-${inflationMultiplier.id}`}
      hasHorizontalPadding={false}
      onClick={() => setSelectedInflationMultiplier(inflationMultiplier)}
      title="ECO global supply change"
      subtitle={moment(inflationMultiplier.timestamp * 1000).format("LT · MMM Do, YYYY")}
      leadingContent={<RebaseIcon />}
      trailingContent={
        <BodyText bold>
          {inflationMultiplier.percentDifference > 0 ? "+" : ""}
          {formatTokenAmount(inflationMultiplier.percentDifference, 2)}% ECO
        </BodyText>
      }
    />
  );
};

function isKnownAddress(addr: string) {
  const addresses = [
    ...Object.values(config.optimism.contracts),
    ...Object.values(config.base.contracts),
    ...PEANUT_ADDRESSES,
  ];
  return isSameAddress(addr, addresses);
}

const TransferRow: React.FC<{
  address: string | undefined;
  oldFactoryAddress: string | undefined;
  transferCluster: TransferCluster;
  setSelectedTransferCluster: React.Dispatch<SetStateAction<TransferCluster | null>>;
}> = ({ address, oldFactoryAddress, transferCluster, setSelectedTransferCluster }) => {
  const { vendingMachineSwap } = transferCluster;

  const isReceive =
    isSameAddress(transferCluster.to.address, address!) ||
    isSameAddress(transferCluster.to.address, oldFactoryAddress!);
  const isFaucet = isSameAddress(transferCluster.from.address, FAUCET_ADDRESS);
  const isFaucetHappyHour = isSameAddress(transferCluster.from.address, FAUCET_HAPPY_HOUR_ADDRESS);
  const isBeamNameRegistration = isSameAddress(transferCluster.to.address, BEAMNAMES_ADDRESS);

  const displayAccount = isReceive ? transferCluster.from : transferCluster.to;

  const displayName =
    isKnownAddress(displayAccount.address) || displayAccount.beamname
      ? getDisplayName(displayAccount.address, undefined, displayAccount.beamname)
      : useDisplayName(displayAccount.address);

  useEffect(() => {
    if (vendingMachineSwap && !(vendingMachineSwap.sent && vendingMachineSwap.received)) {
      console.error(
        "vending machine swap history record error: sent and/or received field are undefined",
        vendingMachineSwap,
      );
    }
  }, [vendingMachineSwap]);

  const trailingContent =
    vendingMachineSwap?.sent && vendingMachineSwap.received ? (
      <>
        <div>
          {"-"}
          {formatBigNumber(
            vendingMachineSwap.sent.amount.add(transferCluster.fee),
            vendingMachineSwap.sent.token.decimals,
            3,
          )}{" "}
          {vendingMachineSwap.sent.token.name}
        </div>
        <div style={{ color: "var(--color-foreground-medium)" }}>&nbsp;{"/"}&nbsp;</div>
        <div style={{ color: "var(--color-success)" }}>
          {"+"}
          {formatBigNumber(vendingMachineSwap.received.amount, vendingMachineSwap.received.token.decimals, 3)}{" "}
          {vendingMachineSwap.received.token.name}
        </div>
      </>
    ) : (
      <div style={{ color: isReceive ? "var(--color-success)" : undefined }}>
        {isReceive ? "+" : "-"}
        {formatBigNumber(transferCluster.amount.add(transferCluster.fee), transferCluster.token.decimals, 3)}{" "}
        {getTokenManagerByToken(transferCluster.token.id).symbol}
      </div>
    );

  const isBeamTransaction = isBeamInternalTxn(transferCluster);
  const _isCoinbaseTransaction = isCoinbaseTransaction(transferCluster.from.address);

  const title = ((): string => {
    const isBeamLink =
      transferCluster.beamlinks.length ||
      PEANUT_ADDRESSES.some(
        peanutAddress =>
          isSameAddress(peanutAddress, transferCluster.from.address) ||
          isSameAddress(peanutAddress, transferCluster.to.address),
      );

    if (isBeamLink) return `Beam Link ${isReceive ? "claimed" : "created"}`;
    if (isFaucet) return `Welcome gift`;
    if (isFaucetHappyHour) return `Happy Hour gift`;
    if (isBeamNameRegistration) return `Beam Name registration`;
    if (transferCluster.vendingMachineSwap?.received) return `ECO purchased`;
    if (transferCluster.vendingMachineSwap?.sent) return `ECO sold`;
    if (transferCluster.isMigration) return `Migrating ${getTokenManagerByToken(transferCluster.token.id).symbol}`;

    if (transferCluster.from.address === ethers.constants.AddressZero) return "Deposit";
    if (_isCoinbaseTransaction) return "Deposit from Coinbase";

    return displayName;
  })();

  return (
    <>
      <Row
        hasHorizontalPadding={false}
        title={title}
        onClick={() => setSelectedTransferCluster(transferCluster)}
        subtitle={moment(transferCluster.timestamp * 1000).format("LT · MMM Do, YYYY")}
        leadingContent={
          vendingMachineSwap ? (
            <GenericEcoIcon />
          ) : _isCoinbaseTransaction ? (
            <CoinbaseIcon />
          ) : isReceive ? (
            <ReceiveIcon address={transferCluster.from.address} showAsBeam={isBeamTransaction} />
          ) : (
            <SendIcon address={transferCluster.to.address} showAsBeam={isBeamTransaction} />
          )
        }
        trailingContent={trailingContent}
      />
    </>
  );
};

const GenericEcoIcon = () => (
  <div className={`${styles.transferIcon} ${styles.receiveIcon}`}>
    <Avatar address="" size={32} showAsEco />
  </div>
);

const ReceiveIcon = ({ address, showAsBeam }: { address: string; showAsBeam: boolean }) => (
  <div className={`${styles.transferIcon} ${styles.receiveIcon}`}>
    <Avatar address={address} size={32} showAsBeam={showAsBeam} />
    <span>
      <ArrowUp />
    </span>
  </div>
);

const CoinbaseIcon = () => (
  <div className={`${styles.transferIcon} ${styles.receiveIcon}`}>
    <Avatar address="" size={32} showAsCoinbase />
    <span>
      <ArrowUp />
    </span>
  </div>
);

const SendIcon = ({ address, showAsBeam }: { address: string; showAsBeam: boolean }) => (
  <div className={`${styles.transferIcon} ${styles.sendIcon}`}>
    <Avatar address={address} size={32} showAsBeam={showAsBeam} />
    <span>
      <ArrowUp />
    </span>
  </div>
);

const RebaseIcon = () => (
  <div className={`${styles.transferIcon} ${styles.rebaseIcon}`}>
    <Avatar address="" size={32} showAsEco />
    <span>
      <ChangeArrow />
    </span>
  </div>
);

const RebaseDetails: React.FC<{
  inflationMultiplier: InflationMultiplier;
}> = ({ inflationMultiplier }) => {
  return (
    <div
      style={{
        width: "100%",
        paddingBottom: "var(--size-medium)",
        display: "flex",
        flexDirection: "column",
        gap: "var(--size-medium)",
        overflow: "hidden",
      }}
    >
      <Card style="muted">
        <RowGroup>
          <Row
            deemphasizeTitle
            hasHorizontalPadding={false}
            title="Type"
            trailingContent={<BodyText bold>ECO global supply change</BodyText>}
          />
          <Row
            deemphasizeTitle
            hasHorizontalPadding={false}
            title="Amount"
            trailingContent={
              <BodyText bold>
                {inflationMultiplier.percentDifference > 0 ? "+" : ""}
                {formatTokenAmount(inflationMultiplier.percentDifference, 2)}% ECO
              </BodyText>
            }
          />
          <Row
            deemphasizeTitle
            hasHorizontalPadding={false}
            title="Date"
            trailingContent={
              <BodyText bold>{moment(inflationMultiplier.timestamp * 1000).format("LT · MMM Do, YYYY")}</BodyText>
            }
          />
        </RowGroup>
      </Card>
      <Card color="accent">
        <div className={styles.rebaseInfoCard}>
          <div style={{ display: "flex", alignItems: "center", gap: "var(--size-small)" }}>
            <InfoCircleOutlined />
            <BodyText bold>What&apos;s this?</BodyText>
          </div>
          <TextBlock>
            <BodyText small>
              This is a supply change event for the ECO currency. Supply adjustments are integral to the ECO currency
              design and are meant to help maintain ECO&apos;s purchasing power over time.
            </BodyText>
            <BodyText small>
              In this event, there was a <b>global</b> supply adjustment to ECO of{" "}
              {formatTokenAmount(inflationMultiplier.percentDifference, 2)}%, meaning every wallet holding ECO would
              adjust by the same amount.
            </BodyText>
          </TextBlock>
        </div>
      </Card>
    </div>
  );
};
