import { Token, UserToken } from "@constants";
import { TokenManager } from "@modules/token-managers/TokenManager";

export class EcoManager extends TokenManager {
  constructor() {
    super(UserToken.ECO, {
      symbol: "ECO",
      decimals: 18,
      composedBy: [Token.ECO],
    });
  }
}
