import { BodyText } from "@components/text/Text";
import * as FormPrimitive from "@radix-ui/react-form";
import classNames from "classnames";
import React from "react";
import styles from "./Form.module.css";

export interface FormProps extends Omit<FormPrimitive.FormProps, "onSubmit"> {
  children: React.ReactNode;
  onSubmit?: (_: Record<string, FormDataEntryValue>) => Promise<void>;
  innerRef?: React.RefObject<HTMLFormElement>;
  submitOnEnter?: boolean;
}

interface FormRowProps {
  children: React.ReactNode;
}

interface FormSubmitProps {
  title: string;
}

export const Form = ({ children, onSubmit, innerRef, submitOnEnter, ...rest }: FormProps) => {
  const handleSubmit: FormPrimitive.FormProps["onSubmit"] = e => {
    const data = Object.fromEntries(new FormData(e.currentTarget));
    onSubmit?.(data);
    e.preventDefault();
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    // Send form if `Enter` key is pressed
    if (event.key === "Enter") innerRef?.current?.requestSubmit();
  };

  return (
    <FormPrimitive.Form
      className={styles.form}
      onSubmit={handleSubmit}
      ref={innerRef}
      onKeyUp={submitOnEnter ? handleKeyUp : undefined}
      {...rest}
    >
      {children}
    </FormPrimitive.Form>
  );
};

export const FormRow = ({ children }: FormRowProps) => {
  return <div className={styles.formRow}>{children}</div>;
};

export const FormSubmit = ({ title }: FormSubmitProps) => {
  return <FormPrimitive.Submit className={classNames(styles.submit, "Button")}>{title}</FormPrimitive.Submit>;
};

export const FormTitle = ({ title }: FormSubmitProps) => {
  return (
    <BodyText bold className={styles.title}>
      {title}
    </BodyText>
  );
};
